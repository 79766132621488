import { Button, Dropdown, Menu, MenuProps, Space } from "antd";
import { Item } from "@formitas-ag/bimfiles-types/lib";
import { defineMessages, useIntl } from "react-intl";
import FileTag from "../../AllUsers/Timeline/Common/FileTag";

type Props = {
  files: Item["files"];
};

const messages = defineMessages({
  files: {
    id: "components.render.file-list.files",
    defaultMessage: "{count} Files",
    description: "File list render message",
  },
});

const FileRender = (props: Props) => {
  const intl = useIntl();

  const items: MenuProps["items"] = props.files.map((file) => {
    return {
      key: file.id,
      label: <FileTag file={file} fullWidth includeFileNameInTitle />,
    };
  });

  return (
    <Dropdown placement="bottom" menu={{ items: items }} trigger={["click"]}>
      <Space>
        <Button type="default" size="middle">
          {intl.formatMessage(messages.files, { count: props.files.length })}
        </Button>
      </Space>
    </Dropdown>
  );
};

export default FileRender;
