import { Button } from "antd";
import translations from "../../../../translations/constants/translations";
import { useIntl } from "react-intl";
import useCreateOrUpdateFiles from "../../../../hooks/useCreateOrUpdateFiles";
import { UpdatingFile } from "../../../../utils/types/updateFilesTypes";
import { useCreateOrUpdateItemStore } from "../../../../state/createOrUpdateItemStore";

type Props = {
  index: number;
  updatingFiles: UpdatingFile[];

  open: boolean;
  setOpen: (open: boolean) => void;
};

export const CreateOrUpdateFileModalFooter = (props: Props) => {
  const intl = useIntl();
  const mode = useCreateOrUpdateItemStore((state) => state.mode);
  const itemMode = useCreateOrUpdateItemStore((state) => state.itemMode);
  const { clearRevitExport, resetUpdatingFiles } = useCreateOrUpdateFiles();

  const hasChanged =
    props.updatingFiles.filter((f) => f.state !== "default").length > 0;

  const resetChanges = () => {
    resetUpdatingFiles(props.index);

    clearRevitExport();

    closeModal();
  };

  const closeModal = () => {
    props.setOpen(false);
  };

  /**
   * single mode:
   * - left button: reset changes
   * - right button: close modal (state change when changes are made)
   *
   * multiple mode:
   * - left button: reset changes (only when updating)
   * - right button: close modal (state change when changes are made)
   */

  const showResetButton =
    itemMode === "single" || (mode === "update" && itemMode === "multiple");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      {showResetButton && (
        <Button
          danger={hasChanged}
          type={"default"}
          disabled={!hasChanged}
          onClick={resetChanges}
        >
          {intl.formatMessage(
            translations.ui.upload.modals.singleFileModalCancel
          )}
        </Button>
      )}

      <div
        style={{
          flexGrow: 2,
        }}
      />

      <Button
        style={{
          float: "right",
        }}
        type={hasChanged ? "primary" : "default"}
        onClick={closeModal}
      >
        {intl.formatMessage(translations.ui.generic.close)}
      </Button>
    </div>
  );
};
