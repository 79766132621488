import { languageApiDrivenTable } from "@formitas-ag/formitable/lib/ApiDrivenTable.types";
import { defineMessages, IntlShape } from "react-intl";

const messages = defineMessages({
    actionsTitle: {
        id: "admin.common.actions_title",
        defaultMessage: "Actions",
        description: "Column title of the column with actions, including delete, edit etc. buttons"
    },
    cancel: {
        id: "admin.common.cancel",
        defaultMessage: "Cancel",
        description: "set a custom name for the cancel button underneath the data form"
    },
    cancelDeleteDialog: {
        id: "admin.common.cancel_delete_dialog",
        defaultMessage: "Cancel",
        description: "custom message for the cancel button in the dialog"
    },
    confirmDeleteDialog: {
        id: "admin.common.confirm_delete_dialog",
        defaultMessage: "Delete this object from the table?",
        description: "custom message for confirmation dialog"
    },
    delete: {
        id: "admin.common.delete",
        defaultMessage: "Delete",
        description: "custom message for the delete button itself",
    },
    createButton: {
        id: "admin.common.create_button",
        defaultMessage: "Create",
        description: "Title of the create button underneath the table, if editInfo is given",
    },
    createdConfirm: {
        id: "admin.common.created_confirm",
        defaultMessage: "Created",
        description: "confirmation upon successfully saved (created) data",
    },
    deletedMessage: {
        id: "admin.common.deleted_message",
        defaultMessage: "Object has been deleted from the table",
        description: "custom message for the deleted message",
    },
    editButton: {
        id: "admin.common.edit_button",
        defaultMessage: "Edit",
        description: "Title of the edit button in each row, if editInfo is given",
    },
    reloadButton: {
        id: "admin.common.reload_button",
        defaultMessage: "Reload",
        description: "reload Button title, none will only show the icon",
    },
    save: {
        id: "admin.common.save",
        defaultMessage: "Save",
        description: "custom text for save button underneath the data form"
    },
    savedError: {
        id: "admin.common.saved_error",
        defaultMessage: "Error while saving",
        description: "Error upon save ('save rejected: ')",
    },
    titleCreate: {
        id: "admin.common.title_create",
        defaultMessage: "Create",
        description: "header of the EditDrawer if shown in create mode",
    },
    titleEdit: {
        id: "admin.common.title_edit",
        defaultMessage: "Edit",
        description: "header of the EditDrawer if shown in edit mode",
    },
    updatedConfirm: {
        id: "admin.common.updated_confirm",
        defaultMessage: "Object has been updated",
        description: "confirmation upon successfully saved (updated) data",
    },
});

export const getApiDrivenTableLanguage = (intl: IntlShape): languageApiDrivenTable => {
    return {
        actionsTitle: intl.formatMessage(messages.actionsTitle),
        cancel: intl.formatMessage(messages.cancel),
        cancelDeleteDialog: intl.formatMessage(messages.cancelDeleteDialog),
        confirmDeleteDialog: intl.formatMessage(messages.confirmDeleteDialog),
        delete: intl.formatMessage(messages.delete),
        createButton: intl.formatMessage(messages.createButton),
        createdConfirm: intl.formatMessage(messages.createdConfirm),
        deletedMessage: intl.formatMessage(messages.deletedMessage),
        editButton: intl.formatMessage(messages.editButton),
        reloadButton: intl.formatMessage(messages.reloadButton),
        save: intl.formatMessage(messages.save),
        savedError: intl.formatMessage(messages.savedError),
        titleCreate: intl.formatMessage(messages.titleCreate),
        titleEdit: intl.formatMessage(messages.titleEdit),
        updatedConfirm: intl.formatMessage(messages.updatedConfirm),
    };
};