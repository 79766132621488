import { useOverwriteItemStore } from "../../state/overwriteItemStore";
import { useCreateOrUpdateItemStore } from "../../state/createOrUpdateItemStore";
import { getLogger } from "../../utils/logger.utils";
import {
  UpdatingAddedFile,
  UpdatingAddedFileUserSource,
  UpdatingFile,
} from "../../utils/types/updateFilesTypes";
import useOverwriteItem from "../useOverwriteItem";
import useGetFileMeta from "./useGetFileMeta";
import { useCreateOrUpdateMultipleFiles } from "../useCreateOrUpdateMultipleFiles";

const logger = getLogger("useAddAnyFile");

const useAddAnyFile = () => {
  const { getName, getRole, getProperties } = useGetFileMeta();
  const { addOverwritingFiles } = useOverwriteItem();
  const { getUpload, setUpload, setItem } = useCreateOrUpdateMultipleFiles();

  const _onOverwriteFileLocalUpload = (
    overwrittenFile: UpdatingFile,
    overwritingFile: UpdatingAddedFile,
    index: number
  ) => {
    const addedFile = overwritingFile.addedFile as UpdatingAddedFileUserSource;

    logger.debug(
      `File '${addedFile.userFile.name}' is overwriting file '${getName(
        overwrittenFile
      )}' [${index}]`
    );

    addOverwritingFiles({
      type: "single",
      index,
      file: overwritingFile,
      overwrittenFile,
    });
  };

  const addAnyFile = (file: UpdatingAddedFile, index?: number) => {
    index = index ?? -1;

    const upload = getUpload(index);

    if (!upload) {
      logger.error(
        `Tried adding a file but the upload with index ${index} doesn't exist`
      );
      return;
    }

    const name =
      file.addedFile.source === "user"
        ? file.addedFile.userFile.name
        : file.addedFile.name;

    logger.debug(
      `Adding file '${name}' as state=added with source=${file.addedFile.source} [${index}]`
    );

    const role = getRole(index, file);

    if (role.result !== "success") {
      if (role.result === "error") {
        logger.error(
          `Error while fetching role for new file '${name}': ${role.message} (${role.id}) [${index}]`
        );
        return;
      }

      if (role.result === "overwrite") {
        _onOverwriteFileLocalUpload(role.overwrittenFile, file, index);
        return;
      }
    }

    const sameNameUpdatingFile = upload.updatingFiles.find(
      (updatingFile) => getName(updatingFile) === name
    );

    if (sameNameUpdatingFile) {
      //if we have a file with the same name we need to check if it's role is special as we then can ignore that it has the same name
      const sameNameUpdatingFileRole = getRole(
        index,
        sameNameUpdatingFile,
        "skipDetectingRole"
      );

      if (sameNameUpdatingFileRole.result === "success") {
        //if the role is not special it is considered an overwrite
        if (sameNameUpdatingFileRole.role === "other") {
          _onOverwriteFileLocalUpload(sameNameUpdatingFile, file, index);
          return;
        }
      } else if (sameNameUpdatingFileRole.result === "error") {
        logger.error(
          `Error while fetching role for file with same name '${name}': ${sameNameUpdatingFileRole.message} (${sameNameUpdatingFileRole.id}) [${index}]`
        );
        return;
      } else {
        logger.error(
          `Reached an invalid state while fetching role for file with same name '${name} [${index}]`
        );
      }
    }

    setUpload(index, {
      updatingFiles: getUpload(index)!.updatingFiles.concat(file),
    });

    logger.debug(
      `Creating file '${name}' as state=added with source=user [${index}]`
    );
  };

  return { addAnyFile };
};
export default useAddAnyFile;
