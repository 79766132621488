import axios from "axios";
import { FileWithUrl } from "@formitas-ag/bimfiles-types/lib/file";
import { File as BimFilesFile } from "@formitas-ag/bimfiles-types/lib/file";
import { useState, useCallback } from "react";
import api from "../api/api";
import { Item } from "@formitas-ag/bimfiles-types/lib";
import { Buffer } from "buffer";
import {
  UpdatingAddedFile,
  UpdatingDefaultFile,
  UpdatingFile,
  UpdatingReplacedFile,
} from "../utils/types/updateFilesTypes";
import useGetFileMeta from "./useCreateOrUpdateFiles/useGetFileMeta";
import { convertBase64ToBlob } from "../components/AllUsers/HomepageComponents/Upload/RevitExport/RevitExport";
import { fileToArrayBuffer } from "../utils/file.utils";
import { useCreateOrUpdateMultipleFiles } from "./useCreateOrUpdateMultipleFiles";

type Props = {
  index?: number;
  source:
    | {
        type: "default";
        id: Item["id"];
        files: Item["files"];
      }
    | {
        type: "whileUpdating";
        files: UpdatingFile[];
      };
  setModalOpen?: (open: boolean) => void;
};

const useSidecarFiles = (props: Props) => {
  const { unmapParentAndChildrenFiles, getRole } = useGetFileMeta();
  const { getUpload } = useCreateOrUpdateMultipleFiles();

  const index = props.index ?? -1;
  const { source } = props;

  const oldItem = getUpload(index)?.oldItem;

  const [sidecarData, setSidecarData] = useState<string | null>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const defaultRoles =
    source.type === "default" ? source.files.map((f) => f.role) : [];

  const whileUpdatingRoles =
    source.type === "whileUpdating"
      ? unmapParentAndChildrenFiles(index, source.files).map((f) => {
          const role = getRole(index, f);

          if (role.result !== "error") return role.role;
          return "other";
        })
      : [];

  const roles = defaultRoles
    .concat(whileUpdatingRoles)
    .filter((r) => r === "sidecar" || r === "sidecar-automatic") as Exclude<
    FileWithUrl["role"],
    "thumbnail" | "family" | "revit" | "other"
  >[];

  // Check if item doesn't have
  // any sidecar file
  const hasNoSidecar: boolean = roles.length === 0;

  // Check if item has a sidecar file
  // not sidecar-automatic
  const hasCustomSidecar: boolean = roles.includes("sidecar");

  // Checks if an item has a sidecar or sidecar-automatic
  // to enable or disable the button
  const hasCustomOrAutomaticSidecar: boolean = roles.length > 0;

  const cleanup = useCallback(() => {
    setSidecarData(null);
  }, []);

  const _getContentFromFile = async (
    file: UpdatingFile
  ): Promise<string | undefined> => {
    if (file.state === "added") {
      const addedFile = file as UpdatingAddedFile;

      if (addedFile.addedFile.source === "addon") {
        return await convertBase64ToBlob(addedFile.addedFile.base64).text();
      }

      if (addedFile.addedFile.source === "user") {
        const buffer = await fileToArrayBuffer(addedFile.addedFile.userFile);
        return Buffer.from(buffer).toString("utf8");
      }
    }

    if (file.state === "replaced") {
      const replacedFile = file as UpdatingReplacedFile;

      if (replacedFile.newFile.addedFile.source === "addon") {
        return await convertBase64ToBlob(
          replacedFile.newFile.addedFile.base64
        ).text();
      }

      if (replacedFile.newFile.addedFile.source === "user") {
        const buffer = await fileToArrayBuffer(
          replacedFile.newFile.addedFile.userFile
        );
        return Buffer.from(buffer).toString("utf8");
      }
    }

    return undefined;
  };

  const _downloadFile = async (
    file: BimFilesFile | UpdatingFile
  ): Promise<string | undefined> => {
    const actualFile: BimFilesFile =
      source.type === "default"
        ? (file as BimFilesFile)
        : (file as UpdatingDefaultFile).file;

    const fileWithUrl = await api.items.getFileWithUrl(
      source.type === "default" ? source.id : oldItem!.id,
      actualFile.id,
      {
        includeUnapproved: true,
      }
    );

    const response = await axios.get(fileWithUrl.url, {
      responseType: "arraybuffer",
      responseEncoding: "binary",
    });

    if (!response.data) {
      return undefined;
    }

    return response.data as string;
  };

  const _load = async (sidecarContent: string) => {
    // Convert response data to utf16le
    let encodedString = Buffer.from(sidecarContent).toString("utf8");

    // Checks if windows-1251 or windows-1252 encoding
    // by finding 䈻 or 〻 character after utf16le conversion
    if (
      encodedString.includes("䈻") ||
      encodedString.includes("〻") ||
      encodedString.includes("⌣")
    ) {
      // Convert it to latin1 which is a readable encoding
      encodedString = Buffer.from(sidecarContent).toString("latin1");
    }

    if (encodedString.includes("Ã")) {
      encodedString = Buffer.from(sidecarContent).toString("utf8");
    }

    setSidecarData(encodedString);

    setLoading(false);
  };

  /**
   * Tries loading a string based on an item
   */
  const _prepareLoadDefault = async (
    files: Item["files"]
  ): Promise<string | undefined> => {
    setLoading(true);

    const roleAndIds = files.map((f) => ({
      role: f.role,
      id: f.id,
    }));

    if (roleAndIds.length === 0) {
      setLoading(false);
      return;
    }

    if (hasCustomOrAutomaticSidecar && props.setModalOpen) {
      props.setModalOpen(true);
    }

    const selectedIdAndRole = roleAndIds.reduce(
      (previousValue, currentValue) => {
        if (previousValue.role === "sidecar") {
          return previousValue;
        }

        if (
          currentValue.role === "sidecar" ||
          currentValue.role === "sidecar-automatic"
        ) {
          return currentValue;
        }

        if (previousValue.role === "sidecar-automatic") {
          return previousValue;
        }

        return currentValue;
      }
    );

    const selectedFile = files.find((f) => f.id === selectedIdAndRole.id);

    if (!selectedFile) {
      setLoading(false);
      return;
    }

    return await _downloadFile(selectedFile);
  };

  const _prepareLoadWhileUpdating = async (
    files: UpdatingFile[]
  ): Promise<string | undefined> => {
    const unmappedFiles = unmapParentAndChildrenFiles(index, files);

    if (unmappedFiles.length === 0) {
      setLoading(false);
      return;
    }

    const roleAndIds = unmappedFiles
      .map((f) => {
        const role = getRole(index, f);

        if (role.result !== "error") {
          return {
            role: role.role,
            id: f.id,
          };
        } else {
          return {
            role: "other",
            id: f.id,
          };
        }
      })
      .filter((r) => r.role !== "other");

    if (roleAndIds.length === 0) {
      setLoading(false);
      return;
    }

    if (hasCustomOrAutomaticSidecar && props.setModalOpen) {
      props.setModalOpen(true);
    }

    const selectedIdAndRole = roleAndIds.reduce(
      (previousValue, currentValue) => {
        if (previousValue.role === "sidecar") {
          return previousValue;
        }

        if (
          currentValue.role === "sidecar" ||
          currentValue.role === "sidecar-automatic"
        ) {
          return currentValue;
        }

        if (previousValue.role === "sidecar-automatic") {
          return previousValue;
        }

        return currentValue;
      }
    );

    const selectedFile =
      selectedIdAndRole.role === "sidecar" ||
      selectedIdAndRole.role === "sidecar-automatic"
        ? unmappedFiles.find((f) => f.id === selectedIdAndRole.id)
        : undefined;

    if (!selectedFile) {
      setLoading(false);
      return;
    }

    if (selectedFile.state === "default") {
      return await _downloadFile(selectedFile);
    } else if (
      selectedFile.state === "added" ||
      selectedFile.state === "replaced"
    ) {
      return await _getContentFromFile(selectedFile);
    } else {
      return undefined;
    }
  };

  const load = async () => {
    if (source.type === "default") {
      const potentialSidecarContent = await _prepareLoadDefault(source.files);

      if (!potentialSidecarContent) {
        setLoading(false);
        return;
      }

      await _load(potentialSidecarContent);
    } else if (source.type === "whileUpdating") {
      const potentialSidecarContent = await _prepareLoadWhileUpdating(
        source.files
      );

      if (!potentialSidecarContent) {
        setLoading(false);
        return;
      }

      await _load(potentialSidecarContent);
    }
  };

  return {
    cleanup,
    loading,
    load,
    sidecarData,
    hasWhichSidecar: {
      no: hasNoSidecar,
      custom: hasCustomSidecar,
      automaticOrCustom: hasCustomOrAutomaticSidecar,
    },
  };
};

export default useSidecarFiles;
