import { useState } from "react";
import { Form, Input, Button, Alert, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { defineMessages, useIntl } from "react-intl";
import api from "../../../../api/api";
import ButtonProps from "../../../../UiProperties/ButtonProps";

const PasswordStartResetForm = () => {
  const intl = useIntl();
  const [state, setState] =
    useState<"loading" | "error" | "success" | "default">("default");

  const [error, setError] =
    useState<"AccountInvalid" | "AlreadyResetting" | undefined>(undefined);

  const messages = defineMessages({
    description: {
      id: "auth.password-start-reset-form.description",
      defaultMessage:
        "Enter the email adress of your BIMfiles account to reset your password.",
      description: "Description of the password start reset form",
    },
    emailRequired: {
      id: "auth.password-start-reset-form.email.required",
      defaultMessage: "Email required",
      description: "Error message when email is not provided",
    },
    emailFormat: {
      id: "auth.password-start-reset-form.email.format",
      defaultMessage: "Input needs to be a valid email",
      description: "Error message when email in the wrong format",
    },
    emailPlaceholder: {
      id: "auth.password-start-reset-form.email.placeholder",
      defaultMessage: "name@company.com",
      description: "Placeholder for email input",
    },
    action: {
      id: "auth.password-start-reset-form.action",
      defaultMessage: "Reset password",
      description: "Action button text",
    },
    successTitle: {
      id: "auth.password-start-reset-form.success.title",
      defaultMessage: "Password reset email sent",
      description: "Title of the success message",
    },
    successDescription: {
      id: "auth.password-start-reset-form.success.description",
      defaultMessage:
        "We've sent you an email with further instructions to reset your password.",
      description: "Description of the success message",
    },
  });

  /**
   * Called when the form is filled correctly
   * @param values from the form (email and password)
   */
  const onFinish = async (values: any) => {
    setState("loading");

    try {
      await api.users.startPasswordReset(values.email);

      setState("success");
    } catch (error) {
      setState("error");
      console.log(error);
    }
  };

  /**
   * Runs when the form fails to submit
   * @param errorInfo of the failed form
   */
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ margin: "0rem 1rem 0rem 1rem" }}>
      {state !== "success" && (
        <Form
          name="basic"
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div
            style={{
              width: "100%",
              background: "#fff",
              fontSize: "1.2rem",
              padding: "1rem",
              marginBottom: "0.4rem",
            }}
          >
            <Typography.Text type="secondary">
              {intl.formatMessage(messages.description)}
            </Typography.Text>
          </div>

          <Form.Item
            style={{ marginBottom: "0.4rem" }}
            name="email"
            rules={[
              {
                required: true,
                message: intl.formatMessage(messages.emailRequired),
              },
              {
                type: "email",
                message: intl.formatMessage(messages.emailFormat),
              },
            ]}
          >
            <Input
              size="large"
              style={{ borderRadius: 0, height: "3rem" }}
              placeholder={intl.formatMessage(messages.emailPlaceholder)}
              spellCheck={false}
            />
          </Form.Item>

          {state === "error" ? <Alert message={error} type="error" /> : null}

          <div className="login-form-button-spinner">
            <Form.Item>
              <Button
                size="large"
                type="dashed"
                style={{
                  height: "3rem",
                  width: "100%",
                  border: "0.2rem solid #fff",
                  borderRadius: 0,
                  color: "#fff",
                  fontSize: "1.2rem",
                  textTransform: "uppercase",
                  ...ButtonProps.ghost,
                }}
                htmlType="submit"
              >
                {state === "loading" ? (
                  <Spin
                    indicator={
                      <LoadingOutlined style={{ fontSize: 24 }} spin />
                    }
                  ></Spin>
                ) : (
                  intl.formatMessage(messages.action)
                )}
              </Button>
            </Form.Item>
          </div>
        </Form>
      )}
      {state === "success" && (
        <Alert
          message={intl.formatMessage(messages.successTitle)}
          description={intl.formatMessage(messages.successDescription)}
          type="success"
          showIcon
        />
      )}
    </div>
  );
};

export default PasswordStartResetForm;
