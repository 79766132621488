import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useQuery } from "react-query";
import { Change } from "@formitas-ag/bimfiles-types";
import { Card, Tag, Tooltip, Typography } from "antd";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { ItemImage } from "../../HomepageComponents/ItemImage";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import TimelineChangeWrapper from "../Changes/Common/TimelineChangeWrapper";
import ApproveOrDenyButton from "../Common/ApproveOrDenyButton";
import TimelineChanges from "../Changes/TimelineChanges";
import Can from "../../../../api/ability/Can";
import api from "../../../../api/api";
import { CategoryRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";

type Props = {
  item: ItemPopulated;
  at: Change["at"];
  by: Change["by"];
  state: Change["state"];
  changes: Change[];
};

const TimelineGroup = (props: Props) => {
  const intl = useIntl();

  const byQuery = useQuery({
    queryKey: ["user", props.by],
    queryFn: async () => {
      return await api.users.getOne(props.by);
    },
  });

  const messages = defineMessages({
    stateApproved: {
      id: "component.timeline.state.approved",
      defaultMessage: "These changes were approved",
      description: "Tooltip for the state of a change",
    },
    stateDenied: {
      id: "component.timeline.state.denied",
      defaultMessage: "These changes were denied",
      description: "Tooltip for the state of a change",
    },
    stateUnknown: {
      id: "component.timeline.state.unknown",
      defaultMessage: "These changes are not yet approved",
      description: "Tooltip for the state of a change",
    },
    changedAt: {
      id: "component.timeline.group.title",
      defaultMessage: "Changed at {date}",
      description: "Title of a group of changes",
    },
    thumbnailError: {
      id: "component.timeline.group.thumbnail.error",
      defaultMessage: "Thumbnail could not be loaded",
      description: "Error message when the thumbnail could not be loaded",
    },
    thumbnailLoading: {
      id: "component.timeline.group.thumbnail.loading",
      defaultMessage: "Loading thumbnail...",
      description: "Loading message when the thumbnail is loading",
    },
    author: {
      id: "component.timeline.group.author",
      defaultMessage: "Author",
      description: "Label for the author of a change",
    },
    unknownAuthor: {
      id: "component.timeline.group.author.unknown",
      defaultMessage: "Unknown",
      description: "Title of any unknown authors",
    },
  });

  const dotFontSize = "20px";
  const dot =
    props.state === "approved" ? (
      <Tooltip
        color="#f7f8fc"
        title={intl.formatMessage(messages.stateApproved)}
      >
        <CheckCircleOutlined
          style={{ fontSize: dotFontSize, color: "green" }}
        />
      </Tooltip>
    ) : props.state === "denied" ? (
      <Tooltip
        color="#f7f8fc"
        title={intl.formatMessage(messages.stateApproved)}
      >
        <CloseCircleOutlined style={{ fontSize: dotFontSize, color: "red" }} />
      </Tooltip>
    ) : (
      <Tooltip
        color="#f7f8fc"
        title={intl.formatMessage(messages.stateUnknown)}
      >
        <QuestionCircleOutlined
          style={{ fontSize: dotFontSize, color: "#888888" }}
        />
      </Tooltip>
    );

  const thumbnailQuery = useQuery(
    ["thumbnail", props.item.id],
    async () => {
      if (props.item.files.some((file) => file.role === "thumbnail"))
        return await api.items.getThumbnail(props.item.id);
    },
    {
      refetchInterval: 25 * 60 * 1000,
    }
  );

  const hasThumbnail = props.item.files.some(
    (file) => file.role === "thumbnail"
  );

  return (
    <>
      <Card
        key={props.at.toString()}
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "1rem" }}>{dot}</div>

            <Typography.Title level={3} style={{ margin: 0, width: "100%" }}>
              {intl.formatMessage(messages.changedAt, {
                date: intl.formatDate(props.at, {
                  dateStyle: "long",
                  timeStyle: "short",
                }),
              })}
            </Typography.Title>

            {props.state === "approvable" && (
              <>
                <Can
                  mode="item"
                  minimumRole={CategoryRoleEnum.APPROVE}
                  itemId={props.item.id}
                >
                  <div style={{ marginLeft: "1rem" }}>
                    <ApproveOrDenyButton
                      type="approve"
                      title="Approve"
                      item={props.item}
                    />
                  </div>
                </Can>
                <Can
                  mode="item"
                  minimumRole={CategoryRoleEnum.APPROVE}
                  itemId={props.item.id}
                >
                  <div style={{ marginLeft: "1rem" }}>
                    <ApproveOrDenyButton
                      type="deny"
                      title="Deny"
                      item={props.item}
                    />
                  </div>
                </Can>
              </>
            )}
          </div>
        }
        style={{ width: "100%" }}
        bodyStyle={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
      >
        {hasThumbnail && (
          <Card.Grid
            hoverable={false}
            style={{
              width: "calc(10rem + 24px + 24px)",
            }}
          >
            <div style={{ width: "10rem" }}>
              {thumbnailQuery.isLoading && (
                <p>
                  <FormattedMessage {...messages.thumbnailLoading} />
                </p>
              )}
              {thumbnailQuery.isError && (
                <p>
                  <FormattedMessage {...messages.thumbnailError} />
                </p>
              )}
              {thumbnailQuery.isSuccess && thumbnailQuery.data && (
                <ItemImage
                  item={props.item}
                  customStyle={{
                    height: "10rem",
                    width: "10rem",
                    objectFit: "contain",
                  }}
                  iconSize="large"
                />
              )}
            </div>
          </Card.Grid>
        )}

        <Card.Grid
          hoverable={false}
          style={{
            flexGrow: "4",
            padding: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TimelineChangeWrapper
            titleChild={
              <Typography.Text>
                <FormattedMessage {...messages.author} />
              </Typography.Text>
            }
          >
            {!byQuery.isSuccess && (
              <Tooltip
                color="#f7f8fc"
                title={"Dieser Benutzer ist nicht bekannt"}
              >
                <Tag color={"#f7f8fc"}>
                  <FormattedMessage {...messages.unknownAuthor} />
                </Tag>
              </Tooltip>
            )}
            {byQuery.isSuccess && (
              <Tooltip color="#f7f8fc" title={byQuery.data.email}>
                <Tag color={"#888888"}>
                  {byQuery.data.preName + " " + byQuery.data.name}
                </Tag>
              </Tooltip>
            )}
          </TimelineChangeWrapper>

          <TimelineChanges changes={props.changes} item={props.item} />
        </Card.Grid>
      </Card>
    </>
  );
};

export default TimelineGroup;
