import { CreateItemData, UpdateItemData } from "@formitas-ag/bimfiles-types/lib/item";
import api from "../../api/api";
import {
  UploadType,
  useCreateOrUpdateItemStore,
} from "../../state/createOrUpdateItemStore";
import { useViewStore } from "../../state/viewStore";
import { useState } from "react";
import useUploadFinalFiles from "./useUploadFinalFiles";
import { getLogger } from "../../utils/logger.utils";
import { useCreateOrUpdateMultipleFiles } from "../useCreateOrUpdateMultipleFiles";
import useGetFileMeta from "./useGetFileMeta";
import useCreateOrUpdateFiles from "../useCreateOrUpdateFiles";
import useUploadNewFiles from "./useUploadFinalFiles/useUploadNewFiles";
import useValidateUpload from "./useValidateUpload";
import {
  UpdatingAddedFile,
  UpdatingDefaultFile,
  UpdatingReplacedFile,
} from "../../utils/types/updateFilesTypes";
import { rootQueryClient } from "../..";

const logger = getLogger("useUpdateItem");

export default () => {
  const mode = useCreateOrUpdateItemStore((state) => state.mode);
  const { getName, getRoleWithDefault, unmapParentAndChildrenFiles } =
    useGetFileMeta();
  const { getUpload, setUpload } = useCreateOrUpdateMultipleFiles();
  const { stopCreateOrUpdate } = useCreateOrUpdateFiles();
  const { uploadNewFile } = useUploadNewFiles();
  const { validateUpload } = useValidateUpload();

  const [isUpdating, setIsUpdating] = useState(false);
  const { uploadFileChanges } = useUploadFinalFiles();

  const _cleanItem = (upload: UploadType) => {
    if (!upload.item) return;

    let filteredUpdatedItem = { ...upload.item };
    const { oldItem, item } = upload;

    /**
     * Loop through all object properties and remove any undefined
     * fields or empty arrays or if the new updated state is equal
     * to the original state
     */
    for (const [key, value] of Object.entries(upload.item!)) {
      // Get selected populatedTags as an array of tagsIds
      const tagsIds = oldItem ? oldItem?.tags : undefined;

      const previosTagsCheck =
        JSON.stringify(tagsIds) ===
        JSON.stringify(item[key as keyof Partial<UpdateItemData>]);

      const previousFieldCheck =
        JSON.stringify(
          oldItem
            ? key in oldItem
              ? oldItem[key as keyof Partial<UpdateItemData>]
              : undefined
            : undefined
        ) === JSON.stringify(item[key as keyof Partial<UpdateItemData>]);

      if (
        value === undefined ||
        previousFieldCheck ||
        previosTagsCheck ||
        JSON.stringify(value) === "[]"
      ) {
        const newItem: Partial<UpdateItemData> = { ...item };
        delete newItem[key as keyof Partial<UpdateItemData>];
        filteredUpdatedItem = { ...newItem };
      }
    }

    return filteredUpdatedItem as Partial<UpdateItemData>;
  };

  const _saveUpdate = async (index: number): Promise<boolean> => {
    const upload = getUpload(index);

    if (!upload) {
      logger.error(
        `Tried updating an item but the upload with index ${index} doesn't exist`
      );
      return false;
    }

    const { item, oldItem } = upload;
    const unmappedUpdatingFiles = unmapParentAndChildrenFiles(
      index,
      upload.updatingFiles
    );

    if (mode !== "update" || !item || !oldItem) {
      logger.error(`Invalid state for saveUpdate.`, {
        mode,
        item,
        oldItem,
      });
      return false;
    }

    const validationResult = validateUpload(upload);

    if (!validationResult.success) {
      logger.error(
        `Failed upload validation due to '${validationResult.error}'`,
        {
          upload,
        }
      );
      return false;
    }

    logger.debug(`Updating the item [${index}]`, {
      item,
    });

    setIsUpdating(true);

    //technically we should only have added or replaced files here so this is more a type conversion
    const filesThatRequireUploading = unmappedUpdatingFiles.filter(
      (f) => f.state === "added" || f.state === "replaced"
    ) as (UpdatingAddedFile | UpdatingReplacedFile)[];
    const filesThatAreUnchanged = unmappedUpdatingFiles.filter(
      (f) => f.state === "default"
    ) as UpdatingDefaultFile[];

    logger.debug(`Uploading files for the item`, {
      item,
      filesThatRequireUploading,
    });

    //we first convert the files into an api friendly format
    const apiFriendlyFiles = filesThatRequireUploading.map<
      CreateItemData["files"][0]
    >((f) => {
      const name = getName(f);
      const roleResult = getRoleWithDefault(index, f);

      return {
        name,
        role: roleResult,
      };
    });

    const parameters = filesThatRequireUploading
      .filter(
        (file) =>
          (file.state === "added" && file.addedFile.source === "addon") ||
          (file.state === "replaced" &&
            file.newFile.addedFile.source === "addon")
      )
      .reduce<object | undefined>((_, file) => {
        if (file.state === "added" && file.addedFile.source === "addon") {
          return file.addedFile.parameters;
        }

        if (
          file.state === "replaced" &&
          file.newFile.addedFile.source === "addon"
        ) {
          return file.newFile.addedFile.parameters;
        }

        return undefined;
      }, undefined);

    const cleanedItem = _cleanItem(upload);

    const updatedItem = await api.items.update(oldItem.id, {
      ...cleanedItem,
      files: apiFriendlyFiles.concat(filesThatAreUnchanged.map((f) => f.file)),
      parameters: item.parameters ?? parameters,
    });

    logger.debug(
      `Updated the item with id ${updatedItem.item.id}. Will now upload the files to S3.`,
      {
        updatedItem,
      }
    );

    try {
      //we now upload the files to S3
      const uploadedFiles = await uploadNewFile(
        index,
        filesThatRequireUploading,
        async (f) => {
          const fileWithUrl = updatedItem.uploadUrls.find(
            (u) => u.name === getName(f)
          );

          if (!fileWithUrl) {
            throw new Error(`Could not find the upload url for file ${f.id}.`);
          }

          return fileWithUrl;
        }
      );

      setIsUpdating(false);

      if (uploadedFiles.length !== filesThatRequireUploading.length) {
        logger.error(`Not all files were uploaded.`, {
          uploadedFiles,
          filesThatRequireUploading,
        });
        throw new Error(`Not all files were uploaded.`);
      }
      logger.debug(`Finished uploading files [${index}].`, {
        uploadedFiles,
      });

      return true;
    } catch (error) {
      logger.error(
        `Couldn't upload files for item ${updatedItem.item.id}. Aborting upload.`
      );

      //await api.items.cancelUpload(updatedItem.item.id);

      //TODO: Add a way for the latest changes to be removed

      return false;
    }
  };

  /**
   * Updates a single upload
   */
  const saveUpdate = async () => {
    const uploads = useCreateOrUpdateItemStore.getState().uploads;

    logger.debug(`Updating all items with length=${uploads.length}`);

    for (const upload of uploads) {
      const index = upload.index;

      if (validateUpload(upload).success === false) {
        //we can safely ignore this upload as it has no changes
        logger.debug(
          `Ignored upload with index ${index} as it has no changes.`
        );
        continue;
      }

      let result = false;
      if (index === 0) {
        result = await _saveUpdate(-1);
      } else {
        result = await _saveUpdate(index);
      }

      if (!result) {
        return;
      }
    }

    logger.debug(`Finished uploading all files. Cleaning up.`);

    //TODO: Add popup for stats

    //update all items
    rootQueryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === "items",
    });

    stopCreateOrUpdate();
  };

  return {
    isUpdating,
    saveUpdate,
  };
};
