import { Card, Typography } from "antd";
import { ReasonChange } from "@formitas-ag/bimfiles-types/lib/change";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
  change: ReasonChange;
};

const TimelineReasonChange = (props: Props) => {
  const messages = defineMessages({
    title: {
      id: "component.timeline.reason_change.title",
      defaultMessage: "Reason for change",
      description: "Title of the reason for change of changes",
    },
  });

  return (
    <Card.Grid
      hoverable={false}
      style={{ padding: "12px", width: "100%", flexGrow: 2 }}
    >
      <Typography.Title level={5}>
        <FormattedMessage {...messages.title} />
      </Typography.Title>

      {`${props.change.type}: ${props.change.reason}`}
    </Card.Grid>
  );
};

export default TimelineReasonChange;
