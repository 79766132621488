import { defineMessages } from "react-intl";

const components = defineMessages({
  fileListFooterExportButton: {
    id: "component.update-item-files-field.export",
    defaultMessage: "Load From Revit",
    description: "Label for the export button",
  },
  fileListFooterUploadButton: {
    id: "component.update-item-files-field.upload",
    defaultMessage: "Upload From Local Drive",
    description: "Label for the upload button",
  },
  fileListFooterMultipleFamiliesInfoTitle: {
    id: "ui.upload.components.file-list-footer-multiple-families-info-title",
    defaultMessage: "Uploading Multiple Families",
    description: "An info title regarding the upload of multiple families",
  },
  fileListFooterMultipleFamiliesInfoDescription: {
    id: "ui.upload.components.file-list-footer-multiple-families-info-description",
    defaultMessage:
      'To upload multiple families at once use "Upload From Local Drive" and select all family files.',
    description:
      "An info description regarding the upload of multiple families",
  },
  fileListItemRemoveAddedTooltip: {
    id: "components.file-list-item.remove-added-tooltip",
    defaultMessage: "Remove the new added file",
    description: "Tooltip for remove added file button",
  },
  fileListItemRemoveReplacedTooltip: {
    id: "components.file-list-item.remove-replaced-tooltip",
    defaultMessage: "Remove the new file and restore the old file",
    description: "Tooltip for remove replaced file button",
  },
  fileListItemRestoreRemovedTooltip: {
    id: "components.file-list-item.restore-removed-tooltip",
    defaultMessage: "Restore the removed file",
    description: "Tooltip for restore removed file button",
  },
  fileListItemRemoveTooltip: {
    id: "components.file-list-item.remove-tooltip",
    defaultMessage: "Remove the file",
    description: "Tooltip for remove file button",
  },
  fileListItemStateAdded: {
    id: "components.file-list-item-state.added",
    defaultMessage: "Added",
    description: "Added file state",
  },
  fileListItemStateRemoved: {
    id: "components.file-list-item-state.removed",
    defaultMessage: "Removed",
    description: "Removed file state",
  },
  fileListItemStateReplaced: {
    id: "components.file-list-item-state.replaced",
    defaultMessage: "Replaced",
    description: "Replaced file state",
  },
  overwriteItemListTitle: {
    id: "components.overwrite-item-files-list.properties-title",
    defaultMessage: "Overwrite Parameters",
    description:
      "Title for the overwrite parameters section within the overwrite item files list",
  },
  fileListDeleteMenuButtonTooltipCreate: {
    id: "components.file-list-delete-menu-button-tooltip-create",
    defaultMessage: "Removes the file from the list",
    description: "Tooltip for delete file button with mode=create",
  },
  fileListDeleteMenuButtonTooltipUpdate: {
    id: "components.file-list-delete-menu-button-tooltip-update",
    defaultMessage: "Cancels the update for this file",
    description: "Tooltip for delete file button with mode=update",
  },
});

export default components;
