import { Category, Response, User } from "@formitas-ag/bimfiles-types";
import { getMigrationUser, getMigrationUserId } from "../utils/id.utils";
import axiosService from "./fetch/axios.service";
import { create, get, getOne, remove, update } from "./utils/endpoint.utils";
import { errorRenderer } from "./utils/errorThrower";
import { CategoryRole, UserRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";
import { EndpointOptions } from "./utils/endpoint.options.utils";

type CreateUserData = {
  organisationId: string;

  preName: string;
  name: string;
  email: string;
  password: string;
  state: "enabled" | "disabled";
};

type UpdateUserData = Partial<Omit<CreateUserData, "password">>;

const users = {
  get: (page: number, size: number, options?: EndpointOptions) =>
    get<User>("users", page, size, options),
  getOne: (id: string, options?: EndpointOptions) => {
    if (id === getMigrationUserId()) {
      return Promise.resolve(getMigrationUser());
    }

    return getOne<User>("users", id, options);
  },
  create: (data: CreateUserData) => create<User, CreateUserData>("users", data),
  update: (id: string, data: UpdateUserData) =>
    update<User, UpdateUserData>("users", id, data),
  updatePassword: (
    id: string,
    oldPassword: string | undefined,
    newPassword: string
  ) =>
    new Promise<void>(async (resolve, reject) => {
      try {
        const data = {
          oldPassword,
          newPassword,
        };

        const url = `/users/${id}/update/password`;

        const response = await axiosService.put<Response<string>>(url, data);

        if (response.status === 200 && response.data.success) {
          resolve();
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  delete: (id: string) => remove("users", id),
  startPasswordReset: (email: string) =>
    new Promise<Required<User["passwordReset"]>>(async (resolve, reject) => {
      try {
        const url = `/users/reset`;
        const response = await axiosService.post<
          Response<Required<User["passwordReset"]>>
        >(url, {
          email,
        });

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }

        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  completePasswordReset: (
    passwordResetId: string,
    password: string,
    passwordConfirm: string
  ) =>
    new Promise<Pick<User, "id">>(async (resolve, reject) => {
      try {
        const url = `/users/reset/${passwordResetId}`;
        const response = await axiosService.post<Response<Pick<User, "id">>>(
          url,
          {
            password,
            passwordConfirm,
          }
        );

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }

        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  activateAccount: (
    activationId: string,
    password: string,
    passwordConfirm: string
  ) =>
    new Promise<Pick<User, "id">>(async (resolve, reject) => {
      try {
        const url = `/users/activate/${activationId}`;
        const response = await axiosService.post<Response<Pick<User, "id">>>(
          url,
          {
            password,
            passwordConfirm,
          }
        );

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }

        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  resendActivation: (id: string) =>
    new Promise<User>(async (resolve, reject) => {
      try {
        const url = `/users/${id}/reactivate`;
        const response = await axiosService.post<Response<User>>(url);

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  role: async (id: string, role: UserRoleEnum): Promise<User> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/users/${id}/role`;
        const response = await axiosService.post<Response<User>>(url, {
          role,
        });

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  categoryRole: async (
    id: string,
    categoryId: string,
    role: CategoryRole
  ): Promise<Category> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/users/${id}/permissions/category/${categoryId}`;
        const response = await axiosService.post<Response<Category>>(url, {
          role,
        });

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  leaveOrganisation: async (
    id: string,
    organisationId?: string
  ): Promise<boolean> =>
    new Promise(async (resolve, reject) => {
      try {
        const url =
          `/users/${id}/leave` +
          (organisationId ? `?organisationId=${organisationId}` : "");
        const response = await axiosService.post<Response<boolean>>(url);

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
};

export default users;
