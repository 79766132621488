import { Category, Pagination } from "@formitas-ag/bimfiles-types/lib";
import { useQuery } from "react-query";
import {
  DistinctSearchResultType,
  FilterType,
} from "@formitas-ag/bimfiles-types/lib/search";
import useFiltersStore from "../state/filtersStore";
import api from "../api/api";

const useGetCategories = (type: "all" | "filter") => {
  const filters = useFiltersStore((state) => state.currentFilters);

  // Get the distinct categories based on the filters
  const query = useQuery(
    type === "all"
      ? ["categories", 1, 100000]
      : ["distinctCategories", filters],
    async () => {
      let categories: Pagination<Category> | DistinctSearchResultType;

      if (type === "all") {
        categories = await api.categories.get(1, 100000, "default");
      } else {
        categories = await api.search.distinct(
          filters,
          "categoryId" as FilterType
        );
      }

      return categories;
    }
  );

  return query;
};

export default useGetCategories;
