import { FC, useState } from "react";
import { Space, Table } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { usePlatform } from "../../../../hooks/usePlatform";
import { useSearchItems } from "../../../../hooks/useSearchItems";
import { useItemsTableStore } from "../../../../state/itemsTableStore";
import BatchDownloadImportButton from "../DownloadItem/BatchDownloadImportButton";
import useGetOSTTranslation from "../../../../hooks/useGetOSTTranslation";
import useGetPageDimentions from "../../../../hooks/usePageHeight";
import CustomExpandIcon from "./TableProperties/CustomExpandIcon";
import useDivHeight from "../../../../hooks/useDivHeight";
import RowSelection from "./TableProperties/RowSelection";
import TableColumns from "./TableColumns";

import "./items-table.css";
import { DeselectSelectedItemsButton } from "./DeselectSelectedItemsButton";
import { BatchUpdateButton } from "../DownloadItem/BatchUpdateButton";

/**
 * Primary UI component for user interaction
 */
export const ItemsTable: FC = () => {
  // Call this hook to get all the ost category translations
  useGetOSTTranslation();

  const [platform] = useState<string>(usePlatform());

  const rowSelection = RowSelection();

  const setPage = useItemsTableStore((state) => state.setPage);
  const setSize = useItemsTableStore((state) => state.setSize);

  const selectedItems = useItemsTableStore((state) => state.selectedItems);

  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const { page, itemsState, query } = useSearchItems();

  const intl = useIntl();
  const messages = defineMessages({
    page: {
      id: "component.items_table.page",
      defaultMessage: " / Page",
      description: "Page label in the items table",
    },
  });

  return (
    <div className="items-table-div">
      <style>
        {
          ".items-table-div td .ant-checkbox-wrapper { position: absolute; top: 1.25rem; left: 0.5rem;}"
        }
      </style>
      <Table
        rowKey="id"
        size="small"
        columns={TableColumns({ expandedRows })}
        // 260
        scroll={{
          y: `${
            (useGetPageDimentions() -
              useDivHeight("expandable-filters-div") -
              210 -
              (selectedItems.length > 0 ? 48 : 0)) as number
          }px`,
        }}
        loading={query.isLoading}
        dataSource={query.data?.data}
        rowSelection={itemsState === "approved" ? rowSelection : undefined}
        expandable={{
          expandIcon: (props) => CustomExpandIcon(props),
          onExpand: (expand: boolean, record) => {
            if (expand) {
              setExpandedRows([...expandedRows, record.id]);
            } else {
              setExpandedRows(expandedRows.filter((id) => id !== record.id));
            }
          },
          expandedRowRender: (item) => undefined,
        }}
        onChange={(pagination) => {
          setPage(pagination.current!);
          setSize(pagination.pageSize!);
        }}
        pagination={{
          locale: {
            items_per_page: intl.formatMessage(messages.page),
          },
          position: ["bottomCenter"],
          showSizeChanger: true,
          showQuickJumper: false,
          defaultPageSize: 20,
          current: page,
          total: query.data?.totalDocuments,
        }}
        footer={
          selectedItems.length > 0
            ? () => {
                return (
                  <>
                    <Space wrap>
                      <DeselectSelectedItemsButton />
                      <BatchUpdateButton />
                      {platform === "browser" && <BatchDownloadImportButton />}
                    </Space>
                  </>
                );
              }
            : undefined
        }
      />
    </div>
  );
};
