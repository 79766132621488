import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useViewStore } from "../../../../../state/viewStore";

export const AddTagsButton: React.FC<{ style?: React.CSSProperties }> = ({
  style,
}) => {
  const onClick = () => {
    useViewStore.setState({
      ...useViewStore.getState(),
      addTagsModal: true,
    });
  };

  return (
    <Button
      onClick={onClick}
      style={{ height: "40px", width: "100%", padding: "4px", ...style }}
    >
      <PlusOutlined style={{ fontSize: "16px" }} />
    </Button>
  );
};
