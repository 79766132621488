import { Col, Collapse, Row, Select, Space, Typography } from "antd";
import { useAccessToken } from "../../../api/utils/auth.utils";
import { useEffect, useMemo, useState } from "react";
import jwtDecode from "jwt-decode";
import { Category, CombinedAccessToken } from "@formitas-ag/bimfiles-types";
import api from "../../../api/api";
import { useQuery } from "react-query";
import { on } from "events";
import { checkFinePermissions } from "../../../api/ability/Can";
import { CategoryRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";
import { CategoryRoleRender } from "../../Admin/Common/CategoryRoleRender";
import UserRoleRender from "../../Admin/Users/UserRoleRender";
import { getCurrentOrganisation } from "../../../api/ability/categoryAbilityExtension";
import BooleanRender from "../../Admin/Common/BooleanRender";

export const DebugPage = () => {
  const [accessToken] = useAccessToken();

  const decodedAccessToken = useMemo(
    () =>
      accessToken ? (jwtDecode(accessToken) as CombinedAccessToken) : undefined,
    [accessToken]
  );

  const categoriesQuery = useQuery({
    queryKey: ["categories"],
    queryFn: () => api.categories.get(1, 10000),
  });

  useEffect(() => {
    if (categoriesQuery.data) {
      const run = async () => {
        const categoryPermissionCheckResults = await Promise.all(
          categoriesQuery.data.data.map(async (category) => {
            const canRead = await checkFinePermissions({
              mode: "category",
              on: category.id,
              minimumRole: CategoryRoleEnum.READ,
            });
            const canWrite = await checkFinePermissions({
              mode: "category",
              on: category.id,
              minimumRole: CategoryRoleEnum.WRITE,
            });
            const canApprove = await checkFinePermissions({
              mode: "category",
              on: category.id,
              minimumRole: CategoryRoleEnum.APPROVE,
            });

            const categoryRole = canApprove
              ? CategoryRoleEnum.APPROVE
              : canWrite
              ? CategoryRoleEnum.WRITE
              : canRead
              ? CategoryRoleEnum.READ
              : CategoryRoleEnum.NONE;

            return {
              ...category,
              role: categoryRole,
            };
          })
        );

        setCategoryPermissionCheckResult(categoryPermissionCheckResults);
      };
      run();
    }
  }, [categoriesQuery.data]);

  const [categoryPermissionCheckResult, setCategoryPermissionCheckResult] =
    useState<(Category & { role: CategoryRoleEnum })[]>([]);

  const [
    generalCategoryPermissionCheckResult,
    setGeneralCategoryPermissionCheckResult,
  ] =
    useState<{
      generalRole: CategoryRoleEnum;
      allowOnlyAdmins: boolean;
      blockUser: boolean;
    }>();

  useEffect(() => {
    const run = async () => {
      const canRead = await checkFinePermissions({
        mode: "general",
        minimumUserRole: CategoryRoleEnum.READ,
      });
      const canWrite = await checkFinePermissions({
        mode: "general",
        minimumUserRole: CategoryRoleEnum.WRITE,
      });
      const canApprove = await checkFinePermissions({
        mode: "general",
        minimumUserRole: CategoryRoleEnum.APPROVE,
      });

      const categoryRole = canApprove
        ? CategoryRoleEnum.APPROVE
        : canWrite
        ? CategoryRoleEnum.WRITE
        : canRead
        ? CategoryRoleEnum.READ
        : CategoryRoleEnum.NONE;

      const allowOnlyAdmins = await checkFinePermissions({
        mode: "general",
        allowOnlyAdmins: true,
      });

      const blockUser = await checkFinePermissions({
        mode: "general",
        blockUser: true,
      });

      setGeneralCategoryPermissionCheckResult({
        generalRole: categoryRole,
        allowOnlyAdmins,
        blockUser,
      });
    };
    run();
  }, [accessToken]);

  return (
    <div style={{ padding: "2rem", width: "100%" }}>
      <Typography.Title level={1}>Debug Page</Typography.Title>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Space direction="vertical">
            <Typography.Title level={3}>
              General Category Permission Check
            </Typography.Title>

            {generalCategoryPermissionCheckResult !== undefined && (
              <>
                <Typography.Title level={5}>
                  Result:{" "}
                  <CategoryRoleRender
                    role={generalCategoryPermissionCheckResult.generalRole}
                  />
                </Typography.Title>

                <Typography.Title level={5}>
                  Passing with "Allow Only Admins":{" "}
                  <BooleanRender
                    value={generalCategoryPermissionCheckResult.allowOnlyAdmins}
                    style="rightAndWrong"
                  />
                </Typography.Title>

                <Typography.Title level={5}>
                  Passing with "Block User":{" "}
                  <BooleanRender
                    value={generalCategoryPermissionCheckResult.blockUser}
                    style="rightAndWrong"
                  />
                </Typography.Title>
              </>
            )}

            <Typography.Title level={3}>
              Category Permission Check
            </Typography.Title>

            {categoryPermissionCheckResult &&
              categoryPermissionCheckResult.length > 0 && (
                <div>
                  {categoryPermissionCheckResult.map((category) => {
                    return (
                      <div key={category.id}>
                        <Typography.Title level={5}>
                          {category.title}{" "}
                          <CategoryRoleRender role={category.role} />
                        </Typography.Title>
                      </div>
                    );
                  })}
                </div>
              )}
          </Space>
        </Col>
        <Col span={6}>
          <Space direction="vertical">
            <Typography.Title level={3}>User Role</Typography.Title>

            <UserRoleRender role={getCurrentOrganisation()!.role} />

            <Typography.Title level={3}>User Type</Typography.Title>

            <Typography.Text>{decodedAccessToken?.user.type}</Typography.Text>
          </Space>
        </Col>
        <Col span={12}>
          <Typography.Title level={3}>Access Token State</Typography.Title>

          <Collapse
            collapsible="icon"
            items={[
              {
                key: "1",
                children: (
                  <Typography.Text>
                    {decodedAccessToken === undefined && "No access token"}
                    {decodedAccessToken !== undefined && (
                      <pre>{JSON.stringify(decodedAccessToken, null, 4)}</pre>
                    )}
                  </Typography.Text>
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  );
};
