import { useState } from "react";
import { useCreateOrUpdateItemStore } from "../../state/createOrUpdateItemStore";
import { getLogger } from "../../utils/logger.utils";
import api from "../../api/api";
import {
  UpdatingAddedFile,
  UpdatingReplacedFile,
} from "../../utils/types/updateFilesTypes";
import useUploadNewFiles from "./useUploadFinalFiles/useUploadNewFiles";
import { File as BimFilesFile } from "@formitas-ag/bimfiles-types";
import useGetFileMeta from "./useGetFileMeta";
import { CreateItemData } from "@formitas-ag/bimfiles-types/lib/item";
import { fieldPatternMatcher } from "@casl/ability";
import { useViewStore } from "../../state/viewStore";
import useCreateOrUpdateFiles from "../useCreateOrUpdateFiles";
import { useItemsTableStore } from "../../state/itemsTableStore";
import useFiltersStore from "../../state/filtersStore";
import { rootQueryClient } from "../..";
import { useCreateOrUpdateMultipleFiles } from "../useCreateOrUpdateMultipleFiles";
import useValidateUpload from "./useValidateUpload";

const logger = getLogger("useCreateItem");

export default () => {
  const [isCreating, setIsCreating] = useState(false);
  const mode = useCreateOrUpdateItemStore((state) => state.mode);
  const { getName, getRole, unmapParentAndChildrenFiles } = useGetFileMeta();
  const { getUpload, setUpload } = useCreateOrUpdateMultipleFiles();
  const { stopCreateOrUpdate } = useCreateOrUpdateFiles();
  const { uploadNewFile } = useUploadNewFiles();
  const { validateUpload } = useValidateUpload();

  const _saveCreate = async (index: number): Promise<boolean> => {
    const upload = getUpload(index);

    if (!upload) {
      logger.error(
        `Tried creating an item but the upload with index ${index} doesn't exist`
      );
      return false;
    }

    const { item } = upload;
    let unmappedUpdatingFiles = unmapParentAndChildrenFiles(
      index,
      upload.updatingFiles
    );

    if (mode !== "create" || !item) {
      logger.error(`Invalid state for saveCreate.`, {
        mode,
        item,
      });
      return false;
    }

    const validationResult = validateUpload(upload);

    if (!validationResult.success) {
      logger.error(
        `Failed upload validation due to '${validationResult.error}'`,
        {
          upload,
        }
      );
      return false;
    }

    const hasCustomSidecar = unmappedUpdatingFiles.some((f) => {
      const result = getRole(index, f);

      return result.result !== "error" && result.role === "sidecar";
    });

    if (hasCustomSidecar) {
      //we filter the role=sidecar-automatic out to avoid name conflicts
      unmappedUpdatingFiles = unmappedUpdatingFiles.filter((f) => {
        const result = getRole(index, f);

        return result.result !== "error" && result.role !== "sidecar-automatic";
      });
    }

    logger.debug(`Creating the item [${index}]`, {
      item,
      hasCustomSidecar,
    });

    setIsCreating(true);

    //technically we should only have added or replaced files here so this is more a type conversion
    const filesThatRequireUploading = unmappedUpdatingFiles.filter(
      (f) => f.state === "added" || f.state === "replaced"
    ) as (UpdatingAddedFile | UpdatingReplacedFile)[];

    logger.debug(`Uploading files for the item`, {
      item,
      filesThatRequireUploading,
    });

    //we first convert the files into an api friendly format
    const apiFriendlyFiles = filesThatRequireUploading.map<
      CreateItemData["files"][0]
    >((f) => {
      const name = getName(f);
      const roleResult = getRole(index, f);

      if (roleResult.result === "error") {
        return {
          name,
          role: "other",
        };
      } else {
        return {
          name,
          role: roleResult.role,
        };
      }
    });

    const parameters = filesThatRequireUploading
      .filter(
        (file) =>
          (file.state === "added" && file.addedFile.source === "addon") ||
          (file.state === "replaced" &&
            file.newFile.addedFile.source === "addon")
      )
      .reduce<object | undefined>((_, file) => {
        if (file.state === "added" && file.addedFile.source === "addon") {
          return file.addedFile.parameters;
        }

        if (
          file.state === "replaced" &&
          file.newFile.addedFile.source === "addon"
        ) {
          return file.newFile.addedFile.parameters;
        }

        return undefined;
      }, undefined);

    //we can't upload the files until the item is created
    const createdItem = await api.items.create({
      categoryId: item.categoryId!,
      changeDescription: item.changeDescription!,
      description: item.description!,
      files: apiFriendlyFiles,
      parameters: item.parameters ?? parameters ?? {},
      tagsIds: item.tagsIds!,
      title: item.title!,
    });

    logger.debug(
      `Created the item with id ${createdItem.item.id}. Will now upload the files to S3.`,
      {
        createdItem,
      }
    );

    try {
      //we now upload the files to S3
      const uploadedFiles = await uploadNewFile(
        index,
        filesThatRequireUploading,
        async (f) => {
          const fileWithUrl = createdItem.uploadUrls.find(
            (u) => u.name === getName(f)
          );

          if (!fileWithUrl) {
            throw new Error(`Could not find the upload url for file ${f.id}.`);
          }

          return fileWithUrl;
        }
      );

      setIsCreating(false);

      if (uploadedFiles.length !== filesThatRequireUploading.length) {
        logger.error(`Not all files were uploaded.`, {
          uploadedFiles,
          filesThatRequireUploading,
        });
        throw new Error(`Not all files were uploaded.`);
      }
      logger.debug(`Finished uploading files [${index}].`, {
        uploadedFiles,
      });

      const finalItem = await api.items.finish(createdItem.item.id);

      logger.debug(`Finished creating the item ${createdItem.item.id}.`, {
        finalItem,
      });

      return true;
    } catch (error) {
      logger.error(
        `Couldn't upload files for item ${createdItem.item.id}. Aborting upload.`
      );

      await api.items.cancelUpload(createdItem.item.id);

      return false;
    }
  };

  const saveCreate = async () => {
    const uploads = useCreateOrUpdateItemStore.getState().uploads;

    logger.debug(`Creating all items with length=${uploads.length}`);

    for (const upload of uploads) {
      const index = upload.index;

      let result = false;
      if (index === 0) {
        result = await _saveCreate(-1);
      } else {
        result = await _saveCreate(index);
      }

      if (!result) {
        return;
      }
    }

    logger.debug(`Finished uploading all files. Cleaning up.`);

    //update all items
    rootQueryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === "items",
    });

    stopCreateOrUpdate();
  };

  return {
    isCreating,
    saveCreate,
  };
};
