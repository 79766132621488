import { useState } from "react";
import { Form, Input, Button, Alert, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import ButtonProps from "../../../../UiProperties/ButtonProps";

type Props = {
  onFinish: (password: string, passwordConfirm: string) => Promise<void>;
  messages: {
    description: string;
    action: string;
  };
};

const PasswordResetForm = (props: Props) => {
  const intl = useIntl();

  const [failed, setFailed] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const messages = defineMessages({
    passwordRequired: {
      id: "auth.password-reset-form.password.required",
      defaultMessage: "Password required",
      description: "Error message when password is not provided",
    },
    passwordConfirmRequired: {
      id: "auth.password-reset-form.password-confirm.required",
      defaultMessage: "Password confirmation required",
      description: "Error message when password confirmation is not provided",
    },
    passwordPlaceholder: {
      id: "auth.password-reset-form.password.placeholder",
      defaultMessage: "Password",
      description: "Placeholder for password input",
    },
    passwordConfirmationPlaceholder: {
      id: "auth.password-reset-form.password-confirmation.placeholder",
      defaultMessage: "Confirm password",
      description: "Placeholder for password confirmation input",
    },
    error: {
      id: "auth.password-reset-form.error",
      defaultMessage: "Error while processing the request: {error}",
      description: "Error message when the request fails",
    },
  });

  /**
   * Called when the form is filled correctly
   * @param values from the form (email and password)
   */
  const onInternalFinish = async (values: any) => {
    setLoading(true);

    try {
      await props.onFinish(values.password, values.passwordConfirm);
    } catch (error) {
      setFailed(intl.formatMessage(messages.error, { error: error + "" }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: "0rem 1rem 0rem 1rem" }}>
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onInternalFinish}
        autoComplete="off"
      >
        <div
          style={{
            width: "100%",
            background: "#fff",
            fontSize: "1.2rem",
            padding: "1rem",
            marginBottom: "0.4rem",
          }}
        >
          <Typography.Text type="secondary">
            {props.messages.description}
          </Typography.Text>
        </div>
        <Form.Item
          style={{ marginBottom: "0.4rem" }}
          name="password"
          rules={[
            {
              required: true,
              message: intl.formatMessage(messages.passwordRequired),
            },
          ]}
        >
          <Input.Password
            size="large"
            style={{ borderRadius: 0, height: "3rem" }}
            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
          />
        </Form.Item>
        <Form.Item
          style={{ marginBottom: "0.4rem" }}
          name="passwordConfirm"
          rules={[
            {
              required: true,
              message: intl.formatMessage(messages.passwordConfirmRequired),
            },
          ]}
        >
          <Input.Password
            size="large"
            style={{ borderRadius: 0, height: "3rem" }}
            placeholder={intl.formatMessage(
              messages.passwordConfirmationPlaceholder
            )}
          />
        </Form.Item>

        {failed ? (
          <Alert
            message={
              <FormattedMessage
                id="auth.login.alert.failed_login"
                defaultMessage="Incorrect email or password"
                description="Alert message when login fails because email or password are incorrect"
              />
            }
            type="error"
          />
        ) : null}

        <div className="login-form-button-spinner">
          <Form.Item>
            <Button
              size="large"
              type="dashed"
              style={{
                height: "3rem",
                width: "100%",
                border: "0.2rem solid #fff",
                borderRadius: 0,
                color: "#fff",
                fontSize: "1.2rem",
                textTransform: "uppercase",
                ...ButtonProps.ghost,
              }}
              htmlType="submit"
            >
              {loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                ></Spin>
              ) : (
                props.messages.action
              )}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default PasswordResetForm;
