import { Alert, Button, Input, Space } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import UpdateHeader from "../Common/ItemHeader";
import { useCreateOrUpdateMultipleFiles } from "../../../../hooks/useCreateOrUpdateMultipleFiles";
import translations from "../../../../translations/constants/translations";
import { useCreateOrUpdateItemStore } from "../../../../state/createOrUpdateItemStore";
import api from "../../../../api/api";
import { useDebouncedCallback } from "use-debounce";
import useCreateOrUpdateFiles from "../../../../hooks/useCreateOrUpdateFiles";
import useFiltersStore from "../../../../state/filtersStore";

type Props = {
  mode: "single" | "multiple";
  index?: number;
};

const UpdateTitle = (props: Props) => {
  const index = props.index ?? -1;

  const intl = useIntl();

  const { getUpload, getItem, setItem } = useCreateOrUpdateMultipleFiles();
  const { stopCreateOrUpdate } = useCreateOrUpdateFiles();

  const oldItem = getUpload(index)?.oldItem;
  const item = getItem(index);

  const [currentValue, setCurrentValue] = useState<string | undefined>(
    oldItem?.title ?? ""
  );

  const [ignoreTitleExists, setIgnoreTitleExists] = useState<boolean>(false);
  const [doesTitleExist, setDoesTitleExist] = useState<boolean>(false);

  const debouncedCheckTitleCallback = useDebouncedCallback(
    async (title: string) => {
      if (useCreateOrUpdateItemStore.getState().mode === "create") {
        const titleExists = await api.items.checkTitleExists(title);

        setDoesTitleExist(titleExists);
      }
    },
    100
  );

  const onClickIgnoreTitleExists = () => {
    setIgnoreTitleExists(true);
  };

  const onClickFindExistingItems = () => {
    if (doesTitleExist && currentValue) {
      stopCreateOrUpdate();

      useFiltersStore.setState({
        itemsState: "approved",
        currentFilters: {
          categoriesIds: [],
          tagsIds: [],
          id: "",
          itemText: [currentValue!],
          ostCategory: [],
          placementMethod: [],
          revitVersion: [],
          usersIds: [],
        },
      });
    }
  };

  useEffect(() => {
    if (currentValue) {
      debouncedCheckTitleCallback(currentValue);
    }
  }, [currentValue]);

  useEffect(() => {
    if (item?.title && item?.title !== currentValue) {
      //check if the title already exists
      setCurrentValue(item?.title);
    }
  }, [item?.title]);

  const didChange = oldItem?.title ? currentValue !== oldItem?.title : false;
  const resetChange = () => {
    setCurrentValue(oldItem?.title ?? "");

    setItem(index, {
      title: undefined,
    });
  };

  return (
    <>
      {props.mode === "single" && (
        <>
          <UpdateHeader
            title={intl.formatMessage(translations.ui.meta.fields.titleName)}
            isResetable={didChange}
            onReset={resetChange}
            required={true}
          />
          <Input
            name="title"
            size="large"
            style={{
              width: "100%",
            }}
            value={currentValue}
            placeholder={intl.formatMessage(
              translations.ui.meta.fields.titlePlaceholder
            )}
            onChange={(e) => {
              setCurrentValue(e.target.value);
              setItem(index, {
                title: e.target.value,
              });
            }}
          />

          {doesTitleExist && !ignoreTitleExists && (
            <Alert
              style={{ marginTop: "8px" }}
              message={intl.formatMessage(
                translations.ui.meta.fields.titleAlreadyExists
              )}
              type="warning"
              action={
                <Space direction="vertical">
                  <Button
                    size="small"
                    type="text"
                    style={{ width: "100%" }}
                    onClick={onClickIgnoreTitleExists}
                  >
                    {intl.formatMessage(translations.ui.generic.ignore)}
                  </Button>

                  <Button
                    size="small"
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={onClickFindExistingItems}
                  >
                    {intl.formatMessage(
                      translations.ui.meta.fields
                        .titleAlreadyExistsFindExistingItemsButton
                    )}
                  </Button>
                </Space>
              }
            />
          )}
        </>
      )}
      {props.mode === "multiple" && (
        <>
          <Input
            name="title"
            size="large"
            value={currentValue}
            placeholder={intl.formatMessage(
              translations.ui.meta.fields.titlePlaceholder
            )}
            onChange={(e) => {
              setCurrentValue(e.target.value);
              setItem(index, {
                title: e.target.value,
              });
            }}
          />
        </>
      )}
    </>
  );
};

export default UpdateTitle;
