import { useEffect, useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import api from "../../api/api";
import {
  hasSelectedOrganisationId,
  selectedOrganisationIdObservable,
  useAccessToken,
  useSelectedOrganisationId,
} from "../../api/utils/auth.utils";
import { useAuthStore } from "../../state/authStore";
import { Row, Spin } from "antd";

/**
 * Guards routes that can only be accessed if the user is not authenticated
 * @returns rerouting based on the user authentication status
 */
const RequireNoAuth = () => {
  const location = useLocation();
  const [accessToken, setAccessToken] = useAccessToken();
  const [isLoading, setIsLoading] = useState(true);
  const possibleOrganisations = useAuthStore(
    (state) => state.possibleOrganisations
  );
  const setPossibleOrganisations = useAuthStore(
    (state) => state.setPossibleOrganisations
  );
  const [selectedOrganisationId, setSelectedOrganisationId] =
    useSelectedOrganisationId();

  useEffect(() => {
    if (location.search.includes("getOrganisations")) {
      //we just returned from logging in with azure and need to get the organisations
      const getOrganisations = async () => {
        try {
          const possibleOrganisations = await api.auth.getOrganisations();

          await api.auth._onOrganisationReceive(possibleOrganisations);
        } catch (error) {
          return;
        }
      };

      getOrganisations();
    }

    if (location.search.includes("clearAuth")) {
      api.auth.logout(true);
    }

    const possibleAccessToken = document.cookie
      .split("; ")
      .filter((row) => row.startsWith("accessToken="))
      .map((c) => c.split("=")[1])[0];

    if (possibleAccessToken) {
      //sso login happened
      setAccessToken(possibleAccessToken);
    }

    const attemptRefreshAccessTokens = async () => {
      try {
        if (hasSelectedOrganisationId()) {
          await api.auth.refreshAccessTokens();
        }
      } finally {
        setIsLoading(false);
      }
    };

    // Avoids any unwanted calls to verifyRefreshToken
    accessToken === null ? attemptRefreshAccessTokens() : setIsLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //whenever possible organisations change
    if (possibleOrganisations.length > 0) {
      if (possibleOrganisations.length === 1 && !hasSelectedOrganisationId()) {
        //we have only one possible organisation and we have not selected one yet
        selectedOrganisationIdObservable.set(possibleOrganisations[0].id);
        setSelectedOrganisationId(possibleOrganisations[0].id);
      } else if (
        possibleOrganisations.length === 1 &&
        hasSelectedOrganisationId() &&
        accessToken === null
      ) {
        //we have only one possible organisation and we have selected one and we have no access token
        api.auth.refreshAccessTokens();
        setSelectedOrganisationId(possibleOrganisations[0].id);
      } else if (
        possibleOrganisations.length > 1 &&
        !hasSelectedOrganisationId()
      ) {
        setIsLoading(false);
      }
    }
  }, [possibleOrganisations]);

  useEffect(() => {
    //whenever selected organisation id changes
    if (selectedOrganisationId !== null && accessToken === null) {
      //when we selected an organisation but haven't refreshed the access token
      api.auth.refreshAccessTokens();
    }
  }, [selectedOrganisationId]);

  return accessToken !== null ? (
    <Navigate to="/homepage" state={{ from: location }} replace />
  ) : isLoading ? (
    <Row
      align="middle"
      justify="center"
      style={{ height: "100vh", width: "100%" }}
    >
      <Spin size="large" />
    </Row>
  ) : (
    <Outlet />
  );
};

export default RequireNoAuth;
