import { Change, Item } from "@formitas-ag/bimfiles-types";
import { getChangeType } from "../utils/change-type.utils";

const SPLIT_BY_CHANGES = ["CategoryChange", "FileChange", "DeleteChange"];
const MINIMUM_DELAY_IN_SECONDS = 60 * 10;
const MAXIMUM_DELAY_IN_SECONDS = 60 * 20;

const needsSplit = (change: Change): boolean => {
  return SPLIT_BY_CHANGES.includes(getChangeType(change));
};

const useTimeline = (item: Item) => {
  //fix date
  const changedWithDate = item.changes.reduce<Change[]>((acc, change) => {
    if (getChangeType(change) === "ApprovedChange") return acc;

    acc.push({
      ...change,
      at: new Date(change.at),
    });

    return acc;
  }, []);

  //first we sort by date
  const changesSortedByDate = changedWithDate.sort((a, b) => {
    return a.at.getTime() - b.at.getTime();
  });

  //split by delay between changes or by importance
  const groups: Change[][] = [];
  let groupStartAt = 0;
  let group: Change[] = [];
  let groupChangeTypes = [];
  let hasStartedGroup = false;
  let hasFoundReferenceChange = false;

  const saveOldGroupAndStartNew = (firstGroupChange: Change) => {
    groups.push(group);
    group = [firstGroupChange];
    groupChangeTypes = [getChangeType(firstGroupChange)];
    hasStartedGroup = true;
    groupStartAt = firstGroupChange.at.getTime();
    hasFoundReferenceChange = needsSplit(firstGroupChange);
  };

  for (let i = 0; i < changesSortedByDate.length; i++) {
    const currentChange = changesSortedByDate[i];

    if (!hasStartedGroup) {
      //we start a new group
      group.push(currentChange);
      groupChangeTypes.push(getChangeType(currentChange));
      hasStartedGroup = true;
      groupStartAt = currentChange.at.getTime();
      if (needsSplit(currentChange)) hasFoundReferenceChange = true;
      continue;
    }

    if (groupChangeTypes.includes(getChangeType(currentChange))) {
      //we have already found such a change
      //we will create another group as this means that the user updated twice
      saveOldGroupAndStartNew(currentChange);
      continue;
    }

    if (
      currentChange.at.getTime() - groupStartAt <
      MINIMUM_DELAY_IN_SECONDS * 1000
    ) {
      //this change is so close to the first change that we include it
      group.push(currentChange);
      groupChangeTypes.push(getChangeType(currentChange));
      continue;
    }

    if (
      currentChange.at.getTime() - groupStartAt >
      MAXIMUM_DELAY_IN_SECONDS * 1000
    ) {
      saveOldGroupAndStartNew(currentChange);
      continue;
    }

    if (needsSplit(currentChange)) {
      //we found another import change
      //we will start a new group
      if (hasFoundReferenceChange) {
        saveOldGroupAndStartNew(currentChange);
        continue;
      }

      //we found the first import change
      group.push(currentChange);
      groupChangeTypes.push(getChangeType(currentChange));
      hasFoundReferenceChange = true;
      continue;
    }

    //we found a change that is not an import change
    //we can just add it to the current group
    group.push(currentChange);
    groupChangeTypes.push(getChangeType(currentChange));
  }
  if (group.length > 0) {
    groups.push(group);
  }

  const globallySortedGroups = groups.sort((a, b) => {
    return b[0].at.getTime() - a[0].at.getTime();
  });

  //sort groups internally
  const interallySortedGroups = globallySortedGroups.map((group) => {
    return group.sort((a, b) => {
      const typeA = getChangeType(a);
      const typeB = getChangeType(b);

      const order: string[] = [
        "FileChange",
        "CategoryChange",
        "TagChange",
        "TitleChange",
        "DescriptionChange",
        "ParametersAndPropertiesChange",
        "ApprovedChange",
        "DeleteChange",
        "ChangeDescriptionChange",
      ];

      if (order.indexOf(typeA) >= 0 && order.indexOf(typeB) >= 0) {
        return order.indexOf(typeA) - order.indexOf(typeB);
      }

      return 0;
    });
  });

  return interallySortedGroups;
};

export default useTimeline;
