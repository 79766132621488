import useUnapprovedItemsLength from "../../../../hooks/useUnapprovedItemsLength";
import translations from "../../../../translations/constants/translations";
import useFiltersStore from "../../../../state/filtersStore";
import FormProps from "../../../../UiProperties/FormProps";
import Can, { checkFinePermissions } from "../../../../api/ability/Can";
import { Badge, Col, Form, Row, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { CategoryRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";
import { DefaultOptionType } from "antd/es/select";

export const ApprovedToggle: React.FC = () => {
  const intl = useIntl();
  const itemsState = useFiltersStore((state) => state.itemsState);
  const setItemsState = useFiltersStore((state) => state.setItemsState);

  const [unapprovedItemsLength, setUnapprovedItemsLength] = useState<number>(0);
  const [currentItem, setCurrentItem] =
    useState<"approved" | "unapproved" | "denied">(itemsState);

  useUnapprovedItemsLength().then((length) => setUnapprovedItemsLength(length));

  const [options, setOptions] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    const run = async () => {
      const newOptions: DefaultOptionType[] = [];

      newOptions.push({
        key: "filter-approved-option",
        value: "approved",
        label: intl.formatMessage(translations.ui.itemTable.filters.approved),
      });

      const canApprove = await checkFinePermissions({
        mode: "general",
        minimumUserRole: CategoryRoleEnum.APPROVE,
      });
      if (canApprove) {
        newOptions.push({
          key: "filter-unapproved-option",
          value: "unapproved",
          label: (
            <Row>
              <Col span={16}>
                {intl.formatMessage(
                  translations.ui.itemTable.filters.unapproved
                )}
              </Col>
              <Col
                span={8}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Badge count={unapprovedItemsLength} size="small" />
              </Col>
            </Row>
          ),
        });
      }

      newOptions.push({
        key: "filter-denied-option",
        value: "denied",
        label: intl.formatMessage(translations.ui.itemTable.filters.denied),
      });

      setOptions(newOptions);
    };
    run();
  }, []);

  const onChange = (value: "approved" | "unapproved" | "denied") => {
    setItemsState(value);
    setCurrentItem(value);
  };

  const onClear = () => {
    setItemsState("approved");
    setCurrentItem("approved");
  };

  return (
    <Form.Item
      style={{ ...FormProps.formItem, margin: "0.2rem 0.5rem 0.2rem 0" }}
    >
      <Select
        showSearch
        allowClear
        open
        onClear={onClear}
        style={{ width: "auto !important", minWidth: "10rem" }}
        placeholder="Select"
        onChange={onChange}
        defaultValue={currentItem}
        optionFilterProp="children"
        options={options}
      />
    </Form.Item>
  );
};
