import { useQuery } from "react-query";
import { FilterType } from "@formitas-ag/bimfiles-types/lib/search";
import useFiltersStore from "../state/filtersStore";
import api from "../api/api";

const useGetTags = (type: "all" | "filter") => {
  const filters = useFiltersStore((state) => state.currentFilters);

  // Get the distinct tags based on the filters
  const query = useQuery(
    type === "all" ? ["tags", 1, 100000] : ["distinctTags", filters],
    async () => {
      if (type === "all") {
        return await api.tags.get(1, 100000);
      } else {
        return await api.search.distinct(filters, "tagsIds" as FilterType);
      }
    }
  );

  return query;
};

export default useGetTags;
