import { defineMessages } from "react-intl";

const generic = defineMessages({
  close: {
    id: "ui.generic.close",
    defaultMessage: "Close",
    description: "Close button text",
  },
  cancel: {
    id: "ui.generic.cancel",
    defaultMessage: "Cancel",
    description: "Reset button text",
  },
  accept: {
    id: "ui.generic.accept",
    defaultMessage: "Accept",
    description: "Accept button text",
  },
  deny: {
    id: "ui.generic.deny",
    defaultMessage: "Deny",
    description: "Deny button text",
  },
  save: {
    id: "ui.generic.save",
    defaultMessage: "Save",
    description: "Save button text",
  },
  error: {
    id: "ui.generic.error",
    defaultMessage: "Error",
    description: "Error message title",
  },
  ignore: {
    id: "ui.generic.ignore",
    defaultMessage: "Ignore",
    description: "Ignore button text",
  },
  roleUser: {
    id: "ui.generic.role-user",
    defaultMessage: "User",
    description: "Role user",
  },
  roleModerator: {
    id: "ui.generic.role-moderator",
    defaultMessage: "Moderator",
    description: "Role moderator",
  },
  roleNone: {
    id: "ui.generic.role-none",
    defaultMessage: "None",
    description: "Role none",
  },
  roleRead: {
    id: "ui.generic.role-read",
    defaultMessage: "Read",
    description: "Role read",
  },
  roleWrite: {
    id: "ui.generic.role-write",
    defaultMessage: "Write",
    description: "Role write",
  },
  roleApprove: {
    id: "ui.generic.role-approve",
    defaultMessage: "Approve",
    description: "Role approve",
  },
});

export default generic;
