import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Image, Spin } from "antd";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { useQuery } from "react-query";
import { FileIcon } from "react-file-icon";
import { IconProps, defaultIconProps } from "../../../UiProperties/IconProps";
import api from "../../../api/api";

type ItemImageProps = {
  item: ItemPopulated;
  customStyle: React.CSSProperties | undefined;
  iconSize: "small" | "large";
};

export const ItemImage: React.FC<ItemImageProps> = ({
  item,
  customStyle,
  iconSize,
}) => {
  const getExtension = (fileName: string | undefined) => {
    if (!fileName) {
      return "";
    }

    const lastIndex = fileName.lastIndexOf(".");
    if (lastIndex === -1) {
      return "";
    }
    return fileName.slice(lastIndex + 1);
  };

  const query = useQuery(
    ["thumbnail", item.id],
    async () => {
      if (item.files.filter((file) => file.role === "thumbnail").length > 0) {
        const response = await api.items.getThumbnail(item.id);

        return response.url;
      }
    },
    {
      refetchInterval: 25 * 60 * 1000,
    }
  );

  return item.files.find((file) => file.role === "thumbnail") ? (
    query.isSuccess ? (
      <Image
        src={query.data}
        style={{ ...customStyle, objectFit: "contain" }}
      ></Image>
    ) : (
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    )
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          position: "relative",
          bottom: "4px",
          width: iconSize === "large" ? "140px" : "40px",
          height: iconSize === "large" ? "100%" : "40px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <FileIcon
          extension={
            getExtension(
              item.files.find(
                (file) =>
                  file.role === "other" ||
                  file.role === "revit" ||
                  file.role === "family"
              )?.name
            ) || "!"
          }
          {...(IconProps[
            getExtension(
              item.files.find(
                (file) =>
                  file.role === "other" ||
                  file.role === "revit" ||
                  file.role === "family"
              )?.name
            ).toString()
          ] || defaultIconProps)}
        />
      </div>
    </div>
  );
};
