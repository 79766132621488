import useApiGetOne from "./useApiGetOne";
import useApi from "./useApi";
import useApiDelete from "./useApiDelete";
import type { Paginated } from "@formitas-ag/formitable/lib/EditDrawer.types";
import {
  apiOptionstype,
  apiType,
  configType,
  idBasedData,
} from "@formitas-ag/formitable/lib/EditDrawer.types";
import { errorThrower } from "../api/utils/errorThrower";
import {
  update as updateUtil,
  create as createUtil,
  get as getUtil,
} from "../api/utils/endpoint.utils";
import axiosService from "../api/fetch/axios.service";
import { Response } from "@formitas-ag/bimfiles-types";
import { rootQueryClient } from "..";
import { QueryClient } from "react-query";
// import { QueryClient as QueryClientFormitas } from "@formitas-ag/formitable/node_modules/react-query/types/core/queryClient";

export default function useApiAll<dataType extends idBasedData>(
  /** Optional parameters that are used to construct the main list api object */
  mainListParams?: {
    options: apiOptionstype;
    service: string;
  },
  /** Optional parameters that are used to CONSTRUCT the api object */
  optionalParams?: {
    dynamicFilterQueryAddition?: React.MutableRefObject<Record<string, any>>;
  }
): apiType<dataType> {
  const update: apiType<dataType>["update"] = async (serviceName, id, data) => {
    try {
      const response = await updateUtil<dataType, dataType>(
        serviceName,
        id,
        data as dataType
      ).catch((e) => {
        throw new Error(e);
      });

      if (typeof response === "undefined")
        throw new Error(
          `Did not receive a response from the update call for id ${id} in service ${serviceName}`
        );

      return response;
    } catch (e) {
      errorThrower("Error updating data", e);
      throw new Error("(typescript fix, errorThrower handles that)");
    }
  };

  const create: apiType<dataType>["create"] = async (serviceName, data) => {
    try {
      const response = await createUtil<dataType, dataType>(
        serviceName,
        data
      ).catch((e) => {
        throw new Error(
          `Failed to create in service ${serviceName}: ${errorThrower(e)}`
        );
      });

      return response;
    } catch (caughtError) {
      errorThrower("Error creating data", caughtError);
      throw new Error("(typescript fix, errorThrower handles that)");
    }
  };

  const get: apiType<dataType>["get"] = (serviceName, id) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useApiGetOne<dataType>(serviceName, id);

  const find: apiType<dataType>["find"] = (
    serviceName: string,
    options: apiOptionstype
  ) => {
    // const params = {
    //   paginated: true,
    //   limit: typeof options.limit === "number" ? options.limit : 50,
    //   skip: options.skip || 0,
    //   ...(options.sortBy && options.sortDir
    //     ? { sortBy: options.sortBy, sortDir: options.sortDir }
    //     : {}),
    //   ...(options && options.filters
    //     ? flattenFilters({ filters: options.filters, method: "post" })
    //     : {}),
    //   ...(options.select ? { select: options.select } : {}),
    //   ...(options.queryAdditions || {}),
    // };

    // TODO: implement sort
    const page =
      typeof options.skip === "number" &&
      typeof options.limit === "number" &&
      options.skip &&
      options.limit
        ? Math.ceil(options.skip / options.limit)
        : 1;

    const dataResponse = getUtil<dataType>(
      serviceName,
      page,
      options.skip ?? 0
    ).then((response) => {
      if(!response) throw new Error("No response from API");

      const skip = response.page * response.size;
      const paginated: Paginated<dataType> = {
        limit: typeof options.limit === "number" ? options.limit : 0,
        total: response.totalDocuments,
        skip,
        data: response.data,
      };
      return paginated;
    });

    return dataResponse;
  };

  const list: apiType<dataType>["list"] = (serviceName, iOptions) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useApi(serviceName, iOptions);

  const deleteFunction: apiType<dataType>["deleteFunction"] = (serviceName) =>
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useApiDelete(serviceName);

  const getRest: apiType<dataType>["getRest"] = async (url, parseJson) => {
    // const response = Axios({
    //   url: url,
    //   method: "GET",
    //   transformResponse: parseJson === false ? (res) => res : undefined,
    // });

    const response = await axiosService.get<Response<dataType>>(url);

    if (response.data && response.data.success) {
      return response.data.result;
    } else
      throw new Error(
        `Failed with getRest, status response is ${response.statusText}`
      );
  };

  const postRest: apiType<dataType>["postRest"] = async (url, data) => {
    const response = await axiosService.post<Response<dataType>>(url, data);
    if (response.data && response.data.success) {
      return response.data.result;
    } else
      throw new Error(
        `Failed with postRest, status response is ${response.statusText}`
      );
  };

  const mainList =
    mainListParams && mainListParams.service && mainListParams.options
      ? list(mainListParams.service, mainListParams.options)
      : undefined;

  // TODO: fake config for now
  const config: configType = {
    distinctGeneral: (serviceName, distinctField, distinctString) => ({
      distinctUrl: "foo",
      queryAdditions: {},
    }),
    restApi: {
      baseUrl: "fooUrl",
    },
    queryClient: rootQueryClient as unknown as QueryClient,
    idField: "id",
  };

  return {
    update,
    create,
    get,
    find,
    list,
    deleteFunction,
    getRest,
    postRest,
    updateOptions: {
      updateSupportsSet: false,
    },
    config,
    mainListParams,
    mainList,
    dynamicFilterQueryAddition: optionalParams?.dynamicFilterQueryAddition,
  };
}
