import axios from "axios";

/**
 * Uploads a single file to s3
 */
const uploadFile = async (
  file: Blob,
  uploadUrl: string,
  setProgress: (progress: number) => void
): Promise<void> => {
  const response = await axios.put(uploadUrl, file, {
    headers: { "Content-Type": "application/octet-stream" },
    onUploadProgress: (event) => {
      setProgress(event.loaded / event.total);
    },
  });

  if (response.status !== 200) {
    throw new Error(`Failed to upload file. Status code: ${response.status}`);
  }

  return Promise.resolve();
};

export default uploadFile;
