import { Select } from "antd";
import {
  DistinctSearchCategory,
  DistinctSearchResultType,
  FilterType,
} from "@formitas-ag/bimfiles-types/lib/search";
import { SelectProps } from "antd/lib/select";
import { useQuery } from "react-query";
import { useMemo, useRef } from "react";
import { Category, Pagination } from "@formitas-ag/bimfiles-types/lib";
import useFiltersStore from "../../../state/filtersStore";
import TagRender from "./TagRender";
import api from "../../../api/api";

type Props = {
  type: "filter" | "all";
  selectProps?: SelectProps;
};

const CategoryDropdown = (componentProps: Props) => {
  const filters = useFiltersStore((state) => state.currentFilters);
  const queryData = useRef<Pagination<Category> | DistinctSearchResultType>([]);

  // Get the distinct categories based on the filters
  const query = useQuery(
    componentProps.type === "all"
      ? ["categories", 1, 100000]
      : ["distinctCategories", filters],
    async () => {
      let categories: Pagination<Category> | DistinctSearchResultType;

      if (componentProps.type === "all") {
        categories = await api.categories.get(1, 100000, "writeable");
      } else {
        categories = await api.search.distinct(
          filters,
          "categoryId" as FilterType
        );
      }

      queryData.current = categories;

      return categories;
    }
  );

  // Create the options needed for the dropdown based on the current filter
  const options = useMemo(() => {
    const categoryOptions: {
      label: string;
      value: string;
    }[] = [];
    if (query.data !== undefined) {
      const queryData =
        componentProps.type === "all"
          ? (query.data as Pagination<Category>).data
          : (query.data as DistinctSearchCategory[]);

      const length = queryData.length;

      for (let i = 0; i < length; i++) {
        const category = queryData[i];
        categoryOptions.push({
          label: category.title,
          value: category.id,
        });
      }
    }

    return categoryOptions;
  }, [query.data, componentProps.type]);

  return (
    <Select
      size="large"
      mode="multiple"
      showSearch
      showArrow
      allowClear
      style={{ width: "100%" }}
      loading={query.isLoading}
      options={options}
      tagRender={(props) => (
        <TagRender
          {...props}
          data={
            componentProps.type === "all"
              ? (queryData.current as Pagination<Category>).data
              : (queryData.current as DistinctSearchCategory[])
          }
          options={options}
        />
      )}
      filterOption={(input, option: any) =>
        option!.label!.toString().toLowerCase().includes(input.toLowerCase())
      }
      optionFilterProp="label"
      {...componentProps.selectProps}
    />
  );
};

export default CategoryDropdown;
