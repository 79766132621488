import { Button, Modal, Tooltip } from "antd";
import { useIntl } from "react-intl";
import { useCreateOrUpdateItemStore } from "../../../state/createOrUpdateItemStore";
import UpdateItemForm from "./CreateOrUpdateItemForm";
import { getLogger } from "../../../utils/logger.utils";
import useUpdateItem from "../../../hooks/useCreateOrUpdateFiles/useUpdateItem";
import useSortFiles from "../../../hooks/useCreateOrUpdateFiles/useSortFiles";
import useCreateItem from "../../../hooks/useCreateOrUpdateFiles/useCreateItem";
import useCreateOrUpdateFiles from "../../../hooks/useCreateOrUpdateFiles";
import { useCreateOrUpdateMultipleFiles } from "../../../hooks/useCreateOrUpdateMultipleFiles";
import translations from "../../../translations/constants/translations";
import useValidateUpload from "../../../hooks/useCreateOrUpdateFiles/useValidateUpload";
import { useEffect, useState } from "react";

const logger = getLogger("UpdateItemModal");

const UpdateItemModal = () => {
  const intl = useIntl();
  const mode = useCreateOrUpdateItemStore((state) => state.mode);
  const itemMode = useCreateOrUpdateItemStore((state) => state.itemMode);
  const { isCreating, saveCreate } = useCreateItem();
  const { isUpdating, saveUpdate } = useUpdateItem();
  const { getUpload } = useCreateOrUpdateMultipleFiles();
  const { stopCreateOrUpdate } = useCreateOrUpdateFiles();
  const { validateUpload } = useValidateUpload();
  const uploads = useCreateOrUpdateItemStore((state) => state.uploads);

  useSortFiles();

  const loading = isCreating || isUpdating;

  const [areUploadsValid, setAreUploadsValid] = useState(false);

  useEffect(() => {
    const areUploadsValid =
      getUpload(-1) === undefined
        ? false
        : validateUpload(getUpload(-1)!).success;
    setAreUploadsValid(areUploadsValid);
  }, [uploads]);

  const save = () => {
    if (mode === "create") saveCreate();
    if (mode === "update") saveUpdate();
  };

  return (
    <Modal
      title={
        mode === "create"
          ? intl.formatMessage(
              translations.ui.upload.modals.singleFileModalCreateTitle
            )
          : mode === "update"
          ? intl.formatMessage(
              translations.ui.upload.modals.singleFileModalUpdateTitle,
              {
                title: getUpload(-1)?.oldItem?.title ?? "",
              }
            )
          : ""
      }
      style={{ padding: "2rem" }}
      centered
      open={mode !== undefined && itemMode === "single"}
      onOk={stopCreateOrUpdate}
      onCancel={stopCreateOrUpdate}
      width={"100%"}
      zIndex={1000}
      destroyOnClose={true}
      footer={[
        <Button
          key="close"
          type="default"
          onClick={stopCreateOrUpdate}
          disabled={loading}
          loading={loading}
          style={{
            marginRight: ".5rem",
          }}
        >
          {intl.formatMessage(translations.ui.generic.close)}
        </Button>,
        <Tooltip
          title={
            !areUploadsValid
              ? intl.formatMessage(
                  translations.ui.upload.modals
                    .multiFileModalMakeDisabledTooltip
                )
              : undefined
          }
          color="white"
        >
          <Button
            key="update"
            type="primary"
            onClick={save}
            disabled={loading || !areUploadsValid}
            loading={loading}
          >
            {intl.formatMessage(translations.ui.generic.save)}
          </Button>
        </Tooltip>,
      ]}
    >
      {itemMode === "single" && (
        <UpdateItemForm isSaveButtonEnabled={validateUpload(getUpload(-1)!).error !== "missingFiles"} />
      )}
    </Modal>
  );
};

export default UpdateItemModal;
