import { ApiDrivenTable } from "@formitas-ag/formitable";
import {
  formFieldType,
  typeEnum,
} from "@formitas-ag/formitable/lib/EditDrawer.types";
import { Col, Row } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { ColumnsType } from "antd/lib/table";
import { User } from "@formitas-ag/bimfiles-types/lib";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import useApiAll from "../../hooks/useApiAll";
import { useAuthStore } from "../../state/authStore";
import { getApiDrivenTableLanguage } from "./Common/apiTableLanguage";
import DateRender from "./Common/DateRender";
import parameterRender from "./Common/parameterRender";
import ActionDropdown from "./Users/ActionDropdown/ActionDropdown";
import RoleRender from "./Users/UserRoleRender";
import StateRender from "./Users/StateRender";
import UpdateUserPasswordModal from "./AuthenticationAndPermissionModal/UpdateUserPasswordModal";
import { useViewStore } from "../../state/viewStore";
import { UserCategoriesModal } from "./RoleModal/UserCategoriesModal";
import { UpdateUserRoleModal } from "./AuthenticationAndPermissionModal/UpdateUserRoleModal";

const messages = defineMessages({
  title: {
    id: "admin.users.title",
    defaultMessage: "Users",
    description: "Title of the user administration page",
  },
  emailField: {
    id: "admin.users.fields.email",
    defaultMessage: "Email",
    description: "Email field for a user",
  },
  firstNameField: {
    id: "admin.users.fields.first_name",
    defaultMessage: "First Name",
    description: "First name field for a user",
  },
  lastNameField: {
    id: "admin.users.fields.last_name",
    defaultMessage: "Last Name",
    description: "Last name field for a user",
  },
  password: {
    id: "admin.users.fields.password",
    defaultMessage: "Password",
    description: "Password field for a user",
  },
  confirmPassword: {
    id: "admin.users.fields.confirm_password",
    defaultMessage: "Confirm",
    description: "Confirm password field for a user",
  },
  roleField: {
    id: "admin.users.fields.role",
    defaultMessage: "Role",
    description: "Role field for a user",
  },
  stateField: {
    id: "admin.users.fields.state",
    defaultMessage: "Active",
    description: "State field for a user",
  },
  createdAtField: {
    id: "admin.users.fields.created_at",
    defaultMessage: "Created At",
    description: "Created at field for a user",
  },
  updatedAtField: {
    id: "admin.users.fields.updated_at",
    defaultMessage: "Updated At",
    description: "Updated at field for a user",
  },
  emailRequired: {
    id: "admin.users.fields.email.required",
    defaultMessage: "Email is required",
    description: "Message when no email is present",
  },
  firstNameRequired: {
    id: "admin.users.fields.first_name.required",
    defaultMessage: "First Name is required",
    description: "Message when no first name is present",
  },
  lastNameRequired: {
    id: "admin.users.fields.last_name.required",
    defaultMessage: "Last Name is required",
    description: "Message when no last name is present",
  },
  passwordRequired: {
    id: "admin.users.fields.password.required",
    defaultMessage: "Password is required",
    description: "Message when no password is present",
  },
  confirmPasswordRequired: {
    id: "admin.users.fields.confirm_password.required",
    defaultMessage: "Please confirm password",
    description: "Message when no confirm password is present",
  },
  notPasswordMatch: {
    id: "admin.users.fields.not_password_match",
    defaultMessage: "Passwords do not match",
    description: "Message when the passwords do not match",
  },
});

const getFormFields = (intl: IntlShape): formFieldType[] => {
  return [
    {
      title: intl.formatMessage(messages.emailField),
      dataIndex: "email",
      type: typeEnum.input,
      key: "email",
      rules: [
        {
          required: true,
          message: intl.formatMessage(messages.emailRequired),
        },
        {
          type: "email",
        },
      ],
    },
    {
      title: intl.formatMessage(messages.firstNameField),
      dataIndex: "preName",
      type: typeEnum.input,
      key: "preName",
      rules: [
        {
          required: true,
          message: intl.formatMessage(messages.firstNameRequired),
        },
      ],
    },
    {
      title: intl.formatMessage(messages.lastNameField),
      dataIndex: "name",
      type: typeEnum.input,
      key: "name",
      rules: [
        {
          required: true,
          message: intl.formatMessage(messages.lastNameRequired),
        },
      ],
    },
  ];
};

export const UserAdminPage: React.FC = () => {
  const selectedOrganisationId = useAuthStore(
    (state) => state.selectedOrganisationId
  );

  const intl = useIntl();
  const api = useApiAll<User>({ options: { limit: 20 }, service: "users" });

  if (!selectedOrganisationId) return <></>;

  const getColumns = (
    organisationId: string,
    intl: IntlShape
  ): ColumnsType<User> => {
    return [
      { title: intl.formatMessage(messages.emailField), dataIndex: "email" },
      {
        title: intl.formatMessage(messages.firstNameField),
        dataIndex: "preName",
      },
      { title: intl.formatMessage(messages.lastNameField), dataIndex: "name" },
      {
        title: intl.formatMessage(messages.roleField),
        dataIndex: "organisations",
        render: parameterRender<User, "organisations">((organisations) => (
          <RoleRender
            role={
              organisations.find((o) => o.organisationId === organisationId)
                ?.role
            }
          />
        )),
      },
      {
        title: intl.formatMessage(messages.stateField),
        dataIndex: "id",
        render: parameterRender<User, "id">((id) => <StateRender id={id} />),
      },
      {
        title: intl.formatMessage(messages.createdAtField),
        dataIndex: "createdAt",
        render: parameterRender<User, "createdAt">((date) => (
          <DateRender date={date} />
        )),
      },
      {
        title: intl.formatMessage(messages.updatedAtField),
        dataIndex: "updatedAt",
        render: parameterRender<User, "updatedAt">((date) => (
          <DateRender date={date} />
        )),
      },
      {
        title: "",
        dataIndex: "id",
        render: parameterRender<User, "id">((id) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActionDropdown
              api={api}
              selectedUserId={id}
              setSelectedUserIdForPermissionEditing={(id) =>
                useViewStore.setState({
                  editRoleUserId: id,
                })
              }
            />
          </div>
        )),
      },
    ];
  };

  return (
    <Row>
      <Col span={24}>
        <PageHeader title={intl.formatMessage(messages.title)} />
      </Col>
      <Col span={24}>
        <ApiDrivenTable
          columns={getColumns(selectedOrganisationId, intl)}
          serviceName="users"
          api={api}
          language={getApiDrivenTableLanguage(intl)}
          rowKey="id"
          reloadButton
          editInfo={{
            formFields: getFormFields(intl),
            editable: true,
            creatable: true,
          }}
        />
      </Col>

      <UpdateUserPasswordModal />
      <UserCategoriesModal />
      <UpdateUserRoleModal api={api} />
    </Row>
  );
};
