import { devtools, persist } from "zustand/middleware";
import { create } from "zustand";

type ostTranslation = {
  ostName: string;
  english: string;
  german: string;
  russian: string;
};

type LanguageStore = {
  language: "en" | "de";
  setLanguage: (language: "en" | "de") => void;

  type: "default" | "selected";
  setType: (type: "default" | "selected") => void;

  ostTranslations: ostTranslation[] | null;
  setOstTranslations: (ostTranslations: ostTranslation[]) => void;
};

export const useLanguageStore = create<LanguageStore>()(
  devtools(
    persist(
      (set) => ({
        // Sets default language by getting language without region code
        language: (navigator.language.split(/[-_]/)[0] || "de") as "en" | "de",
        setLanguage: (language) => {
          set({ language });
        },

        type: "default",
        setType: (type) => {
          set({ type });
        },

        ostTranslations: null,
        setOstTranslations: (ostTranslations) => {
          set({ ostTranslations });
        },
      }),
      {
        name: "lang",
      }
    )
  )
);
