import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import UpdateHeader from "../Common/ItemHeader";
import CategoryDropdown from "../../Dropdowns/CategoryDropdown";
import { useCreateOrUpdateMultipleFiles } from "../../../../hooks/useCreateOrUpdateMultipleFiles";
import translations from "../../../../translations/constants/translations";
import { useCreateOrUpdateItemStore } from "../../../../state/createOrUpdateItemStore";

type Props = {
  mode: "single" | "multiple" | "multipleAll";
  index?: number;
};

const UpdateCategory = (props: Props) => {
  const index = props.index ?? -1;

  const intl = useIntl();

  const { getUpload, getItem, setItem } = useCreateOrUpdateMultipleFiles();

  const oldItem = getUpload(index)?.oldItem;
  const item = getUpload(index)?.item;
  const [category, setCategory] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (oldItem) {
      setCategory(oldItem.categoryId);
    }
  }, [oldItem]);

  useEffect(() => {
    if (
      item?.categoryId &&
      item.categoryId !== category &&
      props.mode !== "multipleAll"
    ) {
      setCategory(item?.categoryId);
    }
  }, [item?.categoryId]);

  const onChange = (newCategory: (string | undefined)[]) => {
    const lastChosenCategory = newCategory[newCategory.length - 1];

    if (props.mode !== "multipleAll") {
      setItem(index, {
        categoryId: lastChosenCategory,
      });
    } else {
      setCategory(lastChosenCategory);

      useCreateOrUpdateItemStore.setState({
        uploads: useCreateOrUpdateItemStore.getState().uploads.map((upload) => {
          return {
            ...upload,
            item: {
              ...upload.item,
              categoryId: lastChosenCategory,
            },
          };
        }),
      });
    }
  };

  //TODO: @Fady adjust this didChange method
  const didChange = false && getItem(index)?.categoryId !== oldItem?.categoryId;
  const resetChange = () => {
    //TODO: @Fady adjust this resetChange method
    setCategory(JSON.parse(JSON.stringify(oldItem!.categoryId)));
  };

  return (
    <>
      {props.mode === "single" && (
        <UpdateHeader
          title={intl.formatMessage(translations.ui.meta.fields.categoryName)}
          isResetable={didChange}
          onReset={resetChange}
          required={true}
        />
      )}
      <CategoryDropdown
        type="all"
        selectProps={{
          id: "category",
          value: category ? [category] : [],
          onChange: onChange,
          placeholder: intl.formatMessage(
            translations.ui.meta.fields.categoryPlaceholder
          ),
        }}
      />
    </>
  );
};

export default UpdateCategory;
