import { Form, Select } from "antd";
import { DistinctSearchString, FilterType } from "@formitas-ag/bimfiles-types/lib/search";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import api from "../../../../../api/api";
import useFiltersStore from "../../../../../state/filtersStore";
import FormProps from "../../../../../UiProperties/FormProps";

export const RevitVersionFilterInput = () => {
  const filters = useFiltersStore((state) => state.currentFilters);
  const setFilters = useFiltersStore((state) => state.setFilters);

  // Sets the filters in the state when something is selected
  const onChange = (value: string) => {
    if (query.data && value) {
      setFilters({
        ...filters,
        revitVersion: [query.data[Number(value)] as string],
      });
    }
  };

  // Removes the revit version filter from the state
  const onClear = () => {
    setFilters({ ...filters, revitVersion: [] });
  };

  // Gets the distinct revit versions based on the current filter (from state)
  const query = useQuery(["revitVersion", filters], async () => {
    const filterItems = await api.search.distinct(
      filters,
      "revitVersion" as FilterType
    );
    return filterItems;
  });

  // Creates the options based on the distinct revit versions received
  const revitVersionOptions = () => {
    const children = [];
    if (query.data) {
      for (let i = 0; i < query.data!.length; i++) {
        children.push(
          <Select.Option key={i}>{`${query.data[i]} `}</Select.Option>
        );
      }
    }
    return children;
  };

  // Sets the input default value if it already has a value in state
  let currentData = query.data;
  let currentItem: DistinctSearchString | undefined;
  if (
    typeof currentData !== "undefined" &&
    typeof currentData === "object" &&
    Array.isArray(currentData)
  ) {
    currentItem = (currentData as DistinctSearchString[]).find(
      (item) => typeof item === "number" && item === filters.revitVersion[0]
    ) as DistinctSearchString;
  }

  return (
    <Form.Item
      style={{ ...FormProps.formItem, margin: "0.2rem 0.5rem 0.2rem 0" }}
      // label={
      //   <label style={TextProps.label}>
      //     <FormattedMessage
      //       id="component.revit-version-filter.label"
      //       defaultMessage="Revit Version"
      //       description="Label for the revit version filter"
      //     />
      //   </label>
      // }
    >
      <Select
        showSearch
        allowClear
        open
        onClear={onClear}
        style={{ width: "auto !important", minWidth: "10rem" }}
        placeholder={
          <FormattedMessage
            id="component.revit-version-filter.placeholder"
            defaultMessage="Revit Version"
            description="Placeholder for the revit version filter"
          />
        }
        onChange={onChange}
        onDropdownVisibleChange={() => {
          query.refetch();
        }}
        value={currentItem ? currentItem : undefined}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option!.children as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      >
        {revitVersionOptions()}
      </Select>
    </Form.Item>
  );
};
