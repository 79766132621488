import { defineMessages } from "react-intl";

const modals = defineMessages({
  editFilesModalTitle: {
    id: "ui.upload.modals.edit-files-modal-title",
    defaultMessage: "Edit Files",
    description: "Edit files modal title",
  },
  singleFileModalCancel: {
    id: "ui.upload.modals.single-file-modal-cancel",
    defaultMessage: "Reset Changes",
    description: "Reset button text",
  },
  singleFileModalMake: {
    id: "ui.upload.modals.single-file-modal-make",
    defaultMessage: "Register changes",
    description: "Register changes button text",
  },
  singleFileModalMakeTooltip: {
    id: "ui.upload.modals.single-file-modal-make-tooltip",
    defaultMessage: "Make changes to the files to register them",
    description: "Make changes button tooltip",
  },
  singleFileModalCreateTitle: {
    id: "ui.upload.modals.single-file-modal-create-title",
    defaultMessage: "Creating new item",
    description: "Create files modal title",
  },
  singleFileModalUpdateTitle: {
    id: "ui.upload.modals.single-file-modal-update-title",
    defaultMessage: "Updating {title}",
    description: "Update files modal title",
  },
  multiFileModalTitle: {
    id: "ui.upload.modals.multi-file-modal-title",
    defaultMessage: "Change files",
    description: "Multi files edit modal title",
  },
  multiFileModalCancel: {
    id: "ui.upload.modals.multi-file-modal-cancel",
    defaultMessage: "Cancel",
    description: "Cancel button text",
  },
  multiFileModalHideIgnoredFilesTitle: {
    id: "ui.upload.modals.multi-file-modal-hide-ignored-files-title",
    defaultMessage: "Shows or hides ignored files",
    description: "The native tooltip of the hide ignored files switch",
  },
  multiFileModalHideIgnoredFilesCheckedTitle: {
    id: "ui.upload.modals.multi-file-modal-hide-ignored-files-checked-title",
    defaultMessage: "Hide Ignored Files",
    description: "The title of the hide ignored files switch when checked",
  },
  multiFileModalHideIgnoredFilesUncheckedTitle: {
    id: "ui.upload.modals.multi-file-modal-hide-ignored-files-unchecked-title",
    defaultMessage: "Show Ignored Files",
    description: "The title of the hide ignored files switch when unchecked",
  },
  multiFileModalShowDetailsTitle: {
    id: "ui.upload.modals.multi-file-modal-show-details-title",
    defaultMessage: "Shows or hides the details of the files",
    description: "The native tooltip of the show details switch",
  },
  multiFileModalShowDetailsCheckedTitle: {
    id: "ui.upload.modals.multi-file-modal-show-details-checked-title",
    defaultMessage: "Show Details",
    description: "The title of the show details switch when checked",
  },
  multiFileModalShowDetailsUncheckedTitle: {
    id: "ui.upload.modals.multi-file-modal-show-details-unchecked-title",
    defaultMessage: "Hide Details",
    description: "The title of the show details switch when unchecked",
  },
  multiFileModalMake: {
    id: "ui.upload.modals.multi-file-modal-make",
    defaultMessage:
      "Upload {families, plural, one {# family} other {# families}}",
    description: "Upload families button text",
  },
  multiFileModalUpdateMake: {
    id: "ui.upload.modals.multi-file-modal-update-make",
    defaultMessage:
      "Update {families, plural, one {# family} other {# families}}",
    description: "Update families button text",
  },
  multiFileModalMakeTooltip: {
    id: "ui.upload.modals.multi-file-modal-make-tooltip",
    defaultMessage:
      "Register families from your file system or Revit to upload them",
    description: "Upload families button tooltip",
  },
  multiFileModalMakeDisabledTooltip: {
    id: "ui.upload.modals.multi-file-modal-make-disabled-tooltip",
    defaultMessage: "Add files, a title and a category before uploading",
    description: "Upload families button disabled tooltip",
  },
  multiFileModalUpdateMakeDisabledTooltip: {
    id: "ui.upload.modals.multi-file-modal-update-make-disabled-tooltip",
    defaultMessage: "Change files or other fields before updating",
    description: "Update families button disabled tooltip",
  },
  multiFileModalAllUploadsTitle: {
    id: "ui.upload.modals.multi-file-modal-all-uploads-title",
    defaultMessage: "All Uploads",
    description: "All uploads tab title",
  }
});
export default modals;
