import { defineMessages } from "react-intl";

const modals = defineMessages({
  denyItemModalTitle: {
    id: "ui.modals.deny-item-modal-title",
    defaultMessage: "Deny Reason",
    description: "Title of the Deny Item modal",
  },
  denyItemModalDescription: {
    id: "ui.modals.deny-item-modal-description",
    defaultMessage: "Why are you denying this change?",
    description: "Description of the Deny Item modal",
  },
});

export default modals;
