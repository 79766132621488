const TableProps = {
  thumbnails: {
    maxHeight: "44px",
    maxWidth: "44px",
    marginRight: "4px",
    border: "none",
  },
  coloredTag: {
    paddingLeft: "6px",
    paddingRight: "6px",
    paddingTop: "2px",
    paddingBottom: "2px",
    margin: "2px",
    color: "#fff",
    fontSize: "0.75rem",
    fontWeight: "600",
    lineHeight: "14px",
  },
};

export default TableProps;
