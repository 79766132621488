import { Modal } from "antd";
import FileList from "./CreateOrUpdateFileModal/FileList";
import { UpdatingFile } from "../../../../utils/types/updateFilesTypes";
import { useIntl } from "react-intl";
import translations from "../../../../translations/constants/translations";
import { useCreateOrUpdateMultipleFiles } from "../../../../hooks/useCreateOrUpdateMultipleFiles";
import { CreateOrUpdateFileModalFooter } from "./CreateOrUpdateFileModalFooter";
import { useEffect, useState } from "react";

type Props = {
  index: number;
  mode: "single" | "multiple";

  open: boolean;
  setOpen: (open: boolean) => void;
};

const UpdateFileModal = (props: Props) => {
  const intl = useIntl();

  const { getUpload } = useCreateOrUpdateMultipleFiles();

  const [updatingFiles, setUpdatingFiles] = useState<UpdatingFile[]>([]);

  const upload = getUpload(props.index);

  useEffect(() => {
    if (upload) {
      setUpdatingFiles(upload.updatingFiles);
    }
  }, [upload]);

  useEffect(() => {
    if (updatingFiles.length === 0 && props.open) {
      props.setOpen(false);
    }
  }, [updatingFiles]);

  return (
    <Modal
      destroyOnClose
      zIndex={1001}
      centered={true}
      closable={false}
      width={"75%"}
      style={{
        minWidth: "600px",
      }}
      open={props.open}
      footer={
        <CreateOrUpdateFileModalFooter
          {...props}
          updatingFiles={updatingFiles}
        />
      }
      title={intl.formatMessage(
        props.mode === "single"
          ? translations.ui.upload.modals.editFilesModalTitle
          : translations.ui.upload.modals.multiFileModalTitle
      )}
    >
      <FileList {...props} files={updatingFiles} />
    </Modal>
  );
};
export default UpdateFileModal;
