import { Response, Tag } from "@formitas-ag/bimfiles-types";
import { get, create, update, remove } from "./utils/endpoint.utils";
import axiosService from "./fetch/axios.service";
import { errorRenderer } from "./utils/errorThrower";
import { EndpointOptions } from "./utils/endpoint.options.utils";

type CreateTagData = Pick<
  Tag,
  "title" | "description" | "color" | "organisationId"
>;
type UpdateTagData = Partial<CreateTagData>;

const tags = {
  get: (page: number, size: number, options?: EndpointOptions) =>
    get<Tag>("tags", page, size, options),
  getOne: (
    id: string,
    withDeleted?: boolean,
    options?: EndpointOptions
  ): Promise<Tag> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/tags/${id}?withDeleted=${withDeleted}`;
        const response = await axiosService.get<Response<Tag>>(url);

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        if (options?.ignoreExceptions) {
          resolve(undefined as any);
        }
        reject(response.statusText);
      } catch (error) {
        if (options?.ignoreExceptions) {
          resolve(undefined as any);
        }
        reject(errorRenderer(error));
      }
    }),
  create: (data: Omit<CreateTagData, "organisationId"> | CreateTagData) =>
    create<Tag, Omit<CreateTagData, "organisationId"> | CreateTagData>(
      "tags",
      data
    ),
  update: (id: string, data: UpdateTagData) =>
    update<Tag, UpdateTagData>("tags", id, data),
  delete: (id: string) => remove("tags", id),
  merge: (oldTagsIds: string[], newTagId: string): Promise<Tag> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/tags/${newTagId}/merge`;
        const response = await axiosService.post<Response<Tag>>(
          url,
          oldTagsIds
        );

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
};

export default tags;
