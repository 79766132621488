import { Alert, Typography } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getRevit } from "../../../../../api/revit";
import { LocalFile } from "../../../../../hooks/useUpload";
import { RevitOutput, useRevitStore } from "../../../../../state/revitStore";
import { useUploadStore } from "../../../../../state/uploadStore";
import { RevitExportButton } from "./Button/RevitExportButton";
import { Blob } from "blob-polyfill";

const startExport = () => {
  if (useRevitStore.getState().currentMode === undefined) {
    useRevitStore.setState({ currentMode: "export", currentError: undefined });

    getRevit().receiveImportTriggered();
  }
};

export const convertBase64ToBlob = (base64: string) => {
  base64 = decodeURIComponent(base64);

  let sliceSize = 512;
  let byteCharacters = atob(base64);
  let byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays);
};

const convertBase64ToBlobNew = (base64: string) => {
  return Buffer.from(base64, "base64").toString();
};

/**
 * Gets executed when both files and parameters are not empty
 * @param revitOutput
 */
const continueExport = (revitOutput: RevitOutput) => {
  useRevitStore.setState({
    currentMode: undefined,
  });

  const localFiles: LocalFile[] = revitOutput.files.map((file) => {
    const content = convertBase64ToBlob(file.data);

    return {
      // @ts-ignore
      file: new File([content], file.name),
      name: file.name,
      size: content.size,
      revitRole: file.role === "sidecar" ? "sidecar-automatic" : file.role, //revit only outputs the role sidecar but it should be sidecar-automatic
      source: "revit",
    };
  });

  //set the local files in the upload store
  useUploadStore.setState({
    currentFiles: localFiles,
  });
};

const RevitExport = () => {
  const [currentExportState, setCurrentExportState] = useState(0);
  const revitOutput = useRevitStore((state) => state.revitOutput);
  const currentError = useRevitStore((state) => state.currentError);
  const currentMode = useRevitStore((state) => state.currentMode);

  const exportStateDescriptionMap: { [key: number]: string } = {
    0: "",
    1: "Loading selected family from revit",
    2: "Uploading family to bim.files",
    50: "You need to select one family in Revit",
    51: "You need to select one family in Revit",
    99: "Could not establish connection to Revit",
  };

  useEffect(() => {
    if (useRevitStore.getState().currentMode === "export") {
      if (
        revitOutput &&
        revitOutput.files &&
        revitOutput.files.length > 0 &&
        revitOutput.parameters
      ) {
        //we can safely cast here as we have proven all elements are not undefined
        setCurrentExportState(2);
        continueExport(revitOutput as RevitOutput);
      }
    }
  }, [revitOutput]);

  useEffect(() => {
    if (currentError) {
      if (currentError.type === "nothing-selected") {
        setCurrentExportState(50);
      } else if (currentError.type === "wrong-selection") {
        setCurrentExportState(51);
      } else {
        //we display the generic error
        setCurrentExportState(-1);
      }
    }
  }, [currentError]);

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography.Title style={{ textAlign: "center" }} level={2}>
        <FormattedMessage
          id="component.upload_modal.revit.title"
          defaultMessage="From Revit"
          description="Subtitle of revit section in upload modal"
        />
      </Typography.Title>

      <div
        style={{
          flexGrow: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography.Paragraph style={{ textAlign: "center" }}>
          <FormattedMessage
            id="component.upload_modal.revit.description"
            defaultMessage="Select a component in Revit and click this button to start the export process"
            description="Description of revit export process"
          />
        </Typography.Paragraph>

        <RevitExportButton
          loading={currentMode === "export"}
          onClick={() => {
            if (currentMode === undefined) {
              setCurrentExportState(1);
              startExport();
            }
          }}
        />
        {currentExportState < 0 && (
          <Alert message={currentError?.content} type="error" />
        )}
        {currentExportState > 0 && (
          <Alert
            message={exportStateDescriptionMap[currentExportState]}
            type="info"
          />
        )}
      </div>
    </div>
  );
};

export default RevitExport;
