import { Typography } from "antd";
import { FileChange, Item } from "@formitas-ag/bimfiles-types";
import { defineMessages, FormattedMessage } from "react-intl";
import { usePlatform } from "../../../../hooks/usePlatform";
import FileTag from "../Common/FileTag";
import TimelineChangeWrapper from "./Common/TimelineChangeWrapper";
import TypeCatalogButton from "../../HomepageComponents/DownloadItem/TypeCatalogButton";
import DownloadItemButton from "../../HomepageComponents/DownloadItem/DownloadItemButton";
import ImportToRevitButton from "../../HomepageComponents/DownloadItem/ImportToRevitButton";

type Props = {
  item: Item;
  change: FileChange;
};

const TimelineFileChange = (props: Props) => {
  const platform = usePlatform();

  const messages = defineMessages({
    title: {
      id: "component.timeline.files.title",
      defaultMessage: "New Files",
      description: "Title of the changed files",
    },
  });

  return (
    <TimelineChangeWrapper
      key={props.change.id}
      titleChild={
        <Typography.Text>
          <FormattedMessage {...messages.title} />
        </Typography.Text>
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {props.change.files
            .filter(
              (file) =>
                file.role !== "thumbnail" && file.role !== "sidecar-automatic"
            )
            .map((file) => (
              <FileTag
                key={file.id}
                downloadItemId={
                  props.item.deletedAt ? undefined : props.item.id
                }
                file={file}
              />
            ))}
        </div>
        {!props.item.deletedAt && props.change.files.length > 0 && (
          <div>
            <TypeCatalogButton id={props.item.id} files={props.change.files} />

            {platform === "browser" ? (
              <DownloadItemButton id={props.item.id} item={props.item} existingChange={props.change} />
            ) : (
              <ImportToRevitButton
                id={props.item.id}
                files={props.change.files}
              />
            )}
          </div>
        )}
      </div>
    </TimelineChangeWrapper>
  );
};

export default TimelineFileChange;
