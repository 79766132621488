import { useEffect } from "react";
import {
  OverwriteItemStore,
  useOverwriteItemStore,
} from "../state/overwriteItemStore";
import { getLogger } from "../utils/logger.utils";

const logger = getLogger("useOverwriteItem");

const useOverwriteItem = () => {
  const currentOverwritingFile = useOverwriteItemStore(
    (state) => state.currentOverwritingFile
  );
  const overwritingFiles = useOverwriteItemStore(
    (state) => state.overwritingFiles
  );

  const addOverwritingFiles = (
    elements: Required<OverwriteItemStore>["currentOverwritingFile"]
  ) => {
    const existingOverwritingFiles = useOverwriteItemStore
      .getState()
      .overwritingFiles.concat(
        useOverwriteItemStore.getState().currentOverwritingFile ?? []
      )
      .flatMap((e) =>
        e.type === "single"
          ? [{ file: e.file, overwrittenFile: e.overwrittenFile }]
          : e.elements
      );

    const elementsArray =
      elements.type === "single"
        ? [{ file: elements.file, overwrittenFile: elements.overwrittenFile }]
        : elements.elements;

    const alreadyExistingFiles = elementsArray.filter((e) =>
      existingOverwritingFiles.some(
        (e2) => e2.overwrittenFile === e.overwrittenFile
      )
    );

    if (alreadyExistingFiles.length > 0) {
      logger.warn(
        `Tried to add overwriting files that already exist: ${alreadyExistingFiles
          .map((e) => e.overwrittenFile)
          .join(", ")}`
      );
      return;
    }

    useOverwriteItemStore.setState({
      overwritingFiles: useOverwriteItemStore
        .getState()
        .overwritingFiles.concat(elements),
    });
  };

  /**
   * If there is no file that is being overwritten but we have files that need overwriting, then we set the first file to be overwritten as the currentOverwritingFile
   */
  useEffect(() => {
    if (!currentOverwritingFile && overwritingFiles.length > 0) {
      //there is no file that is being overwritten but we have files that need overwriting
      const newCurrentOverwritingFile = overwritingFiles[0];

      useOverwriteItemStore.setState({
        currentOverwritingFile: newCurrentOverwritingFile,
        overwritingFiles: overwritingFiles.slice(1),
      });
      return;
    }
  }, [currentOverwritingFile, overwritingFiles]);

  return {
    currentOverwritingFile,
    addOverwritingFiles,
  };
};

export default useOverwriteItem;
