import useFindRole from "../../../../../hooks/useCreateOrUpdateFiles/useGetFileMeta";
import { UpdatingFile } from "../../../../../utils/types/updateFilesTypes";
import FileRoleTag from "../../../Timeline/Common/FileRoleTag";

type Props = {
  index: number;
  file: UpdatingFile;
};

export default (props: Props) => {
  const { getRole } = useFindRole();

  const role = getRole(props.index, props.file);

  return (
    <>
      {role.result === "success" && <FileRoleTag role={role.role} />}
      {role.result === "overwrite" && <FileRoleTag role={role.role} />}
    </>
  );
};
