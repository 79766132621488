import { CreateItemData, CreateItemResponse } from "@formitas-ag/bimfiles-types/lib/item";
import api from "../../api/api";
import { LocalFile } from "../useUpload";

export const rfaTest = new RegExp(".*\\.rfa", "i");
export const rvtTest = new RegExp(".*\\.rvt", "i");
export const txtTest = new RegExp(".*\\.txt", "i");
export const imageTest = new RegExp(".*\\.[jpeng]{3,4}", "i");

export const getRoleFromFileName = (
  fileName: string,
  wasFamilyFound: boolean
): CreateItemData["files"][0]["role"] => {
  if (rfaTest.test(fileName)) {
    return "family";
  }

  if (wasFamilyFound) {
    if (txtTest.test(fileName)) return "sidecar";
    if (imageTest.test(fileName)) return "thumbnail";
  }

  if (rvtTest.test(fileName)) return "revit";

  return "other";
};

export const getRevitRoleFromName = (
  fileName: string,
  wasFamilyFound: boolean
): LocalFile["revitRole"] => {
  if (rfaTest.test(fileName)) {
    return "family";
  }

  if (wasFamilyFound) {
    if (txtTest.test(fileName)) return "sidecar";
    if (imageTest.test(fileName)) return "thumbnail";
  }

  return undefined;
};

const createNewItem = async (
  fileArray: LocalFile[],
  organisationId: string
): Promise<CreateItemResponse> => {
  const files: CreateItemData["files"] = fileArray.map((file) => {
    return {
      name: file.name,
      role: file.revitRole ?? "other",
    };
  });

  return undefined!;
};

export default createNewItem;
