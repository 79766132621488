import { CombinedAccessToken, Organisation } from "@formitas-ag/bimfiles-types";
import { devtools, persist } from "zustand/middleware";
import { create } from "zustand";
import { rootQueryClient } from "..";

type AuthStore = {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;

  user?: CombinedAccessToken["user"];
  setUser: (user: AuthStore["user"]) => void;

  possibleOrganisations: Pick<
    Organisation,
    "id" | "title" | "description" | "host" | "createdAt" | "flags"
  >[];
  setPossibleOrganisations: (
    possibleOrganisations: Pick<
      Organisation,
      "id" | "title" | "description" | "host" | "createdAt" | "flags"
    >[]
  ) => void;

  selectedOrganisationId: string | null;

  tempUrl: string | null;
  setTempUrl: (tempUrl: string | null) => void;
};

export const useAuthStore = create<AuthStore>()(
  devtools(
    persist(
      (set) => ({
        loggedIn: false,
        selectedOrganisationId: null,

        user: undefined,
        setUser: (user) => {
          set({ user });
        },

        setLoggedIn: (loggedIn) => {
          set({ loggedIn });
        },

        possibleOrganisations: [],
        setPossibleOrganisations: (possibleOrganisations) => {
          set({ possibleOrganisations });
        },

        setSelectedOrganisationId: (selectedOrganisationId: string | null) =>
          set({ selectedOrganisationId }),

        tempUrl: null,
        setTempUrl: (tempUrl) => set({ tempUrl }),
      }),
      {
        name: "auth",
      }
    )
  )
);

useAuthStore.subscribe((newState, oldState) => {
  if (oldState.user !== newState.user) {
    if (newState.user) {
      // we have a new user

      rootQueryClient.invalidateQueries("ability");
    }
  }
});
