/**
 * Picks the appropriate font color based on the background color. From: https://stackoverflow.com/a/41491220/9068542
 * @param backgroundColor the color to analyse
 * @param lightColor the lighter color
 * @param darkColor the darker color
 * @returns the color most readable for the given background color
 */
export const pickFontColorBasedOnBackgroundColor = (
  backgroundColor: string,
  lightColor: string,
  darkColor: string
) => {
  var color =
    backgroundColor.charAt(0) === "#"
      ? backgroundColor.substring(1, 7)
      : backgroundColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
};
