import { Form, Select } from "antd";
import { User } from "@formitas-ag/bimfiles-types";
import { DistinctSearchUser, FilterType } from "@formitas-ag/bimfiles-types/lib/search";
import { FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import api from "../../../../../api/api";
import useFiltersStore from "../../../../../state/filtersStore";
import FormProps from "../../../../../UiProperties/FormProps";

export const CreatedByFilterInput = () => {
  const filters = useFiltersStore((state) => state.currentFilters);
  const setFilters = useFiltersStore((state) => state.setFilters);

  // Sets chosen items in state to be used as filters
  const onCreatedByChange = (value: string) => {
    if (query.data && value) {
      const usersIds = [];
      if (!filters.usersIds.includes((query.data[Number(value)] as User).id)) {
        usersIds.push((query.data[Number(value)] as User).id);
      }
      setFilters({ ...filters, usersIds: usersIds });
    }
  };

  // Clears the createdBy ids from the filter state
  const onClear = () => {
    currentItem = undefined;
    setFilters({ ...filters, usersIds: [] });
  };

  // Gets the distinct createdBy objects based on the current filter (from state)
  const query = useQuery(["createdBy", filters], async () => {
    const filterItems = await api.search.distinct(
      filters,
      "createdBy" as FilterType
    );
    return filterItems;
  });

  // Creates the options based on the distinct createdBy objects received
  const createdByOptions = () => {
    const children = [];
    if (query.data) {
      for (let i = 0; i < query.data!.length; i++) {
        children.push(
          <Select.Option key={i}>{`${(query.data[i] as User).preName} ${
            (query.data[i] as User).name
          }`}</Select.Option>
        );
      }
    }
    return children;
  };

  // Sets the input default value if it already has a value in state
  let currentData = query.data;
  let currentItem: DistinctSearchUser | undefined;
  if (
    typeof currentData !== "undefined" &&
    typeof currentData === "object" &&
    Array.isArray(currentData)
  ) {
    currentItem = (currentData as DistinctSearchUser[]).find(
      (item) =>
        typeof item === "object" &&
        "preName" in item &&
        item.id === filters.usersIds[0]
    ) as DistinctSearchUser;
  }

  return (
    <Form.Item
      style={{ ...FormProps.formItem, margin: "0.2rem 0.5rem 0.2rem 0" }}
      // label={
      // <label style={TextProps.label}>
      //   <FormattedMessage
      //     id="component.created_by_filter.label"
      //     defaultMessage="Author"
      //     description="Label for the form item of the created by filter"
      //   />
      // </label>
      // }
    >
      <Select
        showSearch
        allowClear
        open
        style={{ width: "10rem" }}
        placeholder={
          <FormattedMessage
            id="component.created_by_filter.placeholder"
            defaultMessage="Author"
            description="Placeholder for the form item of the created by filter"
          />
        }
        onChange={onCreatedByChange}
        value={
          currentItem ? `${currentItem.preName} ${currentItem.name}` : undefined
        }
        onClear={onClear}
        onDropdownVisibleChange={() => {
          query.refetch();
        }}
        optionFilterProp="children"
        filterOption={(input, option) =>
          (option!.children as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      >
        {createdByOptions()}
      </Select>
    </Form.Item>
  );
};
