import { useIntl } from "react-intl";
import TextRender from "./TextRender";

type Props = {
    date: Date;
    withTime?: boolean;
    withoutDate?: boolean;
};

export default (props: Props) => {
    const intl = useIntl();

    const { date, withTime, withoutDate } = props;

    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    };

    if (withTime) {
        options.hour = "numeric";
        options.minute = "numeric";
    }

    if (withoutDate) {
        delete options.year;
        delete options.month;
        delete options.day;
    }

    const formattedDate = intl.formatDate(date, options);

    return <TextRender text={formattedDate}/>
};