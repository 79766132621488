import { File, Item, Response } from "@formitas-ag/bimfiles-types";
import { FileWithUrl } from "@formitas-ag/bimfiles-types/lib/file";
import axiosService from "./fetch/axios.service";
import { getOne } from "./utils/endpoint.utils";
import { errorRenderer } from "./utils/errorThrower";
import { EndpointOptions } from "./utils/endpoint.options.utils";

export type CreateItemData = Pick<
  Item,
  | "categoryId"
  | "changeDescription"
  | "description"
  | "parameters"
  | "tagsIds"
  | "title"
> & { files: Pick<File, "name" | "role">[] } & { organisationId?: string };

export type CreateItemResponse = {
  item: Item;
  uploadUrls: FileWithUrl[];
};

export type CompleteItemData = Pick<
  Item,
  | "categoryId"
  | "tagsIds"
  | "title"
  | "description"
  | "changeDescription"
  | "parameters"
>;

type UpdateItemData = Omit<
  Item,
  "id" | "organisationId" | "approvedBy" | "approvedAt" | "files"
> & { files: (File | Pick<File, "name" | "role">)[] };

const setStateForChange = (
  id: string,
  changeId: string,
  state: "approved" | "denied",
  reason?: string
): Promise<Item> =>
  new Promise(async (resolve, reject) => {
    const url = `/items/${id}/changes/${changeId}/update?state=${state}`;
    const response = reason
      ? await axiosService.put<Response<Item>>(url, { reason })
      : await axiosService.put<Response<Item>>(url);

    if (response.data && response.data.success) {
      resolve(response.data.result);
    }
    reject(response.statusText);
  });

const items = {
  create: (data: CreateItemData): Promise<CreateItemResponse> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/items/create`;
        const response = await axiosService.post<Response<CreateItemResponse>>(
          url,
          data
        );

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  finish: (id: string): Promise<Item> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/items/${id}/finish`;
        const response = await axiosService.post<Response<Item>>(url);

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  getOne: (id: string, options?: EndpointOptions) =>
    getOne<Item>("items", id, options),
  update: (
    id: string,
    data: Partial<UpdateItemData>
  ): Promise<{ item: Item; uploadUrls: FileWithUrl[] }> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/items/${id}/update`;
        const response = await axiosService.put<
          Response<{ item: Item; uploadUrls: FileWithUrl[] }>
        >(url, data);

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  delete: (id: string, deleteReason: string): Promise<Item> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/items/${id}/delete`;
        const response = await axiosService.post<Response<Item>>(url, {
          message: deleteReason,
        });

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  approve: (id: string, approve: boolean, reason?: string): Promise<Item> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/items/${id}/approve?approve=${approve}`;
        const response = await axiosService.put<Response<Item>>(url, {
          reason,
        });

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  getThumbnail: (
    id: string,
    options?: EndpointOptions
  ): Promise<{ url: string }> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/items/${id}/thumbnail`;
        const response = await axiosService.get<Response<{ url: string }>>(
          url,
          {}
        );

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        if (options?.ignoreExceptions) {
          resolve(undefined as any);
        }
        reject(response.statusText);
      } catch (error) {
        if (options?.ignoreExceptions) {
          resolve(undefined as any);
        }
        reject(errorRenderer(error));
      }
    }),
  getFileWithUrl: (
    id: string,
    fileId: string,
    options?: {
      includeDeleted?: boolean;
      includeUnapproved?: boolean;
    } & EndpointOptions
  ): Promise<FileWithUrl> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/items/${id}/files/${fileId}?includeDeleted=${
          options?.includeDeleted || false
        }&includeUnapproved=${options?.includeUnapproved || false}`;
        const response = await axiosService.get<Response<FileWithUrl>>(url, {});

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        if (options?.ignoreExceptions) {
          resolve(undefined as any);
        }
        reject(response.statusText);
      } catch (error) {
        if (options?.ignoreExceptions) {
          resolve(undefined as any);
        }
        reject(errorRenderer(error));
      }
    }),
  createFiles: (
    id: string,
    files: CreateItemData["files"]
  ): Promise<FileWithUrl[]> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/items/${id}/files/create`;
        const response = await axiosService.post<Response<FileWithUrl[]>>(
          url,
          files
        );

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  cancelUpload: (id: string): Promise<string> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/items/${id}/upload/cancel`;
        const response = await axiosService.delete<Response<string>>(url);

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  changes: {
    approve: (id: string, changeId: string): Promise<Item> =>
      setStateForChange(id, changeId, "approved"),
    deny: (id: string, changeId: string, reason?: string): Promise<Item> =>
      setStateForChange(id, changeId, "denied", reason),
    releaseLatest: (id: string, approve: boolean): Promise<Item> =>
      new Promise(async (resolve, reject) => {
        try {
          const url = `/items/${id}/changes/unreleased/state?approve=${approve}`;
          const response = await axiosService.put<Response<Item>>(url);

          if (response.data && response.data.success) {
            resolve(response.data.result);
          }
          reject(response.statusText);
        } catch (error) {
          reject(errorRenderer(error));
        }
      }),
  },
  getItemsAffectedByTagsMergeCount: (tagsIds: string[]): Promise<number> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/items/affected/tags/merge/count`;
        const response = await axiosService.post<Response<number>>(
          url,
          tagsIds
        );

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
  checkTitleExists: (title: string): Promise<boolean> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/items/exists`;
        const response = await axiosService.post<Response<boolean>>(url, {
          title,
        });

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        reject(response.statusText);
      } catch (error) {
        reject(errorRenderer(error));
      }
    }),
};

export default items;
