import { notification } from "antd";
import { useCreateOrUpdateItemStore } from "../../state/createOrUpdateItemStore";
import { getLogger } from "../../utils/logger.utils";
import {
  UpdatingFile,
  UpdatingRemovedFile,
  UpdatingReplacedFile,
  UpdatingAddedFile,
} from "../../utils/types/updateFilesTypes";
import useCreateOrUpdateFiles from "../useCreateOrUpdateFiles";
import { useCreateOrUpdateMultipleFiles } from "../useCreateOrUpdateMultipleFiles";

const logger = getLogger("useFileListActions");

const useFileListActions = () => {
  const { getUpload, setUpload } = useCreateOrUpdateMultipleFiles();
  const { removeUpload, resetUpdatingFiles } = useCreateOrUpdateFiles();
  const [notificationApi] = notification.useNotification();

  const fileListActions = {
    /**
     * Restores the removed file back to it's previous state.
     * @param file the file that was removed (state needs to be "removed")
     */
    restoreRemovedFile: (index: number, file: UpdatingFile) => {
      const upload = getUpload(index);

      if (!upload) {
        logger.error(
          `Couldn't restore removed file ${file.id} because the upload with index ${index} doesn't exist`
        );
        return;
      }

      if (file.state === "removed") {
        const removedFile = file as UpdatingRemovedFile;

        //filter out the removed file and add the old file back
        const newUpdatedFiles = upload.updatingFiles.filter(
          (f) => f.id !== removedFile.id
        );
        newUpdatedFiles.push({
          ...removedFile.removedFile,
          slot: removedFile.slot,
        });

        setUpload(index, {
          updatingFiles: newUpdatedFiles,
        });

        logger.debug(
          `Restored the deleted file ${removedFile.id} to the previous id ${removedFile.removedFile.id} at slot ${removedFile.slot}`
        );
      }
    },
    /**
     * Restores the given file (which needs to be of type UpdatingReplacedFile) back to the state it was in before it was replaced.
     * @param file the file to restore
     */
    restoreReplacedFile: (index: number, file: UpdatingFile) => {
      const upload = getUpload(index);

      if (!upload) {
        logger.error(
          `Couldn't restore removed file ${file.id} because the upload with index ${index} doesn't exist`
        );
        return;
      }

      //restoring the replaced file requires a state change and to write the old file back
      if (file.state === "replaced") {
        const replacedFile = file as UpdatingReplacedFile;

        //filter out the replaced file and add the old file back
        const newUpdatedFiles = upload.updatingFiles.filter(
          (f) => f.id !== replacedFile.id
        );
        newUpdatedFiles.push({
          ...replacedFile.oldFile,
          slot: replacedFile.slot,
        });

        setUpload(index, {
          updatingFiles: newUpdatedFiles,
        });

        logger.debug(`
              Restored the replaced file ${replacedFile.id} to the previous id ${replacedFile.oldFile.id} at slot ${replacedFile.slot}`);
      }
    },
    /**
     * Removes a newly added file
     * @param file the newly added file
     */
    removeAddedFile: (index: number, file: UpdatingFile) => {
      const upload = getUpload(index);

      if (!upload) {
        logger.error(
          `Couldn't restore removed file ${file.id} because the upload with index ${index} doesn't exist`
        );
        return;
      }

      if (file.state === "added") {
        const addedFile = file as UpdatingAddedFile;

        //filter out the added file
        const newUpdatedFiles = upload.updatingFiles.filter(
          (f) => f.id !== addedFile.id
        );

        if (newUpdatedFiles.length > 0) {
          setUpload(index, {
            updatingFiles: newUpdatedFiles,
          });

          logger.debug(`Removed the added file ${addedFile.id}`);
        } else {
          if (index === -1) {
            resetUpdatingFiles(index);
            //we reset files here and do not stop the upload
          } else {
            removeUpload(index);

            logger.debug(`Removing upload ${index} as there are no files left`);
          }
        }
      }
    },
    /**
     * Removes a given file by setting it's state to "removed"
     * @param file the file to remove
     */
    removeFile: (index: number, file: UpdatingFile) => {
      const upload = getUpload(index);

      if (!upload) {
        logger.error(
          `Couldn't restore removed file ${file.id} because the upload with index ${index} doesn't exist`
        );
        return;
      }

      if (file.state !== "removed") {
        const newUpdatedFiles = upload.updatingFiles.filter(
          (f) => f.id !== file.id
        );
        const newRemovedFile = {
          id: file.id + "_removed",
          state: "removed",
          slot: file.slot,
          removedFile: file,
        } as UpdatingFile;
        newUpdatedFiles.push(newRemovedFile);

        setUpload(index, {
          updatingFiles: newUpdatedFiles,
        });

        logger.debug(
          `Removed the file ${file.id} with the new id ${newRemovedFile.id} at slot ${file.slot}`
        );
      }
    },
    replaceFile: (
      index: number,
      file: UpdatingAddedFile,
      replacedFile: UpdatingFile
    ) => {
      const upload = getUpload(index);

      logger.debug(`Replacing ${replacedFile.id} with ${file.id}`, {
        replacedFile,
        file,
      });

      if (!upload) {
        logger.error(
          `Couldn't restore removed file ${file.id} because the upload with index ${index} doesn't exist`
        );
        return;
      }

      if (replacedFile.state === "added") {
        const newUpdatingFiles = upload.updatingFiles.filter(
          (f) => f.id !== replacedFile.id
        );

        setUpload(index, {
          updatingFiles: newUpdatingFiles.concat({
            ...file,
            slot: replacedFile.slot,
          }),
        });

        logger.debug(
          `Replaced the already added file ${replacedFile.id} with the new file ${file.id} from source=${file.addedFile.source} at slot ${replacedFile.slot}`
        );
      }

      if (replacedFile.state !== "replaced" && replacedFile.state !== "added") {
        const newUpdatedFiles = upload.updatingFiles.filter(
          (f) => f.id !== replacedFile.id
        );

        const newReplacedFile: UpdatingReplacedFile = {
          state: "replaced",
          id: replacedFile.id + "_replaced",
          slot: replacedFile.slot,
          oldFile: replacedFile,
          newFile: file,
        };

        newUpdatedFiles.push(newReplacedFile);

        setUpload(index, {
          updatingFiles: newUpdatedFiles,
        });

        logger.debug(
          `Replaced the file ${replacedFile.id} with the new file ${newReplacedFile.id} from source=${file.addedFile.source} at slot ${replacedFile.slot}`
        );
      }
    },
  };

  return fileListActions;
};

export default useFileListActions;
