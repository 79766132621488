import { ColumnType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { ellipsisRender, noStringRender } from "./useTypeCatalog/TextRender";
import Papa from "papaparse";

const useTypeCatalog = (sidecarData: string | null) => {
  const [loaded, setLoaded] = useState<boolean | null>(null);
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<ColumnType<any>[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // load data if we have an url
    if (sidecarData) {
      setLoaded(false);
      try {
        const csv = Papa.parse<string[]>(sidecarData, {});

        // extract head from first line
        const head = csv.data.shift();

        if (!head) {
          console.log("Cannot parse table data, no head in data ", csv);
          throw new Error("Keine Kopfzeile im Typenkatalog");
        }

        // construct table data
        const parsedTableData = csv.data.reduce((rows, row, i) => {
          // skip rows without a name
          if (!row[0]) return rows;

          if (row.length !== head.length) {
            // return row with rows greater than head length by concatenating
            // the first few columns so that the entire length is equal to the head length
            // this is to avoid having the name in multiple columns if it has commas
            const firstFewColumns = [
              row.slice(0, row.length - head.length + 1).join(", "),
            ];
            const lastFewColumns = row.slice(row.length - head.length + 1);
            row = firstFewColumns.concat(lastFewColumns);
          }

          rows.push({
            key: i,
            name: row[0],
            ...head.reduce((columns: any, field, i) => {
              // skip first element, as this is the name. the rest is dynamic
              if (i === 0) return columns;

              columns[field] = row[i];
              return columns;
            }, {}),
          });
          return rows;
        }, []);

        // construct columns
        const parsedTableColumns: ColumnType<any>[] = head.map((field, i) => {
          if (i === 0) {
            return {
              title: "Name",
              dataIndex: "name",
              key: "name",
              render: (value) => ellipsisRender(value),
            };
          }

          // extract field title from title##type##unit
          const fieldMatch: RegExpMatchArray | null = field.match(
            /^([^#]*)##([^#]*)##([^#]*)$/i
          );
          const title: any =
            fieldMatch && fieldMatch[1] ? fieldMatch[1] : fieldMatch;

          return {
            title: title,
            dataIndex: field,
            key: field,
            render: (value: string) => {
              if (typeof value !== "string") return noStringRender();
              return ellipsisRender(value, true);
            },
            // first, reduce to unique values, then blow up to antd filter object with {text and value}
            filters: csv.data
              .reduce((acc: string[], value) => {
                // skip if no value
                if (
                  value[i] === "" ||
                  value[i] === null ||
                  typeof value[i] === "undefined"
                )
                  return acc;
                // skip value if already in
                if (acc.indexOf(value[i]) !== -1) return acc;
                acc.push(value[i]);
                return acc;
              }, [])
              .map((filterValue) => {
                return {
                  text: filterValue.substr(0, 80),
                  value: filterValue,
                };
              }),
            onFilter: (value, record) => {
              return record[field] === value;
            },
          };
        });

        setTableData(parsedTableData);
        setTableColumns(parsedTableColumns);
        setLoaded(true);
      } catch (e) {
        setLoaded(true);
        if (e instanceof Error) {
          console.error("Error receiving type catalog: " + e.message);
          setError(e.message);
        }
      }
    }
  }, [sidecarData]);

  return {
    loaded,
    tableData,
    tableColumns,
    error,
  };
};

export default useTypeCatalog;
