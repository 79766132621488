import { Category, Pagination, Response } from "@formitas-ag/bimfiles-types";
import axiosService from "./fetch/axios.service";
import { create, getOne, remove, update } from "./utils/endpoint.utils";
import { errorRenderer } from "./utils/errorThrower";
import { EndpointOptions } from "./utils/endpoint.options.utils";

type CreateCategoryData = Pick<
  Category,
  "title" | "description" | "color" | "defaultPermissions" | "shortCode"
>;
type UpdateCategoryData = Partial<CreateCategoryData>;

const categories = {
  get: (
    page: number,
    size: number,
    mode?: "default" | "writeable" | "approvable",
    options?: EndpointOptions,
  ): Promise<Pagination<Category>> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/categories?page=${page}&size=${size}&mode=${
          mode ?? "default"
        }`;
        const response = await axiosService.get<Response<Pagination<Category>>>(
          url,
          {}
        );

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        if(options?.ignoreExceptions) {
          resolve(undefined as any);
        }
        reject(response.statusText);
      } catch (error) {
        if(options?.ignoreExceptions) {
          resolve(undefined as any);
        }
        reject(errorRenderer(error));
      }
    }),
  getOne: (id: string, options?: EndpointOptions) => getOne<Category>("categories", id, options),
  create: (data: CreateCategoryData) =>
    create<Category, CreateCategoryData>("categories", data),
  update: (id: string, data: UpdateCategoryData) =>
    update<Category, UpdateCategoryData>("categories", id, data),
  delete: (id: string): Promise<void> => remove("categories", id),
};

export default categories;
