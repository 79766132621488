import { Col, Form, FormInstance, Input, Row } from "antd";
import { TagObjectType } from "./AddTagsModal";
import { ColorPicker } from "./ColorPicker";
import { defineMessages, useIntl } from "react-intl";

type FormData = {
  title: string;
  description: string;
};

type TagsFormProps = {
  form: FormInstance<any>;
  tagsObjects: TagObjectType[];
  setTagsObjects: React.Dispatch<React.SetStateAction<TagObjectType[]>>;
  onValuesChange: any;
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
};

const messages = defineMessages({
  title: {
    id: "components.add-tags-form.title",
    defaultMessage: "Title",
    description: "Title for the add tags form",
  },
  description: {
    id: "components.add-tags-form.description",
    defaultMessage: "Description",
    description: "Description for the add tags form",
  },
  titleRequired: {
    id: "components.add-tags-form.title-required",
    defaultMessage: "Please add a title",
    description: "Title required for the add tags form",
  },
  descriptionRequired: {
    id: "components.add-tags-form.description-required",
    defaultMessage: "Please add a description",
    description: "Description required for the add tags form",
  },
});

export const AddTagsForm: React.FC<TagsFormProps> = ({
  form,
  tagsObjects,
  setTagsObjects,
  onValuesChange,
  color,
  setColor,
}) => {
  const intl = useIntl();
  const defaultColor = "#931d21";

  const onFinish = (values: FormData) => {
    const newTag: TagObjectType = {
      title: values.title,
      description: values.description,
      color: color ? color : defaultColor,
    };
    setTagsObjects([...tagsObjects, newTag]);
    setColor(defaultColor);
    form.resetFields();
  };

  return (
    <Row style={{ width: "100%" }}>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        style={{ width: "100%" }}
        onValuesChange={onValuesChange}
      >
        <Row>
          <Col span={11}>
            <Form.Item
              label={intl.formatMessage(messages.title)}
              name="title"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(messages.titleRequired),
                },
              ]}
            >
              <Input
                size="large"
                placeholder={intl.formatMessage(messages.title)}
                spellCheck={false}
              />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage(messages.description)}
              name="description"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage(messages.descriptionRequired),
                },
              ]}
            >
              <Input.TextArea
                size="large"
                placeholder={intl.formatMessage(messages.description)}
                spellCheck={false}
              />
            </Form.Item>
          </Col>
          <Col offset={2} span={11}>
            <Form.Item>
              <ColorPicker
                color={color}
                setColor={setColor}
                defaultColor={defaultColor}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};
