import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { useIntl } from "react-intl";
import UpdateHeader from "../Common/ItemHeader";
import { useCreateOrUpdateMultipleFiles } from "../../../../hooks/useCreateOrUpdateMultipleFiles";
import translations from "../../../../translations/constants/translations";

type Props = {
  mode: "single" | "multiple";
  index?: number;
};

const UpdateDescription = (props: Props) => {
  const index = props.index ?? -1;

  const intl = useIntl();

  const { getUpload, setItem } = useCreateOrUpdateMultipleFiles();

  const oldItem = getUpload(index)?.oldItem;

  const [currentValue, setCurrentValue] = useState<string | undefined>(
    oldItem?.description ?? ""
  );

  const didChange = (oldItem?.description ?? "") !== currentValue;
  const resetChange = () => {
    setItem(index, {
      description: undefined,
    });
    setCurrentValue(oldItem?.description ?? "");
  };

  return (
    <>
      {props.mode === "single" && (
        <UpdateHeader
          title={intl.formatMessage(
            translations.ui.meta.fields.descriptionName
          )}
          isResetable={didChange}
          onReset={resetChange}
        />
      )}
      <TextArea
        name="description"
        value={currentValue}
        size="middle"
        rows={props.mode === "single" ? 4 : 1}
        placeholder={intl.formatMessage(
          translations.ui.meta.fields.descriptionPlaceholder
        )}
        onChange={(e) => {
          setItem(index, {
            description: e.target.value,
          });
          setCurrentValue(e.target.value);
        }}
      />
    </>
  );
};

export default UpdateDescription;
