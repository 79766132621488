import { Button, Result } from "antd";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  title: {
    id: "components.no-organisation-selected.title",
    defaultMessage: "You do not have an organisation selected.",
    description: "Title of the no organisation selected component",
  },
  action: {
    id: "components.no-organisation-selected.action",
    defaultMessage: "Select Organisation",
    description: "Action of the no organisation selected component",
  },
});

export default () => {
  const intl = useIntl();

  return (
    <Result
      status="warning"
      title={intl.formatMessage(messages.title)}
      extra={
        <Button type="primary" key="console">
          {intl.formatMessage(messages.action)}
        </Button>
      }
    />
  );
};
