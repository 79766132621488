import { Button } from "antd";
import { useApproveOrDeny } from "../../../../hooks/useApproveOrDeny";
import { pickFontColorBasedOnBackgroundColor } from "../../../../utils/text-color.utils";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";

type Props = {
  type: "approve" | "deny";
  title: string;
  // id: string;
  // changeId: string | string[];
  // state: Change["state"];
  item: ItemPopulated;
};

const approveColors = {
  baseColor: "#43a047",
  borderColor: "#388e3c",
};

const denyColors = {
  baseColor: "#e53935",
  borderColor: "#d32f2f",
};

const ApproveOrDenyButton = (props: Props) => {
  const colors = props.type === "approve" ? approveColors : denyColors;

  const { approveOrDeny, loading } = useApproveOrDeny(props.item);

  const onClick = async () => {
    approveOrDeny(props.type === "approve");
  };

  return (
    <Button
      type="primary"
      loading={loading}
      style={{
        backgroundColor: colors.baseColor,
        borderColor: colors.borderColor,
        color: pickFontColorBasedOnBackgroundColor(
          colors.baseColor,
          "#fff",
          "#000"
        ),
      }}
      onClick={onClick}
    >
      {props.title}
    </Button>
  );
};

export default ApproveOrDenyButton;
