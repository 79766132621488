import React, { useEffect, useState } from "react";
import { Badge, Row, Space, Tooltip } from "antd";
import { CreatedByFilterInput } from "./FiltersModal/CreatedByFilterInput";
import { OstCategoriesFilterInput } from "./FiltersModal/OstCategoriesFilterInput";
import { PlacementMethodFilterInput } from "./FiltersModal/PlacementMethodFilterInput";
import { RevitVersionFilterInput } from "./FiltersModal/RevitVersionFilterInput";
import { TagsFilterInput } from "./FiltersModal/TagsFilterInput";
import { CategoriesFilterInput } from "./FiltersModal/CategoriesFilterInput";
import { ClearFiltersButton } from "./ClearFiltersButton";
import { FilterButton, FilterButtonProps } from "./FilterButton";
import { Category, Pagination, Tag } from "@formitas-ag/bimfiles-types/lib";
import { ApprovedToggle } from "./ApprovedToggle";
import { UseQueryResult } from "react-query";
import { useIntl } from "react-intl";
import useGetTags from "../../../../hooks/useGetTags";
import useFiltersStore from "../../../../state/filtersStore";
import CustomTag from "../CustomTag";
import useGetCategories from "../../../../hooks/useGetCategories";
import Can from "../../../../api/ability/Can";
import api from "../../../../api/api";
import useUnapprovedItemsLength from "../../../../hooks/useUnapprovedItemsLength";
import useOstTranslate from "../../../../hooks/useOstTranslate";
import translations from "../../../../translations/constants/translations";

const ExpandableFilters: React.FC = () => {
  const intl = useIntl();
  const translateOstString = useOstTranslate();

  const [openFilter, setOpenFilter] = useState<string | undefined>(undefined);
  const [unapprovedItemsLength, setUnapprovedItemsLength] = useState<number>(0);

  const itemsState = useFiltersStore((state) => state.itemsState);
  const currentFilters = useFiltersStore((state) => state.currentFilters);

  useUnapprovedItemsLength().then((length) => setUnapprovedItemsLength(length));

  const [selectedTagsComponent, setSelectedTagsComponent] = useState<
    JSX.Element[]
  >([]);
  const [selectedCategoryComponent, setSelectedCategoryComponent] = useState<
    JSX.Element[]
  >([]);
  const [selectedUserComponent, setSelectedUserComponent] = useState<
    JSX.Element[]
  >([]);

  const tags = useGetTags("all") as UseQueryResult<Pagination<Tag>>;
  useEffect(() => {
    if (tags && tags.data)
      setSelectedTagsComponent(
        currentFilters.tagsIds.map((tagId) => {
          const currentTag = tags.data.data.find((tag) => tag.id === tagId);
          return createTag(
            "filter-tag-" + tagId,
            currentTag!.title,
            currentTag!.description,
            currentTag!.color
          );
        })
      );
  }, [tags.data, currentFilters.tagsIds]);

  const categories = useGetCategories("all") as UseQueryResult<
    Pagination<Category>
  >;
  useEffect(() => {
    if (categories && categories.data)
      setSelectedCategoryComponent(
        currentFilters.categoriesIds.map((categoryId) => {
          const currentCategory = categories.data.data.find(
            (category) => category.id === categoryId
          );
          return createTag(
            "filter-tag-" + categoryId,
            currentCategory!.title,
            currentCategory!.description,
            currentCategory!.color
          );
        })
      );
  }, [categories.data, currentFilters.categoriesIds]);

  useEffect(() => {
    (async () => {
      const user = currentFilters.usersIds[0]
        ? await api.users.getOne(currentFilters.usersIds[0])
        : undefined;

      if (user) {
        setSelectedUserComponent([
          createTag(
            "filter-user-" + user.id,
            `${user.preName} ${user.name}`,
            user.email
          ),
        ]);
      } else {
        setSelectedUserComponent([]);
      }
    })();
  }, [currentFilters.usersIds]);

  const createTag = (
    key: string,
    title: string,
    description?: string,
    color?: string
  ): JSX.Element => {
    return description ? (
      <Tooltip
        key={`${key}-tooltip`}
        color="#f7f8fc"
        title={description}
        overlayInnerStyle={{ textAlign: "center" }}
      >
        <CustomTag
          key={key}
          color={color ? color : "#f7f8fc"}
          label={title}
          style={{ marginRight: "0", height: "20px" }}
        />
      </Tooltip>
    ) : (
      <CustomTag
        key={key}
        color={color ? color : "#f7f8fc"}
        label={title}
        style={{ marginRight: "0", height: "20px" }}
      />
    );
  };

  const filterButtonsProps: FilterButtonProps[] = [
    {
      id: "library-filter-button",
      buttonLabel: intl.formatMessage(
        translations.ui.itemTable.filters.libraryFilter
      ),
      passedComponent: <CategoriesFilterInput />,
      hasFilters: currentFilters.categoriesIds.length !== 0,
      openFilter: openFilter,
      setOpenFilter: setOpenFilter,
      currentSelection: selectedCategoryComponent,
    },
    {
      id: "tags-filter-button",
      buttonLabel: intl.formatMessage(
        translations.ui.itemTable.filters.tagsFilter
      ),
      passedComponent: <TagsFilterInput />,
      hasFilters: currentFilters.tagsIds.length !== 0,
      openFilter: openFilter,
      setOpenFilter: setOpenFilter,
      currentSelection: selectedTagsComponent,
    },
    {
      id: "ost-category-filter-button",
      buttonLabel: intl.formatMessage(
        translations.ui.itemTable.filters.ostCategoryFilter
      ),
      passedComponent: <OstCategoriesFilterInput />,
      hasFilters: currentFilters.ostCategory.length !== 0,
      openFilter: openFilter,
      setOpenFilter: setOpenFilter,
      currentSelection: currentFilters.ostCategory[0]
        ? [
            createTag(
              `filter-ost-category-tag-${currentFilters.ostCategory[0]}`,
              translateOstString(currentFilters.ostCategory[0])
            ),
          ]
        : undefined,
    },
    {
      id: "placement-method-filter-button",
      buttonLabel: intl.formatMessage(
        translations.ui.itemTable.filters.placementMethodFilter
      ),
      passedComponent: <PlacementMethodFilterInput />,
      hasFilters: currentFilters.placementMethod.length !== 0,
      openFilter: openFilter,
      setOpenFilter: setOpenFilter,
      currentSelection: currentFilters.placementMethod[0]
        ? [
            createTag(
              `filter-placement-method-tag-${currentFilters.placementMethod[0]}`,
              currentFilters.placementMethod[0]
            ),
          ]
        : undefined,
    },
    {
      id: "revit-version-filter-button",
      buttonLabel: intl.formatMessage(
        translations.ui.itemTable.filters.revitVersionFilter
      ),
      passedComponent: <RevitVersionFilterInput />,
      hasFilters: currentFilters.revitVersion.length !== 0,
      openFilter: openFilter,
      setOpenFilter: setOpenFilter,
      currentSelection: currentFilters.revitVersion[0]
        ? [
            createTag(
              `filter-revit-version-tag-${currentFilters.revitVersion[0].toString()}`,
              currentFilters.revitVersion[0].toString()
            ),
          ]
        : undefined,
    },
    {
      id: "author-filter-button",
      buttonLabel: intl.formatMessage(
        translations.ui.itemTable.filters.authorFilter
      ),
      passedComponent: <CreatedByFilterInput />,
      hasFilters: currentFilters.usersIds.length !== 0,
      openFilter: openFilter,
      setOpenFilter: setOpenFilter,
      currentSelection: selectedUserComponent,
    },
    {
      id: "approval-state-filter-button",
      buttonLabel: intl.formatMessage(
        translations.ui.itemTable.filters.stateFilter
      ),
      passedComponent: <ApprovedToggle />,
      hasFilters: !(itemsState === "approved"),
      openFilter: openFilter,
      setOpenFilter: setOpenFilter,
      currentSelection:
        itemsState === "approved"
          ? [
              createTag(
                "filter-approved-tag",
                intl.formatMessage(translations.ui.itemTable.filters.approved)
              ),
            ]
          : itemsState === "unapproved"
          ? [
              createTag(
                "filter-unapproved-tag",
                intl.formatMessage(translations.ui.itemTable.filters.unapproved)
              ),
            ]
          : [
              createTag(
                "filter-denied-tag",
                intl.formatMessage(translations.ui.itemTable.filters.denied)
              ),
            ],
    },
  ];
  // .sort((a, b) => {
  //   if (a.hasFilters && !b.hasFilters) {
  //     return -1;
  //   } else if (!a.hasFilters && b.hasFilters) {
  //     return 1;
  //   } else {
  //     return 0;
  //   }
  // })

  const filterButtonComponent = (filterButtonProp: FilterButtonProps) => {
    return (
      <FilterButton
        id={filterButtonProp.id}
        key={filterButtonProp.buttonLabel}
        buttonLabel={filterButtonProp.buttonLabel}
        passedComponent={filterButtonProp.passedComponent}
        hasFilters={filterButtonProp.hasFilters}
        openFilter={filterButtonProp.openFilter}
        setOpenFilter={filterButtonProp.setOpenFilter}
        currentSelection={filterButtonProp.currentSelection}
      />
    );
  };

  return (
    <Row
      id="expandable-filters-div"
      align="middle"
      style={{ marginBottom: "0.5rem" }}
    >
      <Space wrap>
        {itemsState
          ? filterButtonsProps.map((filterButton) => {
              if (filterButton.id === "approval-state-filter-button") {
                return (
                  // <Can
                  //   key="can-approve-filters"
                  //   I="approve"
                  //   an="Item"
                  //   requiredPermissions={"itemApprove"}
                  //   or
                  //   requiredPermissionsOnCategory="itemApprove"
                  // >
                  <Badge
                    key="unapproved-count-badge"
                    count={unapprovedItemsLength}
                    title={intl.formatMessage(
                      translations.ui.itemTable.filters.unapprovedItemsCount,
                      {
                        items: unapprovedItemsLength,
                      }
                    )}
                  >
                    {filterButtonComponent(filterButton)}
                  </Badge>
                  //  </Can>
                );
              } else {
                return filterButtonComponent(filterButton);
              }
            })
          : filterButtonsProps
              .filter(
                (filterButtonsProp) =>
                  filterButtonsProp.id === "approval-state-filter-button"
              )
              .map((filterButton) => {
                return filterButtonComponent(filterButton);
              })}

        {itemsState === "approved" && <ClearFiltersButton />}
      </Space>
    </Row>
  );
};

export default ExpandableFilters;
