import { Button, Row, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import useFiltersStore from "../../../../state/filtersStore";
import { CloseOutlined } from "@ant-design/icons";

export type FilterButtonProps = {
  id: string;
  buttonLabel: string;
  passedComponent: JSX.Element;
  hasFilters: boolean;
  openFilter: string | undefined;
  setOpenFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
  currentSelection?: string | JSX.Element[];
};

export const FilterButton = (props: FilterButtonProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [inputVisible, setInputVisible] = useState<boolean>(false);

  const itemsState = useFiltersStore((state) => state.itemsState);
  const setItemsState = useFiltersStore((state) => state.setItemsState);

  const currentFilters = useFiltersStore((state) => state.currentFilters);
  const setFilters = useFiltersStore((state) => state.setFilters);

  const onButtonClick = () => {
    props.setOpenFilter(props.id);
    setInputVisible(!inputVisible);
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      const clickedElement = event.target as HTMLElement;
      const isInside =
        ref.current.contains(clickedElement) ||
        clickedElement.closest(".ant-btn") ||
        clickedElement.closest(".ant-select") ||
        clickedElement.closest(".ant-select-dropdown");
      if (!isInside) {
        props.setOpenFilter(undefined);
        setInputVisible(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (props.openFilter !== props.id) {
      setInputVisible(false);
    }
  }, [props.openFilter]);

  useEffect(() => {
    if (props.id !== "tags-filter-button") {
      setInputVisible(false);
    }
  }, [currentFilters, itemsState]);

  const onClickRemoveFilter = () => {
    switch (true) {
      case props.id.includes("library"):
        setFilters({ ...currentFilters, categoriesIds: [] });
        break;
      case props.id.includes("tags"):
        setFilters({ ...currentFilters, tagsIds: [] });
        break;
      case props.id.includes("ost-category"):
        setFilters({ ...currentFilters, ostCategory: [] });
        break;
      case props.id.includes("placement-method"):
        setFilters({ ...currentFilters, placementMethod: [] });
        break;
      case props.id.includes("revit-version"):
        setFilters({ ...currentFilters, revitVersion: [] });
        break;
      case props.id.includes("author"):
        setFilters({ ...currentFilters, usersIds: [] });
        break;
      case props.id.includes("state"):
        setItemsState("approved");
        break;
      default:
        console.log("The string does not match any case");
    }
  };

  return (
    <Row id={props.id} ref={ref}>
      <Button
        style={{
          paddingLeft: "8px",
          paddingRight: "8px",
          fontWeight: "600",
          backgroundColor: props.hasFilters ? "#931d21" : undefined,
        }}
        type={props.hasFilters ? "primary" : "default"}
        onClick={onButtonClick}
        disabled={
          props.id !== "approval-state-filter-button" &&
          itemsState !== "approved"
        }
      >
        <Space size={8}>
          <span>
            {props.buttonLabel}
            {props.currentSelection &&
              props.currentSelection.length > 0 &&
              `${":"}`}
          </span>

          {props.currentSelection &&
            props.currentSelection.length > 0 &&
            props.currentSelection}

          {props.currentSelection &&
            props.currentSelection.length > 0 &&
            (!(props.id === "approval-state-filter-button") ||
              !(itemsState === "approved")) && (
              <CloseOutlined onClick={onClickRemoveFilter} />
            )}
        </Space>
      </Button>
      {inputVisible && (
        <Row style={{ position: "absolute", zIndex: 9999, marginTop: "2rem" }}>
          {props.passedComponent}
        </Row>
      )}
    </Row>
  );
};
