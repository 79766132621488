import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Button } from "antd";
import useFiltersStore from "../../../../state/filtersStore";

export const ClearFiltersButton: React.FC = () => {
  const itemsState = useFiltersStore((state) => state.itemsState);
  const filters = useFiltersStore((state) => state.currentFilters);
  const clearFilters = useFiltersStore((state) => state.clearFilters);

  const [visible, setVisible] = useState<boolean>(false);

  const onClick = () => {
    clearFilters();
  };

  // Check if filters exist to see if any filters are set or not
  useEffect(() => {
    for (const [key, value] of Object.entries(filters)) {
      if (key === "sortBy") continue;
      if (
        (value && value.length > 0 && key !== "sortBy" && key !== "sortType") ||
        !(itemsState === "approved")
      ) {
        setVisible(true);
        break;
      } else {
        setVisible(false);
      }
    }
  }, [filters, itemsState]);

  return visible ? (
    <Button
      type="text"
      icon={<CloseOutlined style={{ marginRight: "4px" }} />}
      onClick={onClick}
      style={{
        paddingLeft: "8px",
        paddingRight: "8px",
        fontSize: "1rem",
        color: "red",
      }}
    >
      <FormattedMessage
        id="component.clear-filter"
        defaultMessage="Clear Filters"
        description="The clear filters button"
      />
    </Button>
  ) : null;
};
