import { ApiDrivenTable } from "@formitas-ag/formitable";
import { Col, Row } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { ColumnsType } from "antd/lib/table";
import type { Item } from "@formitas-ag/bimfiles-types/lib";
import useApiAll from "../../hooks/useApiAll";
import parameterRender from "./Common/parameterRender";
import StateRender from "./Items/StateRender";
import PropertyRender from "./Items/PropertyRender";
import DateRender from "./Common/DateRender";
import FileRender from "./Items/FileRender";
import { getApiDrivenTableLanguage } from "./Common/apiTableLanguage";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import CategoryRender from "./Items/CategoryRender";

const messages = defineMessages({
  title: {
    id: "admin.items.title",
    defaultMessage: "Items",
    description: "The title of the items admin page",
  },
  titleField: {
    id: "admin.items.field.title",
    defaultMessage: "Title",
    description: "The title field of an item",
  },
  descriptionField: {
    id: "admin.items.field.description",
    defaultMessage: "Description",
    description: "The description field of an item",
  },
  stateField: {
    id: "admin.items.field.state",
    defaultMessage: "State",
    description: "The state field of an item",
  },
  filesField: {
    id: "admin.items.field.files",
    defaultMessage: "Files",
    description: "The files field of an item",
  },
  categoryField: {
    id: "admin.items.field.category",
    defaultMessage: "Library",
    description: "The category field of an item",
  },
  propertyCategoryField: {
    id: "admin.items.field.property_category",
    defaultMessage: "Category",
    description: "The property category field of an item",
  },
  propertyFamilyNameField: {
    id: "admin.items.field.property_family_name",
    defaultMessage: "Family name",
    description: "The property family name field of an item",
  },
  propertyRevitVersionField: {
    id: "admin.items.field.property_revit_version",
    defaultMessage: "Revit version",
    description: "The property revit version field of an item",
  },
  propertyPlacementMethodField: {
    id: "admin.items.field.property_placement_method",
    defaultMessage: "Placement method",
    description: "The property placement method field of an item",
  },
  createdAtField: {
    id: "admin.items.field.created_at",
    defaultMessage: "Created at",
    description: "The created at field of an item",
  },
  updatedAtField: {
    id: "admin.items.field.updated_at",
    defaultMessage: "Updated at",
    description: "The updated at field of an item",
  },
});

const getColumns = (intl: IntlShape): ColumnsType<Item> => [
  { title: intl.formatMessage(messages.titleField), dataIndex: "title" },
  {
    title: intl.formatMessage(messages.descriptionField),
    dataIndex: "description",
  },
  {
    title: intl.formatMessage(messages.stateField),
    dataIndex: "state",
    render: parameterRender<Item, "state">((state) => (
      <StateRender state={state} />
    )),
  },
  {
    title: intl.formatMessage(messages.filesField),
    dataIndex: "files",
    render: parameterRender<Item, "files">((files) => (
      <FileRender files={files} />
    )),
  },
  {
    title: intl.formatMessage(messages.categoryField),
    dataIndex: "categoryId",
    render: parameterRender<Item, "categoryId">((categoryId) => (
      <CategoryRender categoryId={categoryId} />
    )),
  },
  {
    title: intl.formatMessage(messages.propertyCategoryField),
    dataIndex: "properties",
    render: parameterRender<Item, "properties">((properties) => (
      <PropertyRender propertyName="ostCategory" properties={properties} />
    )),
  },
  {
    title: intl.formatMessage(messages.propertyFamilyNameField),
    dataIndex: "properties",
    render: parameterRender<Item, "properties">((properties) => (
      <PropertyRender propertyName="familyName" properties={properties} />
    )),
  },
  {
    title: intl.formatMessage(messages.propertyRevitVersionField),
    dataIndex: "properties",
    render: parameterRender<Item, "properties">((properties) => (
      <PropertyRender propertyName="revitVersion" properties={properties} />
    )),
  },
  {
    title: intl.formatMessage(messages.propertyPlacementMethodField),
    dataIndex: "properties",
    render: parameterRender<Item, "properties">((properties) => (
      <PropertyRender propertyName="placementMethod" properties={properties} />
    )),
  },
  {
    title: intl.formatMessage(messages.createdAtField),
    dataIndex: "createdAt",
    render: parameterRender<Item, "createdAt">((date) => (
      <DateRender date={date} />
    )),
  },
  {
    title: intl.formatMessage(messages.updatedAtField),
    dataIndex: "updatedAt",
    render: parameterRender<Item, "updatedAt">((date) => (
      <DateRender date={date} />
    )),
  },
];

export const ItemAdminPage = () => {
  const intl = useIntl();
  const api = useApiAll<Item>({
    options: { paginated: true, limit: 20 },
    service: "items",
  });

  return (
    <Row>
      <Col span={24}>
        <PageHeader title={intl.formatMessage(messages.title)} />
      </Col>
      <Col span={24}>
        <ApiDrivenTable
          columns={getColumns(intl)}
          serviceName="items"
          api={api}
          rowKey="id"
          reloadButton
          language={getApiDrivenTableLanguage(intl)}
          editInfo={{
            formFields: [],
            editable: false,
            creatable: false,
            deletable: true,
          }}
        />
      </Col>
    </Row>
  );
};
