import { useLanguageStore } from "../state/languageStore";

const useOstTranslate = () => {
  const ostTranslations = useLanguageStore((state) => state.ostTranslations);

  const translateString = (ostCategoryString: string) => {
    if (ostTranslations) {
      const translation = ostTranslations.find(
        (ostTranslation) => ostTranslation.ostName === ostCategoryString
      );

      if (!translation) {
        return ostCategoryString;
      } else if (useLanguageStore.getState().language === "en") {
        return translation.english;
      } else if (useLanguageStore.getState().language === "de") {
        return translation.german;
      }
    }

    return ostCategoryString;
  };

  const translateOstString = (ostCategoryString: string) => {
    return translateString(ostCategoryString);
  };

  return translateOstString;
};

export default useOstTranslate;
