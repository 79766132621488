import { Dropdown } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useAuthStore } from "../../../../state/authStore";
import { useLanguageStore } from "../../../../state/languageStore";
import { LeftOutlined, UserOutlined } from "@ant-design/icons";
import api from "../../../../api/api";

import "./profile-dropdown.css";
import { getCurrentOrganisation } from "../../../../api/ability/categoryAbilityExtension";
import { UserRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";

export const ProfileDropdown = () => {
  const language = useLanguageStore((state) => state.language);
  const user = useAuthStore((state) => state.user);
  const intl = useIntl();
  const messages = defineMessages({
    greeting: {
      id: "component.profile_dropdown.greeting",
      defaultMessage: "Hello, {name}",
      description: "Greeting user within the profile dropdown",
    },
    managment: {
      id: "component.profile_dropdown.managment",
      defaultMessage: "Managment",
      description: "Managment link within the profile dropdown",
    },
    administration: {
      id: "component.profile_dropdown.administration",
      defaultMessage: "Administration",
      description: "Administration link within the profile dropdown",
    },
    logout: {
      id: "component.profile_dropdown.logout",
      defaultMessage: "Logout",
      description: "Logout link within the profile dropdown",
    },
    language: {
      id: "component.profile_dropdown.language",
      defaultMessage: "Language",
      description: "Language link within the profile dropdown",
    },
    languageGerman: {
      id: "component.profile_dropdown.language.german",
      defaultMessage: "German",
      description: "German language link within the profile dropdown",
    },
    languageEnglish: {
      id: "component.profile_dropdown.language.english",
      defaultMessage: "English",
      description: "English language link within the profile dropdown",
    },
  });

  const onClickLogout = async () => {
    await api.auth.logout();

    localStorage.setItem("persist", "false");
  };

  const onClickChangeLanguage = (language: "en" | "de") => {
    useLanguageStore.setState({ language, type: "selected" });
    window.location.reload();
  };

  return (
    <div>
      <Dropdown
        overlayClassName="profile-dropdown"
        menu={{
          className: "profile-dropdown-menu",
          items: [
            {
              type: "group",
              label: intl.formatMessage(messages.greeting, {
                name: user?.preName ?? "unknown",
              }),
              key: "user-name",
              style: {
                fontWeight: "600",
              },
            },
            {
              label: intl.formatMessage(messages.language),
              key: "select-language",
              style: {
                fontWeight: "400",
              },
              icon: <LeftOutlined />,
              expandIcon: <></>,
              children: [
                {
                  key: "select-language-1",
                  style: { color: language === "de" ? "#931d2152" : "#931d21" },
                  label: intl.formatMessage(messages.languageGerman),
                  disabled: language === "de",
                  onClick: () => onClickChangeLanguage("de"),
                },
                {
                  key: "select-language-2",
                  style: { color: language === "de" ? "#931d21" : "#931d2152" },
                  label: intl.formatMessage(messages.languageEnglish),
                  disabled: language === "en",
                  onClick: () => onClickChangeLanguage("en"),
                },
              ],
            },
            getCurrentOrganisation() && getCurrentOrganisation()!.role === UserRoleEnum.MODERATOR
              ? {
                  label: (
                    <Link to="/admin">
                      {intl.formatMessage(messages.administration)}
                    </Link>
                  ),
                  key: "admin",
                  style: {
                    fontWeight: "400",
                  },
                }
              : null,
            {
              label: intl.formatMessage(messages.logout),
              key: "logout",
              style: {
                fontWeight: "400",
              },
              onClick: onClickLogout,
            },
          ],
        }}
        trigger={["click"]}
        placement="bottomRight"
      >
        <div>
          <UserOutlined style={{ fontSize: "2.1rem", color: "#931d21" }} />
        </div>
      </Dropdown>
    </div>
  );
};
