import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Button } from "antd";
import ButtonProps from "../../../../../UiProperties/ButtonProps";

const CustomExpandIcon = (props: any) => {
  const iconProps = {
    fontSize: "20px",
    color: "#931d21",
    fontWeight: "200",
  };
  let expandSign;
  if (props.expanded) {
    expandSign = <CaretUpOutlined style={iconProps} />;
  } else {
    expandSign = <CaretDownOutlined style={iconProps} />;
  }
  return (
    <Button
      style={{
        position: "absolute",
        top: "1rem",
        left: "0.8rem",
        padding: "0.25rem",
        ...ButtonProps.ghost,
      }}
      type="dashed"
      onClick={(e) => {
        props.onExpand(props.record, e);
      }}
    >
      {expandSign}
    </Button>
  );
};

export default CustomExpandIcon;
