import { Space } from "antd";
import { PropertiesResult } from "../../../../../hooks/useCreateOrUpdateFiles/useGetFileMeta";
import ItemProperty from "../../Common/ItemProperty";

type Props = {
  properties: PropertiesResult;
};

const FileProperties = ({ properties }: Props) => {
  return (
    <Space
      size={4}
      wrap
      style={{
        width: "100%",
      }}
    >
      <ItemProperty propertyKey="familyName" properties={properties} />
      <ItemProperty propertyKey="revitVersion" properties={properties} />
      <ItemProperty propertyKey="ostCategory" properties={properties} />
      <ItemProperty propertyKey="placementMethod" properties={properties} />
    </Space>
  );
};

export default FileProperties;
