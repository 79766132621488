import { Pagination, Response } from "@formitas-ag/bimfiles-types";
import axiosService from "../fetch/axios.service";
import { EndpointOptions } from "./endpoint.options.utils";
import { errorRenderer } from "./errorThrower";

export const get = <ResultType>(
  endpoint: string,
  page: number,
  size: number,
  options?: EndpointOptions
): Promise<Pagination<ResultType> | undefined> =>
  new Promise(async (resolve, reject) => {
    try {
      const url = `/${endpoint}?page=${page}&size=${size}`;
      const response = await axiosService.get<Response<Pagination<ResultType>>>(
        url,
        {}
      );

      if (response.data && response.data.success) {
        resolve(response.data.result);
      }
      if (options?.ignoreExceptions) {
        resolve(undefined as any);
      }
      reject(response.statusText);
    } catch (error) {
      if (options?.ignoreExceptions) {
        resolve(undefined as any);
      }
      reject(errorRenderer(error));
    }
  });

export const getOne = <ResultType>(
  endpoint: string,
  id: string,
  options?: EndpointOptions
): Promise<ResultType> =>
  new Promise(async (resolve, reject) => {
    try {
      const url = `/${endpoint}/${id}`;
      const response = await axiosService.get<Response<ResultType>>(url);

      if (response.data && response.data.success) {
        resolve(response.data.result);
      }
      if (options?.ignoreExceptions) {
        resolve(undefined as any);
      }
      reject(response.statusText);
    } catch (error) {
      if (options?.ignoreExceptions) {
        resolve(undefined as any);
      }
      reject(errorRenderer(error));
    }
  });

export const create = <ResultType, DataType>(
  endpoint: string,
  data: DataType
): Promise<ResultType> =>
  new Promise(async (resolve, reject) => {
    try {
      const url = `/${endpoint}/create`;
      const response = await axiosService.post<Response<ResultType>>(url, data);

      if (response.data && response.data.success) {
        resolve(response.data.result);
      }

      reject(response.statusText);
    } catch (error) {
      reject(errorRenderer(error));
    }
  });

export const update = <ResultType, DataType>(
  endpoint: string,
  id: string,
  data: Partial<DataType>
): Promise<ResultType> =>
  new Promise(async (resolve, reject) => {
    try {
      const url = `/${endpoint}/${id}/update`;
      const response = await axiosService.put<Response<ResultType>>(url, data);

      if (response.data && response.data.success) {
        resolve(response.data.result);
      }
      reject(response.statusText);
    } catch (error) {
      reject(errorRenderer(error));
    }
  });

export const remove = (endpoint: string, id: string): Promise<void> =>
  new Promise(async (resolve, reject) => {
    try {
      const url = `/${endpoint}/${id}/delete`;
      const response =
        endpoint === "items"
          ? // Only items can be deleted with POST because it can have a body
            await axiosService.post<Response<string>>(url)
          : await axiosService.delete<Response<string>>(url);

      if (
        (response.status === 200 || response.status === 201) &&
        response.data.success
      ) {
        resolve();
      }
      reject(response.statusText);
    } catch (error) {
      reject(errorRenderer(error));
    }
  });

export const ignoreException = <T>(obj: Promise<T>): Promise<T | undefined> => {
  return new Promise<T | undefined>((resolve) => {
    obj.then((obj) => resolve(obj)).catch((_) => resolve(undefined));
  });
};
