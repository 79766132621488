import { Button } from "antd";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { TypeCatalogModal } from "../../HomepageComponents/DownloadItem/TypeCatalogModal";
import useSidecarFiles from "../../../../hooks/useSidecarFiles";
import useTypeCatalog from "../../../../hooks/useTypeCatalog";
import { useCreateOrUpdateMultipleFiles } from "../../../../hooks/useCreateOrUpdateMultipleFiles";
import translations from "../../../../translations/constants/translations";
import { UpdatingFile } from "../../../../utils/types/updateFilesTypes";

type Props = {
  mode: "single" | "multiple";
  index?: number;
};

const ItemSidecar = (props: Props) => {
  const index = props.index ?? -1;

  const intl = useIntl();

  const { getUpload } = useCreateOrUpdateMultipleFiles();

  const upload = getUpload(index);

  const [files, setFiles] = useState<UpdatingFile[]>([]);

  useEffect(() => {
    if (upload) {
      setFiles(upload.updatingFiles);
    }
  }, [upload]);

  const [modalVisible, setModalVisible] = useState(false);

  const [numberOfTypes, setNumberOfTypes] = useState(-1);

  const [sidecarDataString, setSidecarDataString] =
    useState<string | null>(null);

  const { cleanup, hasWhichSidecar, load, sidecarData } = useSidecarFiles({
    index,
    source: {
      type: "whileUpdating",
      files: files,
    },
  });

  const { tableData } = useTypeCatalog(sidecarData);

  // we load on mount
  useEffect(() => {
    load();

    if (sidecarData) {
      setSidecarDataString(sidecarData);
    }
  }, [files, sidecarData]);

  useEffect(() => {
    if (tableData) {
      setNumberOfTypes(tableData.length);
    }
  }, [tableData]);

  const loading = numberOfTypes === -1;

  return (
    <>
      <Button
        onClick={() => setModalVisible(true)}
        loading={loading}
        disabled={loading}
      >
        {intl.formatMessage(translations.ui.meta.sidecar.viewButton, {
          numberOfTypes,
        })}
      </Button>

      <TypeCatalogModal
        type="view"
        sidecarData={sidecarDataString}
        sidecarType={hasWhichSidecar.custom ? "sidecar" : "sidecar-automatic"}
        visible={modalVisible}
        filesWithBase64={null}
        clearImportHandler={() => {
          cleanup();
          setModalVisible(false);
        }}
      />
    </>
  );
};

export default ItemSidecar;
