import { Col, Row } from "antd";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { ItemImage } from "../ItemImage";
import ExpandedTableItemContent from "./ExpandedTableItemContent";

interface ExpandedTableItemProps {
  dataSource: ItemPopulated;
}

export const ExpandedTableItem = ({ dataSource }: ExpandedTableItemProps) => {
  return (
    <Row>
      <Col offset={1} span={4}>
        <Row justify="center" align="middle" style={{ height: "100%" }}>
          <ItemImage
            item={dataSource}
            customStyle={{
              margin: "auto",
              maxHeight: `190px`,
              width: `100%`,
            }}
            iconSize="large"
          ></ItemImage>
        </Row>
      </Col>

      <Col offset={1} span={18}>
        <ExpandedTableItemContent dataSource={dataSource} />
      </Col>
    </Row>
  );
};
