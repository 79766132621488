import { UploadFile } from "antd";
import { ItemPopulated, UpdateItemData } from "@formitas-ag/bimfiles-types/lib/item";
import { create } from "zustand";
import { UpdatingFile } from "../utils/types/updateFilesTypes";
import { RcFile } from "antd/lib/upload";
import { RevitStore } from "./revitStore";
import { File } from "@formitas-ag/bimfiles-types";
import { ValidateUploadResult } from "../hooks/useCreateOrUpdateFiles/useValidateUpload";

type OverwriteResult =
  | {
      result: "nothing";
    }
  | {
      result: "overwritingFamily";
      files: File[];
    }
  | {
      result: "overwritingOther";
      files: File[];
    };

export type UploadType = {
  index: number;
  mode: "create" | "update";
  oldItem?: ItemPopulated;
  item?: Partial<UpdateItemData>;

  overwriteResult?: OverwriteResult;
  overwriteResultCallback?: {
    ok: () => void;
    cancel: () => void;
  };

  temporaryFiles: UploadFile[];

  currentError?: string;

  updatingFiles: UpdatingFile[];
  exportingFromRevit: boolean;
  localFilesWaitingForRevit: RcFile[];
  updatingFilesError?:
    | "no-revit"
    | Required<RevitStore>["currentError"]["type"];
  updatingFilesGenericError?: string;
  updatingFilesUploadProgress?: number;
};

type CreateOrUpdateItemStore = {
  mode: "create" | "update" | undefined;
  itemMode: "single" | "multiple" | undefined;
  uploads: UploadType[];
  currentRevitExportIndex?: number;
  multiUploadFileGroupQueue: { index: number; familyFile: RcFile }[];
  droppedFilesQueue: RcFile[];
  isLoading: boolean;
};

export const useCreateOrUpdateItemStore = create<CreateOrUpdateItemStore>(
  (set, get) => ({
    mode: undefined,
    itemMode: undefined,
    uploads: [],
    multiUploadFileGroupQueue: [],
    droppedFilesQueue: [],
    isLoading: false,
  })
);

// useCreateOrUpdateItemStore.subscribe((newState, oldState) => {
//   if (JSON.stringify(newState.uploads) !== JSON.stringify(oldState.uploads)) {
//     console.log("newState.uploads", newState.uploads);
//   }
// });
