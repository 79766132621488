import { FileWithUrl } from "@formitas-ag/bimfiles-types/lib/file";
import { getLogger } from "../../../utils/logger.utils";
import {
  UpdatingAddedFile,
  UpdatingReplacedFile,
} from "../../../utils/types/updateFilesTypes";
import uploadFile from "../../useUpload/uploadFile";
import useGetFileMeta from "../useGetFileMeta";
import { File as BimFilesFiles } from "@formitas-ag/bimfiles-types/lib/file";
import { useCreateOrUpdateMultipleFiles } from "../../useCreateOrUpdateMultipleFiles";

const logger = getLogger("useUploadNewFiles");

/**
 * Uploads all files that require upload to S3 because the got added or changed
 */
export default () => {
  const { getName, getFile } = useGetFileMeta();
  const { setUpload } = useCreateOrUpdateMultipleFiles();

  const uploadNewFile = async (
    index: number,
    files: (UpdatingAddedFile | UpdatingReplacedFile)[],
    fileUrlGenerator: (
      file: UpdatingAddedFile | UpdatingReplacedFile
    ) => Promise<FileWithUrl | undefined>
  ): Promise<BimFilesFiles[]> => {
    const result: (BimFilesFiles | undefined)[] = await Promise.all(
      files.map(async (f) => {
        const name = getName(f);

        logger.debug(`Uploading the file ${f.id} with name=${name}.`);

        //generate upload url with generator
        const fileUrl = await fileUrlGenerator(f);

        if (!fileUrl) {
          logger.error(
            `No file url has been returned by generator for file ${f.id}.`,
            {
              file: f,
              fileUrl,
            }
          );
        }

        const file = getFile(f);

        const setProgress = (currentProgress: number) => {
          const progress = ((index + 1) / files.length) * currentProgress;

          setUpload(index, { updatingFilesUploadProgress: progress });
        };

        setProgress(0);

        //upload the file to S3
        await uploadFile(file, fileUrl!.url, setProgress);

        setProgress(1);

        logger.debug(`Completed upload for file ${f.id}`);

        //add the file to be written to our updatedFile
        return {
          id: fileUrl!.id,
          key: fileUrl!.key,
          name: fileUrl!.name,
          role: fileUrl!.role,
        };
      })
    );

    return result.filter((f) => f !== undefined) as BimFilesFiles[];
  };

  return {
    uploadNewFile,
  };
};
