import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import {
  UploadType,
  useCreateOrUpdateItemStore,
} from "../state/createOrUpdateItemStore";
import useUpdateAddonFiles from "./useCreateOrUpdateFiles/useUpdateAddonFiles";
import useUploadLocalFile from "./useCreateOrUpdateFiles/useUploadLocalFile";
import { getLogger } from "../utils/logger.utils";
import { useCreateOrUpdateMultipleFiles } from "./useCreateOrUpdateMultipleFiles";
import { UpdatingFile } from "../utils/types/updateFilesTypes";
import useGetFileMeta from "./useCreateOrUpdateFiles/useGetFileMeta";

const logger = getLogger("useCreateOrUpdateFiles");

export default () => {
  const uploadLocalFile = useUploadLocalFile();
  const updateAddonFiles = useUpdateAddonFiles();
  const { getUpload, setUpload } = useCreateOrUpdateMultipleFiles();
  const { mapParentAndChildrenFiles } = useGetFileMeta();

  const _getDefaultUpload = (
    mode: "create" | "update",
    index: number = 0
  ): UploadType => {
    return {
      exportingFromRevit: false,
      index,
      localFilesWaitingForRevit: [],
      mode,
      temporaryFiles: [],
      updatingFiles: [],
      item: {},
    };
  };

  const addAdditionalUpload = (index: number) => {
    const currentMode = useCreateOrUpdateItemStore.getState().mode;

    if (currentMode !== undefined) {
      useCreateOrUpdateItemStore.setState({
        uploads: useCreateOrUpdateItemStore
          .getState()
          .uploads.concat([_getDefaultUpload(currentMode, index)]),
      });
    }
  };

  const startCreate = () => {
    useCreateOrUpdateItemStore.setState({
      mode: "create",
      itemMode: "single",
      uploads: [_getDefaultUpload("create")],
      multiUploadFileGroupQueue: [],
      droppedFilesQueue: [],
      currentRevitExportIndex: undefined,
      isLoading: false,
    });

    logger.debug(`Started creating a new item with itemMode=single.`);
  };

  const startUpdate = (existingItem: ItemPopulated) => {
    useCreateOrUpdateItemStore.setState({
      mode: "update",
      itemMode: "single",
      uploads: [
        {
          ..._getDefaultUpload("update"),
          oldItem: existingItem,
          item: {
            categoryId: existingItem.categoryId,
            title: existingItem.title,
            description: existingItem.description,
          },
        },
      ],
      multiUploadFileGroupQueue: [],
      droppedFilesQueue: [],
      currentRevitExportIndex: undefined,
      isLoading: false,
    });

    //we need to map the existing files after the upload has been created
    const mappedParentAndChildrenFiles = mapParentAndChildrenFiles(
      -1,
      existingItem.files.map((f, index) => ({
        state: "default",
        file: f,
        id: f.id,
        slot: index,
      }))
    );

    setUpload(-1, {
      updatingFiles: mappedParentAndChildrenFiles,
    });

    logger.debug(
      `Started updating the existing item ${existingItem.id} with itemMode=single.`,
      {
        existingItem,
      }
    );
  };

  const startBatchUpdate = (existingItems: ItemPopulated[]) => {
    let index = 1;
    const indexToExistingFilesList: {
      index: number;
      files: ItemPopulated["files"];
    }[] = [];
    const uploads = existingItems.map((existingItem) => {
      indexToExistingFilesList.push({ index, files: existingItem.files });

      return {
        ..._getDefaultUpload("update", index++),
        oldItem: existingItem,
        item: {
          categoryId: existingItem.categoryId,
          title: existingItem.title,
          description: existingItem.description,
        },
      };
    });

    useCreateOrUpdateItemStore.setState({
      mode: "update",
      itemMode: "multiple",
      uploads,
      multiUploadFileGroupQueue: [],
      droppedFilesQueue: [],
      currentRevitExportIndex: undefined,
      isLoading: false,
    });

    //we need to map the existing files after the upload has been created
    for (const { index, files } of indexToExistingFilesList) {
      const mappedParentAndChildrenFiles = mapParentAndChildrenFiles(
        index,
        files.map((f, fIndex) => ({
          state: "default",
          file: f,
          id: f.id,
          slot: fIndex,
        }))
      );

      setUpload(index, {
        updatingFiles: mappedParentAndChildrenFiles,
      });
    }

    logger.debug(
      `Started updating ${indexToExistingFilesList.length} items with itemMode=multiple.`,
      {
        existingItems,
      }
    );
  };

  const stopCreateOrUpdate = () => {
    logger.debug(`Stopping create or update of item.`);

    useCreateOrUpdateItemStore.setState({
      mode: undefined,
      uploads: [],
      itemMode: undefined,
      currentRevitExportIndex: undefined,
      multiUploadFileGroupQueue: [],
      droppedFilesQueue: [],
      isLoading: false,
    });

    updateAddonFiles.clearRevitExport();
  };

  const removeUpload = (index: number) => {
    if (index === -1) {
      stopCreateOrUpdate();
      return;
    }

    const newUploads = useCreateOrUpdateItemStore
      .getState()
      .uploads.filter((u) => u.index !== index);

    if (newUploads.length >= 1) {
      useCreateOrUpdateItemStore.setState({ uploads: newUploads });
    } else {
      stopCreateOrUpdate();
    }
  };

  const resetUpdatingFiles = (index: number) => {
    const upload = getUpload(index);

    if (upload?.oldItem) {
      //reset the files to the default state
      const newFiles = upload.oldItem.files
        .sort((a, b) => {
          return a.name.localeCompare(b.name);
        })
        .map<UpdatingFile>((file, index) => {
          return {
            id: file.id,
            slot: index,
            file,
            state: "default",
          };
        });

      const mappedParentAndChildrenFiles = mapParentAndChildrenFiles(
        index,
        newFiles
      );

      setUpload(index, {
        updatingFiles: mappedParentAndChildrenFiles,
      });
    } else {
      setUpload(index, {
        updatingFiles: [],
      });
    }
  };

  return {
    startCreate,
    startUpdate,
    stopCreateOrUpdate,
    addAdditionalUpload,
    resetUpdatingFiles,
    startBatchUpdate,
    removeUpload,
    ...uploadLocalFile,
    ...updateAddonFiles,
  };
};
