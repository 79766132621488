import { MenuOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps } from "antd";

const itemStyle = {
  fontWeight: "400",
  fontSize: "0.8rem",
  lineHeight: "20px",
};

const makeMenuItem = (
  key: string,
  label: string,
  onClick: () => void
): Required<MenuProps>["items"][0] => {
  return {
    type: undefined,
    key,
    label,
    style: itemStyle,
    onClick,
  };
};

type Props = {
  categoryId: string;
  selectCategoryForPermissionEditing: (categoryId: string) => void;
};

const CategoryActionDropdown = (props: Props) => {
  return (
    <Dropdown
      menu={{
        items: [
          makeMenuItem("editPermissions", "Edit Permissions", () =>
            props.selectCategoryForPermissionEditing(props.categoryId)
          ),
        ],
      }}
      trigger={["click"]}
      placement="bottomRight"
    >
      <Button type="text" style={{ padding: "6px" }}>
        <MenuOutlined style={{ fontSize: "20px", color: "#70838f" }} />
      </Button>
    </Dropdown>
  );
};

export default CategoryActionDropdown;
