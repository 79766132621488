import { Tag } from "antd";
import { Item } from "@formitas-ag/bimfiles-types/lib";
import { defineMessages, useIntl } from "react-intl";

type Props = {
  state: Item["state"];
};

export default ({ state }: Props) => {
  const intl = useIntl();

  const messages = defineMessages({
    approvable: {
      id: "item.state.approvable",
      defaultMessage: "Approvable",
      description: "The state of an item that can be approved",
    },
    approved: {
      id: "item.state.approved",
      defaultMessage: "Approved",
      description: "The state of an item that has been approved",
    },
    denied: {
      id: "item.state.denied",
      defaultMessage: "Denied",
      description: "The state of an item that has been denied",
    },
    unfinished: {
      id: "item.state.unfinished",
      defaultMessage: "Unfinished",
      description: "The state of an item that has not been finished",
    },
  });

  const stateToColorMap: { [key in Item["state"]]: string } = {
    approvable: "#108ee9",
    approved: "#87d068",
    denied: "#f50",
    unfinished: "#f50",
  };

  const stateToMessageMap: { [key in Item["state"]]: string } = {
    approvable: intl.formatMessage(messages.approvable),
    approved: intl.formatMessage(messages.approved),
    denied: intl.formatMessage(messages.denied),
    unfinished: intl.formatMessage(messages.unfinished),
  };

  return <Tag color={stateToColorMap[state]}>{stateToMessageMap[state]}</Tag>;
};
