import { Progress } from "antd";
import { useCreateOrUpdateMultipleFiles } from "../../../../hooks/useCreateOrUpdateMultipleFiles";

type Props = {
  mode: "single" | "multiple";
  index: number;
};

const FileUploadProgress = (props: Props) => {

  const { getUpload } = useCreateOrUpdateMultipleFiles();

  const rawProgress = getUpload(props.index)?.updatingFilesUploadProgress;

  const progress = rawProgress ? Math.min(Math.max(rawProgress, 0), 1) : -1;

  const canShow = progress >= 0 && progress <= 1;
  const isActive = progress > 0 && progress < 1;
  const isSuccess = progress === 1;

  return (
    <>
      {canShow && (
        <Progress
          percent={Math.round(progress * 100)}
          type="line"
          size="small"
          status={isActive ? "active" : isSuccess ? "success" : "normal"}
        />
      )}
    </>
  );
};

export default FileUploadProgress;
