/* eslint-disable-next-line no-restricted-globals */
const isProduction = location.hostname === "app.bimfiles.de";

export const getLogger = (name: string) => {
  return {
    debug: (message: string, data?: object) => {
      if (isProduction) return;
      if (data) {
        console.debug(`[${name}] ${message}`, data);
      } else {
        console.debug(`[${name}] ${message}`);
      }
    },
    info: (message: string, data?: object) => {
      if (isProduction) return;
      if (data) {
        console.info(`[${name}] ${message}`, data);
      } else {
        console.info(`[${name}] ${message}`);
      }
    },
    warn: (message: string, data?: object) => {
      if (isProduction) return;
      if (data) {
        console.warn(`[${name}] ${message}`, data);
      } else {
        console.warn(`[${name}] ${message}`);
      }
    },
    error: (message: string, data?: object) => {
      if (data) {
        console.error(`[${name}] ${message}`, data);
      } else {
        console.error(`[${name}] ${message}`);
      }
    },
  };
};

const logger = getLogger("Main");

export default logger;
