import { Modal } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { useQuery } from "react-query";
import { useViewStore } from "../../../../state/viewStore";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import Timeline from "../Timeline";
import api from "../../../../api/api";

const TimelineModal = () => {
  const intl = useIntl();
  const selectedTimelineItemId = useViewStore(
    (state) => state.selectedTimelineItemId
  );
  const itemQuery = useQuery({
    queryKey: ["items", selectedTimelineItemId],
    queryFn: async () => {
      if (selectedTimelineItemId !== undefined) {
        return await api.items.getOne(selectedTimelineItemId);
      }
    },
  });

  const closeModal = () => {
    //the dialog gets closed when we don't have an item selected
    useViewStore.setState({
      selectedTimelineItemId: undefined,
    });
  };

  const messages = defineMessages({
    title: {
      id: "component.timeline.title",
      defaultMessage: "Timeline",
      description: "Title of the timeline modal",
    },
  });

  return (
    <Modal
      title={intl.formatMessage(messages.title)}
      centered
      style={{ padding: "2rem" }}
      open={selectedTimelineItemId !== undefined}
      onOk={closeModal}
      width={"100%"}
      closable={true}
      onCancel={closeModal}
      footer={null}
    >
      {itemQuery.isSuccess && itemQuery.data !== undefined && (
        <Timeline item={itemQuery.data as ItemPopulated} />
      )}
    </Modal>
  );
};

export default TimelineModal;
