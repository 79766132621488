import { useEffect } from "react";
import { useItemsTableStore } from "../state/itemsTableStore";
import { useQuery, useQueryClient } from "react-query";
import useFiltersStore from "../state/filtersStore";
import api from "../api/api";

export const useSearchItems = () => {
  const queryClient = useQueryClient();

  const size = useItemsTableStore((state) => state.size);
  const page = useItemsTableStore((state) => state.page);
  const itemsState = useFiltersStore((state) => state.itemsState);
  const filters = useFiltersStore((state) => state.currentFilters);

  const setPage = useItemsTableStore((state) => state.setPage);

  // Return to the first page if filter change state
  useEffect(() => {
    setPage(1);
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === "items",
    });
  }, [filters, itemsState]);

  const query = useQuery(
    ["items", page, size, itemsState, filters],
    async () => {
      const items = await api.search.find(
        page,
        size,
        itemsState,
        itemsState === "approved" ? filters : undefined
      );

      return items;
    }
  );

  return { page, size, itemsState, filters, query };
};
