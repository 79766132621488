import { Button, Modal } from "antd";
import useOverwriteItem from "../../../../hooks/useOverwriteItem";
import useGetFileMeta from "../../../../hooks/useCreateOrUpdateFiles/useGetFileMeta";
import OverwriteItemList from "./OverwriteItemModal/OverwriteItemList";
import { useOverwriteItemStore } from "../../../../state/overwriteItemStore";
import useFileListActions from "../../../../hooks/useCreateOrUpdateFiles/useFileListActions";
import { getLogger } from "../../../../utils/logger.utils";
import { defineMessages, useIntl } from "react-intl";

const logger = getLogger("OverwriteItemModal");

const messages = defineMessages({
  title: {
    id: "modal.overwrite-item-files.title",
    defaultMessage: "Overwrite {nameOrCount}?",
    description: "Title for the overwrite item files modal",
  },
  titleCount: {
    id: "modal.overwrite-item-files.title-count",
    defaultMessage: "{count} files",
    description: "Possible content for title of the overwrite item files modal",
  },
  abort: {
    id: "modal.overwrite-item-files.abort",
    defaultMessage: "Abort",
    description: "Label for the abort button in the overwrite item files modal",
  },
  overwrite: {
    id: "modal.overwrite-item-files.overwrite",
    defaultMessage: "Overwrite",
    description:
      "Label for the overwrite button in the overwrite item files modal",
  },
});

const OverwriteItemModal = () => {
  const intl = useIntl();
  const { currentOverwritingFile } = useOverwriteItem();

  const { getName } = useGetFileMeta();
  const { replaceFile } = useFileListActions();

  const onAcceptOverwrite = () => {
    if (currentOverwritingFile) {
      const elements =
        currentOverwritingFile.type === "single"
          ? [
              {
                file: currentOverwritingFile.file,
                overwrittenFile: currentOverwritingFile.overwrittenFile,
              },
            ]
          : currentOverwritingFile.elements;

      logger.debug(`Overwriting ${elements.length} files: `, elements);

      elements.forEach((element) => {
        replaceFile(
          currentOverwritingFile.index,
          element.file,
          element.overwrittenFile
        );
      });

      useOverwriteItemStore.setState({
        currentOverwritingFile: undefined,
      });
    }
  };

  const onAbortOverwrite = () => {
    useOverwriteItemStore.setState({
      currentOverwritingFile: undefined,
    });
  };

  return (
    <Modal
      destroyOnClose
      zIndex={1001}
      centered={true}
      closable={false}
      width={"70%"}
      style={{
        minWidth: "600px",
      }}
      open={currentOverwritingFile !== undefined}
      footer={
        <>
          <Button type="default" onClick={onAbortOverwrite}>
            {intl.formatMessage(messages.abort)}
          </Button>
          <Button type="primary" danger onClick={onAcceptOverwrite}>
            {intl.formatMessage(messages.overwrite)}
          </Button>
        </>
      }
      title={intl.formatMessage(messages.title, {
        nameOrCount:
          currentOverwritingFile?.type === "single"
            ? `'${getName(currentOverwritingFile.overwrittenFile)}'`
            : intl.formatMessage(messages.titleCount, {
                count: currentOverwritingFile?.elements.length,
              }),
      })}
    >
      <OverwriteItemList elements={currentOverwritingFile!} />
    </Modal>
  );
};
export default OverwriteItemModal;
