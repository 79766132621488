import { Alert, Button, Col, Row } from "antd";
import UpdateCategory from "./Meta/ItemCategory";
import UpdateChangeDescription from "./Meta/ItemChangeDescription";
import UpdateDescription from "./Meta/ItemDescription";
import UpdateTags from "./Meta/ItemTags";
import UpdateTitle from "./Meta/ItemTitle";
import UpdateFiles from "./Meta/ItemFiles";
import { useIntl } from "react-intl";
import { AddTagsButton } from "../HomepageComponents/ItemsTable/AddTags/AddTagsButton";
import { useCreateOrUpdateMultipleFiles } from "../../../hooks/useCreateOrUpdateMultipleFiles";
import OverwriteItemModal from "./Modal/OverwriteItemModal";
import Can from "../../../api/ability/Can";
import translations from "../../../translations/constants/translations";
import { CategoryRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";

type Props = {
  isSaveButtonEnabled: boolean;
};

const UpdateItemForm = (props: Props) => {
  const index = -1;
  const intl = useIntl();
  const { getUpload, setUpload } = useCreateOrUpdateMultipleFiles();

  const currentError = getUpload(index)?.currentError;

  return (
    <>
      {currentError && (
        <Alert
          style={{ marginBottom: "1rem" }}
          message={intl.formatMessage(translations.ui.generic.error)}
          description={currentError}
          type="error"
          closable
          onClose={() => {
            setUpload(index, {
              currentError: undefined,
            });
          }}
        />
      )}

      <Row style={{ marginBottom: "1rem" }}>
        <Col span={24}>
          <UpdateFiles
            mode="single"
            index={-1}
            isSingleUploadSaveButtonEnabled={props.isSaveButtonEnabled}
          />
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: "1rem" }}>
        <Col span={12}>
          <UpdateCategory mode="single" />
        </Col>
        <Col span={10}>
          <UpdateTags mode="single" />
        </Col>
        <Col span={2}>
          <Row align="bottom" style={{ height: "100%" }}>
            <AddTagsButton />
          </Row>
        </Col>
      </Row>

      <Row style={{ marginBottom: "1rem" }}>
        <UpdateTitle mode="single" />
      </Row>

      <Row style={{ marginBottom: "1rem" }}>
        <UpdateDescription mode="single" />
      </Row>

      <Row>
        <UpdateChangeDescription mode="single" />
      </Row>

      <OverwriteItemModal />
    </>
  );
};

export default UpdateItemForm;
