import { Item, ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { devtools } from "zustand/middleware";
import { create } from "zustand";

type SelectedItem = {
  id: string;
  files: Item["files"];
};

export type SelectedColumns =
  | "category"
  | "tags"
  | "description"
  | "ostCategory"
  | "revitVersion"
  | "updatedAt";
// | "createdBy";

type itemsTableStore = {
  page: number;
  setPage: (page: number) => void;

  size: number;
  setSize: (size: number) => void;

  totalDocuments: number;
  setTotalDocuments: (totalDocuments: number) => void;

  currentItems: ItemPopulated[];
  setCurrentItems: (currentItems: ItemPopulated[]) => void;

  selectedItems: SelectedItem[];
  setSelectedItems: (selectedItems: SelectedItem[]) => void;

  selectedColumns: SelectedColumns[];
  setSelectedColumns: (selectedColumns: SelectedColumns[]) => void;
};

export const useItemsTableStore = create<itemsTableStore>()(
  devtools((set) => ({
    page: 1,
    setPage: (page: number) => {
      set({ page: page });
    },

    size: 20,
    setSize: (size: number) => {
      set({ size: size });
    },

    totalDocuments: 0,
    setTotalDocuments: (totalDocuments: number) => {
      set({ totalDocuments: totalDocuments });
    },

    currentItems: [],
    setCurrentItems: (currentItems: ItemPopulated[]) => {
      set({ currentItems: [...currentItems] });
    },

    selectedItems: [],
    setSelectedItems: (selectedItems: SelectedItem[]) => {
      set({ selectedItems: [...selectedItems] });
    },

    selectedColumns: ["tags", "updatedAt"],
    setSelectedColumns: (selectedColumns: SelectedColumns[]) => {
      set({ selectedColumns: [...selectedColumns] });
    },
  }))
);
