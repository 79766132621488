import { QuestionCircleOutlined } from "@ant-design/icons";
import { apiType } from "@formitas-ag/formitable/lib/EditDrawer.types";
import { Popconfirm, PopconfirmProps } from "antd";
import { User } from "@formitas-ag/bimfiles-types/lib";

type Props = {
  api: apiType<User>;
  confirmAction: () => Promise<void>;
} & PopconfirmProps;

const DeletePopConfirm = (props: Props) => {
  return (
    <Popconfirm
      zIndex={10000}
      placement="topRight"
      style={{ width: "100%" }}
      onConfirm={async () => {
        await props.confirmAction();
        props.api.mainList?.reloadData();
      }}
      onCancel={() => {}}
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      {...props}
    >
      <span style={{ display: "block" }}>{props.title?.toString()}</span>
    </Popconfirm>
  );
};

export default DeletePopConfirm;
