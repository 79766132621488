import { User } from "@formitas-ag/bimfiles-types";
import { UserTypeEnum } from "@formitas-ag/bimfiles-types/lib/permission";

export const getSystemUserId = () => "000000000000000000000000";
export const getMigrationUserId = () => "111111111111111111111111";

export const getMigrationUser = (): User => {
  return {
    id: getMigrationUserId(),
    createdBy: getSystemUserId(),
    createdAt: new Date(),
    updatedBy: getSystemUserId(),
    updatedAt: new Date(),
    email: "migration@example.com",
    name: "User",
    preName: "Migration",
    organisations: [],
    password: "",
    state: "disabled",
    type: UserTypeEnum.USER,
    ssoId: "undefined",
    ssoRefreshToken: "undefined",
    ssoToken: "undefined",
  };
};