import { CombinedAccessToken } from "@formitas-ag/bimfiles-types/lib";
import jwtDecode from "jwt-decode";
import React from "react";
import { useAuthStore } from "../../state/authStore";
import { createObservable } from "./obervable.utils";

export const selectedOrganisationIdObservable = createObservable<string | null>(
  localStorage.getItem("selectedOrganisationId")
);

selectedOrganisationIdObservable.subscribe((newSelectedOrganisationId) => {
  if (newSelectedOrganisationId !== null) {
    localStorage.setItem("selectedOrganisationId", newSelectedOrganisationId);
  } else {
    localStorage.removeItem("selectedOrganisationId");
  }
});

export const hasSelectedOrganisationId = () => {
  return selectedOrganisationIdObservable.get() !== null;
};

export const useSelectedOrganisationId = (): [
  string | null,
  (newSelectedOrganisationId: string | null) => void
] => {
  const [selectedOrganisationId, setSelectedOrganisationIdState] =
    React.useState(selectedOrganisationIdObservable.get());

  React.useEffect(() => {
    return selectedOrganisationIdObservable.subscribe(
      setSelectedOrganisationIdState
    );
  }, []);

  const setSelectedOrganisationId = (
    newSelectedOrganisationId: string | null
  ) => {
    selectedOrganisationIdObservable.set(newSelectedOrganisationId);
    useAuthStore.setState({
      selectedOrganisationId: newSelectedOrganisationId,
    });
  };

  return [selectedOrganisationId, setSelectedOrganisationId];
};

export const accessTokenObservable = createObservable<string | null>(
  localStorage.getItem("accessToken")
);

accessTokenObservable.subscribe((newAccessToken) => {
  if (newAccessToken !== null) {
    localStorage.setItem("accessToken", newAccessToken);
  } else {
    localStorage.removeItem("accessToken");
  }
});

export const hasAccessToken = () => {
  return accessTokenObservable.get() !== null;
};

export const useAccessToken = (): [
  string | null,
  (accessToken: string | null) => void
] => {
  const [accessToken, setAccessTokenState] = React.useState(
    accessTokenObservable.get()
  );

  React.useEffect(() => {
    return accessTokenObservable.subscribe(setAccessTokenState);
  }, []);

  React.useEffect(() => {
    if (hasAccessTokenExpired(accessToken)) {
      document.cookie =
        "accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; SameSite=Strict;";
    }
  }, [accessToken]);

  const setAccessToken = (newAccessToken: string | null) => {
    accessTokenObservable.set(newAccessToken);
  };

  return [accessToken, setAccessToken];
};

accessTokenObservable.subscribe((newAccessToken) => {
  //when a new access token exists update the user profile

  if (newAccessToken !== null) {
    const tokenContent: CombinedAccessToken = jwtDecode(newAccessToken);

    useAuthStore.setState({
      user: tokenContent.user,
    });
  } else {
    useAuthStore.setState({
      user: undefined,
    });
  }
});

const hasAccessTokenExpired = (accessToken: string | null) => {
  if (!accessToken) return true;
  const tokenContent: CombinedAccessToken = jwtDecode(accessToken);

  return tokenContent.expiresAtSeconds * 1000 < Date.now();
};
