import { Select } from "antd";
import {
  DistinctSearchResultType,
  DistinctSearchTag,
} from "@formitas-ag/bimfiles-types/lib/search";
import { SelectProps } from "antd/lib/select";
import { useEffect, useMemo, useState } from "react";
import { Pagination, Tag as TagType } from "@formitas-ag/bimfiles-types/lib";
import TagRender from "./TagRender";
import CustomTag from "../HomepageComponents/CustomTag";
import useGetTags from "../../../hooks/useGetTags";

type OptionsType = {
  label: string;
  value: string;
};

type Props = {
  type: "filter" | "all";
  selectProps?: SelectProps;
};

const TagsDropdown = (componentProps: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [queryData, setQueryData] = useState<
    Pagination<TagType> | DistinctSearchResultType
  >([]);

  // Get the distinct tags based on the filters
  const query = useGetTags(componentProps.type);

  // Create the options needed for the dropdown based on the current filter
  const [options, optionsTagsOptions] = useMemo(() => {
    const tagsOptions: OptionsType[] = [];

    const tagsOptionsTags = [];

    if (query.data !== undefined) {
      setQueryData(query.data);
      const queryData =
        componentProps.type === "all"
          ? (query.data as Pagination<TagType>).data
          : (query.data as DistinctSearchTag[]);
      const length = queryData.length;

      for (let i = 0; i < length; i++) {
        const tag = queryData[i];
        tagsOptions.push({
          label: tag.title,
          value: tag.id,
        });

        tagsOptionsTags.push(
          <Select.Option key={tag.id} value={tag.id} className={tag.title}>
            <CustomTag label={tag.title} color={queryData[i].color} />
          </Select.Option>
        );
      }
    }

    return [
      tagsOptions.sort((a, b) => a.label.localeCompare(b.label)),
      tagsOptionsTags.sort((a, b) => {
        const titleA = a.props.children.props.label;
        const titleB = b.props.children.props.label;
        return titleA.localeCompare(titleB);
      }),
    ];
  }, [query.data, componentProps.type]);

  useEffect(() => {
    if (query.isFetching) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [query.isFetching]);

  return (
    <Select
      size="large"
      mode="multiple"
      showSearch
      showArrow
      allowClear
      style={{ width: "100%" }}
      loading={query.isLoading}
      tagRender={(props) => (
        <TagRender
          {...props}
          data={
            componentProps.type === "all"
              ? (queryData as Pagination<TagType>).data
              : (queryData as DistinctSearchTag[])
          }
          options={options}
        />
      )}
      optionLabelProp="className"
      filterOption={(input, option: any) =>
        option.className.toString().toLowerCase().includes(input.toLowerCase())
      }
      {...componentProps.selectProps}
      open={componentProps.selectProps?.open && !loading}
    >
      {optionsTagsOptions}
    </Select>
  );
};

export default TagsDropdown;
