import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { ExpandedTableItem } from "../../ExpandedTableItem/ExpandedTableItem";
import { defineMessages, useIntl } from "react-intl";
import CustomSortButton from "../TableProperties/CustomSortButton";

type Props = {
  expandedRows: string[];
};

const TitleTableColumn = (props: Props) => {
  const intl = useIntl();

  const messages = defineMessages({
    titleColumn: {
      id: "component.items_table.column.title",
      defaultMessage: "Title",
      description: "Title column in the items table",
    },
  });

  return {
    title: (
      <CustomSortButton
        label={intl.formatMessage(messages.titleColumn)}
        sortBy="title"
      ></CustomSortButton>
    ),
    dataIndex: "title",
    key: "title",
    // width: "15%",
    ellipsis: true,
    onCell: (data: ItemPopulated) => ({
      colSpan: !!props.expandedRows.find((id) => id === data.id) ? 4 : 1,
    }),
    render: (text: string, data: ItemPopulated) =>
      !!props.expandedRows.find((id) => id === data.id) ? (
        <ExpandedTableItem dataSource={data}></ExpandedTableItem>
      ) : (
        <div
          className="overflow-cell"
          style={{ fontSize: "0.9rem", fontWeight: "500" }}
        >
          {text}
        </div>
      ),
  };
};

export default TitleTableColumn;
