import { Card, Typography } from "antd";
import { ChangeDescriptionChange } from "@formitas-ag/bimfiles-types";
import { defineMessages, FormattedMessage } from "react-intl";

type Props = {
  change: ChangeDescriptionChange;
};

export default (props: Props) => {
  const messages = defineMessages({
    title: {
      id: "component.timeline.change_description.title",
      defaultMessage: "Description of changes",
      description: "Title of the description of changes",
    },
  });

  return (
    <Card.Grid
      hoverable={false}
      style={{ padding: "12px", width: "100%", flexGrow: 2 }}
    >
      <Typography.Title level={5}>
        <FormattedMessage {...messages.title} />
      </Typography.Title>

      {props.change.changeDescription}
    </Card.Grid>
  );
};
