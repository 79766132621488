import { defineMessages } from "react-intl";

const authentication = defineMessages({
  demoDescription: {
    id: "ui.authentication.demo-description",
    defaultMessage:
      "Request access to a demo version of BIMfiles today! Submit this form to receive information on how to access the demo version.",
    description: "Description for demo access",
  },
  fieldFullNameLabel: {
    id: "ui.authentication.field-full-name-label",
    defaultMessage: "Full Name",
    description: "Label for full name input",
  },
  fieldFullNamePlaceholder: {
    id: "ui.authentication.field-full-name-placeholder",
    defaultMessage: "John Doe",
    description: "Placeholder for full name input",
  },
  fieldFullNameValidationRequired: {
    id: "ui.authentication.field-full-name-validation-required",
    defaultMessage: "Please input your full name",
    description: "Message for the required full name field",
  },
  fieldFullNameValidationIncorrectLength: {
    id: "ui.authentication.field-full-name-validation-format",
    defaultMessage: "The full name must be between 2 and 100 characters long",
    description:
      "Message for the format for the wrong length of the full name field",
  },
  fieldEmailLabel: {
    id: "ui.authentication.field-email-label",
    defaultMessage: "Email",
    description: "Label for email input",
  },
  fieldEmailPlaceholder: {
    id: "ui.authentication.field-email-placeholder",
    defaultMessage: "name@company.de",
    description: "Placeholder for email input",
  },
  fieldEmailValidationRequired: {
    id: "ui.authentication.field-email-validation-required",
    defaultMessage: "Please input your email",
    description: "Message for the required email field",
  },
  fieldEmailValidationFormat: {
    id: "ui.authentication.field-email-validation-format",
    defaultMessage: "Incorrect email format",
    description: "Message for the format for the email field",
  },
  fieldJoinNewsletterLabel: {
    id: "ui.authentication.field-join-newsletter-label",
    defaultMessage: "Join Free Newsletter",
    description: "Label for join newsletter input",
  },
  demoAction: {
    id: "ui.authentication.demo-action",
    defaultMessage: "Request Demo Access",
    description: "Action button for requestion demo access",
  },
  demoAccessSuccess: {
    id: "ui.authentication.demo-access-success",
    defaultMessage:
      "Your access to the demo version of BIMfiles has been sent to your email address. Please check for further instructions.",
    description: "Message for successful demo access request",
  },
  demoAccessFailed: {
    id: "ui.authentication.demo-access-failed",
    defaultMessage:
      "Requesting access to the demo of BIMfiles has failed. Please contact us at bimfiles@formitas.de for further assistance.",
    description: "Message for failed demo access request",
  },
  learnAboutDemoAction: {
    id: "ui.authentication.learn-about-demo-action",
    defaultMessage: "Request Demo Now",
    description: "Action button for learning more about demo access",
  }
});

export default authentication;
