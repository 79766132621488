import { Card } from "antd";

type Props = {
  titleChild: React.ReactNode;
  children: React.ReactNode;
};

export default (props: Props) => {
  return (
    <Card.Grid
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "stretch",
        padding: 0,
        width: "100%",
        minHeight: "3rem",
      }}
      hoverable={false}
    >
      <Card.Grid style={{ padding: "12px", textAlign: "center", width: "20%", display: "flex", justifyContent: "center", alignItems: "center" }} hoverable={false}>
        {props.titleChild}
      </Card.Grid>
      <Card.Grid style={{ padding: "12px", flexGrow: 3 }} hoverable={false}>
        {props.children}
      </Card.Grid>
    </Card.Grid>
  );
};
