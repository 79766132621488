import { Tag, Typography } from "antd";
import { Item } from "@formitas-ag/bimfiles-types/lib";
import { useIntl } from "react-intl";
import { PropertiesResult } from "../../../../hooks/useCreateOrUpdateFiles/useGetFileMeta";
import translations from "../../../../translations/constants/translations";

type Props = {
  propertyKey: keyof Required<Item>["properties"];
  properties: PropertiesResult;
};

const ItemProperty = (props: Props) => {
  const key = props.propertyKey;
  const intl = useIntl();

  const isChanged = props.properties?.updatedPropertieKeys.includes(key);
  const value =
    props.properties !== undefined ? props.properties[key] : undefined;
  const oldValue =
    props.properties && props.properties.updatedProperties
      ? props.properties.updatedProperties[key]
      : undefined;

  const keyTranslated =
    key === "familyName"
      ? intl.formatMessage(translations.ui.meta.sidecar.familyName)
      : key === "revitVersion"
      ? intl.formatMessage(translations.ui.meta.sidecar.revitVersion)
      : key === "ostCategory"
      ? intl.formatMessage(translations.ui.meta.sidecar.ostCategory)
      : key === "placementMethod"
      ? intl.formatMessage(translations.ui.meta.sidecar.placementMethod)
      : key;

  return (
    <Tag key={key} color={isChanged ? "geekblue" : undefined}>
      <b>{keyTranslated}</b>:{" "}
      {value !== undefined
        ? value
        : intl.formatMessage(translations.ui.meta.sidecar.notAvailable)}
      {isChanged && (
        <>
          {" "}
          <Typography.Text
            type="secondary"
            style={{
              fontSize: "12px",
              lineHeight: "20px",
            }}
            delete
          >
            {oldValue}
          </Typography.Text>
        </>
      )}
    </Tag>
  );
};
export default ItemProperty;
