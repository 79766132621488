import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { rootQueryClient } from "../../../..";
import { checkFinePermissions } from "../../../../api/ability/Can";
import { useViewStore } from "../../../../state/viewStore";
import DeleteItemConfirmation from "./DeleteItemConfirmation";
import api from "../../../../api/api";
import ButtonProps from "../../../../UiProperties/ButtonProps";
import { CategoryRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";

const DeleteItemModal: React.FC<{}> = () => {
  const [deleteReason, setDeleteReason] = useState<string | null>(null);
  const [canIApproveOrDelete, setCanIApproveOrDelete] =
    useState<boolean | null>(null);

  const item = useViewStore((state) => state.selectedToDeleteItem);

  const closeModal = () => {
    setDeleteReason(null);
    setCanIApproveOrDelete(null);
    useViewStore.setState({ selectedToDeleteItem: undefined });
  };

  // Check if the user has the ability to approve or delete
  useEffect(() => {
    // Check if the item exists
    if (item) {
      // Check if the user has itemDelete or ItemApprove permissions
      // this is set to allow automatic deletion
      const checkPermissions = async () => {
        const canIApproveOrDelete = await checkFinePermissions({
          mode: "category",
          on: item.categoryId,
          minimumRole: CategoryRoleEnum.APPROVE,
        });

        setCanIApproveOrDelete(canIApproveOrDelete);
      };
      checkPermissions();
    }
  }, [canIApproveOrDelete, item]);

  const onDelete = async () => {
    closeModal();
    await api.items.delete(item!.id!, !deleteReason ? "" : deleteReason);
    rootQueryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === "items",
    });
  };

  return (
    <Modal
      title="Delete Confirmation"
      style={{ padding: "2rem" }}
      centered
      open={!!item}
      onOk={closeModal}
      onCancel={closeModal}
      afterClose={closeModal}
      closable={true}
      width={"100%"}
      zIndex={1000}
      destroyOnClose={true}
      footer={[
        <Button
          key="delete"
          type="primary"
          onClick={onDelete}
          disabled={
            !!canIApproveOrDelete
              ? false
              : !!deleteReason && deleteReason.length > 5
              ? false
              : true
          }
        >
          Delete
        </Button>,
        <Button
          key="close"
          type="dashed"
          onClick={closeModal}
          style={ButtonProps.ghost}
        >
          Cancel
        </Button>,
      ]}
    >
      <DeleteItemConfirmation
        item={item!}
        deleteReason={deleteReason}
        setDeleteReason={setDeleteReason}
      />
    </Modal>
  );
};

export default DeleteItemModal;
