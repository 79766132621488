import { UploadFile } from "antd";
import { DraggerProps } from "antd/es/upload";
import { useState, useEffect } from "react";
import { selectedOrganisationIdObservable } from "../api/utils/auth.utils";
import { useRevitStore } from "../state/revitStore";
import { useUploadStore } from "../state/uploadStore";
import { useViewStore } from "../state/viewStore";
import createNewItem, {
  getRevitRoleFromName,
  getRoleFromFileName,
} from "./useUpload/createNewItem";
import uploadFile from "./useUpload/uploadFile";
import {getRevit, useRevit} from "../api/revit";
import { RcFile } from "antd/lib/upload";
import { changeNameDuplicates } from "../utils/file.utils";

export const fileToBase64 = async (file: File): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      reader.result &&
        resolve(
          reader.result
            .toString()
            .replace("data:;base64,", "")
            .replace("data:application/octet-stream;base64,", "")
        );
    };
    reader.onerror = (error) => reject(error);
  });
};

export type LocalFile = {
  source: "user" | "revit";
  file: File;
  browserFile?: UploadFile;
  revitRole?: "family" | "sidecar" | "thumbnail" | "sidecar-automatic";
  name: string;
  size: number;
};

type UseUploadResponse = {
  currentItemId?: string;
  onSelectedFiles: (fileList: LocalFile[]) => void;
  onStateChanged: DraggerProps["onChange"];
};

export const useUpload = (): UseUploadResponse => {
  const [organisationId, setOrganisationId] = useState<string>("");
  const [currentItemId, setCurrentItemId] = useState<string>();

  const setCurrentState = useUploadStore((state) => state.setCurrentState);
  const setProgress = useUploadStore((state) => state.setCurrentUploadProgress);
  const currentFiles = useUploadStore((state) => state.currentFiles);
  const setCurrentFiles = useUploadStore((state) => state.setCurrentFiles);

  // Get organisation id
  useEffect(() => {
    setOrganisationId(selectedOrganisationIdObservable.get()!);
  }, []);

  useEffect(() => {
    if (currentFiles.length >= 1) {
      const startUpload = async () => {
        setProgress(0);
        setCurrentState("UPLOADING_FILES");

        if (currentItemId) {
          console.error("Item already created");
          return;
        }

        //all files from revit, dropzone and other sources
        const combinedFilesWithoutRoles = [
          ...currentFiles,
          ...useUploadStore.getState().temporaryFiles,
        ];

        const wasFamilyFound = combinedFilesWithoutRoles.some(
          (file) =>
            file.revitRole === "family" ||
            getRoleFromFileName(file.name, false) === "family"
        );

        const combinedFiles: LocalFile[] = combinedFilesWithoutRoles.map(
          (currentFile) => {
            const possibleRevitRole = getRevitRoleFromName(
              currentFile.name,
              wasFamilyFound
            );

            if (
              currentFile.source === "revit" &&
              possibleRevitRole === "sidecar"
            ) {
              return {
                ...currentFile,
                revitRole: "sidecar-automatic",
              };
            }

            return {
              ...currentFile,
              revitRole: currentFile.revitRole ?? possibleRevitRole,
            };
          }
        );

        // Adds "(1)" to the file name if there is a file name duplicate
        const newCombinedFiles = await changeNameDuplicates(combinedFiles);

        const item = await createNewItem(newCombinedFiles, organisationId);

        // Store item id in upload store
        useUploadStore.setState({ currentUploadItemId: item.item.id });

        if (!item) {
          console.error("Item not created");
          return;
        }

        setCurrentItemId(item.item.id);

        if (newCombinedFiles.length === 0) {
          //TODO: Handle error
          console.error("No files to upload");
          return;
        }

        //awaiting all files to be uploaded
        await Promise.all(
          newCombinedFiles.map(async (combinedFile) => {
            const file = item.uploadUrls.find(
              (url) => url.name === combinedFile.name
            );

            if (!file) {
              //TODO: Handle error
              console.error("No file found");
              return;
            }

            const setProgressForFile = (progress: number) => {
              setProgress(progress);
            };

            await uploadFile(combinedFile.file, file.url, setProgressForFile);
          })
        );

        useUploadStore.setState({
          metaFiles: newCombinedFiles,
        });

        //clear temporary files
        useUploadStore.setState({
          temporaryFiles: [],
        });

        setCurrentState("INSERTING_META");
        setProgress(undefined);
      };

      startUpload();
    }
  }, [currentFiles]);

  const onStateChanged: DraggerProps["onChange"] = (info) => {
    //log(`onStateChanged`, info);
  };

  /**
   * Executed whenever the user selects a file, drops a file. This does not include programmatic file selection (e.g. Revit export).
   * @param fileList the files that were added
   */
  const onSelectedFiles = async (fileList: LocalFile[]) => {
    const potentialFamilyFile = fileList.find(
      (file) =>
        file.revitRole === "family" ||
        getRoleFromFileName(file.name, false) === "family"
    );

    if (potentialFamilyFile) {
      //we have a family file within the dropped/selected files
      if (!getRevit().hasRevitObject()) {
        //we have a family file but no connection to revit: aborting
        useViewStore.setState({
          openRequiresRevitModal: true,
          requiresRevitModalDetail:
            "You need to be using the BIMfiles Revit add-on to upload family files.",
        });
        return;
      }

      //enabled exporting content from revit
      useRevitStore.setState({
        currentMode: "export",
        currentError: undefined,
        revitOutput: undefined,
      });

      useUploadStore.setState({
        temporaryFiles: fileList,
      });

      getRevit().receiveFamilyForParsing([
        {
          name: potentialFamilyFile.name,
          role: "family",
          data: await fileToBase64(
            potentialFamilyFile.browserFile
              ? (potentialFamilyFile.browserFile as RcFile as File)
              : potentialFamilyFile.file
          ),
        },
      ]);
    } else {
      //no family file, just add the files
      setCurrentFiles(fileList);
    }
  };

  return {
    currentItemId,
    onStateChanged,
    onSelectedFiles,
  };
};
