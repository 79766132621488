import { defineMessages } from "react-intl";

const sidecar = defineMessages({
  revitVersion: {
    id: "component.update-item-files-field.parameters-revit-version",
    defaultMessage: "Revit Version",
    description: "Label for the revit version parameter",
  },
  familyName: {
    id: "component.update-item-files-field.parameters-family-name",
    defaultMessage: "Family Name",
    description: "Label for the family name parameter",
  },
  ostCategory: {
    id: "component.update-item-files-field.parameters-ost-category",
    defaultMessage: "OST Category",
    description: "Label for the ost category parameter",
  },
  placementMethod: {
    id: "component.update-item-files-field.parameters-placement-method",
    defaultMessage: "Placement Method",
    description: "Label for the placement method parameter",
  },
  notAvailable: {
    id: "component.update-item-files-field.parameters-not-available",
    defaultMessage: "Not Available",
    description: "Label for not available parameters",
  },
  viewButton: {
    id: "component.update-item-sidecar.button",
    defaultMessage:
      "View {numberOfTypes, plural, one {# Type} other {# Types}}",
    description: "Button for viewing the sidecar file",
  },
});
export default sidecar;
