import {
  UploadType,
  useCreateOrUpdateItemStore,
} from "../../state/createOrUpdateItemStore";
import { ItemPopulated, UpdateItemData } from "@formitas-ag/bimfiles-types/lib/item";

export type ValidateUploadResult = {
  success: boolean;
  error?:
    | "missingTitle"
    | "missingFiles"
    | "missingCategory"
    | "updateMissingChanges";
};

const allowedUpdatingFilesStates = ["added", "replaced"];

export default () => {
  const mode = useCreateOrUpdateItemStore((state) => state.mode);

  const _validateCreateUpload = (upload: UploadType): ValidateUploadResult => {
    //if creating we need at least one file
    if (
      upload.updatingFiles.filter((f) =>
        allowedUpdatingFilesStates.includes(f.state)
      ).length === 0
    ) {
      return { success: false, error: "missingFiles" };
    }

    //we need a title
    if (upload.item?.title === undefined || upload.item?.title.trim() === "") {
      return { success: false, error: "missingTitle" };
    }

    //we need a category
    if (upload.item?.categoryId === undefined) {
      return { success: false, error: "missingCategory" };
    }

    return { success: true };
  };

  const _validateUpdateUpload = (upload: UploadType): ValidateUploadResult => {
    const item = upload.item || {};
    const oldItem = upload.oldItem || ({} as ItemPopulated);
    const hasChangedFiles =
      upload.updatingFiles.filter((f) =>
        allowedUpdatingFilesStates.includes(f.state)
      ).length > 0;
    const hasOneFile =
      upload.updatingFiles.filter((f) => f.state !== "removed").length > 0;
    const hasTitle = item?.title !== undefined && item?.title.trim() !== "";
    const hasCategory = item?.categoryId !== undefined;

    const hasNoFieldChanged = Object.entries(item).reduce<boolean>(
      (prev, [key, value]) => {
        const haveNoTagsChanged =
          JSON.stringify(upload.oldItem?.tagsIds || []) ===
          JSON.stringify(item[key as keyof Partial<UpdateItemData>]);

        const haveNoFieldsChanged =
          JSON.stringify(oldItem[key as keyof Partial<UpdateItemData>]) ===
          JSON.stringify(item[key as keyof Partial<UpdateItemData>]);

        const hasNothingChanged =
          value === undefined ||
          value.toString().trim() === "" ||
          haveNoTagsChanged ||
          haveNoFieldsChanged ||
          JSON.stringify(value) === "[]";

        return prev && hasNothingChanged;
      },
      true
    );

    const hasBasicFields = hasOneFile && hasTitle && hasCategory;
    const hasChangedAnything = hasChangedFiles || !hasNoFieldChanged;

    if (hasBasicFields && hasChangedAnything) {
      return { success: true };
    } else {
      return { success: false, error: "updateMissingChanges" };
    }
  };

  const validateUpload = (upload: UploadType): ValidateUploadResult => {
    if (mode === "create") return _validateCreateUpload(upload);
    else return _validateUpdateUpload(upload);
  };

  return { validateUpload };
};
