import { Alert, Button, Space } from "antd";
import { useItemsTableStore } from "../../../../state/itemsTableStore";
import { useState } from "react";
import { FileWithUrl } from "@formitas-ag/bimfiles-types/lib/file";
import api from "../../../../api/api";
import { useIntl } from "react-intl";
import translations from "../../../../translations/constants/translations";

const BatchDownloadImportButton = () => {
  const intl = useIntl();
  const selectedItems = useItemsTableStore((state) => state.selectedItems);
  const setSelectedItems = useItemsTableStore(
    (state) => state.setSelectedItems
  );

  const [downloadStarted, setDownloadStarted] = useState<boolean>(false);
  const [itemsDownloaded, setItemsDownloaded] = useState<number>(0);

  const onDownloadAll = async () => {
    const files = (
      await Promise.all(
        selectedItems.map(async (selectedItem) =>
          (
            await Promise.all(
              selectedItem.files.map((file) =>
                api.items.getFileWithUrl(selectedItem.id, file.id, {
                  includeUnapproved: true,
                })
              )
            )
          )
            .filter(
              (file) =>
                file.role !== "thumbnail" && file.role !== "sidecar-automatic"
            )
            .map((file) => file)
        )
      )
    ).flat();

    setDownloadStarted(true);
    download(files);
  };

  const download = async (fileUrls: FileWithUrl[]) => {
    for (let index = 0; index < fileUrls.length; index++) {
      const file = fileUrls[index];

      try {
        const response = await fetch(file.url);
        const blob = await response.blob();

        setItemsDownloaded(index);

        const link = document.createElement("a");
        link.style.display = "none";
        document.body.appendChild(link);

        const objectUrl = URL.createObjectURL(blob);
        link.setAttribute("href", objectUrl);
        link.setAttribute("download", file.name);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error(`Error downloading file at index ${index}:`, error);
      }
    }
    setItemsDownloaded(0);
    setDownloadStarted(false);
    setSelectedItems([]);
  };

  return (
    <>
      <Button type="primary" onClick={onDownloadAll}>
        {intl.formatMessage(translations.ui.itemTable.batchDownloadButtonTitle)}
      </Button>
      {downloadStarted && (
        <Alert
          message={intl.formatMessage(
            translations.ui.itemTable.batchDownloadStatusMessage,
            {
              completedItems: itemsDownloaded,
              totalItems: selectedItems.length,
            }
          )}
        />
      )}
    </>
  );
};

export default BatchDownloadImportButton;
