import { Dropdown, MenuProps, Button, notification } from "antd";
import { useIntl } from "react-intl";
import { useViewStore } from "../../../../state/viewStore";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  HistoryOutlined,
  LinkOutlined,
  MenuOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { File } from "@formitas-ag/bimfiles-types/lib";
import React, { useEffect, useRef, useState } from "react";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { usePlatform } from "../../../../hooks/usePlatform";
import { checkFinePermissions } from "../../../../api/ability/Can";
import ButtonProps from "../../../../UiProperties/ButtonProps";
import TypeCatalogButton from "../DownloadItem/TypeCatalogButton";
import ImportToRevitButton from "../DownloadItem/ImportToRevitButton";
import useCreateOrUpdateFiles from "../../../../hooks/useCreateOrUpdateFiles";
import translations from "../../../../translations/constants/translations";
import { CategoryRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";

type Props = {
  item: ItemPopulated;
  showButton?: boolean;
  disabled?: boolean;
};

/**
 * Primary UI component for user interaction
 */
export const RowActionDropdown = (props: Props) => {
  const intl = useIntl();

  const [platform] = useState<string>(usePlatform());
  const [canIEdit, setCanIEdit] = useState<boolean | null>(null);
  const typeCatalogButtonRef = useRef<HTMLButtonElement>(null);
  const importTypesToRevitButtonRef = useRef<HTMLButtonElement>(null);
  const [notificationApi, contextHolder] = notification.useNotification();

  const { startUpdate } = useCreateOrUpdateFiles();

  // Check if the user has the ability to approve or delete
  useEffect(() => {
    checkFinePermissions({
      mode: "category",
      on: props.item.categoryId,
      minimumRole: CategoryRoleEnum.WRITE,
    }).then((result) => {
      setCanIEdit(result);
    });
  }, [props.item.categoryId]);

  const itemStyle = {
    fontWeight: "400",
    fontSize: "0.8rem",
    lineHeight: "20px",
    color: "#931d21",
  };

  const onViewOlderVersions = () => {
    useViewStore.setState({
      selectedTimelineItemId: props.item.id,
    });
  };

  const onEditItem = () => {
    startUpdate(props.item);

    useViewStore.setState({
      selectedToBeUpdatedItem: props.item,
    });
  };

  // Copy the url to the clipboard
  const onOpenInBrowser = () => {
    const url = window.location.origin + `/item/${props.item.id}`;

    const textArea = document.createElement("textarea");
    textArea.value = url;

    textArea.style.position = "fixed";
    textArea.style.opacity = "0";

    document.body.appendChild(textArea);

    textArea.select();
    document.execCommand("copy");

    document.body.removeChild(textArea);

    notificationApi.success({
      message: intl.formatMessage(
        translations.ui.itemTable.rowAction.openInBrowserSuccess
      ),
    });
  };

  const onDeleteItem = () => {
    useViewStore.setState({
      selectedToDeleteItem: props.item,
    });
  };

  const onViewTypes = () => {
    if (typeCatalogButtonRef.current) {
      typeCatalogButtonRef.current.click();
    }
  };

  const onImportTypes = () => {
    if (importTypesToRevitButtonRef.current) {
      importTypesToRevitButtonRef.current.click();
    }
  };

  const makeMenuItem = (
    key: string,
    label: string | React.ReactNode,
    icon: any,
    onClick: () => void
  ): Required<MenuProps>["items"][0] => {
    return {
      type: undefined,
      icon,
      key,
      label,
      style: itemStyle,
      onClick,
    };
  };

  const sidecarOrSidecarAutomatic = () => {
    const fileRole: string = props.item.files.reduce(
      (previousValue: File, currentValue: File) => {
        if (previousValue.role === "sidecar") {
          return previousValue;
        }

        if (
          currentValue.role === "sidecar" ||
          currentValue.role === "sidecar-automatic"
        ) {
          return currentValue;
        }

        if (previousValue.role === "sidecar-automatic") {
          return previousValue;
        }

        return currentValue;
      }
    ).role;
    return fileRole;
  };

  const items = [
    props.item.files.find((file) => file.role === "family")
      ? sidecarOrSidecarAutomatic() === "sidecar" || platform === "browser"
        ? makeMenuItem(
            "view-types",
            sidecarOrSidecarAutomatic() === "sidecar"
              ? intl.formatMessage(translations.ui.itemTable.rowAction.sidecar)
              : intl.formatMessage(
                  translations.ui.itemTable.rowAction.sidecarAutomatic
                ),
            <ProfileOutlined style={{ fontSize: "1rem", color: "#931d21" }} />,
            onViewTypes
          )
        : makeMenuItem(
            "import-types",
            intl.formatMessage(translations.ui.itemTable.rowAction.importTypes),
            <DownloadOutlined style={{ fontSize: "1rem", color: "#931d21" }} />,
            onImportTypes
          )
      : undefined,

    props.item.state !== "approvable"
      ? makeMenuItem(
          "viewOlderVersions",
          intl.formatMessage(translations.ui.itemTable.rowAction.timeline),
          <HistoryOutlined style={{ fontSize: "1rem", color: "#931d21" }} />,
          onViewOlderVersions
        )
      : undefined,

    canIEdit
      ? makeMenuItem(
          "edit",
          intl.formatMessage(translations.ui.itemTable.rowAction.edit),
          <EditOutlined style={{ fontSize: "1rem", color: "#931d21" }} />,
          onEditItem
        )
      : undefined,

    makeMenuItem(
      "delete",
      intl.formatMessage(translations.ui.itemTable.rowAction.delete),
      <DeleteOutlined style={{ fontSize: "1rem", color: "#931d21" }} />,
      onDeleteItem
    ),

    platform === "revit"
      ? makeMenuItem(
          "openInBrowser",
          intl.formatMessage(translations.ui.itemTable.rowAction.openInBrowser),
          <LinkOutlined style={{ fontSize: "1rem", color: "#931d21" }} />,
          onOpenInBrowser
        )
      : undefined,
  ];

  const getItems = (): ItemType[] => {
    return items.filter((item) => item !== undefined) as ItemType[];
  };

  return (
    <>
      {/* Add invisible type catalogue to be rendered from the dropdown
          without having to change the whole component */}
      <div style={{ display: "none" }}>
        <TypeCatalogButton
          id={props.item.id}
          files={props.item.files}
          buttonRef={typeCatalogButtonRef}
        />

        {/* Add invisible import types button to be rendered from the 
          dropdown without having to change the whole component */}
        <ImportToRevitButton
          id={props.item.id}
          files={props.item.files}
          importAnyway={true}
          buttonRef={importTypesToRevitButtonRef}
        />
      </div>

      {contextHolder}
      <Dropdown
        menu={{
          items: getItems(),
        }}
        trigger={["click"]}
        placement="bottomRight"
        disabled={props.disabled}
      >
        <Button
          type="dashed"
          style={{
            fontWeight: "600",
            color: "#931d21",
            fontSize: "18px",
            ...ButtonProps.ghost,
          }}
          icon={
            <MenuOutlined
              style={{
                fontSize: props.showButton ? "18px" : "22px",
                color: "#931d21",
              }}
            />
          }
        >
          {props.showButton
            ? intl.formatMessage(translations.ui.itemTable.rowAction.menu)
            : undefined}
        </Button>
      </Dropdown>
    </>
  );
};
