import { Item } from "@formitas-ag/bimfiles-types/lib";
import TextRender from "../Common/TextRender";

type Props = {
  propertyName: keyof Required<Item>["properties"];
  properties: Item["properties"];
};

export default ({ propertyName, properties }: Props) => {
  const property = properties ? "" + properties[propertyName] : undefined;

  return <TextRender text={property ? property : "-"} />;
};
