import { TagPopulated } from "@formitas-ag/bimfiles-types/lib/tag";
import CustomTag from "../CustomTag";
import { FormattedDate, FormattedMessage } from "react-intl";
import { Row } from "antd";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { PropertiesTable } from "../PropertiesTable/PropertiesTable";
import TableProps from "../../../../UiProperties/TableProps";

interface ExpandedTableItemContentProps {
  dataSource: ItemPopulated;
  hideTitle?: boolean;
}

const ExpandedTableItemContent = ({
  dataSource,
  hideTitle,
}: ExpandedTableItemContentProps) => {
  const sectionDivStyle = {
    marginBottom: "0.5rem",
    backgroundColor: "rgb(147,29,33, 0.05)",
    borderRadius: "0.5rem",
    padding: "6px 10px 6px 10px",
  };

  const sectionTitleTextStyle = { color: "#191919", marginBottom: "2px" };

  const tags = (tags: TagPopulated[]) => {
    let returnTags = [];
    for (let i = 0; i < tags.length; i++) {
      returnTags.push(
        <CustomTag
          key={"tag" + i}
          style={TableProps.coloredTag}
          color={tags[i].color}
          label={tags[i].title}
        />
      );
    }
    return returnTags;
  };

  return (
    <div style={{ marginTop: hideTitle ? "1rem" : undefined }}>
      {!hideTitle && (
        <Row style={{ width: "100%" }}>
          <h2 style={{ ...sectionTitleTextStyle, fontWeight: "600" }}>
            {" "}
            {dataSource.title}
          </h2>
          <div
            style={{
              marginLeft: "10px",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              height: "14px",
              marginBottom: "8px",
              color: "rgba(112, 131, 143, 0.75)",
            }}
          >
            <FormattedDate
              value={dataSource.updatedAt}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          </div>
        </Row>
      )}

      {dataSource.tags && dataSource.tags.length > 0 && (
        <div>
          <h3 style={sectionTitleTextStyle}>
            <FormattedMessage
              id="component.expanded_table_item.tags"
              defaultMessage="Tags"
              description="The title for tags within the accordion of an item"
            />
          </h3>
          <div style={{ ...sectionDivStyle, whiteSpace: "normal" }}>
            {tags(dataSource.tags)!}
          </div>
        </div>
      )}

      <div>
        <h3 style={sectionTitleTextStyle}>
          <FormattedMessage
            id="component.expanded_table_item.properties"
            defaultMessage="Properties"
            description="The title for properties within the accordion of an item"
          />
        </h3>
        <div style={sectionDivStyle}>
          <PropertiesTable dataSource={dataSource}></PropertiesTable>
        </div>
      </div>

      {dataSource.description && (
        <div>
          <h3 style={sectionTitleTextStyle}>
            <FormattedMessage
              id="component.expanded_table_item.description"
              defaultMessage="Description"
              description="The title for description within the accordion of an item"
            />
          </h3>
          <div style={sectionDivStyle}>
            <p style={{ marginBottom: "0px", whiteSpace: "normal" }}>
              {dataSource.description}
            </p>
          </div>
        </div>
      )}

      {dataSource.changeDescription && (
        <div>
          <h3 style={sectionTitleTextStyle}>
            <FormattedMessage
              id="component.expanded_table_item.changes"
              defaultMessage="Changes"
              description="The title for changes within the accordion of an item"
            />
          </h3>
          <div style={sectionDivStyle}>
            <p style={{ marginBottom: "0px", whiteSpace: "normal" }}>
              {dataSource.changeDescription}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExpandedTableItemContent;
