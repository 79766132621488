import { useState } from "react";
import { Button, Row, Space, Tooltip } from "antd";
import { ApproveItem } from "../TableActions/ApproveItem";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { usePlatform } from "../../../../../hooks/usePlatform";
import { RowActionDropdown } from "../../RowActionDropdown/RowActionDropdown";
import ImportToRevitButton from "../../DownloadItem/ImportToRevitButton";
import DownloadItemButton from "../../DownloadItem/DownloadItemButton";
import useFiltersStore from "../../../../../state/filtersStore";
import Can from "../../../../../api/ability/Can";
import { CategoryRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import translations from "../../../../../translations/constants/translations";

type Props = {
  expandedRows: string[];
};

const RowActionDropdownColumn = (props: Props) => {
  const intl = useIntl();
  const [platform] = useState<string>(usePlatform());
  const itemsState = useFiltersStore((state) => state.itemsState);

  return {
    align: "right" as any,
    dataIndex: "",
    key: "action",
    width: itemsState === "unapproved" ? 165 : 100,
    render: (item: ItemPopulated) => (
      <Row
        justify="end"
        align="middle"
        style={
          props.expandedRows.find((id) => id === item.id)
            ? { position: "absolute", right: "1.05rem", top: "0.95rem" }
            : { position: "relative", right: "0.8rem" }
        }
      >
        <Space size={4} wrap>
          {itemsState === "unapproved" && (
            <>
              <Can
                mode="item"
                itemId={item.id}
                minimumRole={CategoryRoleEnum.APPROVE}
              >
                <ApproveItem item={item} />
              </Can>
              <Can
                not
                mode="item"
                itemId={item.id}
                minimumRole={CategoryRoleEnum.APPROVE}
              >
                <Row>
                  <Space size={4} wrap>
                    <Tooltip
                      color="#f7f8fc"
                      title={intl.formatMessage(
                        translations.ui.itemTable.rowAction
                          .noPermissionForApproval
                      )}
                    >
                      <Button
                        type="text"
                        disabled
                        icon={
                          <CheckCircleOutlined
                            style={{ fontSize: "24px", color: "gray" }}
                          />
                        }
                      />
                    </Tooltip>

                    <Tooltip
                      color="#f7f8fc"
                      title={intl.formatMessage(
                        translations.ui.itemTable.rowAction
                          .noPermissionForApproval
                      )}
                    >
                      <Button
                        type="text"
                        disabled
                        icon={
                          <CloseCircleOutlined
                            style={{ fontSize: "24px", color: "gray" }}
                          />
                        }
                      />
                    </Tooltip>
                  </Space>
                </Row>
              </Can>
            </>
          )}

          {platform === "browser" ? (
            <DownloadItemButton
              id={item.id}
              item={item}
              disabled={itemsState === "denied"}
            />
          ) : (
            <ImportToRevitButton
              id={item.id}
              files={item.files}
              disabled={itemsState === "denied"}
            />
          )}
          <RowActionDropdown item={item} disabled={itemsState === "denied"} />
        </Space>
      </Row>
    ),
  };
};

export default RowActionDropdownColumn;
