import { Button, Modal, Switch, Tooltip, Typography } from "antd";
import { UploadList } from "./CreateOrUpdateMultipleFilesModal/UploadList";
import {
  UploadType,
  useCreateOrUpdateItemStore,
} from "../../../../state/createOrUpdateItemStore";
import { useViewStore } from "../../../../state/viewStore";
import translations from "../../../../translations/constants/translations";
import { useIntl } from "react-intl";
import useCreateOrUpdateFiles from "../../../../hooks/useCreateOrUpdateFiles";
import OverwriteItemModal from "./OverwriteItemModal";
import useCreateItem from "../../../../hooks/useCreateOrUpdateFiles/useCreateItem";
import useUpdateItem from "../../../../hooks/useCreateOrUpdateFiles/useUpdateItem";
import useValidateUpload from "../../../../hooks/useCreateOrUpdateFiles/useValidateUpload";
import { useEffect, useState } from "react";

export const CreateOrUpdateMultipleFilesModal = () => {
  const intl = useIntl();
  const mode = useCreateOrUpdateItemStore((state) => state.mode);
  const itemMode = useCreateOrUpdateItemStore((state) => state.itemMode);
  const uploads = useCreateOrUpdateItemStore((state) => state.uploads);

  const { stopCreateOrUpdate } = useCreateOrUpdateFiles();
  const { isCreating, saveCreate } = useCreateItem();
  const { isUpdating, saveUpdate } = useUpdateItem();
  const { validateUpload } = useValidateUpload();

  const viewDetailsInBatchUploadModal = useViewStore(
    (state) => state.viewDetailsInBatchUploadModal
  );
  const hideIgnoredFilesInBatchUploadModal = useViewStore(
    (state) => state.hideIgnoredFilesInBatchUploadModal
  );

  const isLoading =
    useCreateOrUpdateItemStore((state) => state.isLoading) ||
    isCreating ||
    isUpdating;

  const onSubmit = () => {
    if (mode === "create") saveCreate();
    if (mode === "update") saveUpdate();
  };

  const [validUploads, setValidUploads] = useState<UploadType[]>([]);

  useEffect(() => {
    setValidUploads(uploads.filter((u) => validateUpload(u).success));
  }, [uploads]);

  const areUploadsValid =
    mode === "create"
      ? validUploads.length === uploads.length
      : validUploads.length > 0;

  const loadingCancelButton = isLoading;
  const cancelButtonText = intl.formatMessage(translations.ui.generic.cancel);

  const disableSaveButton = !areUploadsValid || uploads.length === 0;
  const loadingSaveButton = isLoading;
  const saveButtonTooltip = disableSaveButton
    ? mode === "create"
      ? intl.formatMessage(
          translations.ui.upload.modals.multiFileModalMakeDisabledTooltip
        )
      : intl.formatMessage(
          translations.ui.upload.modals.multiFileModalUpdateMakeDisabledTooltip
        )
    : undefined;
  const saveButtonText =
    mode === "create"
      ? intl.formatMessage(translations.ui.upload.modals.multiFileModalMake, {
          families: uploads.length,
        })
      : intl.formatMessage(
          translations.ui.upload.modals.multiFileModalUpdateMake,
          {
            families: validUploads.length,
          }
        );

  return (
    <Modal
      destroyOnClose
      zIndex={1001}
      centered={true}
      closable={false}
      width={"90%"}
      style={{
        minWidth: "600px",
      }}
      onCancel={stopCreateOrUpdate}
      onOk={stopCreateOrUpdate}
      open={mode !== undefined && itemMode === "multiple"}
      footer={
        <>
          <Button
            style={{
              marginRight: ".5rem",
            }}
            type="default"
            onClick={stopCreateOrUpdate}
            disabled={loadingCancelButton}
            loading={loadingCancelButton}
          >
            {cancelButtonText}
          </Button>
          <Tooltip title={saveButtonTooltip} color="white">
            <Button
              type="primary"
              onClick={onSubmit}
              disabled={disableSaveButton}
              loading={loadingSaveButton}
            >
              {saveButtonText}
            </Button>
          </Tooltip>
        </>
      }
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography.Title level={4}>
            {intl.formatMessage(
              translations.ui.upload.modals.multiFileModalTitle
            )}
          </Typography.Title>
          <div>
            <Switch
              disabled={isLoading}
              loading={isLoading}
              checked={hideIgnoredFilesInBatchUploadModal}
              onChange={(value) => {
                useViewStore.setState({
                  hideIgnoredFilesInBatchUploadModal: value,
                });
              }}
              title={intl.formatMessage(
                translations.ui.upload.modals
                  .multiFileModalHideIgnoredFilesTitle
              )}
              checkedChildren={intl.formatMessage(
                translations.ui.upload.modals
                  .multiFileModalHideIgnoredFilesCheckedTitle
              )}
              unCheckedChildren={intl.formatMessage(
                translations.ui.upload.modals
                  .multiFileModalHideIgnoredFilesUncheckedTitle
              )}
            />
            <Switch
              disabled={isLoading}
              loading={isLoading}
              style={{
                marginLeft: ".5rem",
              }}
              checked={viewDetailsInBatchUploadModal}
              onChange={(value) => {
                useViewStore.setState({
                  viewDetailsInBatchUploadModal: value,
                });
              }}
              title={intl.formatMessage(
                translations.ui.upload.modals.multiFileModalShowDetailsTitle
              )}
              checkedChildren={intl.formatMessage(
                translations.ui.upload.modals
                  .multiFileModalShowDetailsCheckedTitle
              )}
              unCheckedChildren={intl.formatMessage(
                translations.ui.upload.modals
                  .multiFileModalShowDetailsUncheckedTitle
              )}
            />
          </div>
        </div>
      }
    >
      <UploadList uploads={uploads} />
      <OverwriteItemModal />
    </Modal>
  );
};
