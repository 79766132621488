import { Col, List, Row, Tag, Typography } from "antd";
import {
  UpdatingAddedFile,
  UpdatingFile,
} from "../../../../../utils/types/updateFilesTypes";
import FileListItemRoleTag from "../CreateOrUpdateFileModal/FileListItemRoleTag";
import useGetFileMeta from "../../../../../hooks/useCreateOrUpdateFiles/useGetFileMeta";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

type Props = {
  index: number;
  file: UpdatingAddedFile;
  overwrittenFile: UpdatingFile;
};

const OverwriteItemListItem = (props: Props) => {
  const { getName } = useGetFileMeta();

  return (
    <List.Item
      style={{
        width: "100%",
      }}
    >
      <Row gutter={[0, 8]}>
        <Col span={24}>
          <Tag color="red">
            <MinusCircleOutlined />
          </Tag>
          <FileListItemRoleTag
            index={props.index}
            file={props.overwrittenFile}
          />
          <Typography.Text
            style={{ marginLeft: ".25rem", marginRight: ".25rem" }}
          >
            {getName(props.overwrittenFile)}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Tag color="green">
            <PlusCircleOutlined />
          </Tag>
          <FileListItemRoleTag index={props.index} file={props.file} />
          <Typography.Text
            style={{ marginLeft: ".25rem", marginRight: ".25rem" }}
          >
            {getName(props.file)}
          </Typography.Text>
        </Col>
      </Row>
    </List.Item>
  );
};

export default OverwriteItemListItem;
