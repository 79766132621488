import {
  GetRoleResult,
  UpdatingAddedFile,
  UpdatingDefaultFile,
  UpdatingFile,
  UpdatingReplacedFile,
} from "../../utils/types/updateFilesTypes";
import { File as BimFilesFile } from "@formitas-ag/bimfiles-types/lib/file";
import { getRoleFromFileName } from "../useUpload/createNewItem";
import { convertBase64ToBlob } from "../../components/AllUsers/HomepageComponents/Upload/RevitExport/RevitExport";
import { Item } from "@formitas-ag/bimfiles-types/lib";
import { getPropertiesFromParameters } from "../../utils/item-parameters.utils";
import { getLogger } from "../../utils/logger.utils";
import { useCreateOrUpdateMultipleFiles } from "../useCreateOrUpdateMultipleFiles";
import { useGenerateFileId } from "./useGenerateFileId";

const logger = getLogger("useGetFileMeta");

export default () => {
  const { getUpload } = useCreateOrUpdateMultipleFiles();
  const { fileIdGenerator } = useGenerateFileId();

  const mapParentAndChildrenFiles = (
    index: number,
    files: UpdatingFile[]
  ): UpdatingFile[] => {
    //find parent file by family role
    const rawParentFile = files.find((f) => {
      const role = getRole(index, f);

      return (
        (role.result === "success" && role.role === "family") ||
        (role.result === "overwrite" && role.role === "family")
      );
    });

    if (!rawParentFile) {
      //there is no family file, so there can't be any children
      // logger.debug(
      //   `Received ${files.length} files for mapping to parent and children but couldn't find a family.`,
      //   {
      //     files,
      //   }
      // );
      return files;
    }

    const allowedParentStates = ["added", "default"];

    if (!allowedParentStates.includes(rawParentFile.state)) {
      throw new Error(
        `Expected parent file to be in state ${allowedParentStates.join(
          " or "
        )} but was ${rawParentFile.state}`
      );
    }

    const parentFile = rawParentFile as UpdatingAddedFile | UpdatingDefaultFile;

    //find all children files by role
    const childrenRoles = ["sidecar-automatic", "thumbnail"];

    const childrenFiles = files.filter((f) => {
      const role = getRole(index, f);

      return role.result !== "error" && childrenRoles.includes(role.role);
    });

    //map children files to parent file
    const newParentFile =
      parentFile.state === "added"
        ? ({
            state: "added",
            addedFile: parentFile.addedFile,
            id: parentFile.id,
            slot: parentFile.slot,
            childrenAddedFiles: childrenFiles.map((f) => {
              if (f.state === "added") {
                return f.addedFile;
              }
              throw new Error(`Expected file to be added but was ${f.state}`);
            }),
          } as UpdatingAddedFile)
        : ({
            state: "default",
            file: parentFile.file,
            id: parentFile.id,
            slot: parentFile.slot,
            childFiles: childrenFiles.map((f) => {
              if (f.state === "default") {
                return f.file;
              }
              throw new Error(`Expected file to be default but was ${f.state}`);
            }),
          } as UpdatingDefaultFile);

    //find any files that are neither parent nor children
    const remainingFiles = files.filter(
      (f) =>
        f.id !== parentFile.id && !childrenFiles.some((cf) => cf.id === f.id)
    );

    //return a new file array containing the parent file and the remaining files
    const mappedParentAndChildrenFiles = remainingFiles.concat([newParentFile]);

    // logger.debug(
    //   `Mapped ${files.length} files as parent and child [${index}]`,
    //   {
    //     files,
    //     mappedParentAndChildrenFiles,
    //   }
    // );

    return mappedParentAndChildrenFiles;
  };

  const _unmapUpdatingFile = (
    index: number,
    file: UpdatingFile
  ): UpdatingFile[] => {
    if (file.state === "added") {
      const unmappedChildrenFiles = (
        file.childrenAddedFiles ?? []
      ).map<UpdatingAddedFile>((f) => ({
        state: "added",
        addedFile: f,
        id: fileIdGenerator(),
        slot: -1,
      }));

      return unmappedChildrenFiles.concat([
        {
          id: file.id,
          addedFile: file.addedFile,
          slot: file.slot,
          state: "added",
        },
      ]);
    }

    if (file.state === "default") {
      const unmappedChildrenFiles = (
        file.childFiles ?? []
      ).map<UpdatingDefaultFile>((f) => ({
        state: "default",
        file: f,
        id: fileIdGenerator(),
        slot: -1,
      }));

      return unmappedChildrenFiles.concat([
        {
          id: file.id,
          file: file.file,
          slot: file.slot,
          state: "default",
        },
      ]);
    }

    if (file.state === "removed") {
      //unmaps the removed file and all its children
      const removedUnmappedFiles = _unmapUpdatingFile(index, file.removedFile);

      //we need to add state=removed again as the unmapped files are state=default or state=added
      return removedUnmappedFiles.map<UpdatingFile>((f) => ({
        state: "removed",
        id: f.id,
        slot: f.slot,
        removedFile: f,
      }));
    }

    if (file.state === "replaced") {
      //unmaps the new and old file and all its children
      const newUnmappedFiles = _unmapUpdatingFile(
        index,
        file.newFile
      ) as UpdatingAddedFile[]; //type casted here but later to make sure it's correct
      const oldUnmappedFiles = _unmapUpdatingFile(index, file.oldFile);

      //we need to find the files that replace each other by their respective role
      return newUnmappedFiles
        .filter((f) => f.state === "added")
        .map<UpdatingReplacedFile | undefined>((f) => {
          const role = getRole(index, f);

          if (role.result === "error") return undefined;

          const oldFileWithSameRole = oldUnmappedFiles.find((of) => {
            const oldRole = getRole(index, of);

            return oldRole.result !== "error" && oldRole.role === role.role;
          });

          if (!oldFileWithSameRole) return undefined;

          return {
            state: "replaced",
            id: f.id,
            slot: f.slot,
            newFile: f,
            oldFile: oldFileWithSameRole,
          };
        })
        .filter((f) => f !== undefined) as UpdatingReplacedFile[];
    }

    return [file];
  };

  const unmapParentAndChildrenFiles = (
    index: number,
    mappedFiles: UpdatingFile[]
  ) => {
    //find the parent file by role family
    const rawParentFile = mappedFiles.find((f) => {
      const role = getRole(index, f);

      return (
        (role.result === "success" && role.role === "family") ||
        (role.result === "overwrite" && role.role === "family")
      );
    });
    const everyNonFamilyFile = mappedFiles.filter((f) => {
      const role = getRole(index, f);

      return (
        (role.result === "success" && role.role !== "family") ||
        (role.result === "overwrite" && role.role !== "family")
      );
    });

    if (!rawParentFile) {
      //there is no family file, so there can't be any children
      // logger.debug(
      //   `Received ${mappedFiles.length} files for unmapping the parent and children but couldn't find a family. Maybe the files were not mapped?`,
      //   {
      //     mappedFiles,
      //   }
      // );
      return mappedFiles;
    }

    //extract the mapped children
    const unmappedFiles = _unmapUpdatingFile(index, rawParentFile);

    // logger.debug(
    //   `Unmapped ${unmappedFiles.length} files from parent and child mapping [${index}]`,
    //   {
    //     mappedFiles,
    //     unmappedFiles,
    //   }
    // );

    return everyNonFamilyFile.concat(unmappedFiles);
  };

  const _getRoleFromFile = (
    index: number,
    file: UpdatingFile
  ): GetRoleResult | undefined => {
    if (file.state === "added" && file.addedFile.source === "addon") {
      return {
        result: "success",
        role: file.addedFile.role,
      };
    } else if (file.state === "added" && file.addedFile.source === "user") {
      return getRole(index, file, "skipExistingRoles");
    }

    if (file.state === "removed") {
      //checking for infinite recursion here
      if (file.removedFile.state !== "removed") {
        return getRole(index, file.removedFile);
      }
    }
    if (file.state === "default") {
      return {
        result: "success",
        role: file.file.role,
      };
    }
    if (file.state === "replaced") {
      if (file.oldFile.state !== "replaced") {
        return getRole(index, file.oldFile);
      }
    }
  };

  const _detectRoleFromFile = (
    index: number,
    updatingFiles: UpdatingFile[],
    file: UpdatingFile
  ): GetRoleResult => {
    //debugger;

    //this check will only be called if we don't have any information stored yet
    const relevantRoles: BimFilesFile["role"][] = [
      "family",
      "sidecar",
      "sidecar-automatic",
      "thumbnail",
    ];
    //these are all the relevant roles we need to check for overwriting
    const releventRolesForUserUpload: BimFilesFile["role"][] = [
      "family",
      "sidecar",
    ];
    //we find all the relevant roles that already exist on all files (excluding removed items as they would be deleted after the update)
    const doesFamilyExist = updatingFiles
      .filter((f) => f.state === "removed")
      .some((f) => {
        return getRoleFromFileName(getName(f), false) === "family";
      });
    const existingRoles = updatingFiles
      .flatMap((f) => {
        //if we have files that contain other files we add them to the main list
        if (f.state === "replaced") {
          return [f, f.oldFile];
        }
        return [f];
      })
      .filter((f) => f.state !== "removed")
      .filter((f) => {
        const additionalId = f.state === "replaced" ? f.oldFile.id : undefined;

        return f.id !== file.id && f.id !== additionalId;
      })
      .map((f) => {
        const isOtherRole =
          getRoleFromFileName(getName(f), doesFamilyExist) === "other";

        if (isOtherRole) {
          return "other";
        }

        const result = getRole(index, f, "skipDetectingRole");

        if (result.result !== "success")
          return getRoleFromFileName(getName(f), doesFamilyExist);

        return result.role;
      })
      .filter((r) => relevantRoles.includes(r))
      .filter((r, i, a) => a.indexOf(r) === i);
    const name = getName(file);

    let role = getRoleFromFileName(name, existingRoles.includes("family"));

    const hasBeenUploadedByUser =
      file.state === "added" && file.addedFile.source === "user";
    const hasBeenUploadedByAddon =
      file.state === "added" && file.addedFile.source === "addon";

    if (hasBeenUploadedByAddon && role === "sidecar") {
      //if we are uploading through addon and we have a sidecar we will change the role
      role = "sidecar-automatic";
    }

    const isOverwriting = existingRoles.includes(role);
    const hasRelevantRoleForUserUpload =
      releventRolesForUserUpload.includes(role);

    //we check if the role already exists except if the file is uploaded by the user
    if (
      (isOverwriting && !hasBeenUploadedByUser) ||
      (hasBeenUploadedByUser && hasRelevantRoleForUserUpload && isOverwriting)
    ) {
      //we would overwrite a file
      //let's find the file first
      const overwrittenFile = updatingFiles
        .filter((f) => f.id !== file.id)
        .find((f) => {
          const result = getRole(index, f);

          if (result.result !== "success") return false;

          return result.role === role;
        });

      if (!overwrittenFile) {
        logger.error(
          `Did not find an overwritten file when searching for it after detecting an overwrite.`,
          {
            file,
            isOverwriting,
            hasRelevantRoleForUserUpload,
            hasBeenUploadedByAddon,
            hasBeenUploadedByUser,
            existingRoles,
          }
        );

        return {
          result: "error",
          id: "SearchInvalidState",
          message: `The file ${file.id} would overwrite a file with the role ${role} but the file could not be found`,
        };
      }

      return { result: "overwrite", overwrittenFile, role };
    }

    if (
      isOverwriting &&
      !hasRelevantRoleForUserUpload &&
      hasBeenUploadedByUser
    ) {
      //this file is uploaded by the user and would overwrite an existing file but the detected role is something a user can't upload so we ignore it
      return { result: "success", role: "other" };
    }

    return { result: "success", role };
  };

  /**
   * Determines the role of any file within the context of all other files
   * @param file
   * @returns
   */
  const getRole = (
    index: number,
    file: UpdatingFile,
    skipMode?: "skipExistingRoles" | "skipDetectingRole"
  ): GetRoleResult => {
    const upload = getUpload(index);

    if (!upload) {
      return {
        result: "error",
        id: "UploadNotFound",
        message: `Couldn't find an upload with index ${index} for the file ${file.id} when trying to get the role.`,
      };
    }

    if (skipMode !== "skipExistingRoles") {
      const role = _getRoleFromFile(index, file);
      if (role) return role;
    }

    if (skipMode !== "skipDetectingRole") {
      return _detectRoleFromFile(index, upload.updatingFiles, file);
    }

    return {
      result: "success",
      role: "other",
    };
  };

  const getRoleWithDefault = (
    index: number,
    file: UpdatingFile,
    skipMode?: "skipExistingRoles" | "skipDetectingRole",
    defaultRole?: BimFilesFile["role"]
  ) => {
    //logger.debug(`Getting role for index ${index} and file ${file.id}.`);

    const output = getRole(index, file, skipMode);

    if (output.result === "success") return output.role;
    if (output.result === "overwrite") return output.role;

    return defaultRole ?? "other";
  };

  /**
   * Returns the file name based on the state of the file
   * @param file the file
   * @returns the name of the file or an empty string if there is no name
   */
  const getName = (file: UpdatingFile): string => {
    if (file.state === "added") {
      if (file.addedFile.source === "user") {
        return file.addedFile.userFile.name;
      }

      if (file.addedFile.source === "addon") {
        return file.addedFile.name;
      }
    }

    if (file.state === "default") {
      return file.file.name;
    }

    if (file.state === "removed") {
      return file.removedFile.state !== "removed"
        ? getName(file.removedFile)
        : "";
    }

    if (file.state === "replaced") {
      if (file.newFile.addedFile.source === "user") {
        return file.newFile.addedFile.userFile.name;
      }

      if (file.newFile.addedFile.source === "addon") {
        return file.newFile.addedFile.name;
      }
    }

    return "";
  };

  /**
   * Converts the given added file to a browser file
   * @param file the file
   * @returns the browser file
   */
  const _addedFileToFile = (
    file: UpdatingAddedFile["addedFile"]
  ): File | undefined => {
    if (file.source === "user") {
      return file.userFile;
    }

    if (file.source === "addon") {
      const blob = convertBase64ToBlob(file.base64);

      return new File([blob], file.name);
    }

    return undefined;
  };

  const getFile = (file: UpdatingAddedFile | UpdatingReplacedFile): File => {
    if (file.state === "added") {
      if (file.addedFile.source === "user") {
        return file.addedFile.userFile;
      }

      if (file.addedFile.source === "addon") {
        const blob = convertBase64ToBlob(file.addedFile.base64);

        return new File([blob], file.addedFile.name);
      }
    }

    if (file.state === "replaced") {
      if (file.newFile.addedFile.source === "user") {
        return file.newFile.addedFile.userFile;
      }

      if (file.newFile.addedFile.source === "addon") {
        const blob = convertBase64ToBlob(file.newFile.addedFile.base64);

        return new File([blob], file.newFile.addedFile.name);
      }
    }

    throw new Error(`Invalid state for getFile with file ${file.id}.`);
  };

  const getPotentiallyPackedUpdatingFileByRole = (
    index: number,
    role: BimFilesFile["role"]
  ): UpdatingFile | undefined => {
    if (role === "other" || role === "revit")
      throw new Error(
        `Can't get a potentially packed file for role=other or role=revit.`
      );

    const upload = getUpload(index);

    const updatingFiles = upload?.updatingFiles ?? [];

    const unmappedFiles = unmapParentAndChildrenFiles(index, updatingFiles);

    //try finding the appropriate updating file by the given role
    const updatingFile = unmappedFiles.find((f) => {
      const result = getRole(index, f);

      if (result.result !== "success") return false;

      return result.role === role;
    });

    return updatingFile;
  };

  const getPotentiallyPackedFileByRole = (
    index: number,
    role: BimFilesFile["role"]
  ): File | undefined => {
    const updatingFile = getPotentiallyPackedUpdatingFileByRole(index, role);

    if (!updatingFile) return undefined;

    const allowedStates = ["added", "replaced"];

    if (!allowedStates.includes(updatingFile.state))
      throw new Error(
        `Invalid state for potentially packed file with file ${updatingFile.id}.`
      );

    return getFile(updatingFile as UpdatingAddedFile | UpdatingReplacedFile);
  };

  const getProperties = (
    sources?: {
      existingParameters: { key: string; value: string }[];
      newParameters: { key: string; value: string }[];
    },
    index: number = -1
  ): PropertiesResult => {
    const upload = getUpload(index);

    const updatingFiles = unmapParentAndChildrenFiles(
      index,
      upload?.updatingFiles ?? []
    );
    const oldItem = upload?.oldItem;

    let baseProperties = sources
      ? (getPropertiesFromParameters(
          sources.existingParameters
        ) as Item["properties"])
      : undefined;
    let newProperties = sources
      ? (getPropertiesFromParameters(
          sources.newParameters
        ) as Item["properties"])
      : undefined;

    if (!baseProperties && !newProperties) {
      const addonFile = updatingFiles.find(
        (f) =>
          (f.state === "added" && f.addedFile.source === "addon") ||
          (f.state === "replaced" && f.newFile.addedFile.source === "addon")
      ) as UpdatingAddedFile | UpdatingReplacedFile | undefined;
      const hasPropertiesOnItem = oldItem && oldItem.properties !== undefined;

      //if we dont have an addon file or a family file in default we don't have any properties
      if (addonFile === undefined && !hasPropertiesOnItem) return undefined;

      baseProperties = oldItem?.properties ?? undefined;

      //in case the addonFile is state=replaced we need to access it differently
      if (addonFile && addonFile.state === "replaced") {
        newProperties =
          addonFile?.newFile.addedFile.source === "addon"
            ? (getPropertiesFromParameters(
                addonFile.newFile.addedFile.parameters as {
                  key: string;
                  value: string;
                }[]
              ) as Item["properties"])
            : undefined;
      } else if (addonFile && addonFile.state === "added") {
        newProperties =
          addonFile?.addedFile.source === "addon"
            ? (getPropertiesFromParameters(
                addonFile.addedFile.parameters as {
                  key: string;
                  value: string;
                }[]
              ) as Item["properties"])
            : undefined;
      }
    }

    if (!baseProperties && !newProperties) return undefined;

    const updatedPropertieKeys = Object.entries(newProperties ?? {}).reduce<
      string[]
    >((total, current) => {
      const [key, value] = current;

      if (!baseProperties) {
        total.push(key);
        return total;
      }

      if (
        baseProperties[key as keyof Required<Item>["properties"]] &&
        baseProperties[key as keyof Required<Item>["properties"]] !== value
      ) {
        total.push(key);
      }

      return total;
    }, []) as (keyof Required<Item>["properties"])[];

    const properties = newProperties ?? baseProperties;

    if (!properties) return undefined;

    //we force the type because we know all types are present
    return {
      familyName: properties.familyName!,
      familyRevitVersion: properties.familyRevitVersion!,
      ostCategory: properties.ostCategory!,
      placementMethod: properties.placementMethod!,
      revitVersion: properties.revitVersion!,
      updatedProperties: baseProperties,
      updatedPropertieKeys: updatedPropertieKeys,
    };
  };

  return {
    mapParentAndChildrenFiles,
    unmapParentAndChildrenFiles,
    getRole,
    getName,
    getPotentiallyPackedUpdatingFileByRole,
    getPotentiallyPackedFileByRole,
    getFile,
    getProperties,
    getRoleWithDefault,
  };
};

export type PropertiesResult =
  | ({
      updatedProperties?: Item["properties"];
      updatedPropertieKeys: (keyof Required<Item>["properties"])[];
    } & Item["properties"])
  | undefined;
