import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LoginPage from "../AllUsers/Authentication/LoginPage/LoginPage";
import Homepage from "../AllUsers/HomepageComponents/Homepage/Homepage";
import RequireAuth from "./RequireAuth";
import RequireNoAuth from "./RequireNoAuth";
import { UserAdminPage } from "../Admin/UserAdminPage";
import { AdminStart } from "../Admin";
import { TagAdminPage } from "../Admin/TagAdminPage";
import { ItemAdminPage } from "../Admin/ItemAdminPage";
import { OrganisationAdminPage } from "../Admin/OrganisationAdminPage";
import { CategoryAdminPage } from "../Admin/CategoryAdminPage";
import MainAdminPage from "../Admin/MainAdminPage";
import PasswordResetPage from "../AllUsers/Authentication/PasswordResetPage/PasswordResetPage";
import PasswordStartResetPage from "../AllUsers/Authentication/PasswordStartResetPage/PasswordStartResetPage";
import ActivationPage from "../AllUsers/Authentication/ActivationPage/ActivationPage";
import ItemPage from "../AllUsers/ItemPage/ItemPage";
import NavbarLayout from "./NavbarLayout";
import DemoPage from "../AllUsers/Demo/DemoPage";
import { DebugPage } from "../AllUsers/Debug/DebugPage";

const Routing = () => {
  return (
    <Router>
      <Routes>
        <Route path="/debug" element={<DebugPage />} />
        {/* Unprotected Routes */}
        <Route element={<RequireNoAuth />}>
          <Route path="/demo" element={<DemoPage />} />
          <Route path="/" element={<LoginPage />} />
          <Route path="/activate/:id" element={<ActivationPage />} />
          <Route path="/reset" element={<PasswordStartResetPage />} />
          <Route path="/reset/:id" element={<PasswordResetPage />} />
        </Route>
        {/* Routes that require user to be authenticated to access */}
        <Route element={<RequireAuth />}>
          <Route path="/homepage" element={<Homepage />}></Route>
          <Route
            path="/item/:itemId"
            element={
              <NavbarLayout>
                <ItemPage />
              </NavbarLayout>
            }
          />
          <Route path="/admin" element={<AdminStart />}>
            <Route path="*" index element={<MainAdminPage />} />
            <Route path="users" element={<UserAdminPage />} />
            <Route path="tags" element={<TagAdminPage />} />
            <Route path="items" element={<ItemAdminPage />} />
            <Route path="organisations" element={<OrganisationAdminPage />} />
            <Route path="categories" element={<CategoryAdminPage />} />
          </Route>
          <Route path="*" element={<Homepage />}></Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default Routing;
