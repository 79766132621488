import { Tag } from "antd";
import { useIntl } from "react-intl";
import translations from "../../../../../translations/constants/translations";

type Props = {
  state: "added" | "default" | "removed" | "replaced";
};

export default ({ state }: Props) => {
  const intl = useIntl();
  const color =
    state === "added" ? "green" : state === "removed" ? "red" : "blue";

  return (
    <Tag color={color} style={{ marginLeft: ".25rem", marginRight: ".25rem" }}>
      {state === "added" &&
        intl.formatMessage(
          translations.ui.upload.components.fileListItemStateAdded
        )}
      {state === "removed" &&
        intl.formatMessage(
          translations.ui.upload.components.fileListItemStateRemoved
        )}
      {state === "replaced" &&
        intl.formatMessage(
          translations.ui.upload.components.fileListItemStateReplaced
        )}
    </Tag>
  );
};
