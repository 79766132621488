import { Change } from "@formitas-ag/bimfiles-types";

/**
 * This method uses the checks defined below to check the type of change
 * and returns the name of check
 *
 * @param object to be checked if is a change object
 * @returns change type name or null if it is not change type
 */
export const getChangeType = (
  object: Change
):
  | "CategoryChange"
  | "ApprovedChange"
  | "TagChange"
  | "FileChange"
  | "TitleChange"
  | "DescriptionChange"
  | "ChangeDescriptionChange"
  | "ParametersAndPropertiesChange"
  | "DeleteChange"
  | "ReasonChange" => {
  if (categoryChangeCheck(object)) {
    return "CategoryChange";
  } else if (approvedChangeCheck(object)) {
    return "ApprovedChange";
  } else if (tagChangeCheck(object)) {
    return "TagChange";
  } else if (fileChangeCheck(object)) {
    return "FileChange";
  } else if (titleChangeCheck(object)) {
    return "TitleChange";
  } else if (descriptionChangeCheck(object)) {
    return "DescriptionChange";
  } else if (changeDescriptionChangeCheck(object)) {
    return "ChangeDescriptionChange";
  } else if (parametersAndPropertiesChangeCheck(object)) {
    return "ParametersAndPropertiesChange";
  } else if (deleteChangeCheck(object)) {
    return "DeleteChange";
  } else if (reasonChangeCheck(object)) {
    return "ReasonChange";
  }
  return "ApprovedChange";
};

/**
 * All the methods under here are used to
 * check whether provided object is a change object
 * or not based on the object structure
 *
 * The 'equal' tag is to check if it is an exact match
 *
 * The 'contains' tag is to check if the object
 * contains a change object
 */
type CheckType = "equals" | "contains";

const baseChangeCheck = (object: any, checkType: CheckType = "contains") => {
  const check =
    object.id !== undefined &&
    object.at !== undefined &&
    object.by !== undefined &&
    object.state !== undefined;
  return checkType === "contains"
    ? check
    : check && Object.keys(object).length === 4;
};

const categoryChangeCheck = (object: any, checkType: CheckType = "equals") => {
  const check = object.categoryId !== undefined && baseChangeCheck(object);

  return checkType === "contains"
    ? check
    : check && Object.keys(object).length === 5;
};

const approvedChangeCheck = (object: any, checkType: CheckType = "equals") => {
  const check =
    object.approvedAt !== undefined &&
    object.approvedBy !== undefined &&
    baseChangeCheck(object);
  return checkType === "contains"
    ? check
    : check && Object.keys(object).length === 6;
};

const tagChangeCheck = (object: any, checkType: CheckType = "equals") => {
  const check = object.tagsIds !== undefined && baseChangeCheck(object);
  return checkType === "contains"
    ? check
    : check && Object.keys(object).length === 5;
};

const fileChangeCheck = (object: any, checkType: CheckType = "equals") => {
  const check = object.files !== undefined && baseChangeCheck(object);
  return checkType === "contains"
    ? check
    : check && Object.keys(object).length === 5;
};

const titleChangeCheck = (object: any, checkType: CheckType = "equals") => {
  const check = object.title !== undefined && baseChangeCheck(object);
  return checkType === "contains"
    ? check
    : check && Object.keys(object).length === 5;
};

const descriptionChangeCheck = (
  object: any,
  checkType: CheckType = "equals"
) => {
  const check = object.description !== undefined && baseChangeCheck(object);
  return checkType === "contains"
    ? check
    : check && Object.keys(object).length === 5;
};

const changeDescriptionChangeCheck = (
  object: any,
  checkType: CheckType = "equals"
) => {
  const check =
    object.changeDescription !== undefined && baseChangeCheck(object);
  return checkType === "contains"
    ? check
    : check && Object.keys(object).length === 5;
};

const parametersAndPropertiesChangeCheck = (
  object: any,
  checkType: CheckType = "equals"
) => {
  const check = object.parameters !== undefined && baseChangeCheck(object);
  return checkType === "contains"
    ? check
    : check && Object.keys(object).length === 6;
};

const deleteChangeCheck = (object: any, checkType: CheckType = "equals") => {
  const check =
    object.deletedAt !== undefined &&
    object.deletedBy !== undefined &&
    baseChangeCheck(object);
  return checkType === "contains"
    ? check
    : check && Object.keys(object).length === 6;
};

const reasonChangeCheck = (object: any, checkType: CheckType = "equals") => {
  const check =
    object.type !== undefined &&
    object.reason !== undefined &&
    baseChangeCheck(object);
  return checkType === "contains"
    ? check
    : check && Object.keys(object).length === 6;
};
