import { useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAccessToken } from "../../api/utils/auth.utils";
import { useAuthStore } from "../../state/authStore";
import { useEffect } from "react";

/**
 * Guards routes that can only be accessed if the user is authenticated
 * @returns rerouting based on the user authentication status
 */
const RequireAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [accessToken] = useAccessToken();

  const tempUrl = useAuthStore((state) => state.tempUrl);
  const setTempUrl = useAuthStore((state) => state.setTempUrl);

  useEffect(() => {
    if (location.pathname.includes("/item/") && accessToken === null) {
      setTempUrl(location.pathname);
    }
    if (tempUrl) {
      navigate(tempUrl);
      setTempUrl(null);
    }
  }, []);

  return accessToken === null ? (
    <Navigate to="/" state={{ from: location }} replace />
  ) : (
    <Outlet />
  );
};

export default RequireAuth;
