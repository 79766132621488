import React, { useState } from "react";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { FileChange, Item } from "@formitas-ag/bimfiles-types";
import { Button, Tooltip } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { FileWithUrl } from "@formitas-ag/bimfiles-types/lib/file";
import { getChangeType } from "../../../../utils/change-type.utils";
import api from "../../../../api/api";
import useFiltersStore from "../../../../state/filtersStore";
import ButtonProps from "../../../../UiProperties/ButtonProps";

type Props = {
  id: string;
  item: Item;
  disabled?: boolean;
  existingChange?: FileChange;
};

const messages = defineMessages({
  tooltip: {
    id: "components.download-item-button.tooltip",
    defaultMessage: "Download {files, plural, one {# File} other {# Files}}",
    description: "Tooltip for the download item button",
  },
});

const DownloadItemButton = (props: Props) => {
  const intl = useIntl();
  const itemsState = useFiltersStore((state) => state.itemsState);

  const [loading, setLoading] = useState<boolean>(false);

  const download = async (fileUrls: FileWithUrl[]) => {
    setLoading(true);
    for (let index = 0; index < fileUrls.length; index++) {
      const file = fileUrls[index];

      try {
        const response = await fetch(file.url);
        const blob = await response.blob();

        const link = document.createElement("a");
        link.style.display = "none";
        document.body.appendChild(link);

        const objectUrl = URL.createObjectURL(blob);
        link.setAttribute("href", objectUrl);
        link.setAttribute("download", file.name);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error(`Error downloading file at index ${index}:`, error);
      }
    }
    setLoading(false);
  };

  const onClick = async (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();

    const files = props.existingChange
      ? await Promise.all(
          props.existingChange.files
            .filter(
              (file) =>
                file.role !== "thumbnail" && file.role !== "sidecar-automatic"
            )
            .map((file) =>
              api.items.getFileWithUrl(props.id, file.id, {
                includeUnapproved: true,
              })
            )
        )
      : itemsState === "approved"
      ? await Promise.all(
          props.item.files
            .filter(
              (file) =>
                file.role !== "thumbnail" && file.role !== "sidecar-automatic"
            )
            .map((file) =>
              api.items.getFileWithUrl(props.id, file.id, {
                includeUnapproved: true,
              })
            )
        )
      : await Promise.all(
          (
            props.item.changes
              .filter((change) => getChangeType(change) === "FileChange")
              .sort(
                (a, b) => new Date(b.at).getTime() - new Date(a.at).getTime()
              )[0] as FileChange
          ).files
            .filter(
              (file) =>
                file.role !== "thumbnail" && file.role !== "sidecar-automatic"
            )
            .map((file) =>
              api.items.getFileWithUrl(props.id, file.id, {
                includeUnapproved: true,
              })
            )
        );

    // TODO: Add correct write call
    // api.stats.writeItemDownloadsByUser(props.id);

    download(files);
  };

  return (
    <Tooltip
      color="#f7f8fc"
      title={intl.formatMessage(messages.tooltip, {
        files:
          itemsState === "approved"
            ? props.item.files.filter(
                (file) =>
                  file.role !== "thumbnail" && file.role !== "sidecar-automatic"
              ).length
            : (
                props.item.changes
                  .filter((change) => getChangeType(change) === "FileChange")
                  .sort(
                    (a, b) =>
                      new Date(b.at).getTime() - new Date(a.at).getTime()
                  )[0] as FileChange
              ).files.filter(
                (file) =>
                  file.role !== "thumbnail" && file.role !== "sidecar-automatic"
              ).length,
      })}
    >
      <Button
        type="dashed"
        style={ButtonProps.ghost}
        icon={
          <CloudDownloadOutlined
            style={{
              fontSize: "24px",
              padding: "1px 2px 1px 2px",
              color: "#931d21",
            }}
          />
        }
        loading={loading}
        onClick={onClick}
        disabled={props.disabled}
      />
    </Tooltip>
  );
};

export default DownloadItemButton;
