import { RcFile } from "antd/lib/upload";
import { SendEncodedFilesType, getRevit } from "../../api/revit";
import { useRevitStore } from "../../state/revitStore";
import { fileToBase64 } from "../useUpload";
import { useEffect } from "react";
import { UpdatingAddedFile } from "../../utils/types/updateFilesTypes";
import useAddAnyFile from "./useAddAnyFile";
import { getLogger } from "../../utils/logger.utils";
import { useCreateOrUpdateItemStore } from "../../state/createOrUpdateItemStore";
import useGetFileMeta from "./useGetFileMeta";
import useOverwriteItem from "../useOverwriteItem";
import { useDebouncedCallback } from "use-debounce";
import { useCreateOrUpdateMultipleFiles } from "../useCreateOrUpdateMultipleFiles";
import { useGenerateFileId } from "./useGenerateFileId";

const logger = getLogger("useUpdateAddonFiles");

const useUpdateAddonFiles = () => {
  const currentRevitMode = useRevitStore((state) => state.currentMode);
  const currentError = useRevitStore((state) => state.currentError);
  const revitOutput = useRevitStore((state) => state.revitOutput);
  const { addAnyFile } = useAddAnyFile();
  const { getRole, getName, getProperties, mapParentAndChildrenFiles } = useGetFileMeta();
  const { addOverwritingFiles } = useOverwriteItem();
  const { getUpload, setUpload, setItem } = useCreateOrUpdateMultipleFiles();
  const currentRevitExportIndex = useCreateOrUpdateItemStore(
    (state) => state.currentRevitExportIndex
  );
  const { fileIdGenerator } = useGenerateFileId();

  const continueRevitExport = useDebouncedCallback(() => {
    if (currentRevitMode === "exportOnUpdateFiles" && currentRevitExportIndex) {
      const localFilesWaitingForRevit = getUpload(currentRevitExportIndex)
        ?.localFilesWaitingForRevit!;

      if (
        revitOutput &&
        revitOutput.files &&
        revitOutput.parameters &&
        revitOutput.files.length > 0
      ) {
        logger.debug(
          `Received ${revitOutput.files.length} exported files from revit [${currentRevitExportIndex}]`
        );

        logger.debug(
          `Received ${localFilesWaitingForRevit.length} local files [${currentRevitExportIndex}]`
        );

        const _addedFiles = _convertRevitFilesToUpdatingFiles(
          revitOutput.files!,
          revitOutput.parameters!
        ).concat(
          localFilesWaitingForRevit.map<UpdatingAddedFile>((f) => {
            return {
              id: f.uid,
              slot: -1,
              addedFile: {
                source: "user",
                userFile: f,
              },
              state: "added",
            };
          })
        );

        //we can safely cast as the only input is state=added and the only output can be state=added
        const parentAndChildAddedFiles = mapParentAndChildrenFiles(currentRevitExportIndex, _addedFiles) as UpdatingAddedFile[];

        logger.debug(`Received ${parentAndChildAddedFiles.length} files`, parentAndChildAddedFiles);

        clearRevitExport();


        const allRoles = parentAndChildAddedFiles.map((f) =>
          getRole(currentRevitExportIndex, f, "skipExistingRoles")
        );

        const anyBadFiles = allRoles.some((r) => r.result !== "success");

        if (anyBadFiles) {
          //we might be overwriting with revit files
          const anyOverwrite = allRoles.some((r) => r.result === "overwrite");

          if (anyOverwrite) {
            //overwrite process thing
            logger.debug(`Overwriting files with Revit files: `, parentAndChildAddedFiles);

            addOverwritingFiles({
              type: "multiple",
              index: currentRevitExportIndex,
              elements: parentAndChildAddedFiles.map((f) => {
                const role = getRole(
                  currentRevitExportIndex,
                  f,
                  "skipExistingRoles"
                );

                if (role.result === "overwrite") {
                  return {
                    file: f,
                    overwrittenFile: role.overwrittenFile,
                  };
                }
                throw new Error(
                  `Expected role to be overwrite but was ${role.result}`
                );
              }),
            });

            return;
          }
        }

        for (const addedFile of parentAndChildAddedFiles) {
          addAnyFile(addedFile, currentRevitExportIndex);
        }

        const properties = getProperties(undefined, currentRevitExportIndex);

        const familyFile = parentAndChildAddedFiles.find((f) => {
          const roleResult = getRole(currentRevitExportIndex, f);

          return (
            roleResult.result === "success" && roleResult.role === "family"
          );
        });

        let itemTitle = properties?.familyName ?? getName(familyFile!);

        if (itemTitle.endsWith(".rfa")) itemTitle = itemTitle.slice(0, -4);

        //set title from family name
        setItem(currentRevitExportIndex, {
          title: itemTitle,
        });
      }
    }
  }, 500);

  const finishRevitExportAfterError = useDebouncedCallback(() => {
    if (currentRevitMode === "exportOnUpdateFiles" && currentError) {
      logger.debug(`A revit error has occured: `, currentError);
      //an error happened while we were exporting from revit
      if (currentError.type === "generic") {
        setUpload(currentRevitExportIndex!, {
          updatingFilesError: "generic",
          updatingFilesGenericError: currentError.content,
        });
      } else {
        setUpload(currentRevitExportIndex!, {
          updatingFilesError: currentError.type,
        });
      }

      clearRevitExport();
    }
  }, 500);

  useEffect(() => {
    if (currentRevitMode === "exportOnUpdateFiles") {
      if (
        revitOutput &&
        revitOutput.files &&
        revitOutput.parameters &&
        revitOutput.files.length > 0
      ) {
        continueRevitExport();
      }
    }
  }, [revitOutput]);

  useEffect(() => {
    if (currentRevitMode === "exportOnUpdateFiles") {
      finishRevitExportAfterError();
    }
  }, [currentError]);

  /**
   * Converts the output of the revit addon to updating files
   * @param revitFiles the revit files
   * @returns the updating files
   */
  const _convertRevitFilesToUpdatingFiles = (
    revitFiles: SendEncodedFilesType,
    parameters: object
  ): UpdatingAddedFile[] => {
    //convert any sidecar to sidecar-automatic as any generated sidecar will be sidecar-automatic
    return revitFiles.map<UpdatingAddedFile>((file) => {
      return {
        id: fileIdGenerator(),
        slot: -1,
        addedFile: {
          name: file.name,
          role: file.role === "sidecar" ? "sidecar-automatic" : file.role,
          base64: file.data,
          source: "addon",
          parameters,
        },
        state: "added",
      };
    });
  };

  const clearRevitExport = () => {
    const currentRevitExportIndex =
      useCreateOrUpdateItemStore.getState().currentRevitExportIndex!;

    useRevitStore.setState({
      currentError: undefined,
      currentMode: undefined,
      revitOutput: undefined,
    });

    setUpload(currentRevitExportIndex, {
      exportingFromRevit: false,
      localFilesWaitingForRevit: [],
    });

    useCreateOrUpdateItemStore.setState({
      currentRevitExportIndex: undefined,
      isLoading: false,
      multiUploadFileGroupQueue: useCreateOrUpdateItemStore
        .getState()
        .multiUploadFileGroupQueue.filter(
          (mu) => mu.index !== currentRevitExportIndex
        ),
    });

    logger.debug(
      `Cleared any remaining Revit export [${currentRevitExportIndex}]`,
      {
        upload: useCreateOrUpdateItemStore.getState().uploads,
      }
    );
  };

  const onClickRevitExport = (index: number) => {
    if (useRevitStore.getState().currentMode === undefined) {
      useRevitStore.setState({
        currentMode: "exportOnUpdateFiles",
        currentError: undefined,
      });

      logger.debug(
        `Exporting from Revit with mode=exportOnUpdateFiles [${index}]`
      );

      useCreateOrUpdateItemStore.setState({
        currentRevitExportIndex: index,
        isLoading: true,
      });

      setUpload(index, {
        exportingFromRevit: true,
      });

      getRevit().receiveImportTriggered();
    }
  };

  /**
   * Called whenever a user uploads a family through the normal file upload (not exporting from Revit)
   * @param familyFile the family file that was uploaded
   */
  const onDroppedFamilyFile = async (familyFile: RcFile, index: number) => {
    logger.debug(`Called onDroppedFamilyFile`, { familyFile, index });

    useRevitStore.setState({
      currentMode: "exportOnUpdateFiles",
      currentError: undefined,
      revitOutput: undefined,
    });

    useCreateOrUpdateItemStore.setState({
      currentRevitExportIndex: index,
      isLoading: true,
    });

    setUpload(index, {
      exportingFromRevit: true,
    });

    getRevit().receiveFamilyForParsing([
      {
        name: familyFile.name,
        role: "family",
        data: await fileToBase64(familyFile),
      },
    ]);
  };

  return { onClickRevitExport, clearRevitExport, onDroppedFamilyFile };
};

export default useUpdateAddonFiles;
