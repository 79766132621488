import { FileIconProps } from "react-file-icon";

type IconPropsType = {
  [key: string]: FileIconProps;
};

const imageIconProps: IconPropsType[0] = {
  color: "#03C883",
  labelColor: "#01B075",
  type: "image",
  glyphColor: "rgba(255,255,255,0.6)",
  radius: 6,
};

const videoIconProps: IconPropsType[0] = {
  color: "#40C023",
  labelColor: "#19A624",
  type: "video",
  glyphColor: "rgba(255,255,255,0.6)",
  radius: 6,
};

const spreadsheetIconProps: IconPropsType[0] = {
  color: "#28A669",
  labelColor: "#1E623D",
  type: "spreadsheet",
  glyphColor: "rgba(255,255,255,0.6)",
  radius: 6,
};

export const IconProps: IconPropsType = {
  rvt: {
    color: "#5800C6",
    labelColor: "#4902A6",
    type: "3d",
    glyphColor: "rgba(255,255,255,0.6)",
    radius: 6,
  },
  rte: {
    color: "#FF8500",
    labelColor: "#BE6300",
    type: "3d",
    glyphColor: "rgba(255,255,255,0.6)",
    radius: 6,
  },
  rfa: {
    color: "#5800C6",
    labelColor: "#4902A6",
    type: "3d",
    glyphColor: "rgba(255,255,255,0.6)",
    radius: 6,
  },
  mrv: {
    color: "#FF8500",
    labelColor: "#BE6300",
    type: "3d",
    glyphColor: "rgba(255,255,255,0.6)",
    radius: 6,
  },
  dyn: {
    color: "#6D707B",
    labelColor: "#3D454D",
    type: "code",
    glyphColor: "rgba(255,255,255,0.6)",
    radius: 6,
  },
  pdf: {
    color: "#D14423",
    labelColor: "#9B341E",
    type: "acrobat",
    glyphColor: "rgba(255,255,255,0.6)",
    radius: 6,
  },
  doc: {
    color: "#2C5898",
    type: "document",
    glyphColor: "rgba(255,255,255,0.6)",
    radius: 6,
  },
  docx: {
    color: "#2C5898",
    type: "document",
    glyphColor: "rgba(255,255,255,0.6)",
    radius: 6,
  },
  ppt: {
    color: "#FF8500",
    labelColor: "#BE6300",
    type: "presentation",
    glyphColor: "rgba(255,255,255,0.6)",
    radius: 6,
  },
  pptx: {
    color: "#FF8500",
    labelColor: "#BE6300",
    type: "presentation",
    glyphColor: "rgba(255,255,255,0.6)",
    radius: 6,
  },
  svg: {
    color: "#ADD8E6",
    type: "vector",
    glyphColor: "rgba(255,255,255,0.6)",
    radius: 6,
  },
  txt: {
    color: "#98C1D9",
    type: "document",
    glyphColor: "rgba(255,255,255,0.6)",
    radius: 6,
  },
  zip: {
    color: "#FCAD29",
    labelColor: "#EC9011",
    type: "compressed",
    glyphColor: "rgba(255,255,255,0.6)",
    radius: 6,
  },

  png: imageIconProps,
  jpg: imageIconProps,
  jpeg: imageIconProps,
  jfif: imageIconProps,
  pjpeg: imageIconProps,
  pjp: imageIconProps,

  mp4: videoIconProps,
  mov: videoIconProps,
  avi: videoIconProps,
  mkv: videoIconProps,
  webm: videoIconProps,
  flv: videoIconProps,
  wmv: videoIconProps,
  avchd: videoIconProps,

  xlsx: spreadsheetIconProps,
  xls: spreadsheetIconProps,
  xlsm: spreadsheetIconProps,
  xltx: spreadsheetIconProps,
  xltm: spreadsheetIconProps,
  xlsb: spreadsheetIconProps,
  csv: spreadsheetIconProps,
};

export const defaultIconProps: IconPropsType[0] = {
  color: "#A1A1A1",
  labelColor: "rgb(255,255,255, 0)",
  glyphColor: "rgba(255,255,255,0.6)",
  radius: 6,
};
