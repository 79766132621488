import { LoadingOutlined } from "@ant-design/icons";
import { Image, Spin } from "antd";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { useQuery } from "react-query";
import api from "../../../../api/api";

type Props = {
  source: SourceType;
};

export type SourceType =
  | {
      type: "local";
      file: File;
    }
  | {
      type: "remote";
      item: ItemPopulated;
    };

const ItemThumbnail = (props: Props) => {
  const queryId = props.source.type === "remote" ? props.source.item.id : "";
  const query = useQuery(["thumbnail", queryId], async () => {
    if (props.source.type !== "remote") return;

    const item = props.source.item;

    if (item.files.filter((file) => file.role === "thumbnail").length > 0) {
      const response = await api.items.getThumbnail(props.source.item.id);

      return response.url;
    }
  });

  const localSrc =
    props.source.type === "local"
      ? URL.createObjectURL(props.source.file)
      : undefined;

  const style: React.CSSProperties = {
    height: "50px",
    width: "50px",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {props.source.type === "remote" ? (
        query.isSuccess ? (
          <Image
            src={query.data}
            style={{ ...style, objectFit: "contain" }}
          ></Image>
        ) : (
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        )
      ) : (
        <Image
          src={localSrc}
          style={{ ...style, objectFit: "contain" }}
        ></Image>
      )}
    </div>
  );
};

export default ItemThumbnail;
