import { SelectOrganisationForm } from "../SelectOrganisationForm";
import { hasSelectedOrganisationId } from "../../../../api/utils/auth.utils";
import { useAuthStore } from "../../../../state/authStore";
import { Button, Image, Row } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import LoginForm from "../LoginForm/LoginForm";
import bimfilesLogo from "../../../../assets/images/bimfiles-logo.png";

import "./login-page.css";
import translations from "../../../../translations/constants/translations";
import { Link } from "react-router-dom";

const LoginPage = () => {
  const intl = useIntl();

  const possibleOrganisations = useAuthStore(
    (state) => state.possibleOrganisations
  );

  if (possibleOrganisations.length > 1 && !hasSelectedOrganisationId()) {
    return (
      <div className="select-organisation-page-div">
        <SelectOrganisationForm possibleOrganisations={possibleOrganisations} />
      </div>
    );
  }

  return (
    <>
      <Row className="login-page-div">
        <div className="login-form-logo-div">
          <div className="bimfiles-logo-img">
            <Link to="/">
              <Image
                style={{ height: "18rem", width: "18rem" }}
                src={bimfilesLogo}
                preview={false}
              ></Image>
            </Link>
          </div>
          <LoginForm></LoginForm>
          <div className="no-account-div">
            <span style={{ fontSize: "1.2rem", color: "#fff" }}>
              <FormattedMessage
                id="auth.login_page.no_account"
                defaultMessage="Don't have an account?"
                description="Asking the user if they have account string in login page"
              />
            </span>
            <br />
            <Link to="/demo">
              <Button
                style={{
                  padding: "0",
                }}
                type="link"
              >
                <span
                  style={{
                    fontSize: "1.2rem",
                    color: "#fff",
                    textDecoration: "underline",
                  }}
                >
                  {intl.formatMessage(
                    translations.ui.authentication.learnAboutDemoAction
                  )}
                </span>
              </Button>
            </Link>
          </div>
        </div>
      </Row>
    </>
  );
};

export default LoginPage;
