import { Tag } from "antd";
import { useQuery } from "react-query";
import api from "../../../api/api";
import { pickFontColorBasedOnBackgroundColor } from "../../../utils/text-color.utils";

type Props = {
  categoryId: string;
};

export default ({ categoryId }: Props) => {
  const categoryQuery = useQuery({
    queryKey: ["categories", categoryId],
    queryFn: async () => {
      return await api.categories.getOne(categoryId);
    },
  });

  const color = categoryQuery.isSuccess ? categoryQuery.data.color : "default";
  const textColor = categoryQuery.isSuccess
    ? pickFontColorBasedOnBackgroundColor(
        categoryQuery.data.color,
        "#fff",
        "#000"
      )
    : undefined;
  const title = categoryQuery.isSuccess ? categoryQuery.data.title : "";

  return (
    <Tag color={color} style={{ color: textColor }}>
      {title}
    </Tag>
  );
};
