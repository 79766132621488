import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { ItemImage } from "../../ItemImage";

type Props = {
  expandedRows: string[];
};

const ThumbnailTableColumn = (props: Props) => {
  return {
    title: "",
    dataIndex: "",
    key: "thumbnail",
    // width: "10%",
    align: "center" as const,
    ellipsis: true,
    onCell: (data: any, index: any) => ({
      colSpan: !!props.expandedRows.find((id) => id === data.id) ? 0 : 1,
    }),
    render: (item: ItemPopulated) => (
      <ItemImage
        item={item}
        customStyle={{ height: "3rem", width: "3rem" }}
        iconSize="small"
      ></ItemImage>
    ),
  };
};

export default ThumbnailTableColumn;
