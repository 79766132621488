import { Row, Image } from "antd";
import bimfilesLogo from "../../../../assets/images/bimfiles-logo.png";
import PasswordStartResetForm from "../PasswordStartResetPage/PasswordStartResetForm";

const PasswordStartResetPage = () => {
  return (
    <>
      <Row className="login-page-div">
        <div className="login-form-logo-div">
          <div className="bimfiles-logo-img">
            <Image
              style={{ height: "18rem", width: "18rem" }}
              src={bimfilesLogo}
              preview={false}
            ></Image>
          </div>
          <PasswordStartResetForm />
        </div>
      </Row>
    </>
  );
};

export default PasswordStartResetPage;
