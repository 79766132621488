import { defineMessages } from "react-intl";

const filters = defineMessages({
  titleFilter: {
    id: "ui.itemTable.filters.title",
    defaultMessage: "Title",
    description: "Title filter",
  },
  filesFilter: {
    id: "ui.itemTable.filters.files",
    defaultMessage: "Files",
    description: "Files filter",
  },
  descriptionFilter: {
    id: "ui.itemTable.filters.description",
    defaultMessage: "Description",
    description: "Description filter",
  },
  sizeFilter: {
    id: "ui.itemTable.filters.size",
    defaultMessage: "Size",
    description: "Size filter",
  },
  typesFilter: {
    id: "ui.itemTable.filters.types",
    defaultMessage: "Types",
    description: "Types filter",
  },
  libraryFilter: {
    id: "ui.itemTable.filters.library",
    defaultMessage: "Library",
    description: "Library filter",
  },
  tagsFilter: {
    id: "ui.itemTable.filters.tags",
    defaultMessage: "Tags",
    description: "Tags filter",
  },
  ostCategoryFilter: {
    id: "ui.itemTable.filters.ost-category",
    defaultMessage: "OST Category",
    description: "OST Category filter",
  },
  placementMethodFilter: {
    id: "ui.itemTable.filters.placement-method",
    defaultMessage: "Placement Method",
    description: "Placement Method filter",
  },
  revitVersionFilter: {
    id: "ui.itemTable.filters.revit-version",
    defaultMessage: "Revit Version",
    description: "Revit Version filter",
  },
  authorFilter: {
    id: "ui.itemTable.filters.created-by",
    defaultMessage: "Author",
    description: "Author filter",
  },
  stateFilter: {
    id: "ui.itemTable.filters.state",
    defaultMessage: "State",
    description: "State filter",
  },
  approved: {
    id: "ui.itemTable.filters.approved",
    defaultMessage: "Approved",
    description: "Approved toggle option",
  },
  unapproved: {
    id: "ui.itemTable.filters.unapproved",
    defaultMessage: "Unapproved",
    description: "Unapproved toggle option",
  },
  denied: {
    id: "ui.itemTable.filters.denied",
    defaultMessage: "Denied",
    description: "Denied toggle option",
  },
  unapprovedItemsCount: {
    id: "ui.itemTable.filters.unapproved-items-count",
    defaultMessage:
      "{items} Unapproved {items, plural, one {Item} other {Items}}",
    description: "Unapproved items count",
  },
});

export default filters;
