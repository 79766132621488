import { useAdminStore } from "../../../../state/adminStore";

export const openChangeUserPasswordModal = (selectedUserId: string) => {
  useAdminStore.setState({
    changeUserPasswordSelectedUserId: selectedUserId,
  });
};

export const closeChangeUserPasswordModal = () => {
  useAdminStore.setState({
    changeUserPasswordSelectedUserId: undefined,
  });
};
