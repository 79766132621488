import { UndoOutlined } from "@ant-design/icons";
import { Tag, Tooltip, Typography } from "antd";
import { useIntl } from "react-intl";
import translations from "../../../../translations/constants/translations";

type Props = {
  title: string;
  isResetable: boolean;
  onReset: () => void;
  required?: boolean;
};

export default (props: Props) => {
  const intl = useIntl();
  const required = props.required ?? false;

  return (
    <Typography.Title
      level={4}
      style={{ display: "flex", alignItems: "center", flexDirection: "row" }}
    >
      {!required && props.title}
      {required && (
        <div>
          <Tooltip
            color="#f7f8fc"
            title={intl.formatMessage(
              translations.ui.meta.fields.fieldRequiredTitle
            )}
          >
            {props.title}
            <span style={{ marginLeft: "4px", color: "#e53935" }}>*</span>
          </Tooltip>
        </div>
      )}
      {props.isResetable && (
        <Tag
          style={{ marginLeft: ".25rem", cursor: "pointer" }}
          icon={<UndoOutlined />}
          color="red"
          onClick={props.onReset}
        >
          Reset
        </Tag>
      )}
    </Typography.Title>
  );
};
