import { Button, Modal, Typography } from "antd";
import { useCreateOrUpdateItemStore } from "../../../../state/createOrUpdateItemStore";
import { useIntl } from "react-intl";
import translations from "../../../../translations/constants/translations";
import { useCreateOrUpdateMultipleFiles } from "../../../../hooks/useCreateOrUpdateMultipleFiles";

type Props = {
  index: number;
};

const UpdateFileErrorModal = (props: Props) => {
  const intl = useIntl();

  const { getUpload, setUpload } = useCreateOrUpdateMultipleFiles();

  const upload = getUpload(props.index);

  if (!upload) return null;

  const { updatingFilesError, updatingFilesGenericError } = upload;

  const onClose = () => {
    setUpload(props.index, {
      updatingFilesError: undefined,
      updatingFilesGenericError: undefined,
    });
  };

  const title =
    updatingFilesError === "no-revit"
      ? intl.formatMessage(translations.ui.upload.errors.noRevitTitle)
      : updatingFilesError === "nothing-selected"
      ? intl.formatMessage(translations.ui.upload.errors.nothingSelectedTitle)
      : updatingFilesError === "wrong-selection"
      ? intl.formatMessage(translations.ui.upload.errors.wrongSelectionTitle)
      : updatingFilesError === "generic"
      ? intl.formatMessage(translations.ui.upload.errors.title)
      : undefined;

  const description =
    updatingFilesError === "no-revit"
      ? intl.formatMessage(translations.ui.upload.errors.noRevitDescription)
      : updatingFilesError === "nothing-selected"
      ? intl.formatMessage(
          translations.ui.upload.errors.nothingSelectedDescription
        )
      : updatingFilesError === "wrong-selection"
      ? intl.formatMessage(
          translations.ui.upload.errors.wrongSelectionDescription
        )
      : updatingFilesError === "generic"
      ? updatingFilesGenericError
      : undefined;

  return (
    <Modal
      destroyOnClose
      zIndex={1001}
      centered={true}
      closable={true}
      width={"75%"}
      style={{
        minWidth: "600px",
      }}
      onCancel={onClose}
      onOk={onClose}
      footer={
        <Button type="primary" onClick={onClose}>
          {intl.formatMessage(translations.ui.generic.close)}
        </Button>
      }
      open={updatingFilesError !== undefined}
      title={title}
    >
      <Typography.Text>{description}</Typography.Text>
    </Modal>
  );
};

export default UpdateFileErrorModal;
