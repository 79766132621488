import { CombinedAccessToken, Organisation, Response } from "@formitas-ag/bimfiles-types";
import { useAuthStore } from "../state/authStore";
import axiosService from "./fetch/axios.service";
import {
  accessTokenObservable,
  selectedOrganisationIdObservable,
} from "./utils/auth.utils";
import api from "./api";
import { getLogger } from "../utils/logger.utils";
import { errorRenderer } from "./utils/errorThrower";
import * as Sentry from "@sentry/react";
import jwtDecode from "jwt-decode";

const logger = getLogger("AuthApi");

const auth = {
  /**
   * Login with email and password
   * @param email the email
   * @param password the password
   * @returns the access token
   */
  login: async (email: string, password: string): Promise<string> => {
    const response = await axiosService.post<
      Response<{
        organisations: Pick<
          Organisation,
          "id" | "title" | "description" | "host" | "createdAt" | "flags"
        >[];
      }>
    >("/auth/login", { email, password });

    if (response.data && response.data.success) {
      const organisations = response.data.result.organisations;

      await api.auth._onOrganisationReceive(organisations);
    }

    return Promise.reject(response.status);
  },

  _onOrganisationReceive: async (
    organisations: Pick<
      Organisation,
      "id" | "title" | "description" | "host" | "createdAt" | "flags"
    >[]
  ) => {
    logger.debug(`Received ${organisations.length} organisations`);

    if (organisations.length === 1) {
      selectedOrganisationIdObservable.set(organisations[0].id);

      useAuthStore.setState({
        selectedOrganisationId: organisations[0].id,
        possibleOrganisations: organisations,
      });
    } else {
      useAuthStore.setState({
        possibleOrganisations: organisations,
      });
    }
  },

  getOrganisations: async (): Promise<
    Pick<
      Organisation,
      "id" | "title" | "description" | "host" | "createdAt" | "flags"
    >[]
  > => {
    try {
      const response = await axiosService.get<
        Response<
          Pick<
            Organisation,
            "id" | "title" | "description" | "host" | "createdAt" | "flags"
          >[]
        >
      >("/auth/organisations");

      if (response.data && response.data.success) {
        return response.data.result;
      }

      return Promise.reject(response.status);
    } catch (error) {
      return Promise.reject(errorRenderer(error));
    }
  },

  /**
   * Logout from the application
   */
  logout: async (onlyLocalLogout: boolean = false): Promise<void> => {
    if (!onlyLocalLogout) {
      await axiosService.post<Response<{}>>("/auth/logout");
    }

    Sentry.setUser(null);

    accessTokenObservable.set(null);

    selectedOrganisationIdObservable.set(null);

    useAuthStore.setState({
      possibleOrganisations: [],
      user: undefined,
      loggedIn: false,
    });
  },

  /**
   * Refreshes the access token
   * @returns the new access token
   */
  refreshAccessTokens: async (): Promise<string> => {
    const selectedOrganisationId = selectedOrganisationIdObservable.get();

    if (selectedOrganisationId === null)
      return Promise.reject("No organisation selected").catch(() => {
        return "No organisation selected";
      });

    try {
      const response = await axiosService.get<
        Response<{ accessToken: string }>
      >("/auth/refresh?selectedOrganisationId=" + selectedOrganisationId);

      if (response.data && response.data.success) {
        const newAccessToken = response.data.result.accessToken;

        const decodedToken = jwtDecode<CombinedAccessToken>(newAccessToken);

        Sentry.setUser({
          id: decodedToken.user.id,
          email: decodedToken.user.email,
          selectedOrganisationId: decodedToken.selectedOrganisationId,
          username: decodedToken.user.preName + " " + decodedToken.user.name,
        });

        accessTokenObservable.set(newAccessToken);

        return newAccessToken;
      }
      return Promise.reject(response.status);
    } catch (error) {
      //if the refresh token is invalid we remove the possible organisations
      if (useAuthStore.getState().possibleOrganisations.length > 0) {
        useAuthStore.setState({
          ...useAuthStore.getState(),
          possibleOrganisations: [],
        });
      }
      accessTokenObservable.set(null);
      return Promise.reject(errorRenderer(error));
    }
  },

  requestDemoAccess: async (
    email: string,
    fullName: string,
    joinNewsletter: boolean,
    lang?: "de" | "en"
  ): Promise<boolean> => {
    const response = await axiosService.post<Response<{}>>("/auth/demo", {
      email,
      fullName,
      joinNewsletter,
      lang,
    });

    if (response.data && response.data.success) {
      return Promise.resolve(true);
    }

    return Promise.resolve(false);
  },
};

export default auth;
