import { useState } from "react";
import { Alert, Space, Tag, Tooltip, Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import UpdateFileModal from "../Modal/CreateOrUpdateFileModal";
import UpdateFileErrorModal from "../Modal/CreateOrUpdateFileErrorModal";
import FileRoleTag from "../../Timeline/Common/FileRoleTag";
import useGetFileMeta from "../../../../hooks/useCreateOrUpdateFiles/useGetFileMeta";
import FileProperties from "../Modal/CreateOrUpdateFileModal/FileProperties";
import FileUploadProgress from "../Common/FileUploadProgress";
import ItemSidecar from "../Common/ItemSidecar";
import { useCreateOrUpdateMultipleFiles } from "../../../../hooks/useCreateOrUpdateMultipleFiles";
import useUpdateFiles from "../../../../hooks/useCreateOrUpdateFiles";
import translations from "../../../../translations/constants/translations";
import FileListFooter from "../Modal/CreateOrUpdateFileModal/FileListFooter";
import UpdateHeader from "../Common/ItemHeader";
import { useCreateOrUpdateItemStore } from "../../../../state/createOrUpdateItemStore";
import { usePlatform } from "../../../../hooks/usePlatform";

type Props = {
  mode: "single" | "multiple";
  index: number;
  isSingleUploadSaveButtonEnabled?: boolean;
};

const ItemFiles = (props: Props) => {
  const intl = useIntl();
  const { getUpload } = useCreateOrUpdateMultipleFiles();
  const { getRole, getProperties } = useGetFileMeta();
  const {
    onDropLocalFileSingleUpload,
    onClickRevitExport,
    resetUpdatingFiles,
  } = useUpdateFiles();
  const platform = usePlatform();

  const uploadMode = useCreateOrUpdateItemStore((state) => state.mode);

  const properties = getProperties(undefined, props.index);

  const [openModal, setOpenModal] = useState(false);

  const areFilesResetable =
    (getUpload(props.index)?.updatingFiles ?? []).filter(
      (f) => f.state !== "default"
    ).length > 0;
  const areFilesEditable =
    (getUpload(props.index)?.updatingFiles ?? []).length > 0;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {props.mode === "single" && (
        <UpdateHeader
          title={intl.formatMessage(translations.ui.meta.fields.filesName)}
          isResetable={areFilesResetable}
          onReset={() => {
            resetUpdatingFiles(props.index);
          }}
          required={true}
        />
      )}

      <div style={{ marginBottom: ".5rem" }}>
        {getUpload(props.index) &&
          getUpload(props.index)!
            .updatingFiles.filter((f) => f.state !== "removed")
            .map((file) => {
              const roleResult = getRole(props.index, file);

              if (roleResult.result === "success") {
                if (
                  roleResult.role === "sidecar-automatic" ||
                  roleResult.role === "thumbnail"
                )
                  return null;

                return <FileRoleTag key={file.id} role={roleResult.role} />;
              } else {
                return <FileRoleTag key={file.id} role={"other"} />;
              }
            })}

        <Tooltip
          title={
            areFilesEditable
              ? undefined
              : intl.formatMessage(
                  translations.ui.meta.fields.filesEditButtonDisabledTooltip
                )
          }
          color="white"
        >
          <Tag
            color={areFilesEditable ? "blue" : "default"}
            style={{ cursor: areFilesEditable ? "pointer" : "not-allowed" }}
            icon={<EditOutlined />}
            onClick={() => (areFilesEditable ? setOpenModal(true) : null)}
          >
            {intl.formatMessage(translations.ui.meta.fields.filesEditButton)}
          </Tag>
        </Tooltip>

        {props.mode === "single" && (
          <FileUploadProgress mode={props.mode} index={props.index} />
        )}
      </div>

      {props.mode === "single" && (
        <>
          <Space direction="vertical">
            <FileListFooter
              onClickRevitExport={() => onClickRevitExport(props.index)}
              onDropLocalFiles={(fileList) =>
                onDropLocalFileSingleUpload(fileList)
              }
              highlightLocalUpload={
                props.isSingleUploadSaveButtonEnabled == false &&
                platform === "browser"
              }
              highlightRevitImport={
                props.isSingleUploadSaveButtonEnabled == false &&
                platform === "revit"
              }
            />
            {uploadMode === "create" &&
              platform === "revit" &&
              !areFilesEditable && (
                <Alert
                  message={intl.formatMessage(
                    translations.ui.upload.components
                      .fileListFooterMultipleFamiliesInfoTitle
                  )}
                  description={intl.formatMessage(
                    translations.ui.upload.components
                      .fileListFooterMultipleFamiliesInfoDescription
                  )}
                  type="info"
                  closable
                />
              )}
          </Space>
        </>
      )}

      {props.mode === "single" && properties && (
        <>
          <Typography.Title level={4}>
            {intl.formatMessage(
              translations.ui.meta.fields.filesParametersName
            )}
          </Typography.Title>

          <FileProperties properties={properties} />

          <Space
            size={4}
            wrap
            style={{
              marginTop: ".5rem",
            }}
          >
            <ItemSidecar {...props} />
          </Space>
        </>
      )}

      <UpdateFileModal open={openModal} setOpen={setOpenModal} {...props} />
      <UpdateFileErrorModal index={props.index} />
    </div>
  );
};

export default ItemFiles;
