import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Alert,
  Spin,
  Divider,
  Typography,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  useNavigate,
  useLocation,
  Link,
  useSearchParams,
} from "react-router-dom";
import {
  defineMessage,
  defineMessages,
  FormattedMessage,
  useIntl,
} from "react-intl";
import api from "../../../../api/api";

const LoginForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const from = routerLocation.state?.from?.pathname || "/homepage";
  const [searchParams, setSearchParams] = useSearchParams();
  const action = searchParams ? searchParams.get("action") : null;

  const [failed, setFailed] = useState(false);
  const [loading, setLoading] = useState(false);

  const azureAuthUrl =
    (process.env.REACT_APP_BACKEND_BASE_URL !== undefined
      ? process.env.REACT_APP_BACKEND_BASE_URL
      : window.location.origin.toString()) + "/api/auth/azure";

  /**
   * Called when the form is filled correctly
   * @param values from the form (email and password)
   */
  const onFinish = async (values: any) => {
    setLoading(true);

    try {
      await api.auth.login(values.email, values.password);

      setLoading(false);
      navigate(from, { replace: true });
    } catch (error) {
      setFailed(true);
      setLoading(false);
      console.log(error);
    }
  };

  /**
   * Runs when the form fails to submit
   * @param errorInfo of the failed form
   */
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const togglePersist = () => {
    //TODO: implement persistance with obversable
  };

  // Sets persist in the local storage if changed
  useEffect(() => {
    localStorage.setItem("persist", true ? "true" : "false");
  }, [true]);

  const messages = defineMessages({
    emailPlaceholder: {
      id: "auth.login.field.email.placeholder",
      defaultMessage: "name@company.de",
      description: "Placeholder for email input",
    },
    passwordPlaceholder: {
      id: "auth.login.field.password.placeholder",
      defaultMessage: "Password",
      description: "Placeholder for password input",
    },
  });

  const loginButtonMessage = defineMessage({
    id: "auth.login.button.login",
    defaultMessage: "Login",
    description: "Button to login",
  });

  const emailFieldRequiredRuleMessage = defineMessage({
    id: "auth.login.field.email.required",
    defaultMessage: "Please input your email",
    description: "Message for the required email field",
  });

  const emailFieldFormatIncorrectRuleMessage = defineMessage({
    id: "auth.login.field.email.format",
    defaultMessage: "Incorrect email format",
    description: "Message for the format for the email field",
  });

  const passwordFieldRequiredRuleMessage = defineMessage({
    id: "auth.login.field.password.required",
    defaultMessage: "Please input your password",
    description: "Message for the required password field",
  });

  return (
    <div style={{ margin: "0rem 1rem 0rem 1rem" }}>
      <Button
        size="large"
        href={azureAuthUrl}
        type="text"
        style={{
          height: "3rem",
          width: "100%",
          border: "0.2rem solid #fff",
          borderRadius: 0,
          color: "#fff",
          fontSize: "1.2rem",
          textTransform: "uppercase",
        }}
      >
        <FormattedMessage
          id="auth.login.button.login_with_microsoft"
          defaultMessage="Login With Microsoft"
          description="Button to login with microsoft"
        />
      </Button>

      <Divider
        style={{
          color: "#fff",
          borderColor: "#fff",
          fontSize: "1.2rem",
          textTransform: "uppercase",
        }}
      >
        <FormattedMessage
          id="auth.login.or"
          defaultMessage="or"
          description="The word or in the middle of the divider"
        />
      </Divider>

      {action === "account.activate.success" && (
        <div
          style={{
            width: "100%",
            background: "#fff",
            fontSize: "1.2rem",
            padding: "1rem",
            marginBottom: "0.4rem",
          }}
        >
          <Typography.Text type="secondary">
            <FormattedMessage
              id="auth.login.activate.success"
              description="Message when the account is activated successfully"
              defaultMessage="Your BIMfiles account has been activated successfully."
            />
          </Typography.Text>
        </div>
      )}

      {action === "account.password-reset.success" && (
        <div
          style={{
            width: "100%",
            background: "#fff",
            fontSize: "1.2rem",
            padding: "1rem",
            marginBottom: "0.4rem",
          }}
        >
          <Typography.Text type="secondary">
            <FormattedMessage
              id="auth.login.password-reset.success"
              description="Message when the account password is reset successfully"
              defaultMessage="Your BIMfiles account password has been reset successfully."
            />
          </Typography.Text>
        </div>
      )}

      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: intl.formatMessage(emailFieldRequiredRuleMessage),
            },
            {
              // eslint-disable-next-line
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
              message: intl.formatMessage(emailFieldFormatIncorrectRuleMessage),
            },
          ]}
        >
          <Input
            size="large"
            style={{ borderRadius: 0, height: "3rem" }}
            placeholder={intl.formatMessage(messages.emailPlaceholder)}
            spellCheck={false}
          />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: "0.4rem" }}
          name="password"
          rules={[
            {
              required: true,
              message: intl.formatMessage(passwordFieldRequiredRuleMessage),
            },
          ]}
        >
          <Input.Password
            size="large"
            style={{ borderRadius: 0, height: "3rem" }}
            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
          />
        </Form.Item>

        <Form.Item
          name="remember"
          valuePropName="checked"
          style={{ marginBottom: "1rem" }}
        >
          <Checkbox
            style={{ color: "#fff", fontSize: "0.95rem" }}
            onChange={togglePersist}
            checked={true}
          >
            <FormattedMessage
              id="auth.login.field.remember"
              defaultMessage="Stay Logged In"
              description="Label for the remember me checkbox"
            />
          </Checkbox>
        </Form.Item>

        <Link to="/reset">
          <Button
            type="text"
            size="middle"
            style={{
              width: "100%",
              color: "#fff",
              fontSize: "0.95rem",
              marginBottom: "1rem",
            }}
          >
            <FormattedMessage
              id="auth.login.action.password-reset"
              description="Button to reset the account password"
              defaultMessage={"Forgot Password?"}
            />
          </Button>
        </Link>

        {failed ? (
          <Alert
            message={
              <FormattedMessage
                id="auth.login.alert.failed_login"
                defaultMessage="Incorrect email or password"
                description="Alert message when login fails because email or password are incorrect"
              />
            }
            type="error"
          />
        ) : null}

        <div className="login-form-button-spinner">
          <Form.Item>
            <Button
              size="large"
              type="text"
              style={{
                height: "3rem",
                width: "100%",
                border: "0.2rem solid #fff",
                borderRadius: 0,
                color: "#fff",
                fontSize: "1.2rem",
                textTransform: "uppercase",
              }}
              htmlType="submit"
            >
              {loading ? (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                ></Spin>
              ) : (
                intl.formatMessage(loginButtonMessage)
              )}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default LoginForm;
