import { useEffect, useRef, useState } from "react";
import { getOne } from "../api/utils/endpoint.utils";
import { errorRenderer } from "../api/utils/errorThrower";

export default function useApiGetOne<dataType>(
  serviceName: string,
  id: string | null
): [dataType, boolean, string | undefined] {
  const ref = useRef(0);
  const [data, setData] = useState<dataType>({} as dataType);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<undefined | string>(undefined);

  useEffect(() => {
    // cancel the initial data fetching in case we did not start yet
    clearTimeout(ref.current);
    // self awareness of being unmounted
    ref.current = 0;
  }, []);

  useEffect(() => {
    if (id) {
      setLoaded(false);
      ref.current = setTimeout(() => {
        getOne<dataType>(serviceName, id)
          .then((response) => {
            if (ref.current) {
              setData(response);
              setLoaded(true);
            }
          })
          .catch((e) => {
            console.log("Caught error response", errorRenderer(e));
            setData({} as dataType);
            setLoaded(false);
            setError(errorRenderer(e));
          });
      }, 0) as unknown as number;
    } else {
      // we just assume we are done when we do not have an id
      setData({} as dataType);
      setLoaded(true);
    }
  }, [serviceName, id]);

  return [data, loaded, error];
}
