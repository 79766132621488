import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { useViewStore } from "../../../state/viewStore";

export default () => {
  const intl = useIntl();
  const open = useViewStore((state) => state.openRequiresRevitModal) || false;
  const detail = useViewStore((state) => state.requiresRevitModalDetail);

  const onClose = () => {
    useViewStore.setState({
      openRequiresRevitModal: false,
      requiresRevitModalDetail: undefined,
    });
  };

  const messages = defineMessages({
    title: {
      id: "modal.revit-required.title",
      defaultMessage: "Revit is required",
      description:
        "The title of the modal informing the user that he needs to be using Revit",
    },
    defaultDescription: {
      id: "modal.revit-required.default-description",
      defaultMessage:
        "You need to be using the BIMfiles Revit plugin to perform this action.",
      description:
        "The default description of the modal informing the user that he needs to be using Revit",
    },
    ok: {
      id: "modal.revit-required.ok",
      defaultMessage: "Understood",
      description:
        "The text of the button to close the modal informing the user that he needs to be using Revit",
    },
  });

  return (
    <Modal
      centered
      zIndex={1500}
      title={
        <>
          <ExclamationCircleOutlined
            style={{ marginRight: ".5rem", color: "red" }}
          />
          {intl.formatMessage(messages.title)}
        </>
      }
      open={open}
      closable
      onCancel={onClose}
      footer={
        <Button onClick={onClose}>{intl.formatMessage(messages.ok)}</Button>
      }
    >
      <Typography.Text style={{ marginBottom: 0 }}>
        {detail && <>{detail}</>}
        {!detail && <>{intl.formatMessage(messages.defaultDescription)}</>}
      </Typography.Text>
    </Modal>
  );
};
