import { LoadingOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import { useIntl } from "react-intl";
import { UserRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";
import translations from "../../../translations/constants/translations";

type Props = {
  role?: UserRoleEnum;
};

export default ({ role }: Props) => {
  const intl = useIntl();

  const color =
    role === UserRoleEnum.USER
      ? "#888888"
      : role === UserRoleEnum.MODERATOR
      ? "#ffb300"
      : "default";
  const icon = role === null ? <LoadingOutlined spin /> : undefined;
  const content =
    role === UserRoleEnum.USER
      ? intl.formatMessage(translations.ui.generic.roleUser)
      : role === UserRoleEnum.MODERATOR
      ? intl.formatMessage(translations.ui.generic.roleModerator)
      : intl.formatMessage(translations.ui.admin.users.roleRenderLoading);

  return (
    <Tag color={color} icon={icon}>
      {content}
    </Tag>
  );
};
