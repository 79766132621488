import React, { useState } from "react";
import Modal from "antd/es/modal";
import { useRevit, LoadFamilyType, getRevit } from "../../../../api/revit";
import { message, notification } from "antd";
import { TypeCatalogTable } from "./TypeCatalogTable";
import { defineMessages, useIntl } from "react-intl";

type TypeCatalogModalProps = {
  type: "view" | "select";
  filesWithBase64: LoadFamilyType | null;
  sidecarData: string | null;
  sidecarType: "sidecar" | "sidecar-automatic";
  visible: boolean;
  clearImportHandler: () => void;
};

export const TypeCatalogModal: React.FC<TypeCatalogModalProps> = (props) => {
  const { sidecarData, visible, filesWithBase64, clearImportHandler } = props;
  const intl = useIntl();
  const [currentSelection, setCurrentSelection] = useState<any[]>([]);

  const messages = defineMessages({
    sidecarTitle: {
      id: "component.sidecar.title",
      defaultMessage: "Type Catalog",
      description: "Title of the type catalog modal",
    },
    sidecarAutomaticTitle: {
      id: "component.sidecar-automatic.title",
      defaultMessage: "Types",
      description:
        "Title of the type catalog modal of a sidecar-automatic file",
    },
    close: {
      id: "component.sidecar.close",
      defaultMessage: "Close",
      description: "Close button of the type catalog modal",
    },
    ok: {
      id: "component.sidecar.ok",
      defaultMessage: "Import {count} types",
      description: "Ok button of the type catalog modal",
    },
    loading: {
      id: "component.sidecar.loading",
      defaultMessage: "{count} types are loading",
      description:
        "Message displayed when the sidecar file is getting imported",
    },
    loadingAll: {
      id: "component.sidecar.loading-all",
      defaultMessage: "The file is importing",
      description:
        "Message displayed when the entire sidecar file is getting imported",
    },
    error: {
      id: "component.sidecar.error",
      defaultMessage: "An error occured while trying to import the types",
      description: "Message displayed when an error occures",
    },
    empty: {
      id: "component.sidecar.empty",
      defaultMessage: "No types exist",
      description: "Message displayed when no types are existing",
    },
  });

  return (
    <Modal
      title={
        props.sidecarType === "sidecar"
          ? intl.formatMessage(messages.sidecarTitle)
          : intl.formatMessage(messages.sidecarAutomaticTitle)
      }
      open={visible}
      cancelText={intl.formatMessage(messages.close)}
      destroyOnClose={true}
      maskClosable={true}
      okButtonProps={{
        disabled:
          currentSelection.length > 0 ? false : !currentSelection.length,
        hidden: props.type === "view",
      }}
      okText={intl.formatMessage(messages.ok, {
        count: currentSelection.length,
      })}
      onOk={() => {
        message.loading(
          currentSelection.length
            ? intl.formatMessage(messages.loading, {
                count: currentSelection.length,
              })
            : intl.formatMessage(messages.loadingAll)
        );

        try {
          // Load files to import to revit
          if (filesWithBase64 !== null) {
            getRevit().loadFamily(filesWithBase64, {
              types: currentSelection.map((record) => record.name),
            });
          }
        } catch (e) {
          notification.error({ message: intl.formatMessage(messages.error) });
        } finally {
          setCurrentSelection([]);
          clearImportHandler();
        }
      }}
      onCancel={() => {
        setCurrentSelection([]);
        clearImportHandler();
      }}
      closable={true}
      width="80%"
      centered
      bodyStyle={{
        overflow: "auto",
        height: "70vh",
      }}
      zIndex={1050}
    >
      <TypeCatalogTable
        type={props.type}
        sidecarData={sidecarData}
        setCurrentSelection={setCurrentSelection}
      />
    </Modal>
  );
};
