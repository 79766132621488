import { List, Space, Typography } from "antd";
import { OverwriteItemStore } from "../../../../../state/overwriteItemStore";
import OverwriteItemListItem from "./OverwriteItemListItem";
import { useViewStore } from "../../../../../state/viewStore";
import useGetFileMeta from "../../../../../hooks/useCreateOrUpdateFiles/useGetFileMeta";
import useOverwriteItem from "../../../../../hooks/useOverwriteItem";
import { useIntl } from "react-intl";
import FileProperties from "../CreateOrUpdateFileModal/FileProperties";
import ItemSidecar from "../../Common/ItemSidecar";
import translations from "../../../../../translations/constants/translations";
import { UpdatingFile } from "../../../../../utils/types/updateFilesTypes";

type Props = {
  elements: Required<OverwriteItemStore>["currentOverwritingFile"];
};

const OverwriteItemList = (props: Props) => {
  const intl = useIntl();
  const { currentOverwritingFile } = useOverwriteItem();
  const selectedToUpdateItem = useViewStore(
    (state) => state.selectedToBeUpdatedItem
  );
  const { getProperties, getRole } = useGetFileMeta();
  const items =
    props.elements.type === "single"
      ? [
          {
            file: props.elements.file,
            overwrittenFile: props.elements.overwrittenFile,
          },
        ]
      : props.elements.elements;

  const isCurrentOverwritingFileAnAddonFile =
    (currentOverwritingFile?.type === "single" &&
      currentOverwritingFile?.file.addedFile.source === "addon") ||
    (currentOverwritingFile?.type === "multiple" &&
      currentOverwritingFile?.elements.some(
        (element) => element.file.addedFile.source === "addon"
      ));

  const propertySources =
    selectedToUpdateItem &&
    selectedToUpdateItem?.parameters &&
    isCurrentOverwritingFileAnAddonFile
      ? {
          existingParameters: selectedToUpdateItem?.parameters as {
            key: string;
            value: string;
          }[],
          newParameters:
            currentOverwritingFile?.type === "single"
              ? currentOverwritingFile?.file.addedFile.source === "addon"
                ? (currentOverwritingFile?.file.addedFile.parameters as {
                    key: string;
                    value: string;
                  }[])
                : []
              : currentOverwritingFile?.elements[0].file.addedFile.source ===
                "addon"
              ? (currentOverwritingFile?.elements[0].file.addedFile
                  .parameters as { key: string; value: string }[])
              : [],
        }
      : undefined;

  const properties =
    propertySources !== undefined ? getProperties(propertySources) : undefined;

  return (
    <List
      style={{
        width: "100%",
      }}
      header={
        properties ? (
          <>
            <Typography.Title level={5}>
              {intl.formatMessage(
                translations.ui.upload.components.overwriteItemListTitle
              )}
            </Typography.Title>
            <FileProperties properties={properties} />

            <Space
              size={4}
              wrap
              style={{
                marginTop: ".5rem",
              }}
            >
              <ItemSidecar mode="single" />
            </Space>
          </>
        ) : undefined
      }
      bordered
      dataSource={items}
      renderItem={(item) => {
        return (
          <OverwriteItemListItem
            index={currentOverwritingFile?.index ?? -1}
            {...item}
          />
        );
      }}
    />
  );
};

export default OverwriteItemList;
