import { Table, Typography } from "antd";
import Column from "antd/es/table/Column";
import { CategoryRole } from "@formitas-ag/bimfiles-types/lib/permission";
import { useState } from "react";
import { CategoryRoleSwitch } from "./CategoryRoleSwitch";
import { CategoryRoleRender } from "../Common/CategoryRoleRender";
import { useIntl } from "react-intl";
import translations from "../../../translations/constants/translations";

type Props = {
  keyPrefix: string;
  data: AbstractRoleTableData[];
  onSingleRoleChange: (id: string, role: CategoryRole) => Promise<void>;
  onAllRolesChange: (role: CategoryRole) => Promise<void>;

  //features of the abstract role table
  enableFeatures?: {
    enableFinalPermissions?: boolean;
    enableOverwritePermissions?: boolean;
    enableChangingPermissions?: boolean;
  };
};

export type AbstractRoleTableData = {
  id: string;
  name: string | React.ReactNode;
  overwriteRole?: CategoryRole;
  role?: CategoryRole;
};

const titleRenderer = (text: string, centered?: boolean) => {
  return (
    <Typography.Title
      level={5}
      style={{ textAlign: centered ? "center" : "left", margin: 0 }}
    >
      {text}
    </Typography.Title>
  );
};

export const AbstractRoleTable = (props: Props) => {
  const intl = useIntl();

  //contains any rows that are currently updating preventing editing while they are updating
  const [loadingRows, setLoadingRows] = useState<React.Key[]>([]);

  const [dataFilter, setDataFilter] = useState();

  const onChangeOneRole = async (id: string, role: CategoryRole) => {
    setLoadingRows((prev) => [...prev, id]);
    await props.onSingleRoleChange(id, role);
    setLoadingRows((prev) => prev.filter((rowId) => rowId !== id));
  };

  return (
    <Table
      sticky
      dataSource={props.data}
      rowKey={(row) => props.keyPrefix + row.id}
      pagination={false}
    >
      <Column
        title={titleRenderer(
          intl.formatMessage(
            translations.ui.admin.users.abstractRoleTableNameColumn
          )
        )}
        dataIndex="name"
        key="name"
      />
      {props.enableFeatures?.enableOverwritePermissions && (
        <Column
          title={titleRenderer(
            intl.formatMessage(
              translations.ui.admin.users.abstractRoleTableBasePermissionsColumn
            ),
            true
          )}
          width={"20%"}
          render={(_, record: AbstractRoleTableData) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CategoryRoleRender role={record.overwriteRole!} />
              </div>
            );
          }}
        />
      )}

      <Column
        title={titleRenderer(
          intl.formatMessage(
            translations.ui.admin.users
              .abstractRoleTableSpecificPermissionsColumn
          ),
          true
        )}
        width={"30%"}
        render={(_, record: AbstractRoleTableData) => {
          const role = record.role || 0;

          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CategoryRoleSwitch
                mode="single"
                record={record}
                onChange={onChangeOneRole}
                role={role}
                isParentLoading={loadingRows.includes(record.id)}
              />
            </div>
          );
        }}
      />

      <Column
        title={titleRenderer(
          intl.formatMessage(
            translations.ui.admin.users
              .abstractRoleTableResultingPermissionsColumn
          ),
          true
        )}
        width={"20%"}
        render={(_, record: AbstractRoleTableData, index: number) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CategoryRoleRender
                role={(record.role || 0) + (record.overwriteRole || 0)}
              />
            </div>
          );
        }}
      />
    </Table>
  );
};
