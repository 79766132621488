import React from "react";
import { Alert, Table } from "antd";
import { defineMessages, useIntl } from "react-intl";
import useTypeCatalog from "../../../../hooks/useTypeCatalog";

type TypeCatalogTableProps = {
  type: "view" | "select";
  sidecarData: string | null;
  setCurrentSelection: React.Dispatch<React.SetStateAction<any[]>>;
};

export const TypeCatalogTable: React.FC<TypeCatalogTableProps> = (props) => {
  const intl = useIntl();

  const messages = defineMessages({
    loading: {
      id: "component.sidecar-table.loading",
      defaultMessage: "The type catalog is loading",
      description: "Message displayed when the sidecar file is loading",
    },
  });
  const { sidecarData, setCurrentSelection } = props;

  const { loaded, tableData, tableColumns, error } = useTypeCatalog(
    sidecarData!
  );

  if (error) return <Alert showIcon type="error" message={error} />;

  return (
    <div>
      {loaded !== true ? (
        <Alert
          type="info"
          showIcon
          message={
            <span style={{ marginLeft: 10 }}>
              {intl.formatMessage(messages.loading)}
            </span>
          }
        />
      ) : null}
      <Table
        bordered={true}
        size="small"
        dataSource={tableData}
        columns={tableColumns}
        pagination={false}
        loading={loaded !== true}
        rowSelection={
          props.type === "select"
            ? {
                selections: true,
                type: "checkbox",
                onChange: (selectedRowKeys, selectedRows) => {
                  setCurrentSelection(selectedRows);
                },
              }
            : undefined
        }
      />
    </div>
  );
};
