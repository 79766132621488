import { useQuery } from "react-query";
import { useAdminStore } from "../../../state/adminStore";
import { useAuthStore } from "../../../state/authStore";
import api from "../../../api/api";
import { useMemo, useState } from "react";
import { apiType } from "@formitas-ag/formitable/lib/EditDrawer.types";
import { User } from "@formitas-ag/bimfiles-types";
import { Button, Col, Modal, Row, Select, Typography, message } from "antd";
import { UserRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";
import { useIntl } from "react-intl";
import translations from "../../../translations/constants/translations";

type Props = {
  api: apiType<User>;
};

export const UpdateUserRoleModal = (props: Props) => {
  const intl = useIntl();
  const selectedUserId = useAdminStore(
    (state) => state.changeUserRoleSelectedUserId
  );
  const selectedOrganisationId = useAuthStore(
    (state) => state.selectedOrganisationId
  );

  const [role, setRole] = useState<UserRoleEnum>();

  const userQuery = useQuery({
    queryKey: ["user", selectedUserId],
    queryFn: () => {
      if (selectedUserId) {
        return api.users.getOne(selectedUserId);
      }
    },
  });

  const originalRole = useMemo(() => {
    if (userQuery.data) {
      return userQuery.data.organisations.find(
        (o) => o.organisationId === selectedOrganisationId
      )?.role;
    }
  }, [userQuery.data]);

  const [loading, setLoading] = useState(false);

  const onSubmitRole = async () => {
    if (selectedUserId && role) {
      const roleLabel =
        role === UserRoleEnum.USER
          ? intl.formatMessage(translations.ui.generic.roleUser)
          : intl.formatMessage(translations.ui.generic.roleModerator);

      setLoading(true);

      try {
        await api.users.role(selectedUserId, role);

        message.success(
          intl.formatMessage(
            translations.ui.admin.users.messageUpdatedRoleSuccess,
            { role: roleLabel }
          )
        );

        props.api.mainList?.reloadData();

        setLoading(false);
        closeModal();
      } catch (e) {
        message.error(
          intl.formatMessage(
            translations.ui.admin.users.messageUpdatedRoleFailure,
            { role: roleLabel }
          )
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const closeModal = () => {
    useAdminStore.setState({
      changeUserRoleSelectedUserId: undefined,
    });
  };

  return (
    <Modal
      open={selectedUserId !== undefined}
      title={
        userQuery.isLoading
          ? intl.formatMessage(translations.ui.admin.users.roleRenderLoading)
          : intl.formatMessage(
              translations.ui.admin.users.changeRoleModalTitle,
              {
                preName: userQuery.data?.preName,
                name: userQuery.data?.name,
              }
            )
      }
      centered
      style={{ padding: "2rem" }}
      onOk={closeModal}
      closable={true}
      width={600}
      onCancel={closeModal}
      footer={null}
    >
      <Row gutter={[16, 0]} style={{ marginTop: "16" }}>
        <Col span={24}>
          <Typography.Title level={3}>
            {intl.formatMessage(translations.ui.admin.users.changeRoleTitle)}
          </Typography.Title>
        </Col>
        <Col span={12}>
          <Select
            style={{ width: "100%" }}
            size="large"
            value={role || originalRole}
            onChange={(e) => setRole(e)}
            loading={userQuery.isLoading}
            disabled={loading}
            options={[
              {
                label: intl.formatMessage(translations.ui.generic.roleUser),
                value: UserRoleEnum.USER,
              },
              {
                label: intl.formatMessage(
                  translations.ui.generic.roleModerator
                ),
                value: UserRoleEnum.MODERATOR,
              },
            ]}
          />
        </Col>

        <Col span={12}>
          <Button
            type="primary"
            disabled={role === originalRole || !role || loading}
            style={{ width: "100%" }}
            onClick={onSubmitRole}
            loading={loading}
            size="large"
          >
            {intl.formatMessage(translations.ui.generic.save)}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};
