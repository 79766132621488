import { Form } from "antd";
import { FormattedMessage } from "react-intl";
import { rootQueryClient } from "../../../../..";
import { SizeType } from "antd/es/config-provider/SizeContext";
import useFiltersStore from "../../../../../state/filtersStore";
import FormProps from "../../../../../UiProperties/FormProps";
import TagsDropdown from "../../../Dropdowns/TagsDropdown";

export const TagsFilterInput: React.FC = () => {
  const filters = useFiltersStore((state) => state.currentFilters);
  const setFilters = useFiltersStore((state) => state.setFilters);

  // Sets the filters in state when fields are selected
  const onChange = (values: string[]) => {
    setFilters({
      ...filters,
      tagsIds: values,
    });

    // Reloads table items after the item has been approved or not
    rootQueryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === "distinctCategories",
    });
  };

  // Clears the filters of tags ids in the state
  const onClear = () => {
    setFilters({ ...filters, tagsIds: [] });
  };

  const selectProps = {
    style: { width: "10rem" },
    size: "middle" as SizeType,
    open: true,
    value: filters.tagsIds,
    onChange: onChange,
    onClear: onClear,
    placeholder: (
      <FormattedMessage
        id="component.tags_filter_input.placeholder"
        defaultMessage="choose tags"
        description="Placeholder for the select input"
      />
    ),
  };

  return (
    <Form.Item
      style={{ ...FormProps.formItem, margin: "0.2rem 0.5rem 0.2rem 0" }}
    >
      <TagsDropdown type={"filter"} selectProps={selectProps} />
    </Form.Item>
  );
};
