import { MenuOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, message } from "antd";
import { openChangeUserPasswordModal } from "../../AuthenticationAndPermissionModal/util/openModal";
import { apiType } from "@formitas-ag/formitable/lib/EditDrawer.types";
import { User } from "@formitas-ag/bimfiles-types/lib";
import api from "../../../../api/api";
import { useEffect, useState } from "react";
import DeletePopConfirm from "./DeletePopConfirm";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useIntl } from "react-intl";
import translations from "../../../../translations/constants/translations";
import { useAdminStore } from "../../../../state/adminStore";
import { checkFinePermissions } from "../../../../api/ability/Can";
import { useQuery } from "react-query";
import { isUserAllowedToEditUsersPermissions } from "../../../../api/ability/categoryAbilityExtension";
import { useAuthStore } from "../../../../state/authStore";
import { useDebouncedCallback } from "use-debounce";

const itemStyle = {
  fontWeight: "400",
  fontSize: "0.8rem",
  lineHeight: "20px",
  zIndex: "999",
};

const makeMenuItem = (
  key: string,
  label: string | JSX.Element,
  onClick: () => void,
  disabled?: boolean,
  disabledReason?: string
): Required<MenuProps>["items"][0] => {
  return {
    type: undefined,
    key,
    label,
    style: itemStyle,
    onClick,
    disabled,
    title: disabledReason,
  };
};

type Props = {
  api: apiType<User>;
  selectedUserId: string;
  setSelectedUserIdForPermissionEditing: (id: string | undefined) => void;
};

const ActionDropdown = (props: Props) => {
  const intl = useIntl();
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);

  const userQuery = useQuery({
    queryKey: ["users", props.selectedUserId],
    queryFn: () => {
      return api.users.getOne(props.selectedUserId, {
        ignoreExceptions: true,
      });
    },
  });

  const onKickUser = useDebouncedCallback(async () => {
    try {
      const result = await api.users.leaveOrganisation(props.selectedUserId);

      setDropdownVisible(false);

      if (!result) {
        message.error(
          intl.formatMessage(
            translations.ui.admin.users
              .actionDropdownRemoveFromOrganisationFailure,
            {
              user: userQuery.data?.email,
            }
          )
        );
        return;
      }

      message.success(
        intl.formatMessage(
          translations.ui.admin.users
            .actionDropdownRemoveFromOrganisationSuccess,
          {
            user: userQuery.data?.email,
          }
        )
      );

      props.api.mainList?.reloadData();

      return;
    } catch (e) {
      message.error(
        intl.formatMessage(
          translations.ui.admin.users
            .actionDropdownRemoveFromOrganisationFailure,
          {
            user: userQuery.data?.email,
          }
        )
      );
    }
  }, 500);

  const [items, setItems] = useState<ItemType[]>([]);

  useEffect(() => {
    const run = async () => {
      const newItems: ItemType[] = [];

      const isAdmin = await checkFinePermissions({
        allowOnlyAdmins: true,
        mode: "general",
      });

      const isModerator = await checkFinePermissions({
        mode: "general",
        blockUser: true,
      });

      if (isAdmin || isModerator) {
        const children: ItemType[] = [];

        if (isModerator || isAdmin) {
          const isSameUser =
            props.selectedUserId === useAuthStore.getState().user?.id;

          const isAllowedToEditPermissions =
            await isUserAllowedToEditUsersPermissions(props.selectedUserId);

          children.push(
            makeMenuItem(
              "kickUser",
              intl.formatMessage(
                translations.ui.admin.users.actionDropdownRemoveFromOrganisation
              ),
              () => {
                onKickUser();
              },
              isSameUser || !isAllowedToEditPermissions,
              isSameUser
                ? intl.formatMessage(
                    translations.ui.admin.users
                      .actionDropdownRemoveFromOrganisationDisallowedDueToSelf
                  )
                : !isAllowedToEditPermissions
                ? intl.formatMessage(
                    translations.ui.admin.users
                      .actionDropdownRemoveFromOrganisationDisallowedDueToLowPermissions
                  )
                : undefined
            )
          );
        }

        if (isAdmin) {
          children.push(
            makeMenuItem(
              "changeUserPassword",
              intl.formatMessage(
                translations.ui.admin.users.actionDropdownChangeUserPassword
              ),
              () => {
                setDropdownVisible(false);
                openChangeUserPasswordModal(props.selectedUserId);
              }
            ),
            makeMenuItem(
              "deleteUser",
              <DeletePopConfirm
                title={intl.formatMessage(
                  translations.ui.admin.users.actionDropdownDeleteUser
                )}
                description={intl.formatMessage(
                  translations.ui.admin.users.actionDropdownDeleteUserConfirm
                )}
                api={props.api}
                confirmAction={() => api.users.delete(props.selectedUserId)}
              />,
              () => {}
            )
          );
        }

        newItems.push(
          {
            key: "1",
            type: "group",
            label: intl.formatMessage(
              translations.ui.admin.users.actionDropdownAdministrationTitle
            ),
            children: children,
          },
          {
            type: "divider",
          }
        );
      }

      if (
        await checkFinePermissions({
          mode: "general",
          blockUser: true,
        })
      ) {
        newItems.push({
          key: "2",
          type: "group",
          label: intl.formatMessage(
            translations.ui.admin.users.actionDropdownPermissionTitle
          ),
          children: [
            makeMenuItem(
              "editPermissions",
              intl.formatMessage(
                translations.ui.admin.users.actionDropdownEditPermissions
              ),
              () => {
                setDropdownVisible(false);
                props.setSelectedUserIdForPermissionEditing(
                  props.selectedUserId
                );
              }
            ),
            makeMenuItem(
              "updateRole",
              intl.formatMessage(
                translations.ui.admin.users.actionDropdownUpdateRole
              ),
              () => {
                setDropdownVisible(false);
                useAdminStore.setState({
                  changeUserRoleSelectedUserId: props.selectedUserId,
                });
              }
            ),
          ],
        });
      }

      setItems(newItems);
    };

    run();
  }, []);

  return (
    <Dropdown
      open={dropdownVisible}
      onOpenChange={(open) => setDropdownVisible(open)}
      overlayClassName="three-vertical-dots"
      menu={{
        items: items,
      }}
      trigger={["click"]}
      placement="bottomRight"
    >
      <Button type="text" style={{ padding: "6px" }}>
        <MenuOutlined style={{ fontSize: "20px", color: "#70838f" }} />
      </Button>
    </Dropdown>
  );
};

export default ActionDropdown;
