import { SettingOutlined } from "@ant-design/icons";
import { Result } from "antd";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  title: {
    id: "admin.main.title",
    defaultMessage: "Administration",
    description: "The title of the main admin page",
  },
  subTitle: {
    id: "admin.main.subtitle",
    defaultMessage: "Select the objects you want to manage on the left side.",
    description: "The subtitle of the main admin page",
  },
});

const MainAdminPage = () => {
  const intl = useIntl();

  return (
    <Result
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      title={intl.formatMessage(messages.title)}
      icon={<SettingOutlined style={{ color: "#191919" }} />}
      subTitle={intl.formatMessage(messages.subTitle)}
    />
  );
};

export default MainAdminPage;
