import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { Category, Tag as TagType } from "@formitas-ag/bimfiles-types/lib";
import {
  DistinctSearchCategory,
  DistinctSearchTag,
} from "@formitas-ag/bimfiles-types/lib/search";
import CustomTag from "../HomepageComponents/CustomTag";

type Types =
  | TagType[]
  | Category[]
  | DistinctSearchTag[]
  | DistinctSearchCategory[];

interface TagRenderProps {
  options: {
    label: string;
    value: string;
  }[];
  data: Types;
}

const TagRender: React.FC<CustomTagProps & TagRenderProps> = ({
  label,
  value,
  closable,
  onClose,
  options,
  data,
}) => {
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const color = data
    ? data.find((dataObj) => dataObj.id === value)?.color
    : undefined;

  return (
    <CustomTag
      key={value}
      label={label!.toString()}
      color={color!}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    />
  );
};

export default TagRender;
