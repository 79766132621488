import { Button, Space } from "antd";
import { useItemsTableStore } from "../../../../state/itemsTableStore";
import api from "../../../../api/api";
import useFiltersStore from "../../../../state/filtersStore";
import { getLogger } from "../../../../utils/logger.utils";
import useCreateOrUpdateFiles from "../../../../hooks/useCreateOrUpdateFiles";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { useIntl } from "react-intl";
import translations from "../../../../translations/constants/translations";

const logger = getLogger("BatchUpdateButton");

export const BatchUpdateButton = () => {
  const intl = useIntl();
  const selectedItems = useItemsTableStore((state) => state.selectedItems);
  const setSelectedItems = useItemsTableStore(
    (state) => state.setSelectedItems
  );
  const itemsState = useFiltersStore((state) => state.itemsState);
  const { startUpdate, startBatchUpdate } = useCreateOrUpdateFiles();

  const onClick = async () => {
    //map the selected items to ItemPopulated
    const populatedSelectedItems = await Promise.all(
      selectedItems
        .map(async (item) => {
          const searchResult = await api.search.find(1, 1, itemsState, {
            id: item.id,
            categoriesIds: [],
            itemText: [],
            ostCategory: [],
            placementMethod: [],
            revitVersion: [],
            tagsIds: [],
            usersIds: [],
          });

          if (searchResult.totalDocuments === 1) {
            return searchResult.data[0] as ItemPopulated;
          } else {
            logger.warn(
              `Invalid state for BatchUpdateButton#onClick: totalDocuments=${searchResult.totalDocuments}.`
            );
          }
        })
        .filter((item) => item !== undefined)
    );

    //trigger update
    if (populatedSelectedItems.length === 1) {
      const onlyItemPopulated = populatedSelectedItems[0] as ItemPopulated;

      startUpdate(onlyItemPopulated);
    } else if (populatedSelectedItems.length > 1) {
      startBatchUpdate(populatedSelectedItems as ItemPopulated[]);
    } else {
      logger.warn(
        `Invalid state for BatchUpdateButton#onClick: populatedSelectedItems.length=${populatedSelectedItems.length}.`
      );
    }

    //clear selected items
    setSelectedItems([]);
  };

  return (
    <Button type="primary" onClick={onClick}>
      {intl.formatMessage(translations.ui.itemTable.batchUpdateButtonTitle)}
    </Button>
  );
};
