import { Button, Card, Col, Row, Typography } from "antd";
import Title from "antd/es/typography/Title";
import { Organisation } from "@formitas-ag/bimfiles-types/lib";
import { FormattedMessage } from "react-intl";
import { useSelectedOrganisationId } from "../../../api/utils/auth.utils";

type Props = {
  possibleOrganisations: Pick<
    Organisation,
    "id" | "title" | "description" | "host" | "createdAt"
  >[];
};

export const SelectOrganisationForm = (props: Props) => {
  const [_, setSelectedOrganisationId] = useSelectedOrganisationId();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card
        style={{
          margin: ".5rem",
        }}
      >
        <Title style={{ marginBottom: "2rem", textAlign: "center" }}>
          <FormattedMessage
            id="auth.organisation.title"
            defaultMessage="Select Organisation"
            description="Title of the select organisation page"
          />
        </Title>
        <Row gutter={[8, 8]}>
          {props.possibleOrganisations
            .sort((a, b) => a.title.localeCompare(b.title))
            .map((organisation) => (
              <Col span={24} key={organisation.id}>
                <Button
                  size="large"
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                  onClick={() => setSelectedOrganisationId(organisation.id)}
                >
                  {organisation.title}
                </Button>
              </Col>
            ))}
        </Row>
      </Card>
    </div>
  );
};
