import { Search } from "@formitas-ag/bimfiles-types";
import { devtools } from "zustand/middleware";
import { FieldData } from "../components/AllUsers/HomepageComponents/Filters/FiltersModal/FilterModal";
import { create } from "zustand";

export const emptyFilters: Search = {
  id: "",
  ostCategory: [],
  placementMethod: [],
  revitVersion: [],
  tagsIds: [],
  categoriesIds: [],
  usersIds: [],
  itemText: [],
};

type filtersStore = {
  currentFilters: Search;
  setFilters: (search: Search) => void;
  clearFilters: () => void;

  itemsState: "approved" | "unapproved" | "denied";
  setItemsState: (itemsState: "approved" | "unapproved" | "denied") => void;

  filterFormFields: FieldData[];
  setFilterFormFields: (formFields: FieldData[]) => void;
};

const useFiltersStore = create<filtersStore>()(
  devtools((set, get) => ({
    currentFilters: emptyFilters,
    setFilters: (filters) => {
      set({
        currentFilters: filters,
      });
    },
    clearFilters: () => {
      set({
        currentFilters: { ...get().currentFilters, ...emptyFilters },
        itemsState: "approved",
      });
    },

    itemsState: "approved",
    setItemsState: (itemsState) => {
      set({ itemsState });
    },

    filterFormFields: [],
    setFilterFormFields: (formFields) => {
      set({ filterFormFields: formFields });
    },
  }))
);

export default useFiltersStore;
