import { useEffect, useState } from "react";
import { Input } from "antd";
import { defineMessage, useIntl } from "react-intl";
import { useDebounce } from "use-debounce";
import useFiltersStore from "../../../state/filtersStore";

export const SearchBar = () => {
  const filters = useFiltersStore((state) => state.currentFilters);
  const itemsState = useFiltersStore((state) => state.itemsState);
  const setFilters = useFiltersStore((state) => state.setFilters);
  const [width, setWidth] = useState<string>("75%");
  const [searchText, setSearchText] = useState("");
  const [debouncedText] = useDebounce(searchText, 500);
  const intl = useIntl();

  const onFocus = () => {
    setWidth("100%");
  };

  const onBlur = () => {
    setWidth("75%");
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchText(newValue);
  };

  useEffect(() => {
    setFilters({
      ...filters,
      itemText: searchText === "" ? [] : [searchText],
    });
  }, [debouncedText]);

  useEffect(() => {
    if (!filters.itemText[0]) setSearchText("");
  }, [filters.itemText]);

  const message = defineMessage({
    id: "component.searchbar.placeholder",
    defaultMessage: "Search...",
    description: "Placeholder for top the search bar",
  });

  return (
    <Input
      disabled={!(itemsState === "approved")}
      size="large"
      value={searchText}
      placeholder={intl.formatMessage(message)}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
      style={{ width: width, border: "none" }}
      spellCheck={false}
    ></Input>
  );
};
