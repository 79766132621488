import React from "react";
import { Dropdown, MenuProps } from "antd";
import { useAuthStore } from "../../../state/authStore";
import useSwitchOrganisation from "../../../hooks/useSwitchOrganisation";

export type Props = {
  selectedOrganisationId?: string;
};

const OrganisationSelector = ({ selectedOrganisationId }: Props) => {
  const [newOrganisationId, setNewOrganisationId] =
    React.useState<string | undefined>(undefined);

  const possibleOrganisations = useAuthStore(
    (state) => state.possibleOrganisations
  );

  useSwitchOrganisation(newOrganisationId);

  const items: MenuProps["items"] = possibleOrganisations.map((o) => {
    return {
      label: o.title,
      key: o.id,
      onClick: () => {
        setNewOrganisationId(o.id);
      },
    };
  });

  const selectedOrganisation = possibleOrganisations.find(
    (o) => o.id === selectedOrganisationId
  );

  return (
    <Dropdown.Button
      menu={{ items: items }}
      placement="bottomRight"
      size="large"
      trigger={["click"]}
      style={{ width: "100%" }}
      buttonsRender={([leftButton, rightButton]) => [
        React.cloneElement(leftButton as React.ReactElement<any, string>, {
          style: { width: "100%" },
        }),
        rightButton,
      ]}
    >
      {selectedOrganisation?.title}
    </Dropdown.Button>
  );
};

export default OrganisationSelector;
