import { defineMessages } from "react-intl";

const errors = defineMessages({
  noRevitTitle: {
    id: "modal.update-files-error.no-revit.title",
    defaultMessage: "Revit addon not started",
    description: "Title of the update files error modal",
  },
  noRevitDescription: {
    id: "modal.update-files-error.no-revit.description",
    defaultMessage:
      "There was no connection to the BIMfiles Revit addon which is required for uploading families.",
    description: "Description of the update files error modal",
  },
  nothingSelectedTitle: {
    id: "modal.update-files-error.nothing-selected.title",
    defaultMessage: "No element selected",
    description: "Title of the update files error modal",
  },
  nothingSelectedDescription: {
    id: "modal.update-files-error.nothing-selected.description",
    defaultMessage:
      "There needs to be one element selected in Revit for exporting the family.",
    description: "Description of the update files error modal",
  },
  wrongSelectionTitle: {
    id: "modal.update-files-error.wrong-selection.title",
    defaultMessage: "Selection is not correct",
    description: "Title of the update files error modal",
  },
  wrongSelectionDescription: {
    id: "modal.update-files-error.wrong-selection.description",
    defaultMessage:
      "Only one element needs to be selected in Revit for exporting the family.",
    description: "Description of the update files error modal",
  },
  title: {
    id: "modal.update-files-error.error",
    defaultMessage: "Error",
    description: "Error text",
  },
});

export default errors;
