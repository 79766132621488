import { Space, Tag } from "antd";
import { Organisation } from "@formitas-ag/bimfiles-types/lib";

type Props = {
  domainFilter: Organisation["domainFilter"];
};

export default ({ domainFilter }: Props) => {
  return (
    <Space wrap direction="horizontal">
      {domainFilter.map((domain) => (
        <Tag>{domain}</Tag>
      ))}
    </Space>
  );
};
