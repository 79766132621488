import { Tooltip, notification } from "antd";
import { File } from "@formitas-ag/bimfiles-types";
import { useEffect, useState } from "react";
import { download } from "../../../../utils/file.utils";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { usePlatform } from "../../../../hooks/usePlatform";
import FileRoleTag from "./FileRoleTag";
import api from "../../../../api/api";

type Props = {
  file: File;
  onClose?: () => void;
  disableTooltip?: boolean;
  fullWidth?: boolean;
  includeFileNameInTitle?: boolean;
  downloadItemId?: string;
};

const FileTag = (props: Props) => {
  const [platform] = useState<string>(usePlatform());
  const disableTooltip =
    props.disableTooltip ?? props.includeFileNameInTitle ?? false;

  const [notificationApi, contextHolder] = notification.useNotification();

  const [downloadUrl, setDownloadUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (props.downloadItemId) {
      api.items
        .getFileWithUrl(props.downloadItemId, props.file.id)
        .then((response) => {
          setDownloadUrl(response.url);
        });
    }
  }, [props.downloadItemId]);

  const tag = (
    <FileRoleTag
      title={(textTitle) => {
        return (
          <>
            {props.includeFileNameInTitle
              ? `(${textTitle}) ${props.file.name}`
              : textTitle}
            {props.downloadItemId && downloadUrl ? (
              <>
                {" "}
                <CloudDownloadOutlined />
              </>
            ) : (
              ""
            )}
          </>
        );
      }}
      fileName={props.file.name}
      role={props.file.role}
      fullWidth={props.fullWidth}
      onClose={props.onClose}
    />
  );

  const tooltipContent =
    props.downloadItemId && downloadUrl ? (
      <a
        onClick={() => {
          if (platform === "browser") {
            download(downloadUrl);
          } else {
            notificationApi.warning({
              message: "Files can only be downloaded using a browser",
            });
          }
        }}
      >
        {tag}
      </a>
    ) : (
      tag
    );

  return (
    <>
      {contextHolder}
      {!disableTooltip ? (
        <Tooltip
          key={props.file.id}
          color="#f7f8fc"
          title={props.file.name}
          overlayInnerStyle={{ textAlign: "center" }}
        >
          {tooltipContent}
        </Tooltip>
      ) : (
        tooltipContent
      )}
    </>
  );
};

export default FileTag;
