import { rootQueryClient } from "..";
import { useAuthStore } from "../state/authStore";
import {
  accessTokenObservable,
  selectedOrganisationIdObservable,
} from "../api/utils/auth.utils";

const useSwitchOrganisation = (organisationId: string | undefined) => {
  if (organisationId) {
    useAuthStore.setState({ selectedOrganisationId: organisationId });
    selectedOrganisationIdObservable.set(organisationId);
    accessTokenObservable.set(null);

    accessTokenObservable.subscribe((accessToken) => {
      if (accessToken) {
        rootQueryClient.clear();
      }
    });
  }
};

export default useSwitchOrganisation;
