import { ApiDrivenTable } from "@formitas-ag/formitable";
import { Col, Row } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { ColumnsType } from "antd/lib/table";
import type { Category } from "@formitas-ag/bimfiles-types/lib";
import useApiAll from "../../hooks/useApiAll";
import {
  formFieldType,
  typeEnum,
} from "@formitas-ag/formitable/lib/EditDrawer.types";
import { useAuthStore } from "../../state/authStore";
import NoOrganisationSelected from "./Common/NoOrganisationSelected";
import parameterRender from "./Common/parameterRender";
import DateRender from "./Common/DateRender";
import { defineMessages, useIntl } from "react-intl";
import { getApiDrivenTableLanguage } from "./Common/apiTableLanguage";
import ColorRender from "./Common/ColorRender";
import ShortCodeRender from "./Category/ShortCodeRender";
import ItemListRender from "./Common/ItemListRender";
import CategoryActionDropdown from "./Category/CategoryActionDropdown";
import { CategoryUsersModal } from "./RoleModal/CategoryUsersModal";
import { CategoryRoleRender } from "./Common/CategoryRoleRender";
import { useViewStore } from "../../state/viewStore";
import translations from "../../translations/constants/translations";

export const CategoryAdminPage = () => {
  const intl = useIntl();
  const selectedOrgId = useAuthStore((store) => store.selectedOrganisationId);
  const api = useApiAll<Category>({
    options: { paginated: true, limit: 20 },
    service: "categories",
  });

  if (!selectedOrgId) return <NoOrganisationSelected />;

  const messages = defineMessages({
    title: {
      id: "admin.categories.title",
      defaultMessage: "Libraries",
      description: "The title of the categories admin page",
    },
    titleField: {
      id: "admin.categories.field.title",
      defaultMessage: "Title",
      description: "The title field of a category",
    },
    colorField: {
      id: "admin.categories.field.color",
      defaultMessage: "Color",
      description: "The color field of a category",
    },
    descriptionField: {
      id: "admin.categories.field.description",
      defaultMessage: "Description",
      description: "The description field of a category",
    },
    shortCodeField: {
      id: "admin.categories.field.short_code",
      defaultMessage: "ID",
      description: "The short code field of a category",
    },
    itemsField: {
      id: "admin.categories.field.items",
      defaultMessage: "Elements",
      description: "The items field of a category",
    },
    permissionsField: {
      id: "admin.categories.field.permissions",
      defaultMessage: "Permissions",
      description: "The permissions field of a category",
    },
    createdAtField: {
      id: "admin.categories.field.created_at",
      defaultMessage: "Created At",
      description: "The created at field of a category",
    },
    updatedAtField: {
      id: "admin.categories.field.updated_at",
      defaultMessage: "Updated At",
      description: "The updated at field of a category",
    },
  });

  const columns: ColumnsType<Category> = [
    {
      title: intl.formatMessage(messages.shortCodeField),
      dataIndex: "shortCode",
      render: parameterRender<Category, "shortCode">((shortCode) => (
        <ShortCodeRender shortCode={shortCode} />
      )),
    },
    {
      title: intl.formatMessage(messages.titleField),
      dataIndex: "title",
    },
    {
      title: intl.formatMessage(messages.colorField),
      dataIndex: "color",
      render: parameterRender<Category, "color">((color) => (
        <ColorRender color={color} />
      )),
    },
    {
      title: intl.formatMessage(messages.descriptionField),
      dataIndex: "description",
    },
    {
      title: intl.formatMessage(messages.itemsField),
      dataIndex: "id",
      render: parameterRender<Category, "id">((id) => (
        <ItemListRender fromCategoryId={id} />
      )),
    },
    {
      title: intl.formatMessage(messages.permissionsField),
      dataIndex: "id",
      render: (_, record) => (
        <CategoryRoleRender role={record.defaultPermissions} />
      ),
    },
    {
      title: intl.formatMessage(messages.createdAtField),
      dataIndex: "createdAt",
      render: parameterRender<Category, "createdAt">((date) => (
        <DateRender date={date} />
      )),
      sorter: true,
    },
    {
      title: intl.formatMessage(messages.updatedAtField),
      dataIndex: "updatedAt",
      render: parameterRender<Category, "updatedAt">((date) => (
        <DateRender date={date} />
      )),
      sorter: true,
    },
    {
      title: "",
      dataIndex: "id",
      render: parameterRender<Category, "id">((id) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CategoryActionDropdown
            categoryId={id}
            selectCategoryForPermissionEditing={(id) =>
              useViewStore.setState({
                editRoleCategoryId: id,
              })
            }
          />
        </div>
      )),
    },
  ];

  const formFields: formFieldType[] = [
    {
      title: intl.formatMessage(messages.shortCodeField),
      dataIndex: "shortCode",
      key: "shortCode",
      inputOptions: { maxLength: 10 },
      required: true,
    },
    {
      title: intl.formatMessage(messages.titleField),
      dataIndex: "title",
      key: "title",
      required: true,
    },
    {
      title: intl.formatMessage(messages.descriptionField),
      dataIndex: "description",
      key: "description",
      type: typeEnum.textArea,
      textAreaOptions: {
        rows: 5,
      },
      required: true,
    },
    {
      title: intl.formatMessage(messages.colorField),
      type: typeEnum.colour,
      dataIndex: "color",
      key: "color",
      required: true,
    },
    {
      title: intl.formatMessage(messages.permissionsField),
      dataIndex: "defaultPermissions",
      type: typeEnum.select,
      key: "defaultPermissions",
      selectOptions: {
        options: [
          {
            label: intl.formatMessage(translations.ui.generic.roleNone),
            value: 0,
          },
          {
            label: intl.formatMessage(translations.ui.generic.roleRead),
            value: 1,
          },
          {
            label: intl.formatMessage(translations.ui.generic.roleWrite),
            value: 10,
          },
          {
            label: intl.formatMessage(translations.ui.generic.roleApprove),
            value: 100,
          },
        ],
      },
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <PageHeader title={intl.formatMessage(messages.title)} />
      </Col>
      <Col span={24}>
        <ApiDrivenTable
          columns={columns}
          serviceName="categories"
          language={getApiDrivenTableLanguage(intl)}
          api={api}
          rowKey="id"
          reloadButton
          editInfo={{
            formFields,
            editable: true,
            creatable: true,
            deletable: true,
            deleteTest: (_, category) => {
              return category.itemsIds.length === 0;
            },
          }}
        />
      </Col>

      <CategoryUsersModal />
    </Row>
  );
};
