import { Tag, Tooltip, Typography } from "antd";
import { TagChange } from "@formitas-ag/bimfiles-types";
import { defineMessages, FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import api from "../../../../api/api";
import TimelineChangeWrapper from "./Common/TimelineChangeWrapper";

type Props = {
  change: TagChange;
};

export default (props: Props) => {
  const tagsQuery = useQuery({
    queryKey: ["tags", props.change.tagsIds],
    queryFn: async () => {
      return await Promise.all(
        props.change.tagsIds.map(async (tagId) => {
          return api.tags.getOne(tagId, true);
        })
      );
    },
  });

  const messages = defineMessages({
    title: {
      id: "component.timeline.tag.title",
      defaultMessage: "New Tags",
      description: "Title of a tag change",
    },
    loading: {
      id: "component.timeline.tag.loading",
      defaultMessage: "Loading tags...",
      description: "Loading message when the tags are loading",
    },
    error: {
      id: "component.timeline.tag.error",
      defaultMessage: "Tags could not be loaded",
      description: "Error message when the tags could not be loaded",
    },
  });

  return (
    <TimelineChangeWrapper
      key={props.change.id}
      titleChild={
        <Typography.Text>
          <FormattedMessage {...messages.title} />
        </Typography.Text>
      }
    >
      <>
        {tagsQuery.isLoading && (
          <span>
            <FormattedMessage {...messages.loading} />
          </span>
        )}
        {tagsQuery.error && (
          <span>
            <FormattedMessage {...messages.error} />
          </span>
        )}
        {tagsQuery.isSuccess &&
          tagsQuery.data.map((tag) => {
            return (
              <Tooltip
                color="#f7f8fc"
                title={tag.description}
                overlayInnerStyle={{ textAlign: "center" }}
              >
                <Tag color={tag.color}>{tag.title}</Tag>
              </Tooltip>
            );
          })}
      </>
    </TimelineChangeWrapper>
  );
};
