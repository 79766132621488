import { Card, Typography } from "antd";
import TimelineItem from "antd/es/timeline/TimelineItem";
import { TitleChange } from "@formitas-ag/bimfiles-types";
import { defineMessages, FormattedMessage } from "react-intl";
import TimelineChangeWrapper from "./Common/TimelineChangeWrapper";

type Props = {
  change: TitleChange;
};

export default (props: Props) => {
  const messages = defineMessages({
    title: {
      id: "component.timeline.title.title",
      defaultMessage: "New Title",
      description: "Title of the changed title",
    },
  });

  return (
    <TimelineChangeWrapper
      titleChild={
        <Typography.Text>
          <FormattedMessage {...messages.title} />
        </Typography.Text>
      }
    >
      {props.change.title}
    </TimelineChangeWrapper>
  );
};
