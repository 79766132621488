import { AxiosInstance } from "axios";
import api from "../api";
import {
  accessTokenObservable,
  hasAccessToken,
  hasSelectedOrganisationId,
} from "../utils/auth.utils";
import { errorRenderer } from "../utils/errorThrower";

const automaticRefresh = (axiosInstance: AxiosInstance) => {
  const requestInterceptor = axiosInstance.interceptors.request.use(
    (config: any) => {
      if (!config.headers["Authorization"]) {
        const accessToken = accessTokenObservable.get();

        if (accessToken) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
      }
      return config;
    },
    (error) => Promise.reject(errorRenderer(error))
  );

  const responseInterceptors = axiosInstance.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      const previousRequest = error?.config;
      if (
        error?.response?.status === 401 &&
        !previousRequest?.sent &&
        !previousRequest.url.includes("/auth/refresh")
      ) {
        previousRequest.sent = true;

        if (hasAccessToken() && hasSelectedOrganisationId()) {
          try {
            const newAccessToken = await api.auth.refreshAccessTokens();
            previousRequest.headers[
              "Authorization"
            ] = `Bearer ${newAccessToken}`;

            return axiosInstance(previousRequest);
          } catch (error) {
            await api.auth.logout(true);
            return Promise.reject(errorRenderer(error));
          }
        }
      }
      return Promise.reject(error);
    }
  );

  return () => {
    axiosInstance.interceptors.response.eject(responseInterceptors);
    axiosInstance.interceptors.request.eject(requestInterceptor);
  };
};

export default automaticRefresh;
