import { Typography } from "antd";
import { DescriptionChange } from "@formitas-ag/bimfiles-types";
import { defineMessages, FormattedMessage } from "react-intl";
import TimelineChangeWrapper from "./Common/TimelineChangeWrapper";

type Props = {
  change: DescriptionChange;
};

export default (props: Props) => {
  const messages = defineMessages({
    title: {
      id: "component.timeline.description.title",
      defaultMessage: "New Description",
      description: "Title of the changed description",
    },
  });

  return (
    <TimelineChangeWrapper
      key={props.change.id}
      titleChild={
        <Typography.Text>
          <FormattedMessage {...messages.title} />
        </Typography.Text>
      }
    >
      {props.change.description}
    </TimelineChangeWrapper>
  );
};
