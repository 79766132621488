import { Button, Tooltip } from "antd";
import { CloseOutlined, DeleteOutlined } from "@ant-design/icons";
import { useCreateOrUpdateItemStore } from "../../../../../../state/createOrUpdateItemStore";
import { useIntl } from "react-intl";
import translations from "../../../../../../translations/constants/translations";
import useCreateOrUpdateFiles from "../../../../../../hooks/useCreateOrUpdateFiles";

type Props = {
  index: number;
};

export const MenuElement = (props: Props) => {
  const intl = useIntl();
  const mode = useCreateOrUpdateItemStore((state) => state.mode);
  const { removeUpload } = useCreateOrUpdateFiles();

  const tooltipMessage =
    mode === "create"
      ? intl.formatMessage(
          translations.ui.upload.components
            .fileListDeleteMenuButtonTooltipCreate
        )
      : intl.formatMessage(
          translations.ui.upload.components
            .fileListDeleteMenuButtonTooltipUpdate
        );

  return (
    <Tooltip title={tooltipMessage} color="white">
      <Button
        danger={mode === "create"}
        onClick={() => removeUpload(props.index)}
      >
        {mode === "create" && <DeleteOutlined />}
        {mode !== "create" && <CloseOutlined />}
      </Button>
    </Tooltip>
  );
};
