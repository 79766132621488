import { ReactNode } from "react";
import { Navbar } from "../AllUsers/Navbar/Navbar";
import DeleteItemModal from "../AllUsers/HomepageComponents/DeleteItem/DeleteItemModal";
import TimelineModal from "../AllUsers/Timeline/Modal/TimelineModal";

function NavbarLayout({ children }: { children: ReactNode }) {
  return (
    <div>
      <Navbar />
      <main>{children}</main>
      <DeleteItemModal />
      <TimelineModal />
    </div>
  );
}

export default NavbarLayout;
