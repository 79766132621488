import { ApiDrivenTable } from "@formitas-ag/formitable";
import { Col, Row } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { ColumnsType } from "antd/lib/table";
import { Tag } from "@formitas-ag/bimfiles-types/lib";
import useApiAll from "../../hooks/useApiAll";
import {
  formFieldType,
  typeEnum,
} from "@formitas-ag/formitable/lib/EditDrawer.types";
import { useAuthStore } from "../../state/authStore";
import NoOrganisationSelected from "./Common/NoOrganisationSelected";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { getApiDrivenTableLanguage } from "./Common/apiTableLanguage";
import parameterRender from "./Common/parameterRender";
import ColorRender from "./Common/ColorRender";
import ItemListRender from "./Common/ItemListRender";
import DateRender from "./Common/DateRender";

const messages = defineMessages({
  title: {
    id: "admin.tags.title",
    defaultMessage: "Tags",
    description: "Title of the tag administration page",
  },
  titleField: {
    id: "admin.tags.fields.title",
    defaultMessage: "Title",
    description: "Title field for a tag",
  },
  descriptionField: {
    id: "admin.tags.fields.description",
    defaultMessage: "Description",
    description: "Description field for a tag",
  },
  colorField: {
    id: "admin.tags.fields.color",
    defaultMessage: "Color",
    description: "Color field for a tag",
  },
  itemsField: {
    id: "admin.tags.fields.items",
    defaultMessage: "Elements",
    description: "Items field for a tag",
  },
  createdAtField: {
    id: "admin.tags.fields.createdAt",
    defaultMessage: "Created at",
    description: "Created at field for a tag",
  },
  updatedAtField: {
    id: "admin.tags.fields.updatedAt",
    defaultMessage: "Updated at",
    description: "Updated at field for a tag",
  },
});

const getColumns = (intl: IntlShape): ColumnsType<Tag> => {
  return [
    { title: intl.formatMessage(messages.titleField), dataIndex: "title" },
    {
      title: intl.formatMessage(messages.descriptionField),
      dataIndex: "description",
    },
    {
      title: intl.formatMessage(messages.colorField),
      dataIndex: "color",
      render: parameterRender<Tag, "color">((color) => (
        <ColorRender color={color} />
      )),
    },
    {
      title: intl.formatMessage(messages.itemsField),
      dataIndex: "id",
      render: parameterRender<Tag, "id">((id) => (
        <ItemListRender fromTagId={id} />
      )),
    },
    {
      title: intl.formatMessage(messages.createdAtField),
      dataIndex: "createdAt",
      render: parameterRender<Tag, "createdAt">((date) => (
        <DateRender date={date} />
      )),
    },
    {
      title: intl.formatMessage(messages.updatedAtField),
      dataIndex: "updatedAt",
      render: parameterRender<Tag, "updatedAt">((date) => (
        <DateRender date={date} />
      )),
    },
  ];
};

const getFormFields = (intl: IntlShape): formFieldType[] => [
  {
    title: intl.formatMessage(messages.titleField),
    dataIndex: "title",
    type: typeEnum.input,
    key: "title",
    required: true,
  },
  {
    title: intl.formatMessage(messages.descriptionField),
    dataIndex: "description",
    type: typeEnum.textArea,
    textAreaOptions: {
      rows: 5,
    },
    required: true,
    key: "description",
  },
  {
    title: intl.formatMessage(messages.colorField),
    dataIndex: "color",
    type: typeEnum.colour,
    key: "color",
    required: true,
  },
];

export const TagAdminPage = () => {
  const intl = useIntl();
  const selectedOrgId = useAuthStore((store) => store.selectedOrganisationId);
  const api = useApiAll<Tag>({
    options: { limit: 20 },
    service: "tags",
  });
  if (!selectedOrgId) return <NoOrganisationSelected />;

  return (
    <Row>
      <Col span={24}>
        <PageHeader title={intl.formatMessage(messages.title)} />
      </Col>
      <Col span={24}>
        <ApiDrivenTable
          columns={getColumns(intl)}
          serviceName="tags"
          language={getApiDrivenTableLanguage(intl)}
          api={api}
          rowKey="id"
          reloadButton
          editInfo={{
            formFields: getFormFields(intl),
            creatable: true,
            editable: true,
            deletable: true,
          }}
        />
      </Col>
    </Row>
  );
};
