import { Alert, Button, Modal } from "antd";
import { useViewStore } from "../../../state/viewStore";
import MergeTagsForm from "./MergeTagsForm";
import { useEffect, useState } from "react";
import api from "../../../api/api";

const MergeTagsModal = () => {
  const mergeTagsModal = useViewStore((state) => state.mergeTagsModal) || false;

  const [selectedNewTag, setSelectedNewTag] =
    useState<string | undefined>(undefined);
  const [selectedOldTags, setSelectedOldTags] =
    useState<string[] | undefined>(undefined);
  const [affectedItemsCount, setAffectedItemsCount] = useState<number>(0);

  const onMergeTags = async () => {
    await api.tags.merge(selectedOldTags!, selectedNewTag!);
    closeModal();
  };

  const closeModal = () => {
    useViewStore.setState({
      ...useViewStore.getState(),
      mergeTagsModal: false,
    });
    setSelectedNewTag(undefined);
    setSelectedOldTags(undefined);
    setAffectedItemsCount(0);
  };

  useEffect(() => {
    const fetchAffectedItemsCount = async () => {
      const count = selectedOldTags
        ? await api.items.getItemsAffectedByTagsMergeCount(selectedOldTags)
        : 0;
      setAffectedItemsCount(count);
    };

    fetchAffectedItemsCount();
  }, [selectedOldTags]);

  return (
    <Modal
      open={mergeTagsModal}
      title="Merge tags"
      style={{ padding: "2rem", top: 20, bottom: 20 }}
      centered
      onOk={closeModal}
      closable={true}
      onCancel={closeModal}
      destroyOnClose
      footer={[
        <Button
          key="mergeTagsButton"
          type="primary"
          onClick={onMergeTags}
          disabled={
            !selectedNewTag || !selectedOldTags || selectedOldTags.length === 0
          }
        >
          Merge
        </Button>,
        <Button key="cancelMergeTagsButton" type="text" onClick={closeModal}>
          Cancel
        </Button>,
      ]}
      width={"80%"}
    >
      <Alert
        message={
          affectedItemsCount
            ? `${affectedItemsCount} Items will be updated`
            : "Select tags to see how many items will be updated"
        }
        type="info"
        showIcon
        style={{ marginBottom: "1rem" }}
      />
      <MergeTagsForm
        selectedNewTag={selectedNewTag}
        selectedOldTags={selectedOldTags}
        setSelectedNewTag={setSelectedNewTag}
        setSelectedOldTags={setSelectedOldTags}
      />
    </Modal>
  );
};

export default MergeTagsModal;
