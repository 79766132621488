import { Typography } from "antd";

type Props = {
    text: string;
};

export const defaultRender = (value: string) => <TextRender text={value} /> as React.ReactNode;
export const TextRender = ({text}: Props) => {
    return <Typography.Text>
        {text}
    </Typography.Text>
};
export default TextRender;