import { CheckboxOptionType, Radio, Tooltip } from "antd";
import { useState } from "react";
import { CategoryRole } from "@formitas-ag/bimfiles-types/lib/permission";
import { AbstractRoleTableData } from "./AbstractRoleTable";
import { useIntl } from "react-intl";
import translations from "../../../translations/constants/translations";

type Props = (SingleCategoryRoleSwitchProps | AllCategoryRoleSwitchProps) & {
  isParentLoading?: boolean;
  tooltip?: string;
};

type SingleCategoryRoleSwitchProps = {
  mode: "single";
  record: AbstractRoleTableData;
  role?: CategoryRole;
  onChange: (
    id: AbstractRoleTableData["id"],
    role: CategoryRole
  ) => Promise<void>;
};

type AllCategoryRoleSwitchProps = {
  mode: "all";
  tooltip?: string;
  onChange: (role: CategoryRole) => Promise<void>;
};

export type CategoryRoleSwitchProps = Props;

export const CategoryRoleSwitch = (props: Props) => {
  const intl = useIntl();

  const { mode } = props;

  const [loading, setLoading] = useState(false);

  const categoryRoleRadioOptions: CheckboxOptionType[] = [
    {
      label: intl.formatMessage(translations.ui.generic.roleNone),
      value: 0,
    },
    {
      label: intl.formatMessage(translations.ui.generic.roleRead),
      value: 1,
    },
    {
      label: intl.formatMessage(translations.ui.generic.roleWrite),
      value: 10,
    },
    {
      label: intl.formatMessage(translations.ui.generic.roleApprove),
      value: 100,
    },
  ];

  const onChange = async (newCategoryRole: CategoryRole) => {
    setLoading(true);
    if (mode === "single") {
      await props.onChange(props.record.id, newCategoryRole);
    } else {
      await props.onChange(newCategoryRole);
    }
    setLoading(false);
  };

  return (
    <Tooltip
      color="#f7f8fc"
      title={props.tooltip}
      trigger={props.tooltip ? "hover" : []}
    >
      <Radio.Group
        disabled={loading || props.isParentLoading}
        options={categoryRoleRadioOptions}
        onChange={(e) => onChange(e.target.value)}
        value={mode === "single" ? props.role : undefined}
        optionType="button"
        buttonStyle="solid"
      />
    </Tooltip>
  );
};
