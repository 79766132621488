import { create } from "zustand";
import {
  GetRoleResult,
  UpdatingAddedFile,
  UpdatingFile,
} from "../utils/types/updateFilesTypes";

export type OverwriteItemStore = {
  overwritingFiles: (
    | {
        type: "single";
        index: number;
        file: UpdatingAddedFile;
        overwrittenFile: UpdatingFile;
      }
    | {
        type: "multiple";
        index: number;
        elements: {
          file: UpdatingAddedFile;
          overwrittenFile: UpdatingFile;
        }[];
      }
  )[];
  currentOverwritingFile?: OverwriteItemStore["overwritingFiles"][0];
};

export const useOverwriteItemStore = create<OverwriteItemStore>((set) => ({
  overwritingFiles: [],
}));
