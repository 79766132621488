import axios from "axios";
import automaticRefresh from "./automaticRefresh";
import errorHandler from "./errorHandler";

const baseUrlPathPrefix = "/api";

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL ? process.env.REACT_APP_BACKEND_BASE_URL : window.location.origin;

const axiosService = axios.create({
  baseURL: baseUrl + baseUrlPathPrefix,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  transformResponse: [
    (data) => {
      try {
        return JSON.parse(data);
      } catch (e) {
        return data;
      }
    },
  ],
});

automaticRefresh(axiosService);
errorHandler(axiosService);

export default axiosService;
