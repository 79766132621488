import { Button, Row, Space, Tooltip } from "antd";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { useApproveOrDeny } from "../../../../../hooks/useApproveOrDeny";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

export const ApproveItem: React.FC<{ item: ItemPopulated }> = ({ item }) => {
  const { approveOrDeny, loading } = useApproveOrDeny(item);

  const onApproveClick = async (approve: boolean) => {
    approveOrDeny(approve);
  };

  return (
    <Row>
      <Space size={4} wrap>
        <Tooltip color="#f7f8fc" title="Approve">
          <Button
            type="text"
            loading={loading}
            icon={
              <CheckCircleOutlined
                style={{ fontSize: "24px", color: "green" }}
              />
            }
            onClick={() => {
              onApproveClick(true);
            }}
          />
        </Tooltip>

        <Tooltip color="#f7f8fc" title="Reject">
          <Button
            type="text"
            loading={loading}
            icon={
              <CloseCircleOutlined style={{ fontSize: "24px", color: "red" }} />
            }
            onClick={() => {
              onApproveClick(false);
            }}
          />
        </Tooltip>
      </Space>
    </Row>
  );
};
