import { Tag } from "antd";
import { TagProps } from "antd/es/tag";
import getFontColor from "../../../utils/getFontColor.utils";
import { CloseOutlined } from "@ant-design/icons";

type CustomTagProps = {
  color: string;
  label: string;
} & TagProps;

const CustomTag: React.FC<CustomTagProps> = ({
  color,
  label,
  style,
  ...props
}) => {
  return (
    <Tag
      color={color}
      style={style}
      closeIcon={
        props.closable ? (
          <CloseOutlined style={{ color: "rgba(0, 0, 0, 0.88)" }} />
        ) : undefined
      }
      {...props}
    >
      <span
        style={{
          color: "rgba(0, 0, 0, 0.88)",
        }}
      >
        {label}
      </span>
    </Tag>
  );
};

export default CustomTag;
