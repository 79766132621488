import { Space, Typography } from "antd";
import { useIntl } from "react-intl";
import useGetFileMeta from "../../../../../hooks/useCreateOrUpdateFiles/useGetFileMeta";
import FileProperties from "./FileProperties";
import ItemSidecar from "../../Common/ItemSidecar";
import translations from "../../../../../translations/constants/translations";

type Props = {
  index: number;
};

const FileListHeader = (props: Props) => {
  const intl = useIntl();
  const { getProperties } = useGetFileMeta();

  const properties = getProperties(undefined, props.index);

  return (
    <>
      {properties && (
        <div>
          <Typography.Title level={5}>
            {intl.formatMessage(
              translations.ui.meta.fields.filesParametersName
            )}
          </Typography.Title>

          <FileProperties properties={properties} />

          <Space
            size={4}
            wrap
            style={{
              marginTop: ".5rem",
            }}
          >
            <ItemSidecar mode="multiple" index={props.index} />
          </Space>
        </div>
      )}
    </>
  );
};

export default FileListHeader;
