import { ExportOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { usePlatform } from "../../../../../hooks/usePlatform";
import { useIntl } from "react-intl";
import { RcFile } from "antd/lib/upload";
import translations from "../../../../../translations/constants/translations";
import { useCreateOrUpdateItemStore } from "../../../../../state/createOrUpdateItemStore";

type Props = {
  onClickRevitExport: () => void;
  onDropLocalFiles: (files: RcFile[]) => void;
  highlightRevitImport?: boolean;
  highlightLocalUpload?: boolean;
};

const FileListFooter = (props: Props) => {
  const platform = usePlatform();
  const intl = useIntl();

  const uploads = useCreateOrUpdateItemStore((state) => state.uploads);

  const exportingFromRevit = uploads.reduce(
    (previousExportingFromRevit, upload) =>
      previousExportingFromRevit || upload.exportingFromRevit,
    false
  );

  return (
    <div>
      {platform === "revit" && (
        <Button
          icon={<ExportOutlined />}
          style={{
            marginRight: ".5rem",
          }}
          type={props.highlightRevitImport ? "primary" : "default"}
          disabled={exportingFromRevit}
          loading={exportingFromRevit}
          onClick={props.onClickRevitExport}
        >
          {intl.formatMessage(
            translations.ui.upload.components.fileListFooterExportButton
          )}
        </Button>
      )}

      <Upload
        name="file"
        multiple={true}
        accept="*"
        fileList={[]}
        showUploadList={false}
        disabled={exportingFromRevit}
        beforeUpload={async (_, fileList) => {
          props.onDropLocalFiles(fileList);
          return false;
        }}
      >
        <Button
          disabled={exportingFromRevit}
          loading={exportingFromRevit}
          type={props.highlightLocalUpload ? "primary" : "default"}
          icon={<UploadOutlined />}
        >
          {intl.formatMessage(
            translations.ui.upload.components.fileListFooterUploadButton
          )}
          {props.highlightLocalUpload === true}
        </Button>
      </Upload>
    </div>
  );
};
export default FileListFooter;
