import { Tag } from "antd";
import { defineMessages, useIntl } from "react-intl";
import { pickFontColorBasedOnBackgroundColor } from "../../../utils/text-color.utils";

type Props = {
  value: boolean;
  style?: "enabledAndDisabled" | "rightAndWrong";
};

const wrongColor = "#e53935";
const rightColor = "#8bc34a";

const enabledColor = "#1565c0";
const disabledColor = "#424242";

const messages = defineMessages({
  enabled: {
    id: "components.render.boolean.enabled",
    defaultMessage: "Enabled",
    description: "Enabled state of the boolean render",
  },
  disabled: {
    id: "components.render.boolean.disabled",
    defaultMessage: "Disabled",
    description: "Disabled state of the boolean render",
  },
  correct: {
    id: "components.render.boolean.correct",
    defaultMessage: "Correct",
    description: "Correct state of the boolean render",
  },
  incorrect: {
    id: "components.render.boolean.incorrect",
    defaultMessage: "Incorrect",
    description: "Incorrect state of the boolean render",
  },
});

export default (props: Props) => {
  const intl = useIntl();

  const style = props.style ?? "enabledAndDisabled";

  const color = props.value
    ? style === "enabledAndDisabled"
      ? enabledColor
      : rightColor
    : style === "enabledAndDisabled"
    ? disabledColor
    : wrongColor;
  const textColor = pickFontColorBasedOnBackgroundColor(color, "#fff", "#000");

  const content = props.value
    ? style === "enabledAndDisabled"
      ? intl.formatMessage(messages.enabled)
      : intl.formatMessage(messages.correct)
    : style === "enabledAndDisabled"
    ? intl.formatMessage(messages.disabled)
    : intl.formatMessage(messages.incorrect);

  return (
    <Tag color={color} style={{ color: textColor }}>
      {content}
    </Tag>
  );
};
