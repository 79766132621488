import { Form } from "antd";
import { FormattedMessage } from "react-intl";
import { rootQueryClient } from "../../../../..";
import { SizeType } from "antd/es/config-provider/SizeContext";
import useFiltersStore from "../../../../../state/filtersStore";
import CategoryDropdown from "../../../Dropdowns/CategoryDropdown";
import FormProps from "../../../../../UiProperties/FormProps";

export const CategoriesFilterInput = () => {
  const filters = useFiltersStore((state) => state.currentFilters);
  const setFilters = useFiltersStore((state) => state.setFilters);

  // Sets the chosen category in the state
  const onChange = (values: string[]) => {
    setFilters({
      ...filters,
      categoriesIds: values,
    });

    // Reloads table items after the item has been approved or not
    rootQueryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === "distinctCategories",
    });
  };

  // Clears the filter state if the user clears the filter
  const onClear = () => {
    setFilters({ ...filters, categoriesIds: [] });
  };

  const selectProps = {
    style: { width: "10rem" },
    size: "middle" as SizeType,
    open: true,
    value: filters.categoriesIds,
    onChange: onChange,
    onClear: onClear,
    placeholder: (
      <FormattedMessage
        id="component.categories_filter_input.placeholder"
        defaultMessage="choose library"
        description="Placeholder for the select input of libraries filter"
      />
    ),
  };

  return (
    <Form.Item
      style={{ ...FormProps.formItem, margin: "0.2rem 0.5rem 0.2rem 0" }}
    >
      <CategoryDropdown type={"filter"} selectProps={selectProps} />
    </Form.Item>
  );
};
