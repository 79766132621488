import components from "./components";
import errors from "./errors";
import modals from "./modals";

const upload = {
  modals,
  errors,
  components,
};
export default upload;
