const revitPlatformMatches = ['BimFilesRevitPlugin', 'Autodesk.Revit'];

export const isRevit = (userAgent: string): boolean => {
    return revitPlatformMatches.some((match) => userAgent.includes(match));
};

export const usePlatform = (): "revit" | "browser" => {
    const userAgent = navigator.userAgent;

    return isRevit(userAgent) ? "revit" : "browser";
};