import {
  Category,
  CategoryChange,
  CombinedAccessToken,
  Item,
} from "@formitas-ag/bimfiles-types/lib";
import {
  CategoryRole,
  CategoryRoleEnum,
  UserRoleEnum,
  UserTypeEnum,
} from "@formitas-ag/bimfiles-types/lib/permission";
import api from "../api";
import { useAuthStore } from "../../state/authStore";
import { getChangeType } from "../../utils/change-type.utils";

export type CheckOptions =
  | GeneralCheckOptions
  | CategoryCheckOptions
  | ItemCheckOptions;

/**
 * Checks the general permissions of the user
 * @param allowedRoles 3
 */
type GeneralCheckOptions = {
  mode: "general";
  allowOnlyAdmins?: boolean;
  blockUser?: boolean;
  minimumUserRole?: CategoryRoleEnum;
};

type CategoryCheckOptions = {
  mode: "category";
  on: Category["id"];
  minimumRole: CategoryRoleEnum;
};

type ItemCheckOptions = {
  mode: "item";
  itemId: Item["id"];
  minimumRole: CategoryRoleEnum;
};

export const getCurrentOrganisation = () => {
  const user = useAuthStore.getState().user;
  const selectedOrganisationId = useAuthStore.getState().selectedOrganisationId;

  if (!user || !selectedOrganisationId) return undefined;

  const organisation = user.organisations.find(
    (o) => o.organisationId === selectedOrganisationId
  );

  if (!organisation) throw new Error(`No organisation selected`);

  return organisation;
};

/**
 * Checks the given token with the given ability and returns true if the user has permissions that are defined in the options. This involves checking the categories for permissions.
 * @param ability
 * @param token
 * @param options
 * @returns
 */
export const checkToken = async (
  token: CombinedAccessToken,
  options: CheckOptions
): Promise<boolean> => {
  if (getCurrentOrganisation() === undefined) return false;

  if (token.user.type === UserTypeEnum.ADMIN) return true;
  if (options.mode === "general" && options.allowOnlyAdmins) return false;
  if (getCurrentOrganisation()!.role === UserRoleEnum.MODERATOR) return true;

  if (options.mode === "general") {
    if (
      getCurrentOrganisation()!.role === UserRoleEnum.USER &&
      options.blockUser
    )
      return false;

    //find the currently selected organisation
    const currentOrganisation = token.user.organisations.find(
      (o) => o.organisationId === token.selectedOrganisationId
    );

    if (!currentOrganisation) {
      throw new Error(`No organisation selected`);
    }

    const categories = await api.categories.get(1, 10000, "default", {
      ignoreExceptions: true,
    });

    const highestCategoryRole = categories.data.reduce<CategoryRole>(
      (prev, curr) => {
        const userSpecificCategoryRole = curr.permissions.find(
          (p) => p.userId === token.user.id
        );

        const combinedRoleLevel =
          (userSpecificCategoryRole?.role || 0) + curr.defaultPermissions;

        return Math.max(prev, combinedRoleLevel);
      },
      0
    );

    return highestCategoryRole >= (options.minimumUserRole || 0);
  }

  if (options.mode === "category") {
    const category = await api.categories.getOne(options.on, {
      ignoreExceptions: true,
    });

    if (!category) return false;

    const userSpecificCategoryRole = category.permissions.find(
      (p) => p.userId === token.user.id
    );

    const combinedRoleLevel =
      (userSpecificCategoryRole?.role || 0) + category.defaultPermissions;

    return combinedRoleLevel >= options.minimumRole;
  }

  if (options.mode === "item") {
    const item = await api.items.getOne(options.itemId, {
      ignoreExceptions: true,
    });
    //only goal is to get the category of the item

    if (!item) return false;

    //when item is denied check if the user has approve permissions
    if (
      item.state === "denied" &&
      options.minimumRole === CategoryRoleEnum.APPROVE
    ) {
      console.log(`item.state === denied && minimumRole === approve`);
      return checkToken(token, {
        mode: "category",
        on: item.categoryId,
        minimumRole: CategoryRoleEnum.APPROVE,
      });
    }

    if (item.state === "approvable") {
      const categoryId = getItemCategoryId(item);

      console.log(`item.state === approvable, categoryId: ${categoryId}`);

      return checkToken(token, {
        mode: "category",
        on: categoryId,
        minimumRole: options.minimumRole,
      });
    }

    if (item.state === "approved") {
      const hasAnyApprovableChanges = item.changes.some(
        (change) => change.state === "approvable"
      );

      if (hasAnyApprovableChanges) {
        //there are changes that are not approved
        //we need to check for a category change
        //in case we find a category change we need to use it for the check before anything else
        const categoryChange = item.changes.find(
          (change) =>
            change.state === "approvable" &&
            getChangeType(change) === "CategoryChange"
        ) as CategoryChange | undefined;

        if (categoryChange) {
          console.log(
            `item.state === approved && hasAnyApprovableChanges && categoryChange, categoryId: ${categoryChange.categoryId}`
          );

          return checkToken(token, {
            mode: "category",
            on: categoryChange.categoryId,
            minimumRole: options.minimumRole,
          });
        }
      }

      console.log(
        `item.state === approved && hasAnyApprovableChanges, categoryId: ${item.categoryId}`
      );

      //no category change found, we can use the category of the item
      return checkToken(token, {
        mode: "category",
        on: item.categoryId,
        minimumRole: options.minimumRole,
      });
    }
  }

  return false;
};

export const isUserAllowedToEditUsersPermissions = async (
  targetId: string
): Promise<boolean> => {
  const user = await api.users.getOne(targetId, { ignoreExceptions: true });

  if (!user) return false;

  const currentOrganisationId = useAuthStore.getState().selectedOrganisationId;

  const editor = useAuthStore.getState().user!;

  const editorUserType = editor.type ?? undefined;
  const editorUserRole = getCurrentOrganisation()?.role ?? undefined;

  if (!editorUserType || !editorUserRole) return false;

  const userType = user.type;
  const userRole = user.organisations.find(
    (o) => o.organisationId === currentOrganisationId
  )?.role;

  // console.log(
  //   `isUserAllowedtoEditUserPermissions: ${editorUserType}:${editorUserRole} => ${userType}:${userRole}`
  // );

  //admins are allowed to edit each other
  if (
    editorUserType === UserTypeEnum.ADMIN &&
    userType === UserTypeEnum.ADMIN
  ) {
    return true;
  }

  //admins are allowed to edit moderators and users
  if (
    editorUserType === UserTypeEnum.ADMIN &&
    userType !== UserTypeEnum.ADMIN
  ) {
    return true;
  }

  //moderators are allowed to edit users and moderators
  if (
    editorUserRole === UserRoleEnum.MODERATOR &&
    userType !== UserTypeEnum.ADMIN &&
    (userRole === UserRoleEnum.USER || userRole === UserRoleEnum.MODERATOR)
  ) {
    return true;
  }

  return false;
};

/**
 * Returns the items category id from either the item or the changes
 * @param item the item
 * @returns the category id
 */
export const getItemCategoryId = (item: Item): string => {
  if (item.categoryId) return item.categoryId;

  const categoryChange = item.changes.find(
    (change) => getChangeType(change) === "CategoryChange"
  ) as CategoryChange;

  return categoryChange.categoryId;
};
