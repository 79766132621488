import { Typography } from "antd";

export const ellipsisRender = (value: any, withWidthLimit?: boolean) => (
  <Typography.Text
    ellipsis={true}
    {...(withWidthLimit ? { style: { maxWidth: "300px" } } : {})}
  >
    {value}
  </Typography.Text>
);

export const noStringRender = () => <span>NOSTRINGERROR</span>;