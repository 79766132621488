import { Organisation, Pagination, Response, Tag, User } from "@formitas-ag/bimfiles-types";
import axiosService from "./fetch/axios.service";
import { create, get, getOne, remove, update } from "./utils/endpoint.utils";
import { errorRenderer } from "./utils/errorThrower";

type CreateOrganisationData = {
  title: string;
  description: string;
  host: string;
  domainFilter: string[];
};

type UpdateOrganisationData = Partial<CreateOrganisationData>;

const organisations = {
  get: (page: number, size: number) =>
    get<Organisation>("organisations", page, size),
  getOne: (id: string) => getOne<Organisation>("organisations", id),
  create: (data: CreateOrganisationData) =>
    create<Organisation, CreateOrganisationData>("organisations", data),
  update: (id: string, data: UpdateOrganisationData) =>
    update<Organisation, UpdateOrganisationData>("organisations", id, data),
  delete: (id: string): Promise<void> => remove("organisations", id),

  tags: {
    get: (id: string, page: number, size: number) =>
      new Promise<Pagination<Tag>>(async (resolve, reject) => {
        try {
          const url = `/organisations/${id}/tags?page=${page}&size=${size}`;
          const response = await axiosService.get<Response<Pagination<Tag>>>(
            url
          );

          if (response.data && response.data.success) {
            resolve(response.data.result);
          }
          reject(response.statusText);
        } catch (error) {
          reject(errorRenderer(error));
        }
      }),
  },
  users: {
    get: (id: string, page: number, size: number) =>
      new Promise<Pagination<User>>(async (resolve, reject) => {
        try {
          const url = `/organisations/${id}/users?page=${page}&size=${size}`;
          const response = await axiosService.get<Response<Pagination<User>>>(
            url
          );

          if (response.data && response.data.success) {
            resolve(response.data.result);
          }
          reject(response.statusText);
        } catch (error) {
          reject(errorRenderer(error));
        }
      }),
  },
};

export default organisations;
