import { useEffect } from "react";
import { useCreateOrUpdateItemStore } from "../../state/createOrUpdateItemStore";
import { useViewStore } from "../../state/viewStore";
import { UpdatingFile } from "../../utils/types/updateFilesTypes";
import useGetFileMeta from "./useGetFileMeta";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";

export default () => {
  const { getName } = useGetFileMeta();

  const uploads = useCreateOrUpdateItemStore((state) => state.uploads);

  /**
   * Creates a new updating file for each file that exists on the existing item
   * @param item the existing item
   * @returns the new updating files
   */
  const _createUpdatingFileForExistingFiles = (
    item?: ItemPopulated,
    existingFiles: UpdatingFile[] = []
  ): UpdatingFile[] => {
    if (item) {
      return item.files
        .filter((f) => !existingFiles.some((ef) => ef.id === f.id))
        .sort((a, b) => a.name.localeCompare(b.name))
        .map<UpdatingFile>((file, index) => {
          return {
            id: file.id,
            slot: index,
            file,
            state: "default",
          };
        });
    }

    return [];
  };

  /**
   * Assigns a slot to each file that does not contain a slot yet
   * @param files the existing files
   * @returns all files with slots
   */
  const _assignSlotsToUpdatingFilesWithoutSlots = (files: UpdatingFile[]) => {
    const filesWithoutSlot = files.filter((file) => file.slot === -1);

    if (filesWithoutSlot.length > 0) {
      const startIndex = files.length - filesWithoutSlot.length;

      //sort all the files without slots by name and assign them a slot
      const sortedFiles = filesWithoutSlot
        .sort((a, b) => {
          return getName(a).localeCompare(getName(b));
        })
        .map((file, index) => {
          return {
            ...file,
            slot: startIndex + index,
          };
        });

      //and append them to the end of the updatingFiles array
      return files.filter((file) => file.slot !== -1).concat(sortedFiles);
    }

    return files;
  };

  // useEffect(() => {
  //   let hasChanged = false;
  //   uploads.map((upload) => {
  //     if (upload.updatingFiles.some((f) => f.slot === -1)) {
  //       hasChanged = true;
  //       upload = {
  //         ...upload,
  //         updatingFiles: _assignSlotsToUpdatingFilesWithoutSlots(
  //           upload.updatingFiles
  //         ),
  //       };
  //     }

  //     if (upload.oldItem && upload.oldItem.files.length > 0) {
  //       //we have existing files
  //       const updatingFilesForExistingFiles =
  //         _createUpdatingFileForExistingFiles(
  //           upload.oldItem,
  //           upload.updatingFiles
  //         );

  //       if (updatingFilesForExistingFiles.length > 0) {
  //         hasChanged = true;
  //         upload = {
  //           ...upload,
  //           updatingFiles: updatingFilesForExistingFiles,
  //         };
  //       }
  //     }
  //   });

  //   if (hasChanged) {
  //     useCreateOrUpdateItemStore.setState({
  //       uploads,
  //     });
  //   }
  // }, [uploads]);
};
