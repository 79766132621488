import { useCallback, useEffect, useRef, useState } from "react";
import qs from "qs";
import { flattenFilters } from "@formitas-ag/formitable";
import { apiOptionstype } from "@formitas-ag/formitable/lib/EditDrawer.types";
import { get } from "../api/utils/endpoint.utils";

export type filterType = Record<
  string,
  (string | number | boolean)[] | null | { $in: string[] }
>;
interface receivedDataType<dataType> {
  /** contains the actual items in an array */
  dataArray: dataType[];
  /** loaded status */
  loaded: boolean;
  /** pagination limit / page size */
  limit: number;
  /** total number of unpaginated data */
  total: number;
}

/** The item in the store might also contain TTL information */
type cacheDataType<dataType> = receivedDataType<dataType> & {
  restCacheValidUntil?: number;
};

export interface idBasedData {
  id?: string;
  [x: string]: any;
}

export type pageInfoType = { limit: number; total: number };

export default function useApi<dataType extends idBasedData>(
  serviceName: string,
  initialOptions?: apiOptionstype
) {
  // : [
  //     /** Daten */
  //     dataType[],
  //     /** data loaded */
  //     boolean,
  //     pageInfoType,
  //     () => void,
  //     (id: string, updatedData: dataType) => void
  // ]
  const ref = useRef(0);
  /** Immutable options. If set (updated), this will trigger a reload. */
  const [options, setOptions] = useState<apiOptionstype>(initialOptions || {});
  const defaultLimit = 10;
  const [receivedData, setReceivedData] = useState<receivedDataType<dataType>>(
    () => {
      return {
        dataArray: [],
        loaded: false,
        limit: typeof options.limit === "number" ? options.limit : defaultLimit,
        total: 0,
      };
    }
  );

  // can be fired when a form modifies a single item in the current page set
  const updateData = (id: string, updatedData: dataType) => {
    setReceivedData((prevData) => ({
      ...prevData,
      dataArray: prevData.dataArray.map((item) => {
        if (item.id !== id) return item;
        return updatedData;
      }),
    }));
  };

  const loadData = useCallback(
    (allowCache: boolean = false) => {
      // do not query if we have a limit of -1
      if (options.limit === -1) return;

      const params = {
        paginated: true,
        limit: typeof options.limit === "number" ? options.limit : defaultLimit,
        skip: options.skip || 0,
        ...(options.sortBy && options.sortDir
          ? { sortBy: options.sortBy, sortDir: options.sortDir }
          : {}),
        ...(options && options.filters ? flattenFilters(options) : {}),
        ...(options.select ? { select: options.select } : {}),
        ...(options.queryAdditions || {}),
      };

      const paramsString = qs.stringify(params, { arrayFormat: "comma" });
      const cacheIdent = `restCache__${serviceName}?${paramsString}`;

      // if we want to use cache HERE (allowCache for this call)
      // and in GENERAL (options.cacheTtlSeconds)
      if (
        checkCache<dataType>(
          allowCache === true && typeof options.cacheTtlSeconds === "number"
            ? options.cacheTtlSeconds
            : false,
          cacheIdent,
          (cacheObject) => {
            // if we have previously loaded the object
            setReceivedData(cacheObject);
          }
        )
      )
        return;

      // register loaded state in cache if we are using cache (regardless of if we want to use cache for THIS call)
      setCache(
        options.cacheTtlSeconds,
        cacheIdent,
        (cacheSetter, currentCacheObject) => {
          cacheSetter({
            ...(currentCacheObject || {
              dataArray: [],
              limit:
                typeof options.limit === "number"
                  ? options.limit
                  : defaultLimit,
              loaded: false,
              total: 0,
            }),
            loaded: false,
          });
        }
      );
      // and in state
      setReceivedData((previousData) => ({ ...previousData, loaded: false }));

      const page =
        typeof options.skip === "number" &&
        typeof options.limit === "number" &&
        options.skip &&
        options.limit
          ? Math.ceil(options.skip / options.limit) + 1
          : 1;

      get<dataType>(serviceName, page, options.limit ?? 0).then((response) => {
        if (ref.current) {
          if (!response) throw new Error("No response from API");

          // register new content in cache if we are using cache (regardless of if we want to use cache for THIS call)
          if (typeof response.data === "undefined")
            console.warn(
              "formitable API: No items in response. Make sure the api uses the standard format, sending items, limit, skip and total"
            );

          setCache(options.cacheTtlSeconds, cacheIdent, (cacheSetter) => {
            cacheSetter({
              limit: typeof options.limit === "number" ? options.limit : 0,
              dataArray: response.data || [],
              total: response.totalDocuments,
              loaded: true,
            });
          });
          setReceivedData({
            limit: typeof options.limit === "number" ? options.limit : 0,
            dataArray: response.data || [],
            total: response.totalDocuments,
            loaded: true,
          });
        }
      });
    },
    [options, serviceName]
  );

  useEffect(() => {
    ref.current = setTimeout(() => loadData(true), 0) as unknown as number;

    return () => {
      // cancel the initial data fetching in case we did not start yet
      clearTimeout(ref.current);
      // self awareness of being unmounted
      ref.current = 0;
    };
  }, [options, loadData]);

  return {
    data: receivedData.dataArray,
    dataLoaded: receivedData.loaded,
    pageInfo: { limit: receivedData.limit, total: receivedData.total },
    reloadData: loadData,
    updateData,
    options,
    setOptions,
  };
}

function checkCache<dataType>(
  useCache: false | number,
  cacheIdent: string,
  callback: (cacheObject: receivedDataType<dataType>) => void
) {
  if (useCache === false) return false;

  // start checkCache with callback, if true, return
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(cacheIdent);
    // Parse stored json or if none return initialValue
    let itemObject: cacheDataType<dataType>;
    if (item) {
      itemObject = JSON.parse(item);

      // if the object is not valid anymore, delete the cache item
      if (
        itemObject.restCacheValidUntil &&
        new Date().getTime() > itemObject.restCacheValidUntil
      ) {
        window.localStorage.removeItem(cacheIdent);
        return false;
      }

      if (itemObject.loaded === true) {
        // do net pass the TTL info to the caller
        delete itemObject.restCacheValidUntil;
        callback(itemObject);
        return true;
      }
    }
    return false;
  } catch (error) {
    // If error also return initialValue
    console.log("Error getting cache item from local storage: ", error);
    return false;
  }
}

function setCache<dataType>(
  ttlSeconds: undefined | number,
  cacheIdent: string,
  callback: (
    cacheSetter: (newContent: receivedDataType<dataType>) => void,
    cacheObject?: receivedDataType<dataType>
  ) => void
) {
  if (typeof ttlSeconds === "undefined") return false;

  // start checkCache with callback, if true, return
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(cacheIdent);
    // Parse stored json or if none return initialValue
    let itemObject: receivedDataType<dataType> | undefined = undefined;
    if (item) {
      itemObject = JSON.parse(item);
    }

    callback((newContent) => {
      // take newContent object as defined by caller and add the cache ttl (valid until) to it
      window.localStorage.setItem(
        cacheIdent,
        JSON.stringify({
          ...newContent,
          restCacheValidUntil: new Date().getTime() + ttlSeconds * 1000,
        })
      );
    }, itemObject);
    return true;
  } catch (error) {
    // If error also return initialValue
    console.log("Error getting cache item from local storage: ", error);
    return false;
  }
}
