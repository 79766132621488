import { devtools } from "zustand/middleware";
import { create } from "zustand";
import { LocalFile } from "../hooks/useUpload";

type UploadStore = {
  currentUploadItemId: string | undefined;
  setCurrentUploadItemId: (currentUploadItem: string) => void;

  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;

  currentState: "SELECT_FILE" | "UPLOADING_FILES" | "INSERTING_META" | "DONE";
  setCurrentState: (state: UploadStore["currentState"]) => void;

  currentUploadProgress?: number;
  setCurrentUploadProgress: (currentUploadProgress?: number) => void;

  currentFiles: LocalFile[];
  setCurrentFiles: (currentFiles: LocalFile[]) => void;

  temporaryFiles: LocalFile[];
  setTemporaryFiles: (temporaryFiles: LocalFile[]) => void;

  metaFiles: LocalFile[];
};

export const useUploadStore = create<UploadStore>()(
  devtools((set, get) => ({
    currentUploadItemId: undefined,
    setCurrentUploadItemId: (currentUploadItemId) =>
      set({ currentUploadItemId }),

    modalOpen: false,
    setModalOpen: (open) => set({ modalOpen: open }),

    currentState: "SELECT_FILE",
    setCurrentState: (currentState) => set({ currentState }),

    currentUploadProgress: undefined,
    setCurrentUploadProgress(currentUploadProgress) {
      set({ currentUploadProgress });
    },

    currentFiles: [],
    setCurrentFiles(currentFiles) {
      set({ currentFiles });
    },

    temporaryFiles: [],
    setTemporaryFiles(temporaryFiles) {
      set({ temporaryFiles });
    },

    metaFiles: [],
  }))
);

useUploadStore.subscribe((newState, oldState) => {
  if (newState.currentState !== oldState.currentState) {
    console.log(`[Upload] State: ${newState.currentState}`);
  }
});
