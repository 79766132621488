import { LocalFile } from "../hooks/useUpload";

export const fileToArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = function (e) {
      resolve(reader.result as ArrayBuffer);
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const changeNameDuplicates = async (
  files: LocalFile[]
): Promise<LocalFile[]> => {
  const newCombinedFiles = await Promise.all(
    files.map(async (tempFile, tempIndex) => {
      const index = files.findIndex(
        (file, ind) => file.name === tempFile.name && ind !== tempIndex
      );
      if (index !== -1) {
        const indexOfDot = tempFile.name.lastIndexOf(".");
        tempFile.name =
          tempFile.name.slice(0, indexOfDot) +
          "(1)" +
          tempFile.name.slice(indexOfDot);
      }
      return tempFile;
    })
  );
  return newCombinedFiles;
};

export const download = (files: string | string[]) => {
  const urls = Array.isArray(files) ? files : [files];

  var url = urls.pop();

  if (!url) return;

  var a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", "");
  a.setAttribute("target", "_blank");
  a.click();

  if (urls.length) {
    setTimeout(() => download(urls), 300);
  }
};
