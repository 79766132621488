import { Item } from "@formitas-ag/bimfiles-types";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { create } from "zustand";

type ViewStoreType = {
  selectedTimelineItemId?: Item["id"];

  selectedToBeUpdatedItem?: ItemPopulated;

  selectedToDeleteItem?: Item;

  selectedItemToBeDenied?: ItemPopulated;

  openRequiresRevitModal?: boolean;

  requiresRevitModalDetail?: string;

  addTagsModal?: boolean;

  mergeTagsModal?: boolean;

  viewDetailsInBatchUploadModal?: boolean;
  hideIgnoredFilesInBatchUploadModal?: boolean;

  editRoleUserId?: string;
  editRoleCategoryId?: string;
};

export const useViewStore = create<ViewStoreType>((set) => ({
  selectedTimelineItem: undefined,

  selectedToBeUpdatedItem: undefined,

  selectedToDeleteItemId: undefined,

  selectedItemToBeDenied: undefined,
}));
