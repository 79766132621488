import { useEffect } from "react";
import { useLanguageStore } from "../state/languageStore";
import axios from "axios";

const useGetOSTTranslation = () => {
  const ostTranslations = useLanguageStore((state) => state.ostTranslations);
  const setOstTranslations = useLanguageStore(
    (state) => state.setOstTranslations
  );

  useEffect(() => {
    const fetchOSTTranslations = async () => {
      const response = await axios.get(
        "https://bimfiles-static-config.s3.eu-central-1.amazonaws.com/ost-translations.json",
        {
          responseType: "json",
        }
      );

      const data = await response.data;
      setOstTranslations(data);
    };

    fetchOSTTranslations();
  }, []);

  return ostTranslations;
};

export default useGetOSTTranslation;
