import { ApiDrivenTable } from "@formitas-ag/formitable";
import { Col, Row } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { ColumnsType } from "antd/lib/table";
import { Organisation } from "@formitas-ag/bimfiles-types/lib";
import useApiAll from "../../hooks/useApiAll";
import {
  formFieldType,
  typeEnum,
} from "@formitas-ag/formitable/lib/EditDrawer.types";
import { defineMessages, IntlShape, useIntl } from "react-intl";
import { getApiDrivenTableLanguage } from "./Common/apiTableLanguage";
import parameterRender from "./Common/parameterRender";
import DomainFilterRender from "./Organisations/DomainFilterRender";
import BooleanRender from "./Common/BooleanRender";

const messages = defineMessages({
  title: {
    id: "admin.organisations.title",
    defaultMessage: "Organisations",
    description: "The title of the organisations admin page",
  },
  hostField: {
    id: "admin.organisations.field.host",
    defaultMessage: "Host",
    description: "The host field of an organisation",
  },
  domainFilterField: {
    id: "admin.organisations.field.domain_filter",
    defaultMessage: "Domain filter",
    description: "The domain filter field of an organisation",
  },
  titleField: {
    id: "admin.organisations.field.title",
    defaultMessage: "Title",
    description: "The title field of an organisation",
  },
  descriptionField: {
    id: "admin.organisations.field.description",
    defaultMessage: "Description",
    description: "The description field of an organisation",
  },
  guestField: {
    id: "admin.organisations.field.guest",
    defaultMessage: "Is Guest?",
    description: "The guest field of an organisation",
  },
  createdAtField: {
    id: "admin.organisations.field.created_at",
    defaultMessage: "Created at",
    description: "The created at field of an organisation",
  },
  updatedAtField: {
    id: "admin.organisations.field.updated_at",
    defaultMessage: "Updated at",
    description: "The updated at field of an organisation",
  },
});

const getFormFields = (intl: IntlShape): formFieldType[] => [
  {
    title: intl.formatMessage(messages.titleField),
    dataIndex: "title",
    key: "title",
    required: true,
  },
  {
    title: intl.formatMessage(messages.descriptionField),
    dataIndex: "description",
    key: "description",
    required: true,
  },
  {
    title: intl.formatMessage(messages.hostField),
    key: "host",
    dataIndex: "host",
    type: typeEnum.input,
    required: true,
  },
  {
    title: intl.formatMessage(messages.domainFilterField),
    dataIndex: "domainFilter",
    key: "domainFilter",
    type: typeEnum.input,
    rules: [
      {
        type: "regexp",
      },
    ],
    array: true,
    arrayOptions: {
      minItems: 1,
    },
  },
];

const getColumns = (intl: IntlShape): ColumnsType<Organisation> => [
  { title: intl.formatMessage(messages.titleField), dataIndex: "title" },
  {
    title: intl.formatMessage(messages.descriptionField),
    dataIndex: "description",
  },
  {
    title: intl.formatMessage(messages.domainFilterField),
    dataIndex: "domainFilter",
    render: parameterRender<Organisation, "domainFilter">((domainFilter) => (
      <DomainFilterRender domainFilter={domainFilter} />
    )),
  },
  {
    title: intl.formatMessage(messages.guestField),
    dataIndex: "flags",
    render: parameterRender<Organisation, "flags">((flags) => (
      <BooleanRender value={flags?.isGuest ?? false} />
    )),
  },
];

export const OrganisationAdminPage = () => {
  const intl = useIntl();
  const api = useApiAll<Organisation>({
    options: { limit: 20 },
    service: "organisations",
  });

  return (
    <Row>
      <Col span={24}>
        <PageHeader title={intl.formatMessage(messages.title)} />
      </Col>
      <Col span={24}>
        <ApiDrivenTable
          columns={getColumns(intl)}
          serviceName="organisations"
          api={api}
          rowKey="id"
          language={getApiDrivenTableLanguage(intl)}
          reloadButton
          editInfo={{
            formFields: getFormFields(intl),
            editable: true,
            creatable: true,
            deletable: true,
          }}
        />
      </Col>
    </Row>
  );
};
