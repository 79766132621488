import { Tag } from "antd";
import { File } from "@formitas-ag/bimfiles-types/lib";
import { defineMessages, useIntl } from "react-intl";

type Props = {
  title?: React.ReactNode | ((textTitle: string) => React.ReactNode);
  role?: File["role"];
  fileName?: string;
  color?: string;
  onClose?: () => void;
  fullWidth?: boolean;
};

const messages = defineMessages({
  family: {
    id: "components.file-role-tag.family",
    defaultMessage: "Family",
    description: "Label for the family file role tag",
  },
  thumbnail: {
    id: "components.file-role-tag.thumbnail",
    defaultMessage: "Thumbnail",
    description: "Label for the thumbnail file role tag",
  },
  sidecar: {
    id: "components.file-role-tag.sidecar",
    defaultMessage: "Sidecar",
    description: "Label for the sidecar file role tag",
  },
  "sidecar-automatic": {
    id: "components.file-role-tag.sidecar-automatic",
    defaultMessage: "Sidecar (automatic)",
    description: "Label for the sidecar (automatic) file role tag",
  },
  revit: {
    id: "components.file-role-tag.revit",
    defaultMessage: "Revit",
    description: "Label for the revit file role tag",
  },
  other: {
    id: "components.file-role-tag.other",
    defaultMessage: "Other",
    description: "Label for the other file role tag",
  },
});

const familyColor = "#1766f2";
const automaticallyGeneratedColor = "#b0bec5";
const otherColor = "#455a64";

const FileRoleTag = (props: Props) => {
  const intl = useIntl();
  const fileExtensionFilter = /(?:\.([^.]+))?$/;
  const textTitle =
    props.role === "family"
      ? intl.formatMessage(messages.family)
      : props.role === "thumbnail"
      ? intl.formatMessage(messages.thumbnail)
      : props.role === "sidecar"
      ? intl.formatMessage(messages.sidecar)
      : props.role === "sidecar-automatic"
      ? intl.formatMessage(messages["sidecar-automatic"])
      : props.role === "revit"
      ? intl.formatMessage(messages.revit)
      : props.fileName
      ? fileExtensionFilter.exec(props.fileName)![1].toUpperCase()
      : intl.formatMessage(messages.other);

  const color =
    props.role === "family"
      ? familyColor
      : props.role === "thumbnail" || props.role === "sidecar-automatic"
      ? automaticallyGeneratedColor
      : otherColor;

  return (
    <Tag
      key={`${props.role}-${props.fileName}`}
      color={props.color ?? color}
      closable={props.onClose !== undefined}
      onClose={props.onClose}
      style={props.fullWidth ? { width: "100%", textAlign: "center" } : {}}
    >
      {props.title === undefined
        ? textTitle
        : props.title instanceof Function
        ? props.title(textTitle) ?? textTitle
        : props.title}
    </Tag>
  );
};
export default FileRoleTag;
