import { message } from "antd";
import { AxiosInstance } from "axios";
import { errorRenderer } from "../utils/errorThrower";

const errorHandler = (axiosInstance: AxiosInstance) => {
  const errorMap = new Map();

  const debounce = (key: string) => {
    // Check if the error message was displayed within the last 5 seconds
    if (errorMap.has(key) && Date.now() - errorMap.get(key) < 5000) {
      return true;
    } else {
      errorMap.set(key, Date.now());
      return false;
    }
  };

  const errorHandler = (error: any) => {
    try {
      const statusCode = error.response?.status;
      const errorMessage = error.response?.data.message;
      const errorId = error.response?.data.id;

      if (statusCode && statusCode !== 401) {
        console.error(error);
        if (!debounce(errorId)) {
          message.error(`Something went wrong: ${errorMessage}`);
        }

        throw new Error(errorMessage);
      }

      if (statusCode === 401) {
        console.error(error);
        if (!debounce(errorId)) {
          message.error(`Please login again`);
        }

        throw new Error(errorMessage);
      }
    } catch (e) {
      return Promise.reject(errorRenderer(e));
    }

    return Promise.reject(error.response?.data.message);
  };

  axiosInstance.interceptors.response.use(undefined, (error) => {
    return errorHandler(error);
  });
};

export default errorHandler;
