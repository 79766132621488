import admin from "./admin";
import authentication from "./authentication";
import generic from "./generic";
import itemTable from "./itemTable";
import meta from "./meta";
import modals from "./modals";
import upload from "./upload";

const ui = {
  authentication,
  upload,
  meta,
  itemTable,
  generic,
  admin,
  modals,
};
export default ui;
