import { defineMessages } from "react-intl";

const downloadItems = defineMessages({
  importToRevitTooltip: {
    id: "ui.item-table.download-items.import-to-revit-tooltip",
    defaultMessage: "Import Family",
    description: "Tooltip for import revit button",
  },
  importToRevitNotAvailableTooltip: {
    id: "ui.item-table.download-items.import-to-revit-not-available-tooltip",
    defaultMessage: "No Family available",
    description: "Tooltip for import revit button when no family is available",
  },
  importToRevitFailedMessage: {
    id: "ui.item-table.download-items.import-to-revit-failed-message",
    defaultMessage: "Could not import to Revit ({message})",
    description: "Message displayed when import to revit failed",
  },
});

export default downloadItems;
