import React from "react";
import { defaultRender } from "./TextRender";

type ReturnType = (value: unknown) => React.ReactNode | null;

export const untypedParameterRender = (
  key: string,
  render?: (value: unknown) => React.ReactNode
): ReturnType => {
  return (value: unknown) => {
    if (!Array.isArray(value)) return null;

    const v = (value as { key: string; value: string }[]).find(
      (element) => typeof element.key === key
    );
    if (!v) return null;

    return render ? render(v.value) : defaultRender(v.value as string);
  };
};

export default <Type, KeyType extends keyof Type>(
  render?: (value: Type[KeyType]) => React.ReactNode
): ReturnType => {
  return (value: unknown) => {
    if(!value) return null;

    return render
      ? render(value as Type[KeyType])
      : defaultRender(value as string);
  };
};
