import { Button, Col, Input, Modal, Row, Typography, message } from "antd";
import { closeChangeUserPasswordModal } from "./util/openModal";
import { useAccessToken } from "../../../api/utils/auth.utils";
import { useAdminStore } from "../../../state/adminStore";
import { CombinedAccessToken } from "@formitas-ag/bimfiles-types";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import jwtDecode from "jwt-decode";
import api from "../../../api/api";

const UpdateUserPasswordModal = () => {
  const selectedUserId = useAdminStore(
    (state) => state.changeUserPasswordSelectedUserId
  );
  const open = selectedUserId !== undefined;

  const [accessToken] = useAccessToken();
  const [newPassword, setNewPassword] = useState("");
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [canChangePassword, setCanChangePassword] = useState(false);
  const [disableButton, setDisableButton] = useState(true);

  const updatePassword = async () => {
    if (selectedUserId) {
      setLoadingPassword(true);
      try {
        await api.users.updatePassword(selectedUserId, undefined, newPassword);
        setLoadingPassword(false);
        closeChangeUserPasswordModal();
      } catch (e) {
        setLoadingPassword(false);
        message.error("Error: " + e ?? "Unknown Error");
      }
    }
  };

  useEffect(() => {
    if (accessToken) {
      const token: CombinedAccessToken = jwtDecode(accessToken);

      setCanChangePassword(token.user.type === "admin");
    }
  }, [accessToken]);

  useEffect(() => {
    if (newPassword.length > 0) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [newPassword]);

  const userQuery = useQuery({
    queryKey: ["user", selectedUserId],
    queryFn: () => {
      if (selectedUserId) {
        return api.users.getOne(selectedUserId);
      }
    },
  });

  return (
    <Modal
      open={open}
      title={
        "Change " +
        (userQuery.data
          ? userQuery.data?.preName + " " + userQuery.data?.name
          : " ...") +
        "'s Password"
      }
      centered
      style={{ padding: "2rem" }}
      onOk={closeChangeUserPasswordModal}
      closable={true}
      onCancel={closeChangeUserPasswordModal}
      footer={null}
    >
      {canChangePassword && (
        <Row gutter={[16, 0]}>
          <Col span={24}>
            <Typography.Title level={3}>Change Password</Typography.Title>
          </Col>
          <Col span={18}>
            <Input.Password
              size="large"
              disabled={loadingPassword}
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
            />
          </Col>

          <Col span={6}>
            <Button
              type="primary"
              disabled={disableButton}
              style={{ width: "100%" }}
              onClick={updatePassword}
              loading={loadingPassword}
              size="large"
            >
              Update
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default UpdateUserPasswordModal;
