import { CategoryRole, CategoryRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";
import { pickFontColorBasedOnBackgroundColor } from "../../../utils/text-color.utils";
import { Tag } from "antd";
import { useIntl } from "react-intl";
import translations from "../../../translations/constants/translations";

type Props = {
  role: CategoryRole;
  fullWidth?: boolean;
};

export const CategoryRoleRender = ({ role, fullWidth }: Props) => {
  const intl = useIntl();

  const text =
    role >= CategoryRoleEnum.READ && role < CategoryRoleEnum.WRITE
      ? intl.formatMessage(translations.ui.generic.roleRead)
      : role >= CategoryRoleEnum.WRITE && role < CategoryRoleEnum.APPROVE
      ? intl.formatMessage(translations.ui.generic.roleWrite)
      : role >= CategoryRoleEnum.APPROVE
      ? intl.formatMessage(translations.ui.generic.roleApprove)
      : intl.formatMessage(translations.ui.generic.roleNone);

  const color =
    role >= CategoryRoleEnum.READ && role < CategoryRoleEnum.WRITE
      ? "#8bc34a"
      : role >= CategoryRoleEnum.WRITE && role < CategoryRoleEnum.APPROVE
      ? "#ffb300"
      : role >= CategoryRoleEnum.APPROVE
      ? "#e53935"
      : "#616161";
  const textColor = pickFontColorBasedOnBackgroundColor(color, "#fff", "#000");

  return (
    <Tag
      color={color}
      style={{
        color: textColor,
        width: fullWidth ? "100%" : undefined,
        textAlign: fullWidth ? "center" : undefined,
      }}
    >
      {text}
    </Tag>
  );
};
