import { Form, Input } from "antd";
import { Item } from "@formitas-ag/bimfiles-types/lib";

const DeleteItemConfirmation: React.FC<{
  item: Item;
  deleteReason: string | null;
  setDeleteReason: React.Dispatch<React.SetStateAction<string | null>>;
}> = (props) => {
  return (
    <Form layout="vertical">
      <Form.Item label="Why would you like to delete this item?">
        <Input.TextArea
          onChange={(e) => {
            props.setDeleteReason(e.target.value);
          }}
          spellCheck={false}
        />
      </Form.Item>
    </Form>
  );
};

export default DeleteItemConfirmation;
