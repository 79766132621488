import { AbstractRoleTable, AbstractRoleTableData } from "./AbstractRoleTable";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import api from "../../../api/api";
import { CategoryRole, CategoryRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";
import { useViewStore } from "../../../state/viewStore";
import { Modal } from "antd";
import { useIntl } from "react-intl";
import translations from "../../../translations/constants/translations";

export const CategoryUsersModal = () => {
  const intl = useIntl();
  const categoryId = useViewStore((state) => state.editRoleCategoryId);

  const categoryQuery = useQuery({
    queryKey: ["categories", categoryId],
    queryFn: () => {
      if (!categoryId) return Promise.resolve(undefined);
      return api.categories.getOne(categoryId);
    },
  });

  const usersQuery = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const result = await api.users.get(1, 10000);

      if (!result) throw new Error("No users found");

      return result;
    },
  });

  const [data, setData] = useState<AbstractRoleTableData[]>([]);

  useEffect(() => {
    if (!categoryQuery.data || !usersQuery.data) return;

    const data: AbstractRoleTableData[] =
      categoryQuery.data.permissions.map<AbstractRoleTableData>(
        (permission) => {
          const user = usersQuery.data.data.find(
            (u) => u.id === permission.userId
          )!;

          return {
            id: user.id,
            name: user.preName + " " + user.name,
            role: permission.role,
            overwriteRole: categoryQuery.data?.defaultPermissions,
          };
        }
      );

    setData(
      data.concat(
        usersQuery.data.data
          .filter((u) => !data.some((d) => d.id === u.id))
          .filter((u) => u.type !== "admin")
          .map((user) => {
            return {
              id: user.id,
              name: user.preName + " " + user.name,
              role: CategoryRoleEnum.NONE,
              overwriteRole: categoryQuery.data?.defaultPermissions,
            };
          })
      )
    );
  }, [categoryQuery.data, usersQuery.data]);

  const updateUserPermissions = async (userId: string, role: CategoryRole) => {
    await api.users.categoryRole(userId, categoryId!, role);

    //refetch queries
    categoryQuery.refetch();
    usersQuery.refetch();
  };

  const onAllRolesChange = async (role: CategoryRole) => {
    for (const element of data) {
      await updateUserPermissions(element.id, role);
    }
  };

  return (
    <Modal
      open={categoryId !== undefined}
      title={intl.formatMessage(
        translations.ui.admin.users.categoryUsersModalTitle,
        {
          category: categoryQuery.data?.title,
        }
      )}
      style={{ padding: "2rem", top: 20, bottom: 20 }}
      bodyStyle={{
        overflow: "auto",
        height: "70vh",
      }}
      onOk={() => useViewStore.setState({ editRoleCategoryId: undefined })}
      closable={true}
      onCancel={() => useViewStore.setState({ editRoleCategoryId: undefined })}
      footer={null}
      width={"80%"}
    >
      <AbstractRoleTable
        keyPrefix="category-users-"
        data={data}
        onSingleRoleChange={updateUserPermissions}
        onAllRolesChange={onAllRolesChange}
        enableFeatures={{
          enableFinalPermissions: true,
          enableOverwritePermissions: true,
          enableChangingPermissions: true,
        }}
      />
    </Modal>
  );
};
