import jwtDecode from "jwt-decode";
import { PropsWithChildren, PureComponent, useEffect, useState } from "react";
import { accessTokenObservable, useAccessToken } from "../utils/auth.utils";
import { CheckOptions, checkToken } from "./categoryAbilityExtension";

type Props = {
  alternativeChildren?: React.ReactNode;
  not?: boolean;
} & PropsWithChildren &
  CheckOptions;

export const checkFinePermissions = async (options: CheckOptions) => {
  const accessToken = accessTokenObservable.get();
  if (!accessToken) return false;

  return checkToken(jwtDecode(accessToken), options);
};

export default (props: Props) => {
  const [accessToken] = useAccessToken();

  if (!accessToken) return null;

  const [can, setCan] = useState(false);

  useEffect(() => {
    const run = async () => {
      const result = await checkToken(jwtDecode(accessToken), props);

      setCan(props.not ? !result : result);
    };
    run();
  }, [props]);

  if(can) return <>{props.children}</>;
  if(props.alternativeChildren) return <>{props.alternativeChildren}</>;
  return null;
};
