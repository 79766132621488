import useCreateOrUpdateFiles from "../useCreateOrUpdateFiles";
import getNextFreeIndex from "./useNextFreeIndex";

export const useUploadAddonFile = () => {
  const { addAdditionalUpload } = useCreateOrUpdateFiles();
  const { onClickRevitExport } = useCreateOrUpdateFiles();

  /**
   * Creates a revit upload and returns the used index
   * @returns the index of the revit upload
   */
  const createNewRevitUpload = () => {
    const nextFreeIndex = getNextFreeIndex();

    addAdditionalUpload(nextFreeIndex);

    return nextFreeIndex;
  };

  const exportFromRevitIntoNewUpload = () => {
    const newUploadIndex = createNewRevitUpload();

    onClickRevitExport(newUploadIndex);
  };

  return { exportFromRevitIntoNewUpload };
};
