import { defineMessages } from "react-intl";

const users = defineMessages({
  actionDropdownAdministrationTitle: {
    id: "ui.admin.users.action-dropdown-administration-title",
    defaultMessage: "Administration",
    description: "Title for the administration action dropdown",
  },
  actionDropdownPermissionTitle: {
    id: "ui.admin.users.action-dropdown-permission-title",
    defaultMessage: "Permissions",
    description: "Title for the permissions action dropdown",
  },
  actionDropdownRemoveFromOrganisation: {
    id: "ui.admin.users.action-dropdown-remove-from-organisation",
    defaultMessage: "Remove from Organisation",
    description:
      "Label for the remove from organisation action in the action dropdown",
  },
  actionDropdownRemoveFromOrganisationDisallowedDueToLowPermissions: {
    id: "ui.admin.users.action-dropdown-remove-from-organisation-disallowed-due-to-low-permissions",
    defaultMessage: "You are not allowed to remove this user from the organisation due to low permissions.",
    description:
      "Label for the remove from organisation action in the action dropdown",
  },
  actionDropdownRemoveFromOrganisationDisallowedDueToSelf: {
    id: "ui.admin.users.action-dropdown-remove-from-organisation-disallowed-due-to-self",
    defaultMessage: "You are not allowed to remove yourself from the organisation.",
    description:
      "Label for the remove from organisation action in the action dropdown",
  },
  actionDropdownRemoveFromOrganisationSuccess: {
    id: "ui.admin.users.action-dropdown-remove-from-organisation-success",
    defaultMessage: "The user {user} was removed from the organisation successfully.",
    description:
      "Label for the remove from organisation action in the action dropdown",
  },
  actionDropdownRemoveFromOrganisationFailure: {
    id: "ui.admin.users.action-dropdown-remove-from-organisation-failure",
    defaultMessage: "Failed to remove the user {user} from the organisation.",
    description:
      "Label for the remove from organisation action in the action dropdown",
  },
  actionDropdownUpdateRole: {
    id: "ui.admin.users.action-dropdown-update-role",
    defaultMessage: "Update Role",
    description: "Label for the update role action in the action dropdown",
  },
  actionDropdownChangeUserPassword: {
    id: "ui.admin.users.action-dropdown-change-user-password",
    defaultMessage: "Change User Password",
    description:
      "Label for the change user password action in the action dropdown",
  },
  actionDropdownEditPermissions: {
    id: "ui.admin.users.action-dropdown-edit-permissions",
    defaultMessage: "Edit Permissions",
    description: "Label for the edit permissions action in the action dropdown",
  },
  actionDropdownDeleteUser: {
    id: "ui.admin.users.action-dropdown-delete-user",
    defaultMessage: "Delete User",
    description: "Label for the delete user action in the action dropdown",
  },
  actionDropdownDeleteUserConfirm: {
    id: "ui.admin.users.action-dropdown-delete-user-confirm",
    defaultMessage: "Do you really want to delete this user?",
    description:
      "Description for the delete user action in the action dropdown",
  },
  roleRenderLoading: {
    id: "ui.admin.users.role-render-loading",
    defaultMessage: "Loading...",
    description: "Loading state of the role render",
  },
  changeRoleModalTitle: {
    id: "ui.admin.users.change-role-modal-title",
    defaultMessage: "Change role for {preName} {name}",
    description: "Title for the change role modal",
  },
  changeRoleTitle: {
    id: "ui.admin.users.change-role-title",
    defaultMessage: "Change role",
    description: "Title for the change role modal",
  },
  messageUpdatedRoleSuccess: {
    id: "ui.admin.users.message-updated-role-success",
    defaultMessage: "The user got assigned the role {role} successfully",
    description: "Success message for updating the role",
  },
  messageUpdatedRoleFailure: {
    id: "ui.admin.users.message-updated-role-failure",
    defaultMessage: "Failed to assign the user the role {role}",
    description: "Success message for updating the role",
  },
  userCategoriesModalTitle: {
    id: "ui.admin.users.user-categories-modal-title",
    defaultMessage: "Editing permissions for {preName} {name}",
    description: "Title for the user categories modal",
  },
  categoryUsersModalTitle: {
    id: "ui.admin.users.category-users-modal-title",
    defaultMessage: "Editing permissions for {category}",
    description: "Title for the category users modal",
  },
  abstractRoleTableNameColumn: {
    id: "ui.admin.users.abstract-role-table-name-column",
    defaultMessage: "Name",
    description: "Name column for the abstract role table",
  },
  abstractRoleTableBasePermissionsColumn: {
    id: "ui.admin.users.abstract-role-table-base-permissions-column",
    defaultMessage: "Base Permissions",
    description: "Base Permissions column for the abstract role table",
  },
  abstractRoleTableSpecificPermissionsColumn: {
    id: "ui.admin.users.abstract-role-table-specific-permissions-column",
    defaultMessage: "Specific Permissions",
    description: "Specific Permissions column for the abstract role table",
  },
  abstractRoleTableResultingPermissionsColumn: {
    id: "ui.admin.users.abstract-role-table-resulting-permissions-column",
    defaultMessage: "Resulting Permissions",
    description: "Resulting Permissions column for the abstract role table",
  },
});

export default users;
