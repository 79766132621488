import { UpdatingAddedFile } from "../../utils/types/updateFilesTypes";
import { RcFile } from "antd/lib/upload";
import { getLogger } from "../../utils/logger.utils";
import { getRoleFromFileName } from "../useUpload/createNewItem";
import { getRevit } from "../../api/revit";
import useAddAnyFile from "./useAddAnyFile";
import useUpdateAddonFiles from "./useUpdateAddonFiles";
import {
  UploadType,
  useCreateOrUpdateItemStore,
} from "../../state/createOrUpdateItemStore";
import { useCreateOrUpdateMultipleFiles } from "../useCreateOrUpdateMultipleFiles";
import { useEffect } from "react";
import { useRevitStore } from "../../state/revitStore";
import { useDebouncedCallback } from "use-debounce";
import getNextFreeIndex from "./useNextFreeIndex";

const logger = getLogger("useUploadLocalFile");

const useUploadLocalFile = () => {
  const { onDroppedFamilyFile } = useUpdateAddonFiles();
  const { addAnyFile } = useAddAnyFile();
  const { setUpload } = useCreateOrUpdateMultipleFiles();

  const multiUploadFileGroupQueue = useCreateOrUpdateItemStore(
    (state) => state.multiUploadFileGroupQueue
  );
  useEffect(() => {
    if (
      multiUploadFileGroupQueue.length > 0 &&
      useRevitStore.getState().currentMode === undefined
    ) {
      const { familyFile, index } = multiUploadFileGroupQueue[0];
      onDroppedFamilyFile(familyFile, index);
    }
  }, [multiUploadFileGroupQueue]);

  const _onDropLocalFileForSingleUpload = (file: RcFile) => {
    _onDropLocalFileForMultiUpload(file, -1);
  };

  const _onDropLocalFileForMultiUpload = (file: RcFile, index: number) => {
    const newFile: UpdatingAddedFile = {
      id: file.uid,
      slot: -1,
      addedFile: {
        source: "user",
        userFile: file,
      },
      state: "added",
    };

    addAnyFile(newFile, index);
  };

  const _internalDebouncedOnDropLocalFileSingleUpload = useDebouncedCallback(
    (existingIndex?: number) => {
      const files = useCreateOrUpdateItemStore.getState().droppedFilesQueue;

      useCreateOrUpdateItemStore.setState({
        droppedFilesQueue: [],
      });

      const index = existingIndex ?? -1;

      logger.debug(`Dropped ${files.length} files [${index}]`, {
        files,
        existingIndex,
        index,
      });

      const familyFiles = files.filter((file) => {
        const newFileName = file.name;
        return getRoleFromFileName(newFileName, false) === "family";
      });

      if (familyFiles.length > 1) {
        onDropLocalFileMultiUpload(files);
        return;
      }

      if (familyFiles.length === 1) {
        //a family is being uploaded manually which requires a connection to revit
        if (!getRevit().hasRevitObject()) {
          //but we do not have a connection to revit so we abort
          logger.warn(
            `Tried uploading files but no connection to Revit was found`,
            files
          );
          setUpload(index, { updatingFilesError: "no-revit" });
          return;
        }

        //we have a connection to revit so we can continue
        setUpload(index, {
          localFilesWaitingForRevit: files,
        });

        onDroppedFamilyFile(familyFiles[0], index);
        return;
      }

      files.forEach((file) => _onDropLocalFileForSingleUpload(file));
    },
    250,
    {
      trailing: true,
      leading: false,
    }
  );

  /**
   * Adds the dropped file to the overall files
   * @param file the dropped browser file
   */
  const onDropLocalFileSingleUpload = (
    files: RcFile[],
    existingIndex?: number
  ) => {
    useCreateOrUpdateItemStore.setState({
      droppedFilesQueue: useCreateOrUpdateItemStore
        .getState()
        .droppedFilesQueue.filter((f) => !files.some((fi) => fi.uid === f.uid))
        .concat(files),
    });

    _internalDebouncedOnDropLocalFileSingleUpload(existingIndex);
  };

  /**
   * A user dropped or selected a folder with multiple RFAs and other items that now need to be sorted into groups and checked into the system
   * @param files the dropped files
   */
  const onDropLocalFileMultiUpload = (files: RcFile[]) => {
    logger.debug(
      `Detected multiple family files and switched to multi upload.`,
      {
        files,
      }
    );

    const familyFiles = files.filter((f) => {
      const newFileName = f.name;
      return getRoleFromFileName(newFileName, false) === "family";
    });

    //group by name of the family file
    const filesGroupedByFamilyFileName = familyFiles.map<RcFile[]>(
      (familyFile) => {
        const groupFileName = familyFile.name.split(".rfa")[0];

        return files.filter((file) => {
          const splitFileName = file.name.split(".");
          const newFileNameWithoutExtension = splitFileName
            .filter((_, i) => i < splitFileName.length - 1)
            .join(".");
          return newFileNameWithoutExtension === groupFileName;
        });
      }
    );

    const nextFreeIndex = getNextFreeIndex();

    logger.debug(
      `Grouped files by family file name and obtained latest index ${nextFreeIndex}`,
      {
        filesGroupedByFamilyFileName,
        nextFreeIndex,
      }
    );

    let currentIndex = nextFreeIndex === -1 ? 0 : nextFreeIndex;
    //create all uploads for each file group
    const newUploads: UploadType[] = filesGroupedByFamilyFileName.map(
      (fileGroup) => {
        return {
          exportingFromRevit: false,
          index: currentIndex++,
          localFilesWaitingForRevit: fileGroup,
          mode: "create",
          temporaryFiles: [],
          updatingFiles: [],
          item: {},
        };
      }
    );

    const usedIndexes = newUploads.map((u) => u.index);

    const uploads = useCreateOrUpdateItemStore
      .getState()
      .uploads.filter(
        (u) => u.updatingFiles.length > 0 && !usedIndexes.includes(u.index)
      )
      .map(u => {
        return {
          ...u,
          //if the index is -1 this is a single upload that now needs to be transformed into a multi upload hence the index=1 (space has been kept see useNextFreeIndex)
          index: u.index === -1 ? 1 : u.index
        }
      })
      .concat(newUploads);

    logger.debug(
      `Generated ${newUploads.length} new uploads and preserved ${
        uploads.length - newUploads.length
      } existing uploads`,
      {
        uploads,
      }
    );

    useCreateOrUpdateItemStore.setState({
      uploads,
      itemMode: "multiple",
      multiUploadFileGroupQueue: newUploads.map((newUpload) => {
        return {
          index: newUpload.index,
          familyFile: newUpload.localFilesWaitingForRevit.find(
            (f) => getRoleFromFileName(f.name, false) === "family"
          )!,
        };
      }),
    });

    logger.debug(`Switched to itemMode=multiple`);
  };

  return {
    onDropLocalFileSingleUpload,
    onDropLocalFileMultiUpload,
  };
};

export default useUploadLocalFile;
