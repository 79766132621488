import { defineMessages } from "react-intl";

const fields = defineMessages({
  categoryName: {
    id: "component.update-item-library-field.title",
    defaultMessage: "Library",
    description: "Title of the library field",
  },
  categoryPlaceholder: {
    id: "component.update-item-library-field.placeholder",
    defaultMessage: "Select a library",
    description: "Placeholder of the library field",
  },
  changeDescriptionName: {
    id: "component.update-item-change-description-field.title",
    defaultMessage: "Description of Changes",
    description: "Title of the change description field",
  },
  changeDescriptionPlaceholder: {
    id: "component.update-item-change-description-field.placeholder",
    defaultMessage: "Describe the changes you made...",
    description: "Placeholder of the change description field",
  },
  descriptionName: {
    id: "component.update-item-description-field.title",
    defaultMessage: "Description",
    description: "Title of the description field",
  },
  descriptionPlaceholder: {
    id: "component.update-item-description-field.placeholder",
    defaultMessage: "Describe the item...",
    description: "Placeholder of the description field",
  },
  filesName: {
    id: "component.update-item-files-field.title",
    defaultMessage: "Files",
    description: "Title of the files field",
  },
  filesParametersName: {
    id: "component.update-item-files-field.parameters",
    defaultMessage: "Parameters & Types",
    description: "Label for the parameters",
  },
  filesEditButton: {
    id: "component.update-item-files-field.edit-files",
    defaultMessage: "Edit files",
    description: "Edit files button text",
  },
  filesEditButtonDisabledTooltip: {
    id: "component.update-item-files-field.edit-files-disabled-tooltip",
    defaultMessage: "You need to upload or import files before editing them",
    description: "Edit files button disabled tooltip",
  },
  tagsName: {
    id: "component.update-item-tags-field.title",
    defaultMessage: "Tags",
    description: "Title of the tags field",
  },
  tagsPlaceholder: {
    id: "component.update-item-tags-field.placeholder",
    defaultMessage: "Select tags...",
    description: "Placeholder of the tags field",
  },
  titleName: {
    id: "component.update-item-title-field.title",
    defaultMessage: "Title",
    description: "Title of the title field",
  },
  titlePlaceholder: {
    id: "component.update-item-title-field.placeholder",
    defaultMessage: "The item title...",
    description: "Placeholder of the title field",
  },
  titleAlreadyExists: {
    id: "ui.meta.fields.title-already-exists", 
    defaultMessage: "This title is already in use. If you just want to upload a new state of the item use the 'Find Existing Items' button to cancel the upload and search for items with the same title.",
    description: "Title already exists alert",
  },
  titleAlreadyExistsFindExistingItemsButton: {
    id: "ui.meta.fields.title-already-exists-find-existing-items-button", 
    defaultMessage: "Find Existing Items",
    description: "Find Existing Items button text",
  },
  fieldRequiredTitle: {
    id: "ui.meta.fields.field-required-title", 
    defaultMessage: "This field is required",
    description: "Field required alert title",
  }
});
export default fields;
