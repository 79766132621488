import { Tag, Typography } from "antd";
import { pickFontColorBasedOnBackgroundColor } from "../../../utils/text-color.utils";

type Props = {
    color: string;
    text?: string;
};

export default ({color, text}: Props) => {
    const textColor = pickFontColorBasedOnBackgroundColor(color, "#fff", "#000");

    return <Tag color={color}>
        <Typography.Text style={{color: textColor}}>
            {text ?? color}
        </Typography.Text>
    </Tag>
};