import {
  ArrowLeftOutlined,
  ExpandOutlined,
  FolderOpenOutlined,
  TagOutlined,
  UngroupOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useAuthStore } from "../../state/authStore";
import { Outlet, useLocation } from "react-router-dom";
import { Divider, Layout, Space, Typography } from "antd";
import { AddTagsModal } from "../AllUsers/HomepageComponents/ItemsTable/AddTags/AddTagsModal";
import Can from "../../api/ability/Can";
import MenuButton from "./Common/MenuButton";
import MergeTagsModal from "./MergeTags/MergeTagsModal";
import MergeTagsButton from "./MergeTags/MergeTagsButton";
import OrganisationSelector from "./Common/OrganisationSelector";
import { CategoryRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";

const messages = defineMessages({
  leave: {
    id: "components.admin.leave",
    defaultMessage: "Leave Administration",
    description: "Content of the leave administration button",
  },
  organisations: {
    id: "components.admin.organisations",
    defaultMessage: "Organisations",
    description: "Content of the organisations button",
  },
  selectOrganisation: {
    id: "components.admin.select-organisation",
    defaultMessage: "Select Organisation",
    description: "Content of the select organisation button",
  },
  users: {
    id: "components.admin.users",
    defaultMessage: "Users",
    description: "Content of the users button",
  },
  roles: {
    id: "components.admin.roles",
    defaultMessage: "Roles",
    description: "Content of the roles button",
  },
  categories: {
    id: "components.admin.categories",
    defaultMessage: "Categories",
    description: "Content of the categories button",
  },
  tags: {
    id: "components.admin.tags",
    defaultMessage: "Tags",
    description: "Content of the tags button",
  },
  items: {
    id: "components.admin.items",
    defaultMessage: "Items",
    description: "Content of the items button",
  },
});

export const AdminStart = () => {
  const intl = useIntl();
  const selectedOrganisationId = useAuthStore(
    (state) => state.selectedOrganisationId
  );
  const [collapsed, setCollapsed] = useState(false);
  const { pathname } = useLocation();
  const subPage = pathname.match(/\/admin\/([^\\/]+$)/i);
  const selectedId = subPage && subPage[1] ? subPage[1] : "";

  // const selectedOrganisation = useQuery({
  //   queryKey: ["organisation", selectedOrganisationId],
  //   enabled: !!selectedOrganisationId,
  //   queryFn: async () => {
  //     if (!selectedOrganisationId) return null;
  //     return api.organisations.getOne(selectedOrganisationId);
  //   },
  //   cacheTime: 60,
  // });

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Layout.Sider
        theme="light"
        collapsible
        width={300}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Space
          direction="vertical"
          size="middle"
          style={{ width: "100%", padding: "1rem" }}
        >
          <MenuButton
            id="back"
            icon={<ArrowLeftOutlined />}
            selectedId={selectedId}
            title={intl.formatMessage(messages.leave)}
            href="/"
          />

          <Can mode="general" allowOnlyAdmins>
            <MenuButton
              id="organisations"
              icon={<UngroupOutlined />}
              selectedId={selectedId}
              title={intl.formatMessage(messages.organisations)}
              href="/admin/organisations"
            />
          </Can>

          <Can mode="general" allowOnlyAdmins>
            <Divider
              type="horizontal"
              style={{ margin: 0, marginBottom: 16 }}
            />

            <Typography.Title
              level={3}
              style={{ marginBottom: 0, textAlign: "center" }}
            >
              {intl.formatMessage(messages.selectOrganisation)}
            </Typography.Title>

            <OrganisationSelector
              selectedOrganisationId={selectedOrganisationId ?? undefined}
            />
          </Can>

          <Divider type="horizontal" style={{ margin: 0 }} />

          <Can mode="general" blockUser>
            <MenuButton
              id="users"
              icon={<UserOutlined />}
              selectedId={selectedId}
              title={intl.formatMessage(messages.users)}
              href="/admin/users"
            />

            <Divider
              type="horizontal"
              style={{ marginBottom: 0, marginTop: 16 }}
            />
          </Can>

          <Can mode="general" blockUser>
            <MenuButton
              id="categories"
              icon={<FolderOpenOutlined />}
              selectedId={selectedId}
              title={intl.formatMessage(messages.categories)}
              href="/admin/categories"
            />

            <Divider
              type="horizontal"
              style={{ marginBottom: 0, marginTop: 16 }}
            />
          </Can>

          <Can mode="general" minimumUserRole={CategoryRoleEnum.WRITE}>
            <MenuButton
              id="tags"
              icon={<TagOutlined />}
              selectedId={selectedId}
              title={intl.formatMessage(messages.tags)}
              href="/admin/tags"
            />

            {selectedId === "tags" && (
              <>
                <MergeTagsButton />
                <MergeTagsModal />
                <AddTagsModal />
              </>
            )}
          </Can>

          <Divider
            type="horizontal"
            style={{ marginBottom: 0, marginTop: 0 }}
          />

          <Can mode="general" minimumUserRole={CategoryRoleEnum.WRITE}>
            <MenuButton
              id="items"
              icon={<ExpandOutlined />}
              selectedId={selectedId}
              title={intl.formatMessage(messages.items)}
              href="/admin/items"
            />
          </Can>
        </Space>
      </Layout.Sider>
      <Layout.Content
        style={{
          padding: 15,
          height: "100%",
          overflow: "auto",
          marginLeft: collapsed ? 80 : 300,
        }}
      >
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};
