import { Row, Tag } from "antd";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { defineMessages, useIntl } from "react-intl";
import TableProps from "../../../../UiProperties/TableProps";
import useOstTranslate from "../../../../hooks/useOstTranslate";

import "./properties-table.css";

interface PropertiesTableProps {
  dataSource: ItemPopulated;
  backgroundColor?: string;
}

/**
 * Primary UI component for user interaction
 */
export const PropertiesTable = ({
  backgroundColor,
  dataSource,
  ...props
}: PropertiesTableProps) => {
  const translateOstString = useOstTranslate();

  const intl = useIntl();
  const messages = defineMessages({
    revit: {
      id: "component.properties_table.revit",
      defaultMessage: "Revit",
      description: "Revit version element in the properties table",
    },
    author: {
      id: "component.properties_table.author",
      defaultMessage: "Author",
      description: "Author element in the properties table",
    },
    category: {
      id: "component.properties_table.category",
      defaultMessage: "Category",
      description: "Category element in the properties table",
    },
    approver: {
      id: "component.properties_table.approver",
      defaultMessage: "Approved By",
      description: "Approved by element in the properties table",
    },
    changes: {
      id: "component.properties_table.changes",
      defaultMessage: "Changes",
      description: "Changes element in the properties table",
    },
    state: {
      id: "component.properties_table.state",
      defaultMessage: "State",
      description: "State element in the properties table",
    },
    family: {
      id: "component.properties_table.family",
      defaultMessage: "Family",
      description: "Family element in the properties table",
    },
    library: {
      id: "component.properties_table.library",
      defaultMessage: "Library",
      description: "Library element in the properties table",
    },
    notAvailable: {
      id: "component.properties_table.not_available",
      defaultMessage: "Not Available",
      description: "Not available in the properties table",
    },
  });

  const buildPropertyTag = (firstText: string, secondText: string) => {
    return (
      <Tag color="#fff" style={TableProps.coloredTag}>
        <span style={{ color: "#70838f", marginRight: "0.5rem" }}>
          {firstText}
        </span>
        <span style={{ color: "#70838f", opacity: "60%" }}>{secondText}</span>
      </Tag>
    );
  };

  return (
    <Row>
      {buildPropertyTag(
        intl.formatMessage(messages.family),
        dataSource.properties
          ? dataSource.properties!.familyName
          : intl.formatMessage(messages.notAvailable)
      )}

      {buildPropertyTag(
        intl.formatMessage(messages.library),
        dataSource.category
          ? dataSource.category.title
          : intl.formatMessage(messages.notAvailable)
      )}

      {buildPropertyTag(
        intl.formatMessage(messages.revit),
        dataSource.properties
          ? dataSource.properties!.familyRevitVersion.toString() === "1"
            ? dataSource.properties!.revitVersion.toString()
            : dataSource.properties!.familyRevitVersion.toString()
          : intl.formatMessage(messages.notAvailable)
      )}

      {buildPropertyTag(
        intl.formatMessage(messages.category),
        dataSource.properties
          ? translateOstString(dataSource.properties!.ostCategory)
          : intl.formatMessage(messages.notAvailable)
      )}

      {buildPropertyTag(
        intl.formatMessage(messages.author),
        !dataSource.createdBy
          ? intl.formatMessage(messages.notAvailable)
          : `${dataSource.createdByPopulated.preName} ${dataSource.createdByPopulated.name}`
      )}

      {buildPropertyTag(
        intl.formatMessage(messages.approver),
        !dataSource.approvedBy
          ? intl.formatMessage(messages.notAvailable)
          : `${dataSource.approvedByPopulated.preName} ${dataSource.approvedByPopulated.name}`
      )}

      {buildPropertyTag(intl.formatMessage(messages.state), dataSource.state)}
    </Row>
  );
};
