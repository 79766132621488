import { useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useViewStore } from "../../../state/viewStore";
import { useApproveOrDeny } from "../../../hooks/useApproveOrDeny";
import ButtonProps from "../../../UiProperties/ButtonProps";
import api from "../../../api/api";
import { useIntl } from "react-intl";
import translations from "../../../translations/constants/translations";

const DenyItemModal = () => {
  const intl = useIntl();
  const [denyReason, setDenyReason] = useState<string | null>(null);
  const selectedItemToBeDenied = useViewStore(
    (state) => state.selectedItemToBeDenied
  );

  const { invalidateQueries } = useApproveOrDeny(selectedItemToBeDenied!);

  const closeModal = () => {
    setDenyReason(null);
    useViewStore.setState({
      selectedItemToBeDenied: undefined,
    });
  };

  const onDeny = async () => {
    if (
      selectedItemToBeDenied &&
      selectedItemToBeDenied.id &&
      selectedItemToBeDenied.state &&
      selectedItemToBeDenied.changes
    ) {
      if (denyReason) {
        if (selectedItemToBeDenied.state === "approvable") {
          await api.items.approve(
            selectedItemToBeDenied.id!,
            false,
            denyReason
          );
        } else {
          const approvableChanges = selectedItemToBeDenied.changes.filter(
            (change) => change.state === "approvable"
          );

          for (let i = 0; i < approvableChanges.length; i++) {
            const addReason = i === approvableChanges.length - 1;
            await api.items.changes.deny(
              selectedItemToBeDenied.id!,
              approvableChanges[i].id,
              addReason ? denyReason : undefined
            );
          }
        }
      }
    }

    await invalidateQueries();
    closeModal();
  };

  return (
    <Modal
      title={intl.formatMessage(translations.ui.modals.denyItemModalTitle)}
      style={{ padding: "2rem" }}
      centered
      open={!!selectedItemToBeDenied?.id}
      onOk={closeModal}
      onCancel={closeModal}
      afterClose={closeModal}
      closable={true}
      width={"100%"}
      zIndex={1000}
      destroyOnClose={true}
      footer={[
        <Button
          key="delete"
          type="primary"
          onClick={onDeny}
          disabled={!!denyReason && denyReason.length > 0 ? false : true}
        >
          {intl.formatMessage(translations.ui.generic.deny)}
        </Button>,
        <Button
          key="close"
          type="dashed"
          onClick={closeModal}
          style={ButtonProps.ghost}
        >
          {intl.formatMessage(translations.ui.generic.cancel)}
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Form.Item
          label={intl.formatMessage(
            translations.ui.modals.denyItemModalDescription
          )}
        >
          <Input.TextArea
            onChange={(e) => {
              setDenyReason(e.target.value);
            }}
            spellCheck={false}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DenyItemModal;
