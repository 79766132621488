import auth from "./auth";
import categories from "./categories";
import items from "./items";
import organisation from "./organisations";
import search from "./search";
import stats from "./stats";
import tags from "./tags";
import users from "./users";

const api = {
  auth: auth,
  categories: categories,
  items: items,
  organisations: organisation,
  search: search,
  stats: stats,
  tags: tags,
  users: users,
};

export default api;
