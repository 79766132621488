import { defineMessages, useIntl } from "react-intl";
import { pickFontColorBasedOnBackgroundColor } from "../../../utils/text-color.utils";
import { User } from "@formitas-ag/bimfiles-types/lib";
import { Spin, Tag, Tooltip, notification } from "antd";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import api from "../../../api/api";

type Props = {
  id: User["id"];
};

const messages = defineMessages({
  enabled: {
    id: "components.render.state.enabled",
    defaultMessage: "Enabled",
    description: "Enabled state of the user state render",
  },
  disabled: {
    id: "components.render.state.disabled",
    defaultMessage: "Disabled",
    description: "Disabled state of the user state render",
  },
  notActivated: {
    id: "components.render.state.notActivated",
    defaultMessage: "Not Activated",
    description: "Not Activated state of the user state render",
  },
  resendActivationTooltip: {
    id: "components.render.state.resend-activation-tooltip",
    defaultMessage:
      "Click here to send another activation link to the user. The last one will be invalidated.",
    description: "Tooltip for the resend activation link button",
  },
  resendActivationSuccess: {
    id: "components.render.state.resend-activation-success",
    defaultMessage: "Sent a new activation link to the user.",
    description: "Success message for the resend activation link button",
  },
  resendActivationError: {
    id: "components.render.state.resend-activation-error",
    defaultMessage:
      "Failed to send a new activation link to the user. Error: {error}",
    description: "Error message for the resend activation link button",
  },
});

const enabledColor = "#8bc34a";
const notActivatedColor = "#f57c00";
const disabledColor = "#e53935";

const StateRender = (props: Props) => {
  const intl = useIntl();

  const [color, setColor] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const userQuery = useQuery<User>({
    queryKey: ["user", props.id],
    queryFn: () => api.users.getOne(props.id),
  });

  const resendActivationEmail = async () => {
    setLoading(true);

    try {
      await api.users.resendActivation(props.id);

      notification.success({
        message: intl.formatMessage(messages.resendActivationSuccess),
      });
    } catch (error) {
      notification.error({
        message: intl.formatMessage(messages.resendActivationError, {
          error: error + "",
        }),
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userQuery.data) {
      setColor(
        userQuery.data.state === "disabled"
          ? disabledColor
          : userQuery.data.state === "notActivated"
          ? notActivatedColor
          : enabledColor
      );

      setContent(
        userQuery.data.state === "disabled"
          ? intl.formatMessage(messages.disabled)
          : userQuery.data.state === "notActivated"
          ? intl.formatMessage(messages.notActivated)
          : intl.formatMessage(messages.enabled)
      );
    }
  }, [userQuery.data]);

  return userQuery.isSuccess ? (
    userQuery.data.state === "notActivated" ? (
      <Tooltip
        color="#f7f8fc"
        title={intl.formatMessage(messages.resendActivationTooltip)}
      >
        <Tag
          color={color}
          style={{
            color: pickFontColorBasedOnBackgroundColor(color, "#fff", "#000"),
            cursor: "pointer",
          }}
          onClick={resendActivationEmail}
        >
          {loading && <Spin />}
          {!loading && content}
        </Tag>
      </Tooltip>
    ) : (
      <Tag
        color={color}
        style={{
          color: pickFontColorBasedOnBackgroundColor(color, "#fff", "#000"),
        }}
      >
        {content}
      </Tag>
    )
  ) : (
    <Spin />
  );
};
export default StateRender;
