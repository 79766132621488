import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { defineMessages, useIntl } from "react-intl";
import { TagPopulated } from "@formitas-ag/bimfiles-types/lib/tag";
import { Row } from "antd";
import TableProps from "../../../../../UiProperties/TableProps";
import ChangeTableColumns from "../TableActions/TableColumnsChanger";
import CustomTag from "../../CustomTag";

type Props = {
  expandedRows: string[];
};

const TagsTableColumn = (props: Props) => {
  const intl = useIntl();
  const messages = defineMessages({
    actionsColumn: {
      id: "component.items_table.column.tags",
      defaultMessage: "Tags",
      description: "Tags column in the items table",
    },
  });
  return {
    title: (
      <Row justify="space-between" align="middle">
        <span
          style={{
            fontSize: "1rem",
            color: "#931d21",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontStyle: "normal",
            fontWeight: 600,
          }}
        >
          {intl.formatMessage(messages.actionsColumn)}
        </span>
        <ChangeTableColumns currentItem="tags" />
      </Row>
    ),
    key: "id",
    // width: "35%",
    ellipsis: true,
    onCell: (data: any, index: any) => ({
      colSpan: !!props.expandedRows.find((id) => id === data.id) ? 0 : 1,
    }),
    render: (item: ItemPopulated) => {
      return (
        <div style={{ display: "flex", flexFlow: "wrap", overflowY: "auto" }}>
          {item.tags
            .map((tag: TagPopulated, index: number) => {
              return (
                <CustomTag
                  key={"tag" + index}
                  style={TableProps.coloredTag}
                  color={JSON.parse(JSON.stringify(tag)).color}
                  label={JSON.parse(JSON.stringify(tag)).title}
                />
              );
            })
            .slice(0, 3)}
        </div>
      );
    },
  };
};

export default TagsTableColumn;
