import { Button } from "antd";
import { useViewStore } from "../../../state/viewStore";
import { MergeCellsOutlined } from "@ant-design/icons";

const MergeTagsButton = () => {
  const mergeTagsModal = useViewStore((state) => state.mergeTagsModal);

  const onClick = () => {
    useViewStore.setState({
      ...useViewStore.getState(),
      mergeTagsModal: true,
    });
  };

  return (
    <Button
      type={mergeTagsModal ? "primary" : "default"}
      icon={<MergeCellsOutlined />}
      size="large"
      style={{ width: "100%" }}
      onClick={onClick}
    >
      Merge Tags
    </Button>
  );
};

export default MergeTagsButton;
