import { Form, Row } from "antd";
import TagsDropdown from "../../AllUsers/Dropdowns/TagsDropdown";
import React from "react";
import { AddTagsButton } from "../../AllUsers/HomepageComponents/ItemsTable/AddTags/AddTagsButton";

type MergeTagsProps = {
  selectedNewTag: string | undefined;
  setSelectedNewTag: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedOldTags: string[] | undefined;
  setSelectedOldTags: React.Dispatch<
    React.SetStateAction<string[] | undefined>
  >;
};

const MergeTagsForm = (props: MergeTagsProps) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} layout="vertical">
      <Form.Item label="Old Tags">
        <TagsDropdown
          type="all"
          selectProps={{
            value: props.selectedOldTags ? props.selectedOldTags : undefined,
            onChange: (keys: string[], value: any) => {
              props.setSelectedOldTags(keys);
              form.setFieldsValue({
                tags: keys,
              });
              form.validateFields(["tags"]);
            },
            placeholder: "Select tags to be replaced",
          }}
        />
      </Form.Item>
      <Row>
        <Form.Item label="New Tag" style={{ width: "85%" }}>
          <TagsDropdown
            type="all"
            selectProps={{
              value: props.selectedNewTag ? [props.selectedNewTag] : undefined,
              onChange: (key: string[], value: any) => {
                props.setSelectedNewTag(key[key.length - 1]);
                form.setFieldsValue({
                  category: key[key.length - 1],
                });
                form.validateFields(["category"]);
              },
              placeholder: "Select new tag",
            }}
          />
        </Form.Item>
        <AddTagsButton
          style={{ width: "12%", marginTop: "1.9rem", marginLeft: "3%" }}
        />
      </Row>
    </Form>
  );
};

export default MergeTagsForm;
