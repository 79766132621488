import { FormattedDate, defineMessages, useIntl } from "react-intl";
import CustomSortButton from "../TableProperties/CustomSortButton";
import { Row } from "antd";
import ChangeTableColumns from "../TableActions/TableColumnsChanger";

type Props = {
  expandedRows: string[];
};

const UpdatedAtTableColumn = (props: Props) => {
  const intl = useIntl();

  const messages = defineMessages({
    lastUpdateColumn: {
      id: "component.items_table.column.updated_at",
      defaultMessage: "Updated At",
      description: "Updated at column in the items table",
    },
  });

  return {
    title: (
      <Row justify="space-between" align="middle">
        <CustomSortButton
          label={intl.formatMessage(messages.lastUpdateColumn)}
          sortBy="updatedAt"
        ></CustomSortButton>
        <ChangeTableColumns currentItem="updatedAt" />
      </Row>
    ),
    dataIndex: "updatedAt",
    key: "updatedAt",
    // width: "25%",
    ellipsis: true,
    onCell: (data: any, index: any) => ({
      colSpan: !!props.expandedRows.find((id) => id === data.id) ? 0 : 1,
    }),
    render: (text: string) => (
      <div className="overflow-cell">
        <FormattedDate
          value={text}
          day="2-digit"
          month="2-digit"
          year="numeric"
        />
      </div>
    ),
  };
};

export default UpdatedAtTableColumn;
