import { defineMessages } from "react-intl";

const rowAction = defineMessages({
  menu: {
    id: "ui.itemTable.rowAction.menu",
    defaultMessage: "Menu",
    description: "Row action button title",
  },
  edit: {
    id: "ui.itemTable.rowAction.edit",
    defaultMessage: "Edit",
    description: "Edit action within the row action dropdown",
  },
  delete: {
    id: "ui.itemTable.rowAction.delete",
    defaultMessage: "Delete",
    description: "Delete action within the row action dropdown",
  },
  timeline: {
    id: "ui.itemTable.rowAction.timeline",
    defaultMessage: "Timeline",
    description: "Timeline action within the row action dropdown",
  },
  importTypes: {
    id: "ui.itemTable.rowAction.import-types",
    defaultMessage: "Import Types",
    description: "Import types action within the row action dropdown",
  },
  sidecar: {
    id: "ui.itemTable.rowAction.sidecar",
    defaultMessage: "View Type Catalogue",
    description: "Tooltip text for item with sidecar type catalog",
  },
  sidecarAutomatic: {
    id: "ui.itemTable.rowAction.sidecar-automatic",
    defaultMessage: "View Types",
    description: "Tooltip text for item with sidecar-automatic type catalog",
  },
  openInBrowser: {
    id: "ui.itemTable.rowAction.open_in_browser",
    defaultMessage: "Open in browser",
    description: "Open in browser action within the row action dropdown",
  },
  openInBrowserSuccess: {
    id: "ui.itemTable.rowAction.open_in_browser_success",
    defaultMessage: "Link copied to clipboard",
    description: "Sent when the link is copied to the clipboard",
  },
  noPermissionForApproval: {
    id: "ui.itemTable.rowAction.no_permission_for_approval",
    defaultMessage: "You do not have permission to approve this item.",
    description: "Message when the user does not have permission to approve an item",
  }
});

export default rowAction;
