import { Dropdown, MenuProps } from "antd";
import { CheckOutlined, EditOutlined } from "@ant-design/icons";
import {
  SelectedColumns,
  useItemsTableStore,
} from "../../../../../state/itemsTableStore";
import { defineMessages, useIntl } from "react-intl";

type Props = {
  currentItem: SelectedColumns;
};

const ChangeTableColumns = (ChangeTableColumnsProps: Props) => {
  const intl = useIntl();
  const selectedColumns = useItemsTableStore((state) => state.selectedColumns);
  const setSelectedColumns = useItemsTableStore(
    (state) => state.setSelectedColumns
  );

  const messages = defineMessages({
    library: {
      id: "component.column-changer.library",
      defaultMessage: "Library",
      description: "The library option of the column changer dropdown",
    },
    tags: {
      id: "component.column-changer.tags",
      defaultMessage: "Tags",
      description: "The tags option of the column changer dropdown",
    },
    description: {
      id: "component.column-changer.description",
      defaultMessage: "Description",
      description: "The description option of the column changer dropdown",
    },
    ostCategory: {
      id: "component.column-changer.ost-category",
      defaultMessage: "OST Category",
      description: "The OST Category option of the column changer dropdown",
    },
    revitVersion: {
      id: "component.column-changer.revit-version",
      defaultMessage: "Revit Version",
      description: "The Revit Version option of the column changer dropdown",
    },
    updatedAt: {
      id: "component.column-changer.updated-at",
      defaultMessage: "Updated At",
      description: "The Updated At option of the column changer dropdown",
    },
  });

  const getItemIcon = () => {
    return <CheckOutlined style={{ color: "#fff" }} />;
  };

  const makeMenuItem = (
    key: string,
    label: string,
    props?: Omit<Required<MenuProps>["items"][0], "key">
  ) => {
    const columnName = key
      .substring("items-table-column-".length)
      .replace(/-(\w)/g, (_, letter) => letter.toUpperCase());

    return {
      key,
      label,
      itemIcon: getItemIcon(),

      style: { color: "#931d21" },

      onClick: () => {
        setSelectedColumns(
          selectedColumns.map(
            (prevColumn) =>
              (prevColumn === ChangeTableColumnsProps.currentItem
                ? columnName
                : prevColumn) as SelectedColumns
          )
        );
      },
      ...props,
    };
  };

  const items: MenuProps["items"] = [
    makeMenuItem(
      "items-table-column-category",
      intl.formatMessage(messages.library)
    ),
    makeMenuItem("items-table-column-tags", intl.formatMessage(messages.tags)),
    makeMenuItem(
      "items-table-column-description",
      intl.formatMessage(messages.description)
    ),
    makeMenuItem(
      "items-table-column-ost-category",
      intl.formatMessage(messages.ostCategory)
    ),
    makeMenuItem(
      "items-table-column-revit-version",
      intl.formatMessage(messages.revitVersion)
    ),
    makeMenuItem(
      "items-table-column-updated-at",
      intl.formatMessage(messages.updatedAt)
    ),
  ].filter(
    (item) =>
      !selectedColumns.includes(
        item.key
          .substring("items-table-column-".length)
          .replace(/-(\w)/g, (_, letter) =>
            letter.toUpperCase()
          ) as SelectedColumns
      )
  );

  // return items;
  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement="bottomRight"
      overlayStyle={{ width: "10rem" }}
    >
      <EditOutlined style={{ fontSize: "1rem", color: "#931d21" }} />
    </Dropdown>
  );
};

export default ChangeTableColumns;
