import { DeleteChange } from "@formitas-ag/bimfiles-types";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import TimelineChangeWrapper from "./Common/TimelineChangeWrapper";
import { Typography } from "antd";

type Props = {
  change: DeleteChange;
};

export default (props: Props) => {
  const intl = useIntl();

  const messages = defineMessages({
    stateKey: {
      id: "component.timeline.delete.state.key",
      defaultMessage: "New State",
      description: "Key text of the delete change",
    },
    stateValue: {
      id: "component.timeline.delete.state.value",
      defaultMessage: "Deleted",
      description: "Value text of the delete change",
    },
    deletedAt: {
      id: "component.timeline.delete.at",
      defaultMessage: "Deleted At",
      description: "Date of the delete change",
    },
  });

  return (
    <>
      <TimelineChangeWrapper
        key={`${props.change.id}-state`}
        titleChild={
          <Typography.Text>
            {intl.formatMessage(messages.stateKey)}
          </Typography.Text>
        }
      >
        <Typography.Text type="danger">
          {intl.formatMessage(messages.stateValue)}
        </Typography.Text>
      </TimelineChangeWrapper>
      <TimelineChangeWrapper
        key={`${props.change.id}-date`}
        titleChild={
          <Typography.Text>
            {intl.formatMessage(messages.deletedAt)}
          </Typography.Text>
        }
      >
        {intl.formatDate(props.change.deletedAt, {
          dateStyle: "medium",
          timeStyle: "short",
        })}
      </TimelineChangeWrapper>
    </>
  );
};
