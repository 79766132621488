import { LoadingOutlined, ProfileOutlined } from "@ant-design/icons";
import { Item } from "@formitas-ag/bimfiles-types";
import { useState } from "react";
import { TypeCatalogModal } from "./TypeCatalogModal";
import { Button, Spin, Tooltip } from "antd";
import { defineMessages, useIntl } from "react-intl";
import useSidecarFiles from "../../../../hooks/useSidecarFiles";

type Props = {
  id: Item["id"];
  files: Item["files"];
  buttonRef?: React.RefObject<HTMLButtonElement>;
};

const TypeCatalogButton = (props: Props) => {
  const intl = useIntl();
  const [modalVisible, setModalVisible] = useState(false);
  const { cleanup, hasWhichSidecar, load, loading, sidecarData } =
    useSidecarFiles({
      source: {
        id: props.id,
        type: "default",
        files: props.files,
      },
      setModalOpen: setModalVisible,
    });

  const messages = defineMessages({
    sidecar: {
      id: "components.type-catalog.sidecar",
      defaultMessage: "View Type Catalogue",
      description: "Tooltip text for item with sidecar type catalog",
    },
    sidecarAutomatic: {
      id: "components.type-catalog.sidecar-automatic",
      defaultMessage: "View Types",
      description: "Tooltip text for item with sidecar-automatic type catalog",
    },
    noSidecar: {
      id: "components.type-catalog.no-sidecar",
      defaultMessage: "N/A",
      description: "Tooltip text for item with no sidecar file/s",
    },
  });

  return (
    <>
      {loading ? (
        <Spin
          style={{ marginRight: "1rem" }}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        />
      ) : (
        <Tooltip
          color="#f7f8fc"
          title={intl.formatMessage(
            hasWhichSidecar.no
              ? messages.noSidecar
              : hasWhichSidecar.custom
              ? messages.sidecar
              : messages.sidecarAutomatic
          )}
          overlayInnerStyle={{ textAlign: "center" }}
        >
          <Button
            ref={props.buttonRef}
            type="text"
            // style={{ padding: "3px", marginRight: "4px" }}
            disabled={!hasWhichSidecar.automaticOrCustom}
            onClick={load}
            icon={
              <ProfileOutlined
                style={{
                  fontSize: "24px",
                  color: hasWhichSidecar.automaticOrCustom ? "#70838f" : "",
                }}
              />
            }
          />
        </Tooltip>
      )}

      <TypeCatalogModal
        type="view"
        sidecarData={sidecarData}
        sidecarType={hasWhichSidecar.custom ? "sidecar" : "sidecar-automatic"}
        visible={modalVisible}
        filesWithBase64={null}
        clearImportHandler={() => {
          cleanup();
          setModalVisible(false);
        }}
      />
    </>
  );
};
export default TypeCatalogButton;
