import { Button, List, Tooltip, Typography } from "antd";
import { DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import FileListItemState from "./FileListItemState";
import useUpdateFiles from "../../../../../hooks/useCreateOrUpdateFiles";
import { FileListProps } from "./FileList";
import FileListItemRoleTag from "./FileListItemRoleTag";
import useGetFileMeta from "../../../../../hooks/useCreateOrUpdateFiles/useGetFileMeta";
import { useIntl } from "react-intl";
import translations from "../../../../../translations/constants/translations";
import useFileListActions from "../../../../../hooks/useCreateOrUpdateFiles/useFileListActions";

type Props = {
  index: number;
  file: FileListProps["files"][0];
};
export type FileListItemProps = Props;

const FileListItem = (props: Props) => {
  const intl = useIntl();
  const {
    removeFile,
    removeAddedFile,
    restoreReplacedFile,
    restoreRemovedFile,
  } = useFileListActions();
  const { getName } = useGetFileMeta();

  const onClick = () => {
    switch (props.file.state) {
      case "default":
        removeFile(props.index, props.file);
        break;
      case "added":
        removeAddedFile(props.index, props.file);
        break;
      case "replaced":
        restoreReplacedFile(props.index, props.file);
        break;
      case "removed":
        restoreRemovedFile(props.index, props.file);
        break;
    }
  };

  return (
    <List.Item>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            flexGrow: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <FileListItemRoleTag {...props} />
          <Typography.Text
            style={{ marginLeft: ".25rem", marginRight: ".25rem" }}
          >
            {getName(props.file)}
          </Typography.Text>
        </div>

        <div>
          {props.file.state !== "default" && (
            <FileListItemState state={props.file.state} />
          )}

          <Tooltip
            color="#f7f8fc"
            title={
              props.file.state === "added"
                ? intl.formatMessage(
                    translations.ui.upload.components
                      .fileListItemRemoveAddedTooltip
                  )
                : props.file.state === "replaced"
                ? intl.formatMessage(
                    translations.ui.upload.components
                      .fileListItemRemoveReplacedTooltip
                  )
                : props.file.state === "removed"
                ? intl.formatMessage(
                    translations.ui.upload.components
                      .fileListItemRestoreRemovedTooltip
                  )
                : intl.formatMessage(
                    translations.ui.upload.components.fileListItemRemoveTooltip
                  )
            }
          >
            <Button
              type={props.file.state === "removed" ? "default" : "primary"}
              danger={
                props.file.state === "default" || props.file.state === "added"
              }
              style={{ marginLeft: ".25rem" }}
              onClick={onClick}
            >
              {(props.file.state === "default" ||
                props.file.state === "added") && <DeleteOutlined />}
              {(props.file.state === "removed" ||
                props.file.state === "replaced") && <ReloadOutlined />}
            </Button>
          </Tooltip>
        </div>
      </div>
    </List.Item>
  );
};
export default FileListItem;
