import { Pagination, Search, Response } from "@formitas-ag/bimfiles-types";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import {
  DistinctSearchResultType,
  FilterType,
} from "@formitas-ag/bimfiles-types/lib/search";
import axiosService from "./fetch/axios.service";
import { errorRenderer } from "./utils/errorThrower";
import { EndpointOptions } from "./utils/endpoint.options.utils";

const search = {
  find: (
    page: number,
    size: number,
    itemsState: "approved" | "unapproved" | "denied",
    search?: Search,
    options?: EndpointOptions
  ): Promise<Pagination<ItemPopulated & { url: string }>> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/search/items?page=${page}&size=${size}&itemsState=${itemsState}`;
        const response = await axiosService.post<
          Response<Pagination<ItemPopulated & { url: string }>>
        >(url, search);

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        if (options?.ignoreExceptions) {
          resolve(undefined as any);
        }
        reject(response.statusText);
      } catch (error) {
        if (options?.ignoreExceptions) {
          resolve(undefined as any);
        }
        reject(errorRenderer(error));
      }
    }),
  distinct: (
    search: Partial<Search>,
    filter: FilterType,
    options?: EndpointOptions
  ): Promise<DistinctSearchResultType> =>
    new Promise(async (resolve, reject) => {
      try {
        const url = `/search/filters?field=${filter}`;
        const response = await axiosService.post<
          Response<DistinctSearchResultType>
        >(url, search);

        if (response.data && response.data.success) {
          resolve(response.data.result);
        }
        if (options?.ignoreExceptions) {
          resolve(undefined as any);
        }
        reject(response.statusText);
      } catch (error) {
        if (options?.ignoreExceptions) {
          resolve(undefined as any);
        }
        reject(errorRenderer(error));
      }
    }),
};

export default search;
