import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { useIntl } from "react-intl";
import UpdateHeader from "../Common/ItemHeader";
import { useCreateOrUpdateMultipleFiles } from "../../../../hooks/useCreateOrUpdateMultipleFiles";
import translations from "../../../../translations/constants/translations";

type Props = {
  mode: "single" | "multiple";
  index?: number;
};

const UpdateChangeDescription = (props: Props) => {
  const index = props.index ?? -1;

  const intl = useIntl();

  const { setItem } = useCreateOrUpdateMultipleFiles();

  const [currentValue, setCurrentValue] = useState<string | undefined>();

  return (
    <>
      {props.mode === "single" && (
        <>
          <UpdateHeader
            title={intl.formatMessage(
              translations.ui.meta.fields.changeDescriptionName
            )}
            isResetable={false}
            onReset={() => {}}
          />
          <TextArea
            size="large"
            name="changeDescription"
            value={currentValue}
            placeholder={intl.formatMessage(
              translations.ui.meta.fields.changeDescriptionName
            )}
            onChange={(e) => {
              setItem(index, {
                changeDescription: e.target.value,
              });
              setCurrentValue(e.target.value);
            }}
          />
        </>
      )}
      {props.mode === "multiple" && (
        <>
          <TextArea
            size="large"
            name="changeDescription"
            value={currentValue}
            placeholder={intl.formatMessage(
              translations.ui.meta.fields.changeDescriptionPlaceholder
            )}
            onChange={(e) => {
              setItem(index, {
                changeDescription: e.target.value,
              });
              setCurrentValue(e.target.value);
            }}
          />
        </>
      )}
    </>
  );
};

export default UpdateChangeDescription;
