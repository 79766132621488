import { List, Table, Spin, Typography } from "antd";
import {
  UploadType,
  useCreateOrUpdateItemStore,
} from "../../../../../state/createOrUpdateItemStore";
import UpdateDescription from "../../Meta/ItemDescription";
import useGetFileMeta from "../../../../../hooks/useCreateOrUpdateFiles/useGetFileMeta";
import useUpdateFiles from "../../../../../hooks/useCreateOrUpdateFiles";
import UpdateTags from "../../Meta/ItemTags";
import UpdateTitle from "../../Meta/ItemTitle";
import UpdateCategory from "../../Meta/ItemCategory";
import ItemFiles from "../../Meta/ItemFiles";
import { useViewStore } from "../../../../../state/viewStore";
import ItemSidecar from "../../Common/ItemSidecar";
import { MenuElement } from "./Elements/MenuElement";
import ItemThumbnail, { SourceType } from "../../Meta/ItemThumbnail";
import FileListFooter from "../CreateOrUpdateFileModal/FileListFooter";
import { useUploadAddonFile } from "../../../../../hooks/useCreateOrUpdateFiles/useUploadAddonFile";
import { useIntl } from "react-intl";
import translations from "../../../../../translations/constants/translations";

type Props = {
  uploads: UploadType[];
};

export const UploadList = (props: Props) => {
  const intl = useIntl();
  const { getFile, getPotentiallyPackedUpdatingFileByRole, getName } =
    useGetFileMeta();

  const viewDetailsInBatchUploadModal = useViewStore(
    (state) => state.viewDetailsInBatchUploadModal
  );

  const { onDropLocalFileSingleUpload } = useUpdateFiles();
  const { exportFromRevitIntoNewUpload } = useUploadAddonFile();

  const isLoading = useCreateOrUpdateItemStore((state) => state.isLoading);

  return (
    <List
      id="uploadlist"
      footer={null}
      bordered
      dataSource={[1]}
      renderItem={(_: number) => {
        const findThumbnailSource = (
          upload: UploadType
        ): SourceType | undefined => {
          const thumbnailFile = getPotentiallyPackedUpdatingFileByRole(
            upload.index,
            "thumbnail"
          );

          if (!thumbnailFile || thumbnailFile.state === "removed")
            return undefined;

          if (thumbnailFile.state === "default") {
            //files with state=default only exist if we are updating an existing item hence we will have an oldItem
            return {
              type: "remote",
              item: upload.oldItem!,
            };
          } else if (
            thumbnailFile.state === "added" ||
            thumbnailFile.state === "replaced"
          ) {
            return {
              type: "local",
              file: getFile(thumbnailFile),
            };
          }

          return undefined;
        };

        return (
          <Spin spinning={isLoading}>
            <Table
              id="uploadlist"
              dataSource={[...props.uploads, {}]}
              pagination={false}
              style={{
                alignContent: "center",
                alignItems: "center",
              }}
              footer={() =>
                useCreateOrUpdateItemStore.getState().mode === "create" ? (
                  <FileListFooter
                    onClickRevitExport={exportFromRevitIntoNewUpload}
                    onDropLocalFiles={(fileList) =>
                      onDropLocalFileSingleUpload(fileList)
                    }
                  />
                ) : null
              }
            >
              <Table.Column
                title=""
                fixed="left"
                render={(value: UploadType | {}) => {
                  if (Object.keys(value).length === 0) return null;

                  const thumbnailSource = findThumbnailSource(
                    value as UploadType
                  );

                  if (!thumbnailSource) return null;

                  return <ItemThumbnail source={thumbnailSource} />;
                }}
              />

              <Table.Column
                title={intl.formatMessage(
                  translations.ui.itemTable.filters.titleFilter
                )}
                width={viewDetailsInBatchUploadModal ? "15%" : "30%"}
                render={(value: UploadType | {}) => {
                  if (Object.keys(value).length === 0)
                    return (
                      <Typography.Title
                        level={5}
                        style={{
                          width: "100%",
                          textAlign: "right",
                        }}
                      >
                        {intl.formatMessage(
                          translations.ui.upload.modals
                            .multiFileModalAllUploadsTitle
                        )}
                      </Typography.Title>
                    );
                  return (
                    <UpdateTitle
                      mode="multiple"
                      index={(value as UploadType).index}
                    />
                  );
                }}
              />

              <Table.Column
                title={intl.formatMessage(
                  translations.ui.itemTable.filters.libraryFilter
                )}
                width={viewDetailsInBatchUploadModal ? "10%" : "15%"}
                render={(value: UploadType | {}) => {
                  if (Object.keys(value).length === 0)
                    return <UpdateCategory mode="multipleAll" />;
                  return (
                    <UpdateCategory
                      mode="multiple"
                      index={(value as UploadType).index}
                    />
                  );
                }}
              />

              <Table.Column
                title={intl.formatMessage(
                  translations.ui.itemTable.filters.tagsFilter
                )}
                width={viewDetailsInBatchUploadModal ? "15%" : "25%"}
                render={(value: UploadType | {}) => {
                  if (Object.keys(value).length === 0)
                    return <UpdateTags mode="multipleAll" />;
                  return (
                    <UpdateTags
                      mode="multiple"
                      index={(value as UploadType).index}
                    />
                  );
                }}
              />

              <Table.Column
                title={intl.formatMessage(
                  translations.ui.itemTable.filters.filesFilter
                )}
                width={viewDetailsInBatchUploadModal ? "15%" : "20%"}
                render={(value: UploadType | {}) => {
                  if (Object.keys(value).length === 0) return null;
                  return (
                    <ItemFiles
                      mode="multiple"
                      index={(value as UploadType).index}
                    />
                  );
                }}
              />

              {viewDetailsInBatchUploadModal && (
                <>
                  <Table.Column
                    title={intl.formatMessage(
                      translations.ui.itemTable.filters.descriptionFilter
                    )}
                    width={"25%"}
                    render={(value: UploadType | {}) => {
                      if (Object.keys(value).length === 0) return null;
                      return (
                        <UpdateDescription
                          mode="multiple"
                          index={(value as UploadType).index}
                        />
                      );
                    }}
                  />

                  <Table.Column
                    title={intl.formatMessage(
                      translations.ui.itemTable.filters.sizeFilter
                    )}
                    width={"10%"}
                    render={(value: UploadType | {}) => {
                      return "NaN MB";
                    }}
                  />

                  <Table.Column
                    title={intl.formatMessage(
                      translations.ui.itemTable.filters.typesFilter
                    )}
                    width={"10%"}
                    render={(value: UploadType | {}) => {
                      if (Object.keys(value).length === 0) return null;
                      return (
                        <ItemSidecar
                          mode="multiple"
                          index={(value as UploadType).index}
                        />
                      );
                    }}
                  />
                </>
              )}

              <Table.Column
                fixed="right"
                render={(value: UploadType) => {
                  if (Object.keys(value).length === 0) return null;
                  return <MenuElement index={value.index} />;
                }}
              />
            </Table>
          </Spin>
        );
      }}
    />
  );
};
