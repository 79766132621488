export const createObservable = <T> (initialValue: T) => {
    let listeners: ((newValue: T) => void)[] = [];
    let value: T = initialValue;
  
    const get = () => value;
  
    const set = (newValue: T) => {
        if(value === newValue) return;
        value = newValue;
        listeners.forEach(listener => listener(newValue));
    }

    const subscribe = (listener: (newValue: T) => void) => {
        listeners.push(listener);
        return () => unsubscribe(listener);
    }

    const unsubscribe = (listener: (newValue: T) => void) => {
        listeners = listeners.filter(l => l !== listener);
    }
  
    return {
      get,
      set,
      subscribe,
    };
  }