import { Row, TableColumnProps } from "antd";
import ChangeTableColumns from "../TableActions/TableColumnsChanger";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { defineMessages, useIntl } from "react-intl";

type Props = {
  expandedRows: string[];
};

const RevitVersionTableColumn = (
  props: Props
): TableColumnProps<ItemPopulated> => {
  const intl = useIntl();
  const messages = defineMessages({
    revitVersion: {
      id: "component.column-changer.revit-version",
      defaultMessage: "Revit Version",
      description: "The Revit Version option of the column changer dropdown",
    },
  });

  return {
    title: (
      <Row justify="space-between" align="middle">
        <span
          style={{
            fontSize: "1rem",
            color: "#931d21",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontStyle: "normal",
            fontWeight: 600,
          }}
        >
          {intl.formatMessage(messages.revitVersion)}
        </span>
        <ChangeTableColumns currentItem="revitVersion" />
      </Row>
    ),
    dataIndex: ["properties", "familyRevitVersion"],
    key: "familyRevitVersion",
    render: (familyRevitVersion: number, record: ItemPopulated) => {
      return familyRevitVersion === 1
        ? record.properties?.revitVersion
        : familyRevitVersion;
    },
    ellipsis: true,
    onCell: (data: any, index: any) => ({
      colSpan: !!props.expandedRows.find((id) => id === data.id) ? 0 : 1,
    }),
  };
};

export default RevitVersionTableColumn;
