import { useIntl } from "react-intl";
import UpdateHeader from "../Common/ItemHeader";
import TagsDropdown from "../../Dropdowns/TagsDropdown";
import { useCreateOrUpdateMultipleFiles } from "../../../../hooks/useCreateOrUpdateMultipleFiles";
import translations from "../../../../translations/constants/translations";
import { useEffect, useState } from "react";
import { useCreateOrUpdateItemStore } from "../../../../state/createOrUpdateItemStore";

type Props = {
  mode: "single" | "multiple" | "multipleAll";
  index?: number;
};

const UpdateTags = (props: Props) => {
  const index = props.index ?? -1;

  const intl = useIntl();

  const uploads = useCreateOrUpdateItemStore((state) => state.uploads);
  const { getUpload, getItem, setItem } = useCreateOrUpdateMultipleFiles();

  const [oldGlobalValue, setOldGlobalValue] =
    useState<string[] | undefined>(undefined);
  const [globalValue, setGlobalValue] =
    useState<string[] | undefined>(undefined);

  const oldItem = getUpload(index)?.oldItem;
  const item = getItem(index);

  //this effect makes sure the globalValue is set to the union of all tagsIds of all uploads
  useEffect(() => {
    if (props.mode === "multipleAll") {
      const tags = uploads.map((upload) => {
        return upload.item?.tagsIds ?? [];
      });
      const tagsIds = tags.flat();

      //all tags combined from the inputs
      const uniqueTagsIds = Array.from(new Set(tagsIds));

      if (JSON.stringify(oldGlobalValue) !== JSON.stringify(uniqueTagsIds)) {
        setOldGlobalValue(globalValue);
        setGlobalValue(uniqueTagsIds);
      }
    }
  }, [uploads]);

  useEffect(() => {
    if (
      item?.tagsIds &&
      item?.tagsIds !== oldItem?.tagsIds &&
      props.mode !== "multipleAll"
    ) {
      setItem(index, {
        tagsIds: item?.tagsIds,
      });
    }
  }, [item?.tagsIds]);

  // Set default tags values
  const currentValue = oldItem && oldItem.tagsIds ? oldItem.tagsIds : undefined;

  const onChange = (newTags: string[]) => {
    if (props.mode !== "multipleAll") {
      setItem(index, {
        tagsIds: newTags,
      });
    } else {
      setOldGlobalValue(globalValue);
      setGlobalValue(newTags);

      //we need to update all uploads with the new tags
      useCreateOrUpdateItemStore.setState({
        uploads: useCreateOrUpdateItemStore.getState().uploads.map((upload) => {
          //anything that does not exist in the old global tags is a new tag
          const addedTags =
            newTags?.filter((t) => !oldGlobalValue?.includes(t)) ?? [];

          return {
            ...upload,
            item: {
              ...upload.item,
              tagsIds: Array.from(
                new Set(
                  (upload.item?.tagsIds ?? [])
                    .concat(addedTags ?? [])
                    .filter((tagId) => newTags.includes(tagId))
                )
              ),
            },
          };
        }),
      });
    }
  };

  //TODO: @Fady fix this didChange method
  const didChange = false && currentValue !== oldItem?.tagsIds;
  const resetChange = () => {
    //TODO: @Fady adjust this resetChange method
  };

  return (
    <>
      {props.mode === "single" && (
        <UpdateHeader
          title={intl.formatMessage(translations.ui.meta.fields.tagsName)}
          isResetable={didChange}
          onReset={resetChange}
        />
      )}
      <TagsDropdown
        type="all"
        selectProps={{
          id: "tags",
          value:
            props.mode === "multipleAll"
              ? globalValue
              : getItem(index)?.tagsIds
              ? getItem(index)?.tagsIds
              : currentValue,
          onChange: onChange,
          placeholder: intl.formatMessage(
            translations.ui.meta.fields.tagsPlaceholder
          ),
        }}
      />
    </>
  );
};

export default UpdateTags;
