import api from "../../api/api";
import { useCreateOrUpdateItemStore } from "../../state/createOrUpdateItemStore";
import { getLogger } from "../../utils/logger.utils";
import {
  UpdatingAddedFile,
  UpdatingReplacedFile,
} from "../../utils/types/updateFilesTypes";
import { useCreateOrUpdateMultipleFiles } from "../useCreateOrUpdateMultipleFiles";
import useGetFileMeta from "./useGetFileMeta";
import useUploadNewFiles from "./useUploadFinalFiles/useUploadNewFiles";

const logger = getLogger("useUploadFinalFiles");

const useUploadFinalFiles = () => {
  const { getUpload, setUpload } = useCreateOrUpdateMultipleFiles();
  const mode = useCreateOrUpdateItemStore((state) => state.mode);
  const { getName, getRole } = useGetFileMeta();
  const { uploadNewFile } = useUploadNewFiles();

  const uploadFileChanges = async (index: number) => {
    const oldItem = getUpload(index)?.oldItem;
    const updatingFiles = getUpload(index)?.updatingFiles!;

    if (mode === "create" || (mode === "update" && !oldItem)) {
      logger.error(`Invalid state for uploading file changes.`, {
        mode,
        oldItem,
      });
      return;
    }

    //check if any changes have happened
    const changedFiles = updatingFiles.filter((f) => f.state !== "default");

    if (changedFiles.length === 0) {
      logger.debug(
        `No changes to files happened so uploading is not required.`
      );
      return;
    }

    //find all state=remove files from the current item
    const filesThatRequireRemoval = changedFiles.filter(
      (f) => f.state === "removed" || f.state === "replaced"
    );

    //remove all files that require removal from the current item if it exists
    const filesAfterRemoval = (oldItem ? oldItem.files : []).filter(
      (f) =>
        !filesThatRequireRemoval.some((r) => {
          if (r.state === "replaced") {
            return r.oldFile.id === f.id;
          } else if (r.state === "removed") {
            return r.removedFile.id === f.id;
          }
        })
    );

    //only replacements or additions require and upload
    const filesThatRequireUpload = changedFiles
      .filter((f) => f.state === "added" || f.state === "replaced")
      .map<UpdatingAddedFile | UpdatingReplacedFile>(
        (f) => f as UpdatingAddedFile | UpdatingReplacedFile
      );

    logger.debug(
      `There are ${filesThatRequireRemoval.length} file(s) that required removal and ${filesThatRequireUpload.length} file(s) that require an upload.`,
      { filesThatRequireRemoval, filesThatRequireUpload, filesAfterRemoval }
    );

    //upload all files that require an upload
    const uploadedFiles = await uploadNewFile(
      index,
      filesThatRequireUpload,
      async (f) => {
        const roleResult = getRole(index, f);

        if (roleResult.result !== "success") {
          throw new Error(
            `Invalid role for file ${f.id} that requires upload.`
          );
        }
        const fileWithUrl = await api.items.createFiles(oldItem!.id, [
          {
            name: getName(f),
            role: roleResult.role,
          },
        ]);

        return fileWithUrl[0];
      }
    );

    //concat the files that are left after removal and the new updated files
    const finalFiles = filesAfterRemoval.concat(uploadedFiles);

    setUpload(index, {
      updatingFilesUploadProgress: undefined,
    });

    return finalFiles;
  };

  return {
    uploadFileChanges,
  };
};

export default useUploadFinalFiles;
