import axios from "axios";
import { ErrorResponse } from "@formitas-ag/bimfiles-types/lib";

/**
 * @throws {Error}
 */
export const errorThrower = (
  message: string,
  forwardedError?: unknown
): never => {
  if (!forwardedError) {
    throw new Error(`DirectError: ${message}`);
  } else throw new Error(`${message}: ${errorRenderer(forwardedError)}`);
};

export const errorRenderer = (forwardedError: unknown): string => {
  if (forwardedError instanceof TypeError) {
    return `TypeError: ${forwardedError.message}`;
  } else if (forwardedError instanceof RangeError) {
    return `RangeError: ${forwardedError.message}`;
  } else if (forwardedError instanceof EvalError) {
    return `EvalError: ${forwardedError.message}`;
  } else if (typeof forwardedError === "string") {
    return `StringError: ${forwardedError}`;
  } else if (axios.isAxiosError(forwardedError)) {
    console.log(forwardedError);
    if (
      forwardedError.response?.data &&
      typeof forwardedError.response.data === "object" &&
      "success" in forwardedError.response.data
    ) {
      const bimfilesError = forwardedError.response.data as ErrorResponse;
      return `BimfilesError ${bimfilesError.id}/${bimfilesError.message}`;
    } else if (
      forwardedError.response?.data &&
      typeof forwardedError.response.data === "object" &&
      "message" in forwardedError.response.data &&
      "error" in forwardedError.response.data
    ) {
      const axiosErrorResponse = forwardedError.response.data as {
        error: string;
        message: string;
        statusCode?: number;
      };
      return `BimfilesServerError ${forwardedError.message}: ${axiosErrorResponse.statusCode}/${axiosErrorResponse.error}/${axiosErrorResponse.message}`;
    }
    return `AxiosError ${forwardedError.message}${
      forwardedError.response &&
      typeof forwardedError.response.data === "string"
        ? `, data: "${forwardedError.response.data.substr(0, 512)}${
            forwardedError.response.data.length > 512 ? " (cropped)" : ""
          }"`
        : ""
    }${forwardedError.code ? `, code ${forwardedError.code}` : ""}`;
  } else if (forwardedError instanceof Error) {
    return `Error: ${forwardedError.message}`;
  } else {
    if (typeof forwardedError === "undefined") {
      return "UndefinedError";
    } else if (forwardedError === null) {
      return "NullError";
    } else if (typeof forwardedError === "object" && forwardedError !== null) {
      if (typeof (forwardedError as { message: string }).message === "string") {
        return `UnknownErrorTypeM: ${
          (forwardedError as { message: string }).message
        }`;
      } else if (
        typeof (forwardedError as { name: string }).name === "string"
      ) {
        return `UnknownErrorTypeN: ${
          (forwardedError as { name: string }).name
        }`;
      }
    } else {
      return `UnknownErrorType: ${typeof forwardedError}`;
    }
  }

  return `UnknownErrorType: ${typeof forwardedError}`;
};
