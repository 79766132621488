import {
  CloseOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { useRef } from "react";
import useFiltersStore from "../../../../../state/filtersStore";
import { defineMessages, useIntl } from "react-intl";

type CustomSortButtonProps = {
  label: string;
  sortBy: string;
};

const messages = defineMessages({
  sortAscending: {
    id: "components.custom-sort-button.sort-ascending",
    defaultMessage: "Sort Ascending",
    description: "Tooltip for sort ascending button",
  },
  sortDescending: {
    id: "components.custom-sort-button.sort-descending",
    defaultMessage: "Sort Descending",
    description: "Tooltip for sort descending button",
  },
  removeSort: {
    id: "components.custom-sort-button.remove-sort",
    defaultMessage: "Remove Sort",
    description: "Tooltip for remove sort button",
  },
});

const CustomSortButton: React.FC<CustomSortButtonProps> = ({
  label,
  sortBy,
}) => {
  const intl = useIntl();
  const sortType = useRef<"asc" | "desc" | undefined>(undefined);

  const filters = useFiltersStore((state) => state.currentFilters);
  const setFilters = useFiltersStore((state) => state.setFilters);
  const itemsState = useFiltersStore((state) => state.itemsState);

  /**
   * Gets the sort icon based on global state and local state
   * if the global state filters has sortBy equal to the sort
   * being chosen then the default ascending icon will be returned
   *
   * Otherwise, return icon based on local state
   *
   * @returns
   */
  const getSortIcon = () => {
    if (filters.sortBy !== sortBy) {
      sortType.current = undefined;
      return <SortAscendingOutlined />;
    } else {
      return sortType.current === undefined ? (
        <SortAscendingOutlined />
      ) : sortType.current === "asc" ? (
        <SortDescendingOutlined />
      ) : (
        <CloseOutlined />
      );
    }
  };

  // OnClick set local and global sorting based on sortBy
  const onClick = () => {
    if (sortType.current === undefined) {
      sortType.current = "asc";
    } else if (sortType.current === "asc") {
      sortType.current = "desc";
    } else {
      sortType.current = undefined;
    }

    setFilters({
      ...filters,
      sortBy: sortType ? sortBy : undefined,
      sortType: sortType.current,
    });
  };

  return (
    <>
      <Tooltip
        color="#f7f8fc"
        title={
          sortType.current === undefined
            ? intl.formatMessage(messages.sortAscending)
            : sortType.current === "asc"
            ? intl.formatMessage(messages.sortDescending)
            : intl.formatMessage(messages.removeSort)
        }
      >
        <Button
          onClick={onClick}
          style={{
            padding: "0px",
            fontSize: "1.1rem",
            color: "#931d21",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontStyle: "normal",
            fontWeight: 600,
          }}
          type="link"
          disabled={!(itemsState === "approved")}
        >
          {label}
          {getSortIcon()}
        </Button>
      </Tooltip>
    </>
  );
};

export default CustomSortButton;
