import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { ColumnsType } from "antd/es/table";
import RowActionDropdownColumn from "./TableColumns/RowActionDropdownColumn";
import ThumbnailTableColumn from "./TableColumns/ThumbnailTableColumn";
import UpdatedAtTableColumn from "./TableColumns/UpdatedAtTableColumn";
import TitleTableColumn from "./TableColumns/TitleTableColumn";
import TagsTableColumn from "./TableColumns/TagsTableColumn";
import CategoryTableColumn from "./TableColumns/CategoryTableColumn";
import DescriptionTableColumn from "./TableColumns/DescriptionTableColumn";
import OstCategoryTableColumn from "./TableColumns/OstCategoryTableColumn";
import RevitVersionTableColumn from "./TableColumns/RevitVersionTableColumn";
import { useItemsTableStore } from "../../../../state/itemsTableStore";

type Props = {
  expandedRows: string[];
};

const TableColumns = (props: Props): ColumnsType<ItemPopulated> => {
  const selectedColumns = useItemsTableStore((state) => state.selectedColumns);

  const columnsMap = {
    category: CategoryTableColumn(props),
    tags: TagsTableColumn(props),
    description: DescriptionTableColumn(props),
    ostCategory: OstCategoryTableColumn(props),
    revitVersion: RevitVersionTableColumn(props),
    updatedAt: UpdatedAtTableColumn(props),
  };

  const dynamicColumns: any = [
    columnsMap[selectedColumns[0]],
    columnsMap[selectedColumns[1]],
  ];

  const columns: ColumnsType<ItemPopulated> = [
    ThumbnailTableColumn(props),
    TitleTableColumn(props),
    ...dynamicColumns,
    RowActionDropdownColumn(props),
  ];

  return columns;
};

export default TableColumns;
