import { Row, Image } from "antd";
import bimfilesLogo from "../../../../assets/images/bimfiles-logo.png";
import PasswordResetForm from "./PasswordResetForm";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../api/api";
import { defineMessages, useIntl } from "react-intl";

const PasswordResetPage = () => {
  const navigate = useNavigate();
  const id = useParams().id ?? "";
  const intl = useIntl();

  const onFinish = async (password: string, passwordConfirm: string) => {
    const res = await api.users.completePasswordReset(
      id,
      password,
      passwordConfirm
    );

    navigate("/?action=account.password-reset.success");
  };

  const messages = defineMessages({
    description: {
      id: "auth.password-reset.description",
      defaultMessage:
        "Geben Sie ein Passwort ein, welches als neues Passwort für Ihren BIMfiles-Account dient.",
      description: "Description of the password reset page",
    },
    action: {
      id: "auth.password-reset.action",
      defaultMessage: "Passwort speichern",
      description: "Button for completing the password reset",
    },
  });

  return (
    <>
      <Row className="login-page-div">
        <div className="login-form-logo-div">
          <div className="bimfiles-logo-img">
            <Image
              style={{ height: "18rem", width: "18rem" }}
              src={bimfilesLogo}
              preview={false}
            ></Image>
          </div>
        </div>
        <PasswordResetForm
          onFinish={onFinish}
          messages={{
            description: intl.formatMessage(messages.description),
            action: intl.formatMessage(messages.action),
          }}
        />
      </Row>
    </>
  );
};

export default PasswordResetPage;
