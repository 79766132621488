import { Button } from "antd";
import { useNavigate } from "react-router-dom";

type Props = {
  id: string;
  selectedId: string;
  title: string;
  icon: React.ReactNode;
  onClick?: () => void;
  href?: string;
};

export default (props: Props) => {
  const navigate = useNavigate();

  const onClick = props.onClick || (() => navigate(props.href || "/"));

  return (
    <Button
      key={props.id}
      type={props.selectedId === props.id ? "primary" : "default"}
      icon={props.icon}
      size={"large"}
      style={{ width: "100%" }}
      onClick={onClick}
    >
      {props.title}
    </Button>
  );
};
