import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Tooltip, notification } from "antd";
import { useViewStore } from "../../../../../state/viewStore";
import { AddTagsForm } from "./AddTagsForm";
import { Tag } from "@formitas-ag/bimfiles-types/lib";
import { useQueryClient } from "react-query";
import api from "../../../../../api/api";
import CustomTag from "../../CustomTag";
import { defineMessages, useIntl } from "react-intl";

export type TagObjectType = Pick<Tag, "title" | "description" | "color">;

const messages = defineMessages({
  tagsCreated: {
    id: "components.add-tags-modal.tags-created",
    defaultMessage: "Tags created successfully",
    description: "Tags created notification",
  },
  tagsCreatedError: {
    id: "components.add-tags-modal.tags-created-error",
    defaultMessage: "Error creating tags",
    description: "Tags created error notification",
  },
  modalTitle: {
    id: "components.add-tags-modal.modal-title",
    defaultMessage: "Create Tags",
    description: "Title for the add tags modal",
  },
  createMore: {
    id: "components.add-tags-modal.create-more",
    defaultMessage: "Add another tag",
    description: "Create more tags button",
  },
  createTags: {
    id: "components.add-tags-modal.create-tags",
    defaultMessage: "Create {tags, plural, one {# Tag} other {# Tags}}",
    description: "Create tags button",
  },
  cancel: {
    id: "components.add-tags-modal.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button",
  },
});

export const AddTagsModal: React.FC = () => {
  const intl = useIntl();
  const defaultColor = "#931d21";
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [notificationApi, contextHolder] = notification.useNotification();
  const addTagsModal = useViewStore((state) => state.addTagsModal) || false;

  const [color, setColor] = useState<string>(defaultColor);
  const [tagsElements, setTagsElements] = useState<JSX.Element[]>([]);
  const [tagsObjects, setTagsObjects] = useState<TagObjectType[]>([]);
  const [createTagsButtonDisabled, setCreateTagsButtonDisabled] =
    useState<boolean>(true);
  const [currentTag, setCurrentTag] = useState<{
    title: string;
    description: string;
  }>({
    title: "",
    description: "",
  });

  const currentTagHasValues =
    currentTag.title &&
    currentTag.description &&
    currentTag.title !== "" &&
    currentTag.description !== "";

  const onAddAnotherTag = () => {
    if (currentTagHasValues) {
      setTagsObjects([
        ...tagsObjects,
        {
          title: currentTag.title,
          description: currentTag.description,
          color: color,
        },
      ]);

      form.resetFields();
      setCurrentTag({ title: "", description: "" });
    }
  };

  const onAddTagsObjects = async () => {
    try {
      if (currentTagHasValues) {
        await api.tags.create({
          title: currentTag.title,
          description: currentTag.description,
          color,
        });
      }
      for (let tag of tagsObjects) {
        await api.tags.create(tag);
      }
      notificationApi.success({
        message: intl.formatMessage(messages.tagsCreated),
      });
    } catch (error) {
      console.log(error);
      notificationApi.error({
        message: intl.formatMessage(messages.tagsCreatedError),
      });
    }

    // Invalidate tags query to refetch the data after being changed
    queryClient.invalidateQueries("tags");
    closeModal();
  };

  const closeModal = () => {
    form.resetFields();
    setTagsObjects([]);
    setCurrentTag({ title: "", description: "" });
    setCreateTagsButtonDisabled(true);
    useViewStore.setState({
      ...useViewStore.getState(),
      addTagsModal: false,
    });
  };

  const onValuesChange = (
    changedValues: any,
    values: { title: string; description: string }
  ) => {
    setCurrentTag({
      title: !!values.title ? values.title : "",
      description: !!values.description ? values.description : "",
    });

    if (
      tagsObjects.length > 0
        ? false
        : !values.title ||
          !values.description ||
          values.title === "" ||
          values.description === ""
    ) {
      setCreateTagsButtonDisabled(true);
    } else {
      setCreateTagsButtonDisabled(false);
    }
  };

  const removeTag = (index: number) => {
    const tempTagsObjects = tagsObjects;
    tempTagsObjects.splice(index, 1);

    const tempTagsElements = tagsElements;
    tempTagsElements.splice(index, 1);

    setTagsObjects([...tempTagsObjects]);
    setTagsElements([...tempTagsElements]);
  };

  useEffect(() => {
    let returnTags = [];
    for (let i = 0; i < tagsObjects.length; i++) {
      returnTags.push(
        <Tooltip
          key={"tag" + i}
          color="#f7f8fc"
          title={tagsObjects[i].description}
          overlayInnerStyle={{ textAlign: "center" }}
        >
          <CustomTag
            color={tagsObjects[i].color}
            label={tagsObjects[i].title}
            closable
            onClose={() => removeTag(i)}
          />
        </Tooltip>
      );
    }
    setTagsElements([...returnTags]);
    // return returnTags;
  }, [tagsObjects]);

  return (
    <Modal
      title={intl.formatMessage(messages.modalTitle)}
      centered
      open={addTagsModal}
      onOk={closeModal}
      onCancel={closeModal}
      afterClose={closeModal}
      closable={true}
      zIndex={1001}
      destroyOnClose={true}
      footer={[
        <Button
          key="createAnotherTags"
          type="default"
          onClick={onAddAnotherTag}
          disabled={currentTag.title === "" || currentTag.description === ""}
        >
          {intl.formatMessage(messages.createMore)}
        </Button>,
        <Button
          key="createTags"
          type="primary"
          onClick={onAddTagsObjects}
          disabled={createTagsButtonDisabled}
        >
          {intl.formatMessage(messages.createTags, {
            tags:
              tagsObjects.length === 0
                ? currentTagHasValues
                  ? 1
                  : 0
                : tagsObjects.length + (currentTagHasValues ? 1 : 0),
          })}
        </Button>,
        <Button key="Cancel" type="text" onClick={closeModal}>
          {intl.formatMessage(messages.cancel)}
        </Button>,
      ]}
    >
      {contextHolder}

      {currentTagHasValues ? (
        <Tooltip
          key={"currentTag"}
          color="#f7f8fc"
          title={currentTag.description}
          overlayInnerStyle={{ textAlign: "center" }}
        >
          <CustomTag
            color={color}
            label={currentTag.title!}
            closable
            onClose={() => {
              setCurrentTag({
                title: "",
                description: "",
              });
              setCreateTagsButtonDisabled(true);
              form.resetFields();
            }}
          />
        </Tooltip>
      ) : undefined}

      {tagsObjects.length > 0 ? <>{tagsElements}</> : undefined}
      <AddTagsForm
        form={form}
        tagsObjects={tagsObjects}
        setTagsObjects={setTagsObjects}
        onValuesChange={onValuesChange}
        color={color}
        setColor={setColor}
      />
    </Modal>
  );
};
