import { CloudUploadOutlined } from "@ant-design/icons";
import useCreateOrUpdateFiles from "../../../../../../hooks/useCreateOrUpdateFiles";

export const UploadMenuButton = () => {
  const { startCreate } = useCreateOrUpdateFiles();

  return (
    <CloudUploadOutlined
      onClick={() => startCreate()}
      style={{ fontSize: "2.1rem", color: "#931d21" }}
    />
  );
};
