import { CategoryRole } from "@formitas-ag/bimfiles-types/lib/permission";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import api from "../../../api/api";
import { useViewStore } from "../../../state/viewStore";
import { AbstractRoleTable, AbstractRoleTableData } from "./AbstractRoleTable";
import { Modal } from "antd";
import { useIntl } from "react-intl";
import translations from "../../../translations/constants/translations";

export const UserCategoriesModal = () => {
  const intl = useIntl();
  const userId = useViewStore((state) => state.editRoleUserId);

  const userQuery = useQuery({
    queryKey: ["users", userId],
    queryFn: () => {
      if (!userId) return Promise.resolve(undefined);
      return api.users.getOne(userId);
    },
  });

  const categoriesQuery = useQuery({
    queryKey: ["categories"],
    queryFn: () => api.categories.get(1, 10000),
  });

  const [data, setData] = useState<AbstractRoleTableData[]>([]);

  useEffect(() => {
    if (!userQuery.data || !categoriesQuery.data) return;

    const data: AbstractRoleTableData[] =
      categoriesQuery.data.data.map<AbstractRoleTableData>((category) => {
        const user = userQuery.data!;

        return {
          id: category.id,
          name: category.title,
          role:
            category.permissions.find((up) => up.userId === user.id)?.role ||
            undefined,
          overwriteRole: category.defaultPermissions,
        };
      });

    setData(data);
  }, [userQuery.data, categoriesQuery.data]);

  const updateUserPermissions = async (
    categoryId: string,
    role: CategoryRole
  ) => {
    await api.users.categoryRole(userId!, categoryId, role);

    //refetch queries
    userQuery.refetch();
    categoriesQuery.refetch();
  };

  const onAllRolesChange = async (role: CategoryRole) => {
    for (const element of data) {
      await updateUserPermissions(element.id, role);
    }
  };

  return (
    <Modal
      open={userId !== undefined}
      title={intl.formatMessage(
        translations.ui.admin.users.userCategoriesModalTitle,
        {
          preName: userQuery.data?.preName,
          name: userQuery.data?.name,
        }
      )}
      style={{ padding: "2rem", top: 20, bottom: 20 }}
      bodyStyle={{
        overflow: "auto",
        height: "70vh",
      }}
      onOk={() => useViewStore.setState({ editRoleUserId: undefined })}
      closable={true}
      onCancel={() => useViewStore.setState({ editRoleUserId: undefined })}
      footer={null}
      width={"80%"}
    >
      <AbstractRoleTable
        keyPrefix="user-categories-"
        data={data}
        onSingleRoleChange={updateUserPermissions}
        onAllRolesChange={onAllRolesChange}
        enableFeatures={{
          enableFinalPermissions: true,
          enableOverwritePermissions: true,
          enableChangingPermissions: true,
        }}
      />
    </Modal>
  );
};
