import { Button, Dropdown, Space, Tag } from "antd";
import { MenuProps } from "antd/lib/menu";
import { Search } from "@formitas-ag/bimfiles-types/lib";
import { defineMessages, FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import api from "../../../api/api";

type Props = {
  fromCategoryId?: string;
  fromTagId?: string;
  fromUserId?: string;
};

const messages = defineMessages({
  items: {
    id: "components.render.item-list.items",
    defaultMessage: "{count} Items",
    description: "Item list render message",
  },
});

/**
 * Renders a list of items from a given category, tag or user.
 */
const ItemListRender = (props: Props) => {
  const itemsQuery = useQuery({
    queryKey: ["items", JSON.stringify(props)],
    queryFn: async () => {
      const search: Search = {
        id: "",
        categoriesIds: props.fromCategoryId ? [props.fromCategoryId] : [],
        tagsIds: props.fromTagId ? [props.fromTagId] : [],
        usersIds: props.fromUserId ? [props.fromUserId] : [],
        itemText: [],
        ostCategory: [],
        placementMethod: [],
        revitVersion: [],
      };

      const items = await api.search.find(1, 1000, "approved", search);

      return items;
    },
  });

  const items: MenuProps["items"] = !itemsQuery.isSuccess
    ? []
    : itemsQuery.data.data.map((item) => {
        return {
          key: item.id,
          label: (
            <Tag style={{ width: "100%", textAlign: "center" }}>
              {item.title}
            </Tag>
          ),
        };
      });

  return (
    <Dropdown placement="bottom" menu={{ items: items }} trigger={["click"]}>
      <Space>
        <Button
          type="default"
          danger={itemsQuery.isError}
          size="middle"
          loading={itemsQuery.isLoading}
        >
          <FormattedMessage
            {...messages.items}
            values={{ count: itemsQuery.data?.totalDocuments || 0 }}
          />
        </Button>
      </Space>
    </Dropdown>
  );
};

export default ItemListRender;
