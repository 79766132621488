import { devtools } from "zustand/middleware";
import { create } from "zustand";
import { SendEncodedFilesType } from "../api/revit";
import { getLogger } from "../utils/logger.utils";

export type RevitOutput = {
  files: SendEncodedFilesType;
  parameters: object;
};

const logger = getLogger("useRevitStore");

export type RevitStore = {
  currentMode?: "import" | "export" | "exportOnEdit" | "exportOnUpdateFiles";
  setCurrentMode: (currentMode: "import" | "export") => void;

  currentError?: {
    type: "nothing-selected" | "wrong-selection" | "generic";
    content: string;
  };
  setCurrentError: (
    currentError:
      | {
          type: "nothing-selected" | "wrong-selection" | "generic";
          content: string;
        }
      | undefined
  ) => void;

  revitOutput?: Partial<RevitOutput>;
  setRevitOutput: (revitOutput: Partial<RevitOutput>) => void;
};

export const useRevitStore = create<RevitStore>()(
  devtools((set, get) => ({
    currentMode: undefined,
    setCurrentMode: (currentMode) => {
      set({ currentMode });
    },
    currentError: undefined,
    setCurrentError: (currentError) => {
      set({ currentError });
    },
    revitOutput: undefined,
    setRevitOutput: (revitOutput) => {
      set({
        revitOutput: {
          ...get().revitOutput,
          ...revitOutput,
        },
      });
    },
  }))
);

useRevitStore.subscribe((newState, oldState) => {
  if (oldState.currentMode !== newState.currentMode) {
    logger.debug(
      `currentMode changed from ${oldState.currentMode} to ${newState.currentMode}`
    );
  }
});
