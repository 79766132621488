import { ProfileDropdown } from "../HomepageComponents/ProfileDropdown/ProfileDropdown";
import { Button, Col, Image, Row, Space } from "antd";
import { SearchBar } from "./SearchBar";
import { User } from "@formitas-ag/bimfiles-types";
import logo from "../../../assets/images/logo.png";
import Can from "../../../api/ability/Can";
import { UploadMenuButton } from "../HomepageComponents/Upload/Common/MenuButton/UploadMenuButton";
import ButtonProps from "../../../UiProperties/ButtonProps";
import { CategoryRoleEnum } from "@formitas-ag/bimfiles-types/lib/permission";

interface NavbarProps {
  user?: User;
}

const navbarStyleProps = {
  // padding: "15px 20px",
  height: "75px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#f7f8fc",
};

export const Navbar = ({ user }: NavbarProps) => (
  <header>
    <Row id="navbar" style={navbarStyleProps} justify="center" align="middle">
      <Col span={5}>
        <Image
          src={logo}
          height="60px"
          width="60px"
          preview={false}
          style={{ cursor: "pointer", marginLeft: "0.4rem" }}
        />
      </Col>
      <Col span={14}>
        <Row justify="center">
          <SearchBar></SearchBar>
        </Row>
      </Col>

      <Col
        span={5}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            height: "2.8rem",
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
            marginRight: "0.4rem",
          }}
        >
          <Space>
            <Can mode="general" minimumUserRole={CategoryRoleEnum.WRITE}>
              <>
                <Button
                  type="dashed"
                  style={{
                    height: "2.7rem",
                    padding: "0rem 0.4rem 0rem 0.4rem",
                    ...ButtonProps.ghost,
                  }}
                >
                  <UploadMenuButton />
                </Button>
              </>
            </Can>
            <Button
              type="dashed"
              style={{
                height: "2.7rem",
                padding: "0rem 0.4rem 0rem 0.4rem",
                ...ButtonProps.ghost,
              }}
            >
              <ProfileDropdown />
            </Button>
          </Space>
        </div>
      </Col>
    </Row>
  </header>
);
