import { Tag } from "antd";
import { Category } from "@formitas-ag/bimfiles-types/lib";

type Props = {
  shortCode: Category["shortCode"];
};

export default ({ shortCode }: Props) => {
  return <Tag>{shortCode}</Tag>;
};
