import { useRevitStore } from "../state/revitStore";
import { getPropertiesFromParameters } from "../utils/item-parameters.utils";
import { getLogger } from "../utils/logger.utils";

const logger = getLogger("revit");

export type LoadFamilyType = MakeFileArray<
  "family" | "revit" | "sidecar" | "thumbnail" | "sidecar-automatic" | "other"
>;

export type ReceiveFamilyFileForParsingType = MakeFileArray<"family">;

export type SendEncodedFilesType = MakeFileArray<
  "family" | "sidecar-automatic" | "sidecar" | "thumbnail"
>;

type MakeFileArray<RoleType> = {
  name: string;
  role: RoleType;
  data: string;
}[];

export const revit = {
  /**
   * Get the revit object "BIMWinForm" and error out when not found
   */
  getRevitObject: function () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (BIMWinForm) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return BIMWinForm;
    } else {
      throw new ReferenceError(
        "Missing object 'BIMWinForm'. Seems like no connection to revit exists"
      );
    }
  },

  /**
   * Returns true if there is a connection to revit and the injected object exists
   */
  hasRevitObject: function () {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return BIMWinForm !== undefined;
    } catch (e) {
      return false;
    }
  },

  /**
   * Will get triggered by BROWSER when the import is starting
   */
  receiveImportTriggered: function () {
    try {
      this.getRevitObject().receiveImportTriggered();

      logger.debug(`Triggering import`);
    } catch (e) {
      logger.error(`Error while triggering import: ${e}`);
    }
  },

  /**
   * Will get triggered by BROWSER if a given family should be imported
   * structure:
   *      [{
   *          'name' -> the files name,
   *          'role' -> either 'family', 'revit', 'sidecar', 'thumbnail' or 'other' (only 'family' and 'sidecar' should be processed)
   *          'data' -> the base64 encoded file
   *      }]
   *
   * @param {Array} files the files array
   */
  loadFamily: function (files: LoadFamilyType, options = {}) {
    files = files.filter((file) => {
      return file.role && (file.role === "sidecar" || file.role === "family");
    });

    logger.debug(`Loading family`, { files, options });

    const parsedFiles = JSON.stringify(files);
    const parsedOptions = JSON.stringify(options);

    try {
      this.getRevitObject().loadFamily(parsedFiles, parsedOptions);
    } catch (e) {
      logger.error(`Error loading family: ${e}`);
    }
  },

  /**
   * Will get triggered by BROWSER when a RFA file is uploaded via plugin and requires params and a thumbnail to be generated
   * @param {Array} files just a array with one element family
   */
  receiveFamilyForParsing: function (files: ReceiveFamilyFileForParsingType) {
    const parsedFiles = JSON.stringify(files);

    logger.debug(`Sending family for parsing to revit`, { files });

    try {
      this.getRevitObject().receiveFamilyFileForParsing(parsedFiles);
    } catch (e) {
      logger.error(`Error while sending family to parse: ${e}`);
    }
  },

  /**
   * Will get triggered by REVIT with the file array
   *
   * structure:
   *      [{
   *          'name' -> the files name,
   *          'role' -> either 'family', sidecar-automatic' or 'thumbnail'
   *          'data' -> the base64 encoded file
   *      }]
   *
   * @param {String} rawFiles the json files array
   */
  sendEncodedFiles: function (rawFiles: string | "abort") {
    if (rawFiles === "abort") {
      logger.warn(`Receiving files from Revit has been aborted.`);
      return;
    }

    let files: SendEncodedFilesType = JSON.parse(rawFiles);

    files = files.map((file) => {
      if (file.role && file.role === "sidecar") {
        file.role = "sidecar-automatic";
      }

      return file;
    });

    logger.debug(`Received ${files.length} files from Revit`, files);

    useRevitStore.setState({
      revitOutput: {
        ...useRevitStore.getState().revitOutput,
        files,
      },
    });
  },

  /**
   * Will get triggered by REVIT with parameters that need to be stored in the backend
   * @param {String} parameters the paramaters to be stored (as JSON)
   */
  sendRevitParameters: function (rawParameters: string) {
    const parameters: object = JSON.parse(rawParameters);

    useRevitStore.setState({
      revitOutput: {
        ...useRevitStore.getState().revitOutput,
        parameters,
      },
    });

    logger.debug(
      `Received parameters from Revit`,
      getPropertiesFromParameters(
        parameters as { key: string; value: string }[]
      )
    );
  },

  /**
   * Will get triggered by REVIT if any error occurs
   * @param {String} type the type of error ('nothing-selected', 'wrong-selection' or 'generic')
   * @param {String} content additional content for the generic error (optional)
   */
  sendError: function (
    type: "nothing-selected" | "wrong-selection" | "generic",
    content: string = ""
  ) {
    useRevitStore.setState({
      currentError: {
        type,
        content,
      },
    });

    logger.error(`Received error from Revit`, { type, content });
  },
};
(window as any).interface = revit;

//this is fixed in revit
export const useRevit = (): typeof revit => (window as any).interface

export const getRevit = (): typeof revit => (window as any).interface