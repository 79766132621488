import FileListItem from "./FileListItem";
import { List } from "antd";
import FileListFooter from "./FileListFooter";
import { UpdatingFile } from "../../../../../utils/types/updateFilesTypes";
import useUpdateFiles from "../../../../../hooks/useCreateOrUpdateFiles";
import FileListHeader from "./FileListHeader";
import useGetFileMeta from "../../../../../hooks/useCreateOrUpdateFiles/useGetFileMeta";
import "./FileList.css";
import { useCreateOrUpdateItemStore } from "../../../../../state/createOrUpdateItemStore";

type Props = {
  mode: "single" | "multiple";
  index: number;
  files: UpdatingFile[];
};
export type FileListProps = Props;

const FileList = (props: Props) => {
  const itemMode = useCreateOrUpdateItemStore((state) => state.itemMode);
  const files = props.files.sort((a, b) => a.slot - b.slot);
  const { getProperties, getRole } = useGetFileMeta();

  const properties = getProperties(undefined, props.index);

  const { onDropLocalFileSingleUpload, onClickRevitExport } = useUpdateFiles();

  return (
    <List
      id="filelist"
      footer={
        itemMode === "multiple" ? (
          <FileListFooter
            onClickRevitExport={() => onClickRevitExport(props.index)}
            onDropLocalFiles={(fileList) =>
              onDropLocalFileSingleUpload(fileList, props.index)
            }
          />
        ) : null
      }
      header={properties ? <FileListHeader index={props.index} /> : null}
      bordered
      dataSource={files}
      renderItem={(item: Props["files"][0]) => {
        return <FileListItem file={item} index={props.index} />;
      }}
    />
  );
};

export default FileList;
