import { TableRowSelection } from "antd/es/table/interface";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { useItemsTableStore } from "../../../../../state/itemsTableStore";

const RowSelection = (): TableRowSelection<ItemPopulated> => {
  const selectedItems = useItemsTableStore((state) => state.selectedItems);
  const setSelectedItems = useItemsTableStore(
    (state) => state.setSelectedItems
  );

  return {
    selectedRowKeys: selectedItems.map((item) => {
      return item.id;
    }),
    onSelect: (
      record: ItemPopulated,
      selected: boolean,
      selectedRows: ItemPopulated[]
    ) => {
      selected
        ? setSelectedItems([
            ...selectedItems,
            { id: record.id, files: record.files },
          ])
        : setSelectedItems(
            selectedItems.filter((item) => item.id !== record.id)
          );
    },
    onSelectAll: (
      selected: boolean,
      selectedRows: ItemPopulated[],
      changeRows: any
    ) => {
      const newSelectedRows = selectedRows.slice(selectedItems.length);

      const alreadySelected: boolean = newSelectedRows.every(
        (item: ItemPopulated) => selectedItems.some((i) => i.id === item.id)
      );

      let tempSelectedItems = [];
      selected
        ? (tempSelectedItems = Array.from(
            new Set([
              ...selectedItems,
              ...newSelectedRows
                .filter(
                  (item: ItemPopulated) =>
                    !selectedItems.some((i) => i.id === item.id)
                )
                .map((item: ItemPopulated) => {
                  return { id: item.id, files: item.files };
                }),
            ])
          ))
        : (tempSelectedItems = alreadySelected
            ? selectedItems
                .filter(
                  (item) =>
                    !changeRows.some((i: ItemPopulated) => i.id === item.id)
                )
                .map((item) => ({ id: item.id, files: item.files }))
            : selectedItems.filter(
                (item) => !newSelectedRows.some((i) => i.id === item.id)
              ));
      setSelectedItems(tempSelectedItems);
    },
    checkStrictly: true,
  };
};

export default RowSelection;
