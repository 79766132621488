import { useEffect, useRef } from "react";
import { remove } from "../api/utils/endpoint.utils";

export type deleteFunction = (
  id: string,
  successCallback: (successMessage?: string) => void,
  errorCallback: (errorMessage: string) => void,
  /** additional params to send with the delete request */
  params?: any
) => void;

export default function useApiDelete(serviceName: string): [deleteFunction] {
  const ref = useRef(0);

  const deleteFunction = (
    id: string,
    successCallback: (message?: string) => void,
    errorCallback: (errorMessage: string) => void,
    /** additional params to send with the delete request */
    params?: any
  ) => {
    remove(serviceName, id)
      .then(() => {
        successCallback(`DELETED ${id}`);
      })
      .catch((reason) => {
        let messageText;
        if (reason.response && reason.response.data) {
          messageText = reason.response.data;
        } else {
          messageText = reason;
        }

        errorCallback("failed: " + messageText);
      });
  };

  useEffect(() => {
    ref.current = 1;

    return () => {
      // self awareness of being unmounted. not used currently, but might be good to
      // have when we implement deleting state etc.
      ref.current = 0;
    };
  }, []);

  return [deleteFunction];
}
