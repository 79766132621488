import { Button } from "antd";
import { useItemsTableStore } from "../../../../state/itemsTableStore";
import { useIntl } from "react-intl";
import translations from "../../../../translations/constants/translations";

export const DeselectSelectedItemsButton = () => {
  const intl = useIntl();
  const selectedItems = useItemsTableStore((state) => state.selectedItems);
  const setSelectedItems = useItemsTableStore(
    (state) => state.setSelectedItems
  );

  return (
    <Button onClick={() => setSelectedItems([])}>
      {intl.formatMessage(
        translations.ui.itemTable.deselectSelectedItemsButtonTitle,
        {
          items: selectedItems.length,
        }
      )}
    </Button>
  );
};
