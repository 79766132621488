import { LoadFamilyType, getRevit } from "../../../../api/revit";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Item } from "@formitas-ag/bimfiles-types";
import { useCallback, useRef, useState } from "react";
import { Buffer } from "buffer";
import { TypeCatalogModal } from "./TypeCatalogModal";
import { Button, notification, Tooltip } from "antd";
import { defineMessages, useIntl } from "react-intl";
import api from "../../../../api/api";
import axios from "axios";
import useSidecarFiles from "../../../../hooks/useSidecarFiles";
import ButtonProps from "../../../../UiProperties/ButtonProps";
import { getLogger } from "../../../../utils/logger.utils";
import translations from "../../../../translations/constants/translations";

type Props = {
  id: Item["id"];
  files: Item["files"];
  disabled?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  importAnyway?: boolean;
};

const logger = getLogger("ImportToRevitButton");

const ImportToRevitButton = (props: Props) => {
  const intl = useIntl();
  const [modalVisible, setModalVisible] = useState(false);
  const filesWithBase64 = useRef<LoadFamilyType>([]);
  const { cleanup, hasWhichSidecar, load, loading, sidecarData } =
    useSidecarFiles({
      source: {
        id: props.id,
        type: "default",
        files: props.files,
      },
      setModalOpen: setModalVisible,
    });

  // Check if the item is of type family
  // to enable or disable the button
  const hasFamily: boolean =
    props.files.find((file) => file.role === "family") !== undefined
      ? true
      : false;

  const clearImportHandler = useCallback(() => {
    cleanup();
    setModalVisible(false);
    filesWithBase64.current = [];
  }, []);

  const onClick = async (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();

    const files = await Promise.all(
      props.files.map((file) => api.items.getFileWithUrl(props.id, file.id, {
        includeUnapproved: true,
      }))
    );

    logger.debug(`Importing ${files.length} files into Revit.`, {
      files,
    });

    for (const fileWithUrl of files) {
      const response = await axios.get(fileWithUrl.url, {
        responseType: "arraybuffer",
        responseEncoding: "binary",
      });

      const newFile: LoadFamilyType[number] = {
        name: fileWithUrl.name,
        role: fileWithUrl.role,
        data: response.data,
      };

      const base64: LoadFamilyType[number] = {
        ...newFile,
        data: Buffer.from(response.data).toString("base64"),
      };

      filesWithBase64.current.push(base64);
    }

    const hasCustomSidecar = hasWhichSidecar.custom;
    const hasFamilyWithoutSidecarOrAutomaticSidecar =
      files.some((file) => file.role === "family") &&
      (hasWhichSidecar.automaticOrCustom || !hasWhichSidecar.custom);

    try {
      if (
        hasCustomSidecar ||
        (hasFamilyWithoutSidecarOrAutomaticSidecar && props.importAnyway)
      ) {
        //a custom sidecar is available or importing is forced by UI
        logger.debug(
          `Family '${
            props.files.find((file) => file.role === "family")?.name
          }' has sidecar files requiring type selection.`,
          {
            hasWhichSidecar,
          }
        );
        load();
      } else if (hasFamilyWithoutSidecarOrAutomaticSidecar) {
        logger.debug(
          `Family '${
            props.files.find((file) => file.role === "family")?.name
          }' does not require type selection.`
        );
        getRevit().loadFamily(filesWithBase64.current);
      } else {
        logger.debug(
          `Couldn't import into Revit as no family file or custom sidecar was found.`,
          {
            files,
            hasCustomSidecar,
            hasFamilyWithoutSidecarOrAutomaticSidecar,
          }
        );
      }
    } catch (e) {
      notification.error({
        message: intl.formatMessage(
          translations.ui.itemTable.downloadItems.importToRevitFailedMessage,
          { message: "" + e }
        ),
      });
      logger.error(
        `Failed to import family '${
          props.files.find((file) => file.role === "family")?.name
        }': ${e}`,
        {
          error: e,
          files: props.files,
        }
      );
    }
  };

  return (
    <>
      <Tooltip
        color="#f7f8fc"
        title={
          hasFamily
            ? intl.formatMessage(
                translations.ui.itemTable.downloadItems.importToRevitTooltip
              )
            : intl.formatMessage(
                translations.ui.itemTable.downloadItems
                  .importToRevitNotAvailableTooltip
              )
        }
        overlayInnerStyle={{ textAlign: "center" }}
      >
        <Button
          ref={props.buttonRef}
          icon={
            <CloudDownloadOutlined
              style={{
                fontSize: "24px",
                padding: "1px 2px 1px 2px",
                color: "#931d21",
              }}
            />
          }
          type="dashed"
          style={ButtonProps.ghost}
          loading={loading}
          disabled={!hasFamily || props.disabled}
          onClick={onClick}
        />
      </Tooltip>

      <TypeCatalogModal
        type="select"
        sidecarData={sidecarData}
        sidecarType={hasWhichSidecar.custom ? "sidecar" : "sidecar-automatic"}
        visible={modalVisible}
        filesWithBase64={filesWithBase64.current}
        clearImportHandler={clearImportHandler}
      />
    </>
  );
};

export default ImportToRevitButton;
