import { Tag, Typography } from "antd";
import { ParametersAndPropertiesChange } from "@formitas-ag/bimfiles-types";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import TimelineChangeWrapper from "./Common/TimelineChangeWrapper";

type Props = {
  change: ParametersAndPropertiesChange;
};

const TimelineParametersAndPropertiesChange = (props: Props) => {
  const intl = useIntl();

  if (!props.change.properties) return <></>;

  const messages = defineMessages({
    title: {
      id: "component.timeline.parameters.title",
      defaultMessage: "New Revit Parameters",
      description: "Title of the changed parameters",
    },
    revit: {
      id: "component.timeline.parameters.revit",
      defaultMessage: "Revit",
      description: "Revit version element in the properties table",
    },
    family: {
      id: "component.timeline.parameters.family",
      defaultMessage: "Family",
      description: "Family element in the properties table",
    },
    category: {
      id: "component.timeline.parameters.category",
      defaultMessage: "Category",
      description: "Category element in the properties table",
    },
    placement: {
      id: "component.timeline.parameters.placement",
      defaultMessage: "Placement",
      description: "Placement element in the properties table",
    },
  });

  return (
    <TimelineChangeWrapper
      key={props.change.id}
      titleChild={
        <Typography.Text>
          <FormattedMessage {...messages.title} />
        </Typography.Text>
      }
    >
      <Tag>{`${intl.formatMessage(messages.revit)}: ${
        props.change.properties.familyRevitVersion === 1
          ? props.change.properties.revitVersion
          : props.change.properties.familyRevitVersion
      }`}</Tag>
      <Tag>{`${intl.formatMessage(messages.family)}: ${
        props.change.properties.familyName
      }`}</Tag>
      <Tag>{`${intl.formatMessage(messages.category)}: ${
        props.change.properties.ostCategory
      }`}</Tag>
      <Tag>{`${intl.formatMessage(messages.placement)}: ${
        props.change.properties.placementMethod
      }`}</Tag>
    </TimelineChangeWrapper>
  );
};

export default TimelineParametersAndPropertiesChange;
