import { UpdateItemData } from "@formitas-ag/bimfiles-types/lib/item";
import {
  UploadType,
  useCreateOrUpdateItemStore,
} from "../state/createOrUpdateItemStore";

export const useCreateOrUpdateMultipleFiles = () => {
  const getItem = (index: number) => {
    const upload = getUpload(index);

    if (!upload) return undefined;

    return upload.item;
  };

  const getUpload = (index: number) => {
    const searchIndex = index === -1 ? 0 : index;

    return useCreateOrUpdateItemStore
      .getState()
      .uploads.find((u) => u.index === searchIndex);
  };

  const setItem = (index: number, item?: Partial<UpdateItemData>) => {
    const upload = getUpload(index);

    if (!upload) return;

    setUpload(index, {
      item: {
        ...upload.item,
        ...item,
      },
    });
  };

  const setUpload = (index: number, partialUpload: Partial<UploadType>) => {
    const uploads = useCreateOrUpdateItemStore.getState().uploads;
    if (index == -1) {
      useCreateOrUpdateItemStore.setState({
        uploads: [
          {
            ...uploads[0],
            ...partialUpload,
          },
        ],
      });
    } else {
      useCreateOrUpdateItemStore.setState({
        uploads: uploads.map((upload) => {
          if (upload.index === index) {
            return {
              ...upload,
              ...partialUpload,
            };
          } else {
            return upload;
          }
        }),
      });
    }
  };

  return {
    getUpload,
    setUpload,
    getItem,
    setItem,
  };
};
