import { useCreateOrUpdateItemStore } from "../../state/createOrUpdateItemStore";

const getNextFreeIndex = () => {
  const latestIndex = useCreateOrUpdateItemStore
      .getState()
      .uploads.filter((u) => u.updatingFiles.length > 0)
      .sort((a, b) => b.index - a.index)[0]?.index ?? -1

      //latestIndex -1 means the only upload was in itemMode=single
      //we therefor return 2 as the next free index to keep a space to move the single upload to index 1
      return latestIndex === -1 ? 2 : latestIndex + 1;
};

export default getNextFreeIndex;
