import { Col, Row } from "antd";
import { Navbar } from "../../Navbar/Navbar";
import { ItemsTable } from "../ItemsTable/ItemsTable";
import { AddTagsModal } from "../ItemsTable/AddTags/AddTagsModal";
import TimelineModal from "../../Timeline/Modal/TimelineModal";
import DeleteItemModal from "../DeleteItem/DeleteItemModal";
import RequiresRevitModal from "../../Modal/RequiresRevitModal";
import ExpandableFilters from "../Filters/ExpandableFilters";
import UpdateItemModal from "../../CreateOrUpdateItem/CreateOrUpdateItemModal";
import { CreateOrUpdateMultipleFilesModal } from "../../CreateOrUpdateItem/Modal/CreateOrUpdateMultipleFilesModal";
import DenyItemModal from "../../Modal/DenyItemModal";

const Homepage = () => {
  return (
    <div>
      <Navbar></Navbar>
      <Row align="middle" style={{ marginTop: "0.5rem", width: "100%" }}>
        <Col offset={1} span={22}>
          <ExpandableFilters />
        </Col>
      </Row>
      <AddTagsModal />
      <TimelineModal />
      <UpdateItemModal />
      <CreateOrUpdateMultipleFilesModal />
      <DeleteItemModal />
      <RequiresRevitModal />
      <DenyItemModal />

      <Row>
        <Col offset={1} span={22}>
          <ItemsTable></ItemsTable>
        </Col>
      </Row>
    </div>
  );
};

export default Homepage;
