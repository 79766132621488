import { useEffect, useState } from "react";

const useDivHeight = (id: string) => {
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const element = document.getElementById(id);
      if (element) {
        setHeight(element.offsetHeight);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [id]);

  return height;
};
export default useDivHeight;
