import useTimeline from "../../../hooks/useTimeline";
import TimelineGroup from "./Group/TimelineGroup";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";

type Props = {
  item: ItemPopulated;
};

const Timeline = (props: Props) => {
  const changes = useTimeline(props.item);

  return (
    <div>
      {changes.map((changes, index, array) => {
        const at = new Date(changes[0].at);
        const by = changes[0].by;
        const state = changes[0].state;

        const style =
          index < array.length - 1 ? { marginBottom: "1.5rem" } : {};

        return (
          <div style={style}>
            <TimelineGroup
              key={`timeline-group-change-${index}`}
              item={props.item}
              by={by}
              at={at}
              state={state}
              changes={changes}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Timeline;
