import React from "react";
import { ColorResult, ChromePicker } from "react-color";
import "react-color/lib/components/sketch/Sketch";
import CustomTag from "../../CustomTag";
// import "react-color/lib/components/sketch/SketchPointer";

type ColorPickerProps = {
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
  defaultColor: string;
};

export const ColorPicker: React.FC<ColorPickerProps> = ({
  color,
  setColor,
  defaultColor,
}) => {
  const handleChangeComplete = (color: ColorResult) => {
    setColor(color.hex);
  };

  const onChange = (color: any, something2: any) => {
    setColor(color.hex);
  };

  return (
    <>
      <CustomTag
        color={color}
        label={color}
        style={{ width: "225px", textAlign: "center" }}
      />
      <ChromePicker
        color={color ? color : defaultColor}
        onChangeComplete={handleChangeComplete}
        onChange={onChange}
        disableAlpha
      />
    </>
  );
};
