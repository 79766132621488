import { Button, Col, Row } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import { emptyFilters } from "../../../state/filtersStore";
import { RowActionDropdown } from "../HomepageComponents/RowActionDropdown/RowActionDropdown";
import { ItemImage } from "../HomepageComponents/ItemImage";
import { FormattedDate } from "react-intl";
import { useQuery } from "react-query";
import ExpandedTableItemContent from "../HomepageComponents/ExpandedTableItem/ExpandedTableItemContent";
import ButtonProps from "../../../UiProperties/ButtonProps";
import api from "../../../api/api";

const ItemPage = () => {
  const navigate = useNavigate();
  const { itemId } = useParams();
  const [itemPopulated, setItemPopulated] =
    useState<ItemPopulated | undefined>(undefined);

  useQuery(["itemPage", itemId], async () => {
    const items = await api.search.find(1, 10, "approved", {
      ...emptyFilters,
      id: itemId!,
    });
    setItemPopulated(items.data[0]);
  });

  const toHomepage = () => {
    navigate("/homepage");
  };

  return (
    <div
      style={{
        borderStyle: "solid",
        borderWidth: "5px",
        margin: "0.5rem 8rem 0.5rem 8rem",
        paddingBottom: "1rem",
        borderColor: "#f7f8fc",
        borderRadius: "8px",
      }}
    >
      <Row
        style={{
          margin: "0.5rem 8% 0.5rem 8%",
        }}
      >
        <Col span={12}>
          <Button
            type="dashed"
            icon={
              <ArrowLeftOutlined
                style={{
                  fontSize: "18px",
                }}
              />
            }
            onClick={toHomepage}
            style={{
              fontWeight: "600",
              color: "#931d21",
              fontSize: "18px",
              ...ButtonProps.ghost,
            }}
          >
            Homepage
          </Button>
        </Col>

        <Col span={12}>
          <Row justify="end">
            {itemPopulated && (
              <RowActionDropdown item={itemPopulated} showButton />
            )}
          </Row>
        </Col>
      </Row>

      <Row justify="center" style={{ marginTop: "-1.8rem" }}>
        <h1>{itemPopulated?.title}</h1>{" "}
      </Row>
      <Row
        justify="center"
        style={{
          marginBottom: "2rem",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "15px",
          height: "14px",
          color: "rgba(112, 131, 143, 0.75)",
        }}
      >
        <FormattedDate
          value={itemPopulated ? itemPopulated.updatedAt : ""}
          day="2-digit"
          month="2-digit"
          year="numeric"
        />
      </Row>

      <Row style={{ marginTop: "0.5rem" }}>
        <Col offset={2} span={20}>
          {itemPopulated && (
            <div>
              <Row justify="center" align="middle" style={{ height: "100%" }}>
                <ItemImage
                  item={itemPopulated}
                  customStyle={{
                    margin: "auto",
                    maxHeight: `350px`,
                    width: `100%`,
                  }}
                  iconSize="large"
                ></ItemImage>
              </Row>
              <Row justify="center" style={{ marginTop: "0.5rem" }}>
                <Col span={24}>
                  <ExpandedTableItemContent
                    dataSource={itemPopulated}
                    hideTitle={true}
                  ></ExpandedTableItemContent>
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ItemPage;
