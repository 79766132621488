import { useState } from "react";
import { rootQueryClient } from "..";
import { useViewStore } from "../state/viewStore";
import { ItemPopulated } from "@formitas-ag/bimfiles-types/lib/item";
import api from "../api/api";

export const useApproveOrDeny = (item: ItemPopulated) => {
  const [loading, setLoading] = useState<boolean>(false);

  const setStoreData = () => {
    useViewStore.setState({
      ...useViewStore.getState(),
      selectedItemToBeDenied: item,
    });
  };

  const approveOrDenyItem = async (approve: boolean) => {
    setLoading(true);

    if (approve) {
      await api.items.approve(item.id, approve);

      await invalidateQueries();
    } else {
      setStoreData();
    }

    setLoading(false);
  };

  // We use loop here because we want to have the first change to be approved
  // before the next one is. Using map will cause a write conflict
  const approveChangesLoop = async (changes: ItemPopulated["changes"]) => {
    for (const change of changes) {
      if (change.state === "approvable")
        await api.items.changes.approve(item.id, change.id);
    }
  };

  const approveOrDenyAllChanges = async (approve: boolean) => {
    setLoading(true);

    approve
      ? item.changes &&
        (await approveChangesLoop(item.changes).then(
          async () => await invalidateQueries()
        ))
      : setStoreData();

    setLoading(false);
  };

  const approveOrDeny = (approve: boolean) => {
    if (item.state === "approvable") {
      approveOrDenyItem(approve);
    } else {
      approveOrDenyAllChanges(approve);
    }
  };

  const invalidateQueries = async () => {
    // Wait 250 ms to make sure that the item has been
    // updated and the new updated data will be retrieved
    setTimeout(async () => {
      //invalidate items query
      await rootQueryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "items",
      });
    }, 250);
  };

  return {
    approveOrDeny,
    loading,
    setLoading,
    invalidateQueries,
  };
};
