import { Tag, Tooltip, Typography } from "antd";
import { CategoryChange } from "@formitas-ag/bimfiles-types";
import { defineMessages, FormattedMessage } from "react-intl";
import { useQuery } from "react-query";
import api from "../../../../api/api";
import TimelineChangeWrapper from "./Common/TimelineChangeWrapper";

type Props = {
  change: CategoryChange;
};

export default (props: Props) => {
  const categoryQuery = useQuery({
    queryKey: ["category", props.change.categoryId],
    queryFn: async () => {
      return await api.categories.getOne(props.change.categoryId);
    },
  });

  const messages = defineMessages({
    title: {
      id: "component.timeline.category.title",
      defaultMessage: "New Category",
      description: "Title of a category change",
    },
    loading: {
      id: "component.timeline.category.loading",
      defaultMessage: "Loading category...",
      description: "Loading message when the category is loading",
    },
    error: {
      id: "component.timeline.category.error",
      defaultMessage: "Category could not be loaded",
      description: "Error message when the category could not be loaded",
    },
  });

  return (
    <TimelineChangeWrapper
      key={props.change.id}
      titleChild={
        <Typography.Text>
          <FormattedMessage {...messages.title} />
        </Typography.Text>
      }
    >
      {categoryQuery.isLoading && (
        <span>
          <FormattedMessage {...messages.loading} />
        </span>
      )}
      {categoryQuery.isError && (
        <span>
          <FormattedMessage {...messages.error} />
        </span>
      )}
      {categoryQuery.isSuccess && (
        <span>
          <Tooltip
            color="#f7f8fc"
            title={categoryQuery.data.description}
            overlayInnerStyle={{ textAlign: "center" }}
          >
            <Tag color={categoryQuery.data.color} style={{ color: "black" }}>
              {categoryQuery.data.title}
            </Tag>
          </Tooltip>
        </span>
      )}
    </TimelineChangeWrapper>
  );
};
