import { Item } from "@formitas-ag/bimfiles-types/lib";

export const getPropertiesFromParameters = (
  parameters: { key: string; value: string }[]
): Partial<Item["properties"]> => {
  const properties: Partial<Item["properties"]> = {};

  const mapped: { [key: string]: string } = parameters.reduce(
    (total, current) => {
      return {
        ...total,
        [current.key]: current.value,
      };
    },
    {}
  );

  if (mapped["Familientyp"]) properties.ostCategory = mapped["Familientyp"];
  if (mapped["Revit-Version"])
    properties.revitVersion = Number.parseInt(mapped["Revit-Version"]);
  if (mapped["Familien-Revit-Version"])
    properties.familyRevitVersion = Number.parseInt(
      mapped["Familien-Revit-Version"]
    );
  if (mapped["Familienname"]) properties.familyName = mapped["Familienname"];
  if (mapped["Platzierungsmethod"])
    properties.placementMethod = mapped["Platzierungsmethod"];

  return properties;
};
