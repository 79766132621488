import { Row } from "antd";
import ChangeTableColumns from "../TableActions/TableColumnsChanger";
import { defineMessages, useIntl } from "react-intl";

type Props = {
  expandedRows: string[];
};

const CategoryTableColumn = (props: Props) => {
  const intl = useIntl();
  const messages = defineMessages({
    library: {
      id: "component.column.library",
      defaultMessage: "Library",
      description: "The library option of the column changer dropdown",
    },
  });

  return {
    title: (
      <Row justify="space-between" align="middle">
        <span
          style={{
            fontSize: "1rem",
            color: "#931d21",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontStyle: "normal",
            fontWeight: 600,
          }}
        >
          {intl.formatMessage(messages.library)}
        </span>
        <ChangeTableColumns currentItem="category" />
      </Row>
    ),
    dataIndex: ["category", "title"],
    key: "category",
    ellipsis: true,
    onCell: (data: any, index: any) => ({
      colSpan: !!props.expandedRows.find((id) => id === data.id) ? 0 : 1,
    }),
  };
};

export default CategoryTableColumn;
