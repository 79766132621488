import {
  Alert,
  Button,
  Checkbox,
  Form,
  Image,
  Input,
  Row,
  Spin,
  Typography,
} from "antd";
import "../Authentication/LoginPage/login-page.css";
import bimfilesLogo from "../../../assets/images/bimfiles-logo.png";
import { useState } from "react";
import { useIntl } from "react-intl";
import translations from "../../../translations/constants/translations";
import api from "../../../api/api";
import { Link } from "react-router-dom";

const DemoPage = () => {
  const intl = useIntl();

  const [formState, setFormState] =
    useState<undefined | "loading" | "finished" | "failed">(undefined);

  const onFinish = async (values: any) => {
    setFormState("loading");

    try {
      const email = values.email as string;
      const fullName = values.fullName as string;
      const joinNewsletter = values.joinNewsletter as boolean;

      const result = await api.auth.requestDemoAccess(
        email,
        fullName,
        joinNewsletter,
        intl.locale === "en" ? "en" : "de"
      );

      if (!result) {
        setFormState("failed");
        return;
      }

      setFormState("finished");
    } catch (error) {
      setFormState("failed");
      console.log(error);
    }
  };

  return (
    <Row className="login-page-div">
      <div className="login-form-logo-div">
        <div className="bimfiles-logo-img">
          <Link to="/">
            <Image
              style={{ height: "18rem", width: "18rem" }}
              src={bimfilesLogo}
              preview={false}
            ></Image>
          </Link>
        </div>

        <div style={{ margin: "0rem 1rem 0rem 1rem" }}>
          {formState === "finished" && (
            <Alert
              description={intl.formatMessage(
                translations.ui.authentication.demoAccessSuccess
              )}
              type="success"
              showIcon
            />
          )}

          {formState !== "finished" && (
            <>
              {formState === "failed" && (
                <>
                  <Alert
                    message={intl.formatMessage(
                      translations.ui.authentication.demoAccessFailed
                    )}
                    type="error"
                  />
                  <br />
                </>
              )}
              <Form
                name="basic"
                layout="vertical"
                initialValues={{}}
                onFinish={onFinish}
                autoComplete="off"
                disabled={formState === "loading"}
              >
                <Form.Item>
                  <div
                    style={{
                      width: "100%",
                      background: "#fff",
                      fontSize: "1.2rem",
                      padding: "1rem",
                      marginBottom: "0.4rem",
                    }}
                  >
                    <Typography.Text type="secondary">
                      {intl.formatMessage(
                        translations.ui.authentication.demoDescription
                      )}
                    </Typography.Text>
                  </div>
                </Form.Item>

                <Typography.Text
                  style={{
                    color: "#fff",
                    fontSize: "1rem",
                  }}
                >
                  {intl.formatMessage(
                    translations.ui.authentication.fieldFullNameLabel
                  )}
                </Typography.Text>

                <Form.Item
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage(
                        translations.ui.authentication
                          .fieldFullNameValidationRequired
                      ),
                    },
                    {
                      min: 2,
                      message: intl.formatMessage(
                        translations.ui.authentication
                          .fieldFullNameValidationIncorrectLength
                      ),
                    },
                    {
                      max: 100,
                      message: intl.formatMessage(
                        translations.ui.authentication
                          .fieldFullNameValidationIncorrectLength
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    style={{ borderRadius: 0, height: "3rem" }}
                    placeholder={intl.formatMessage(
                      translations.ui.authentication.fieldFullNamePlaceholder
                    )}
                    spellCheck={false}
                  />
                </Form.Item>

                <Typography.Text
                  style={{
                    color: "#fff",
                    fontSize: "1rem",
                  }}
                >
                  {intl.formatMessage(
                    translations.ui.authentication.fieldEmailLabel
                  )}
                </Typography.Text>

                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage(
                        translations.ui.authentication
                          .fieldEmailValidationRequired
                      ),
                    },
                    {
                      // eslint-disable-next-line
                      pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      message: intl.formatMessage(
                        translations.ui.authentication
                          .fieldEmailValidationFormat
                      ),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    style={{ borderRadius: 0, height: "3rem" }}
                    placeholder={intl.formatMessage(
                      translations.ui.authentication.fieldEmailPlaceholder
                    )}
                    spellCheck={false}
                  />
                </Form.Item>

                <Form.Item
                  name="joinNewsletter"
                  valuePropName="checked"
                  style={{ marginBottom: "1rem" }}
                >
                  <Checkbox
                    style={{ color: "#fff", fontSize: "0.95rem" }}
                    checked={true}
                  >
                    {intl.formatMessage(
                      translations.ui.authentication.fieldJoinNewsletterLabel
                    )}
                  </Checkbox>
                </Form.Item>

                <div className="login-form-button-spinner">
                  <Form.Item>
                    <Button
                      size="large"
                      type="text"
                      style={{
                        height: "3rem",
                        width: "100%",
                        border: "0.2rem solid #fff",
                        borderRadius: 0,
                        color: "#fff",
                        fontSize: "1.2rem",
                        textTransform: "uppercase",
                      }}
                      htmlType="submit"
                      disabled={formState === "loading"}
                      loading={formState === "loading"}
                    >
                      {intl.formatMessage(
                        translations.ui.authentication.demoAction
                      )}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
              )
            </>
          )}
        </div>
      </div>
    </Row>
  );
};

export default DemoPage;
