import "core-js/stable";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { IntlProvider } from "react-intl";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { isRevit } from "./hooks/usePlatform";
import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from "@ant-design/cssinjs";
import { useLanguageStore } from "./state/languageStore";
import { ConfigProvider } from "antd";
import { revit } from "./api/revit";

import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? undefined,
  release: process.env.REACT_APP_SENTRY_RELEASE ?? undefined,
  dsn: process.env.REACT_APP_SENTRY_DSN ?? undefined,
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.01,
  sampleRate: 1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["staging.bimfiles.de", "app.bimfiles.de"],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  // CURRENTLY DISABLED FOR LEGAL REASONS
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const loadLanguage = (code: string): Record<string, string> => {
  return require(`./translations/locales/${code}.json`);
};

const loadLanguages = (codes: string[]) => {
  return codes.reduce((acc, code) => {
    acc[code] = loadLanguage(code);
    return acc;
  }, {} as Record<string, Record<string, string>>);
};

const languages = loadLanguages(["en", "de"]);

// this is a fix until we are able to receive the configured language from revit
// for now as long as the language is not selected by user it is set to "de" (German)
if (
  isRevit(navigator.userAgent) &&
  useLanguageStore.getState().type === "default"
) {
  useLanguageStore.setState({ language: "de", type: "default" });
}

(window as any).interface = revit;

export const rootQueryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

root.render(
  <React.StrictMode>
    <ConfigProvider theme={{ token: { colorPrimary: "#931d21" } }}>
      <StyleProvider
        hashPriority="high"
        transformers={[legacyLogicalPropertiesTransformer]}
      >
        <IntlProvider
          locale={useLanguageStore.getState().language}
          messages={languages[useLanguageStore.getState().language]}
          defaultLocale="en"
          onError={(err) => {
            console.warn(
              `[IntlProvider Error ${err.name}] ${err.message}`,
              err
            );
          }}
        >
          <QueryClientProvider client={rootQueryClient}>
            <App />
            {/* <ReactQueryDevtools /> */}
          </QueryClientProvider>
        </IntlProvider>
      </StyleProvider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
