import { defineMessages } from "react-intl";
import filters from "./filters";
import rowAction from "./rowAction";
import downloadItems from "./downloadItems";

const itemTable = defineMessages({
  deselectSelectedItemsButtonTitle: {
    id: "ui.item-table.deselected-selected-items-button-title",
    defaultMessage: "Deselect {items, plural, one {# Item} other {# Items}}",
    description: "Title for the deselect items button",
  },
  batchUpdateButtonTitle: {
    id: "ui.item-table.batch-update-button-title",
    defaultMessage: "Update All",
    description: "Title for the batch update button",
  },
  batchDownloadButtonTitle: {
    id: "ui.item-table.batch-download-button-title",
    defaultMessage: "Download All",
    description: "Title for the batch download button",
  },
  batchDownloadStatusMessage: {
    id: "ui.item-table.batch-download-status-message",
    defaultMessage:
      "Downloading {completedItems} of {totalItems, plural, one {# Item} other {# Items}}",
    description: "Status message for batch download",
  },
});

export default {
  ...itemTable,
  filters,
  rowAction,
  downloadItems,
};
