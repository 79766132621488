import {
  CategoryChange,
  Change,
  ChangeDescriptionChange,
  DeleteChange,
  DescriptionChange,
  FileChange,
  Item,
  ParametersAndPropertiesChange,
  TagChange,
  TitleChange,
} from "@formitas-ag/bimfiles-types";
import { getChangeType } from "../../../../utils/change-type.utils";
import { ReasonChange } from "@formitas-ag/bimfiles-types/lib/change";
import TimelineCategoryChange from "./TimelineCategoryChange";
import TimelineChangeDescriptionChange from "./TimelineChangeDescriptionChange";
import TimelineDeleteChange from "./TimelineDeleteChange";
import TimelineDescriptionChange from "./TimelineDescriptionChange";
import TimelineFileChange from "./TimelineFileChange";
import TimelineParametersAndPropertiesChange from "./TimelineParametersAndPropertiesChange";
import TimelineTagChange from "./TimelineTagChange";
import TimelineTitleChange from "./TimelineTitleChange";
import TimelineReasonChange from "./TimelineReasonChange";

type Props = {
  changes: Change[];
  item: Item;
};

const TimelineChanges = (props: Props) => {
  return (
    <>
      {props.changes.map((change: Change) => {
        const type = getChangeType(change);

        switch (type) {
          case "TitleChange":
            return (
              <TimelineTitleChange
                key={change.id}
                change={change as TitleChange}
              />
            );
          case "DescriptionChange":
            return (
              <TimelineDescriptionChange
                key={change.id}
                change={change as DescriptionChange}
              />
            );
          case "CategoryChange":
            return (
              <TimelineCategoryChange
                key={change.id}
                change={change as CategoryChange}
              />
            );
          case "ChangeDescriptionChange":
            return (
              <TimelineChangeDescriptionChange
                key={change.id}
                change={change as ChangeDescriptionChange}
              />
            );
          case "DeleteChange":
            return (
              <TimelineDeleteChange
                key={change.id}
                change={change as DeleteChange}
              />
            );
          case "FileChange":
            return (
              <TimelineFileChange
                key={change.id}
                change={change as FileChange}
                item={props.item}
              />
            );
          case "ParametersAndPropertiesChange":
            return (
              <TimelineParametersAndPropertiesChange
                key={change.id}
                change={change as ParametersAndPropertiesChange}
              />
            );
          case "TagChange":
            return (
              <TimelineTagChange key={change.id} change={change as TagChange} />
            );
          case "ReasonChange":
            return (
              <TimelineReasonChange
                key={change.id}
                change={change as ReasonChange}
              />
            );
        }

        return <></>;
      })}
    </>
  );
};

export default TimelineChanges;
